<template>
  <div class="mt-auto">
    <!-- Divider -->
    <div class="flex justify-center px-4 sm:px-12 py-3">
      <div class="w-10/12 h-px bg-grayscale-500"></div>
    </div>

    <!-- Footer -->
    <p class="text-center text-sm sm:text-base font-normal leading-5 text-blue-800 font-sans px-4 sm:px-12 pb-6" style="text-wrap: balance;">
      Visit createacademy.com and apply your voucher code at the checkout. This voucher is valid for 12 months from the date of purchase.
      <template v-if="isMembershipGift">
        Subscription starts when code is redeemed.
      </template>
    </p>
  </div>
</template>

<script>
export default {
  name: 'GiftFooter',
  props: {
    isMembershipGift: {
      type: Boolean,
      default: false
    }
  }
}
</script> 