const saleTag = {
  computed: {
    showSaleTag() {
      return this.$page.props.saleTag?.active
    },

    saleTag() {
      return this.$page.props.saleTag
    },

    backgroundColor() {
      return `bg-${this.$page.props.saleTag?.backgroundColor}`
    },

    textColor() {
      return `text-${this.$page.props.saleTag?.textColor}`
    }
  }
}

export default saleTag
