export const inputErrors = {
  props: {
    errors: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    capitalizedError() {
      if (!this.errors.length) return null

      const fullErrors = []
      for (const error of this.errors) {
        fullErrors.push(error.charAt(0).toUpperCase() + error.slice(1))
      }

      return fullErrors.join(', ')
    }
  }
}
