import AuthModal from '@/Components/Shared/AuthModal'

export const paramAuthModal = {
  methods: {
    openModalParamsToProps(urlParams) {
      let authProps = { next: window.location.pathname }

      if(urlParams.get('open_modal_next')) authProps['next'] = urlParams.get('open_modal_next')
      if(urlParams.get('open_modal_query_parameters')) authProps['queryParameters'] = urlParams.get('open_modal_query_parameters')
      if(urlParams.get('open_modal_form_body')) authProps['formBody'] = urlParams.get('open_modal_form_body')
      if(urlParams.get('open_modal_extra_action')) authProps['extraAction'] = JSON.parse(decodeURI(urlParams.get('open_modal_extra_action')))

      return authProps
    },
    openModalWithParam() {
      const urlParams = new URLSearchParams(window.location.search)
      if (urlParams.get('open_modal') == 'true') {
        this.$modal.show(AuthModal,
          this.openModalParamsToProps(urlParams),
          {
            width: '95%',
            maxWidth: 500
          }
        )
      }
    }
  }
}

export default paramAuthModal
