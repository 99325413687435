<template>
  <div class="">
    <MetaTags :metaTags="metaTags" />
    <div data-testid="year-of-learning-banner">
      <div class="banner_custom">
        <img
          loading="lazy"
          data-testid="year-of-learning-banner-image"
          :src="HeadBanner.url"
          :data-src="HeadBanner.url"
          class="relative w-full image-cover banner-image"
        />
      </div>
      <div class="bg-green-500">
        <div class="container px-4 py-7 lg:px-16 lg:pt-10 lg:pb-16">
          <h2
            class="text-4xl lg:title-largester text-grayscale-100 font-medium font-serif text-center mb-4"
          >
            <Link
              href="/year-of-learning"
              class="text-4xl lg:title-largester text-grayscale-100 font-medium font-serif text-center mb-4"
              >A Year of Joyful Learning</Link
            >
          </h2>
          <p
            class="text-lg lg:text-2xl leading-8 font-normal font-sans text-grayscale-100 tracking-wide text-center text-balance"
          >
            Learn throughout the seasons with our expert-led creative courses. You'll discover how
            to transform your home, ideas for a flourishing garden, new seasonal recipes and
            step-by-step craft projects that will boost your creativity all year long.
          </p>
        </div>
      </div>
    </div>
    <div class="relative w-full">
      <div class="container py-0">
        <div class="flex gap-8 items-center overflow-x-auto w-full py-3 lg:py-3.5 tab_scroll">
          <div
            :class="{
              'active border-b-2 border-blue-800 font-medium': localSelectedMonth === 'all'
            }"
            @click="changeTab('all')"
            class="py-2 text-sm font-sans text-blue-800 uppercase font-normal cursor-pointer"
          >
            All
          </div>
          <div
            v-for="month in months"
            :key="month"
            :class="{
              'active border-b-2 border-blue-800 font-medium': localSelectedMonth === month
            }"
            @click="changeTab(month)"
            class="text-sm font-sans text-blue-800 py-2 uppercase font-normal cursor-pointer"
          >
            {{ month }}
          </div>
        </div>
      </div>
    </div>
    <div>
      <MonthlySection :courses="courses" :currentMonth="currentMonth" />
    </div>
    <section class="bg-blue-500">
      <div class="container px-4 lg:px-16 py-7 lg:py-16">
        <div
          class="font-serif text-center font-medium mb-6 md:mb-8 text-4xl md:text-6xl text-white w-4/5 md:w-auto max-w-screen-md mx-auto"
        >
          Start your year of creative learning today
        </div>
        <div
          class="flex flex-row flex-wrap w-full md:w-auto justify-center items-center gap-x-6 gap-y-4 mb-2 lg:mb-3"
        >
          <Link href="/membership" class="button button--yellow" @navigate="trackSubscribeButton">
            <span class="text-base font-normal tracking-wider"
              >Join for {{ getGeoPrice(membershipPlan.monthlyPrice) }}/month</span
            >
          </Link>
        </div>
        <div
          class="mt-2 font-normal text-sm lg:text-base text-grayscale-100 lg:text-blue-100 text-center"
        >
          (Billed annually)
        </div>
      </div>
    </section>
    <div
      @click="goToTop"
      class="fixed bottom-3 right-3 transition-opacity bg-blue-500 w-10 h-10 shadow-lg cursor-pointer flex items-center justify-center"
      :class="showGoToTop ? 'opacity-100 z-60' : 'opacity-0 hidden'"
    >
      <Icon name="Arrow" class="text-base md:text-xl text-white rotateIcon" />
    </div>
  </div>
</template>

<script>
  import { metaTags } from '@/mixins/metaTags'
  import monetize from '@/mixins/monetize'
  import mixpanel from '@/mixins/mixpanel'
  import currentUser from '@/mixins/currentUser'
  import { Link } from '@inertiajs/inertia-vue'

  import MetaTags from '@/Components/Shared/MetaTags'
  import Layout from '@/Layouts/TransparentLayout'
  import MonthlySection from './MonthlySection'
  import Icon from '@/Components/Shared/Icon'

  export default {
    layout: Layout,

    mixins: [metaTags, monetize, mixpanel, currentUser],

    components: {
      Link,
      MetaTags,
      MonthlySection,
      Icon
    },

    data() {
      return {
        showGoToTop: false,
        localSelectedMonth: this.selectedMonth
      }
    },

    props: {
      months: {
        type: Array,
        required: true
      },
      courses: {
        type: Object,
        default: null
      },
      selectedMonth: {
        type: String,
        default: 'all'
      },
      membershipPlan: {
        type: Object,
        default: null
      },
      geoProps: {
        type: Object,
        required: true
      }
    },

    methods: {
      changeTab(tab) {
        if (tab !== this.localSelectedMonth) {
          this.$inertia.visit(`/year-of-learning/${tab}`, {
            preserveState: true,
            preserveScroll: true
          })
          this.localSelectedMonth = tab
        }
      },
      goToTop() {
        window.scrollTo({
          top: 0,
          behavior: 'smooth'
        })
      },
      handleScroll() {
        this.showGoToTop = window.scrollY > 100 // Adjust the threshold as needed
      },
      getGeoPrice(gbpBasePrice) {
        const decimalPlaces = this.geoProps.currency === 'USD' ? 0 : 2

        return this.geoMonetize(
          gbpBasePrice * this.geoProps.exchangeRate,
          this.geoProps.currencySymbol,
          decimalPlaces
        ).replace(/\.00$/, '')
      },
      trackSubscribeButton() {
        this.trackEvent('subscribe_button', {
          userType: this.currentUser ? 'authed' : 'anonymous',
          page: window.location.pathname,
          component: 'Year of Learning Promotional Banner'
        })
      },

      getCurrentMonth() {
        const months = [
          'january',
          'february',
          'march',
          'april',
          'may',
          'june',
          'july',
          'august',
          'september',
          'october',
          'november',
          'december'
        ]
        const currentDate = new Date()
        return months[currentDate.getMonth()]
      }
    },
    mounted() {
      window.addEventListener('scroll', this.handleScroll)
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.handleScroll)
    },

    created() {
      this.HeadBanner = {
        url: require('@/assets/images/backgrounds/year-learning-banner.webp'),
        sizes: {
          large: require('@/assets/images/backgrounds/year-learning-banner.webp'),
          medium: require('@/assets/images/backgrounds/year-learning-banner.webp'),
          small: require('@/assets/images/backgrounds/year-learning-banner-mobile.png')
        }
      }
    },

    watch: {
      selectedMonth(newVal) {
        this.localSelectedMonth = newVal
      }
    }
  }
</script>

<style scoped>
  .text-balance {
    text-wrap: balance;
  }

  .banner-image {
    max-height: 300px; /* Adjust this value to your preferred height */
    object-fit: cover;
    object-position: center;
  }

  @media (min-width: 768px) {
    .banner-image {
      max-height: 400px; /* Adjust this value for larger screens if needed */
    }
  }
</style>
