var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"banner",class:[_vm.height && 'banner--is-' + _vm.height]},[(_vm.videoId && _vm.brightcove)?_c('BrightcovePlayer',_vm._g({staticClass:"banner__video",attrs:{"data-testid":"banner-player","id":`banner-player-${_vm.uid}`,"isHideControls":true,"isAutoPlay":_vm.videoAutoPlay,"options":{
      videoId: _vm.videoId,
      embedId: 'default',
      preload: _vm.preload,
      ..._vm.brightcove
    }},on:{"onCanPlayThrough":_vm.onCanPlayThrough}},_vm.$listeners)):_vm._e(),_vm._v(" "),(!_vm.videoId || !_vm.brightcove)?_c('img',{class:_vm.classNames,attrs:{"data-testid":"banner-image","id":`banner-image-${_vm.uid}`,"alt":`${_vm.imageAlt}`,"src":!_vm.lazySlide ? _vm.bannerImage.url : null,"data-src":_vm.lazySlide ? _vm.bannerImage.url : null,"srcset":!_vm.lazySlide ? _vm.bannerImage.srcset : null,"data-srcset":_vm.lazySlide ? _vm.bannerImage.srcset : null,"fetchpriority":!_vm.lazySlide ? 'high' : 'low'}}):_vm._e(),_vm._v(" "),_c('div',{staticClass:"banner__content",class:[
      _vm.verticalAlign && 'justify-' + _vm.verticalAlign,
      _vm.darken ? 'gradient--overlay' : 'banner__content--partial-darken'
    ]},[_c('div',{staticClass:"container",class:[
        _vm.contentAlign && 'text-' + _vm.contentAlign,
        `pt-${_vm.topPadding}`,
        `pb-${_vm.bottomPadding}`,
        `lg:pt-${_vm.lgTopPadding}`,
        `lg:pb-${_vm.lgBottomPadding}`,
        { 'flex flex-col h-full': _vm.verticalAlign !== 'end' }
      ]},[_c('div',{staticClass:"w-full relative",class:{
          'lg:max-w-screen-sm': _vm.altBanner,
          'xl:w-10/12': !_vm.altBanner,
          'mt-auto': _vm.verticalAlign === 'end',
          'mb-auto': _vm.verticalAlign === 'start',
          'my-auto': _vm.verticalAlign === 'center'
        }},[_vm._t("default")],2)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }