<template>
  <div class="flex flex-row flex-wrap">

    <OrderSummary :order="order" class="mb-6">
      <div class="text-blue-800 mb-8">
        <div class="flex flex-row items-center mb-2">
          <p data-testid="order-id" class="w-max py-1 px-2 bg-yellow-800 rounded-sm subheading--medium text-grayscale-100 mr-2">#{{ order.id }}</p>
          <h1 data-testid="order-title" class="title-medium">{{ $I18n.t('order', i18nScope) }}</h1>
        </div>

        <p data-testid="order-payment-confirmed-at" class="text-sm flex flex-row items-center">{{ order.humanized_payment_confirmed_at }}</p>
      </div>
    </OrderSummary>

    <OrderDetails :order="order"/>
  </div>
</template>

<script>
import OrderSummary from '@/Components/User/OrderSummary'
import OrderDetails from '@/Components/User/OrderDetails'

export default {
  created() {
    this.i18nScope = { scope: 'pages.users.purchase_item' }
  },

  components: {
    OrderSummary,
    OrderDetails
  },

  props: {
    order: {
      type: Object,
      required: true
    }
  }
}
</script>
