<template>
  <div class="input-group" :class="{ 'w-full': block }" :required="required">
    <label
      v-if="label"
      class="label"
      :for="id"
    >{{ label }}</label>

    <div class="flex flex-row">
      <textarea
        :id="id"
        :name="name"
        :data-testid="`textarea-${testid}`"
        v-bind="$attrs"
        class="input"
        :class="[
          {'error' : errors.length},
          { 'resize-none h-24' : !resize }
        ]"
        :hidden="hidden"
        :value="value"
        :autofocus="autofocus"
        :placeholder="placeholder"
        :maxlength="maxLength"
        @input="$emit('input', $event.target.value)"
      />
    </div>

    <p data-testid="text-area-chars-message" v-if="maxLength" class="text-xs mt-2" :class="charLimitReachedClass">{{ instructions }}</p>

    <InputError :testid="testid" :errors="errors" />
    <InputSuccess :testid="testid" :successMessage="successMessage" />
  </div>
</template>

<script>
import InputError from '@/Components/Shared/Forms/InputError'
import InputSuccess from '@/Components/Shared/Forms/InputSuccess'

/**
 * InputGroup component for Create Academy
 * @displayName InputGroup
 */
export default {
  name: 'TextArea',

  components: {
    InputError,
    InputSuccess
  },

  props: {
    /**
     * The id for the input
     */
    id: {
      type: String,
      default() {
        return `textarea-input-${this._uid}`
      }
    },
    /**
     * The name for the input
     */
    name: {
      type: String,
      default: null
    },
    /**
     * The value for the input
     */
    value: {
      type: [String],
      default: null
    },
    /**
     * The label for the input - this should be more short and informative.
     */
    label: {
      type: String,
      default: null
    },
    /**
     * The placeholder for the input - this should be more explanatory of the desired input information
     */
    placeholder: {
      type: String,
      default: null
    },
    /**
     * Ability for the button to ocupy the all parent
     */
    block: {
      type: Boolean,
      default: false
    },
    /**
     * Ability for the text area to be resizable by the user
     */
    resize: {
      type: Boolean,
      default: false
    },
    /**
     * Field to be mandatory
     */
    required: {
      type: Boolean,
      default: false
    },
    /**
     * Visibility depnding on logic
     */
    hidden: {
      type: Boolean,
      default: false
    },
    /**
     * Ability to push for autofocus on page load
     */
    autofocus: {
      type: String,
      default: null
    },
    /**
     * Ability to add a Character limit
     */
    maxLength: {
      type: Number,
      required: false
    },
    errors: {
      type: Array,
      default: () => []
    },
    successMessage: {
      type: String,
      default: null
    },
    testid: String
  },

  computed: {
    instructions() {
      return this.textLength === 0 ?
        'message limit is ' + this.maxLength + ' characters' :
        this.remaining + ' characters remaining'
    },

    textLength() {
      return this.value ? this.value.length : 0
    },

    remaining() {
      return this.maxLength - this.textLength
    },

    charLimitReachedClass(){
      return this.remaining === 0 ? 'text-danger' : 'text-grayscale-600'
    }
  },

  methods: {
    focus() {
      this.$refs.input.focus()
    },
    select() {
      this.$refs.input.select()
    },
    setSelectionRange(start, end) {
      this.$refs.input.setSelectionRange(start, end)
    },
    inputNumberOnKeyDown(event) {
      // By default number inputs accept the letter 'e' because it stands for
      // exponential. This prevents our number inputs from accepting the
      // letter 'e'.
      if (event.keyCode === 69) event.preventDefault()
    }
  }
}
</script>

<docs>
  ### ✎ Text Area

  ```jsx
    <TextArea
      placeholder="Insert your personal message"
      label="Personal Message"
    ></TextArea>
  ```
</docs>
