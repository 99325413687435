<template>
  <ImageWrapper background="error">
    <MetaTags :metaTags="metaInfo" :disableCanonical="true" />

    <SerifHeader
      :title="title"
      content-align="left"
    />
    <p class="mb-8 text-lg leading-tight">
      {{ description }}
    </p>

    <ButtonLink
      :href="$routes.root()"
      block
    >
      {{ $I18n.t('home_button', i18nScope) }}
    </ButtonLink>
  </ImageWrapper>
</template>

<script>
import { metaTags } from '@/mixins/metaTags'
import MetaTags from '@/Components/Shared/MetaTags'

import ButtonLink from '@/Components/Shared/ButtonLink'
import ImageWrapper from '@/Components/Shared/Objects/ImageWrapper'
import SerifHeader from '@/Components/Shared/SectionHeader/Serif'

export default {
  layout: false,

  mixins: [metaTags],

  components: {
    MetaTags,
    ImageWrapper,
    ButtonLink,
    SerifHeader
  },



  props: {
    status: {
      type: Number,
      required: true
    }
  },

  computed: {
    metaInfo () {
      return {
        title: this.metaTitle,
        description: this.description
      }
    },
    metaTitle() {
      return {
        403: '403: Forbidden | CreateAcademy',
        404: '404: Page Not Found | CreateAcademy',
        500: '500: Server Error | CreateAcademy',
        503: '503: Service Unavailable | CreateAcademy'
      }[this.status]
    },
    title() {
      return {
        403: 'Sorry, you don’t have permissions to access the page you are looking for.',
        404: 'Sorry, the page you are looking for could not be found.',
        500: 'Sorry, what you’re looking for caused an unexpected problem in our servers.',
        503: 'Sorry, we are doing some maintenance that blocks what you’re looking for.'
      }[this.status]
    },
    description() {
      return {
        403: 'You mean the most to us, but we still can let you in on what you’re looking for. Let’s get you back on track.',
        404: 'We searched high and low but couldn’t find what you’re looking for. Let’s get you back on track.',
        500: 'Our server found something wrong when presenting what you’re looking for and we are dealing with it right now. Let’s get you back on track meanwhile.',
        503: 'Due to maintenence or an unavaible service, we can’t help you reach the page you’re looking for. Let’s get you back on track meanwhile.'
      }[this.status]
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.errors' }
  }
}
</script>
