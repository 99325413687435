// import moment from "moment";
import moment from 'moment'

export function formatTimeAgo(created_at) {
  const now = moment()
  const createdAtMoment = moment(created_at)
  const duration = moment.duration(now.diff(createdAtMoment))

  if (duration.asSeconds() < 60) {
    return `Added ${Math.floor(duration.asSeconds())} seconds ago`
  } else if (duration.asMinutes() < 60) {
    return `Added ${Math.floor(duration.asMinutes())} minutes ago`
  } else if (duration.asHours() < 24) {
    return `Added ${Math.floor(duration.asHours())} hours ago`
  } else if (duration.asDays() < 30) {
    return `Added ${Math.floor(duration.asDays())} days ago`
  } else if (duration.asMonths() < 12) {
    return `Added ${Math.floor(duration.asMonths())} months ago`
  } else {
    return `Added ${Math.floor(duration.asYears())} years ago`
  }
}
