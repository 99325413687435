<template>
  <div class="bg-blue-300 min-h-40 w-full flex flex-col py-12 lg:py-14">
    <h2 class="font-serif text-4xl lg:text-5xl text-center text-blue-800">{{ headline }}</h2>
    <p class="text-center text-black text-lg lg:text-2xl font-normal px-4 lg:px-0">
      {{ description }}
    </p>
    <div class="overflow-hidden whitespace-nowrap py-5 lg:py-12 loop-scroll">
      <div class="inline-flex animate-loop-scroll">
        <PillButton
          v-for="item in modifiedPills"
          :key="item.name"
          :text="item.name"
          class="mr-4 uppercase"
        />
      </div>
      <div class="inline-flex animate-loop-scroll" aria-hidden="true">
        <PillButton
          v-for="item in modifiedPills"
          :key="item.name"
          :text="item.name"
          class="mr-4 uppercase"
        />
      </div>
    </div>
    <div class="container px-3 lg:px-11 py-0 lg:py-0 flex flex-col md:flex-row">
      <div class="w-full relative custom-swiper px-4 sm:px-6">
        <!-- Mobile layout -->
        <div class="sm:hidden">
          <div v-for="course in randomizedCourses" :key="course.id" class="w-full mb-6">
            <CourseCard :course="course" />
          </div>
        </div>

        <!-- Desktop layout with Swiper -->
        <div ref="swiperContainer" class="hidden sm:block w-full">
          <div class="swiper-wrapper">
            <div v-for="course in randomizedCourses" :key="course.id" class="swiper-slide">
              <CourseCard :course="course" />
            </div>
          </div>
        </div>

        <!-- Navigation buttons container (only for desktop) -->
        <div class="navigation-container mt-4 relative hidden sm:block">
          <div
            class="swiper-button-prev custom-swiper-button bg-white w-12 h-12 flex items-center justify-center border border-blue-800"
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              viewBox="0 0 44 46"
              fill="none"
              class="navigation-arrow"
            >
              <path
                d="M18.5 44.8789L1 22.9391M1 22.9391L18.5 0.999245M1 22.9391L43 22.9391"
                stroke="#0E1E38"
                stroke-linecap="square"
              />
            </svg>
          </div>
          <div class="swiper-button-next custom-swiper-button border border-blue-800">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="23"
              height="23"
              viewBox="0 0 44 46"
              fill="none"
              class="navigation-arrow"
            >
              <path
                d="M25.5 1L43 22.9398M43 22.9398L25.5 44.8797M43 22.9398L1 22.9398"
                stroke="#0E1E38"
                stroke-linecap="square"
              />
            </svg>
          </div>
        </div>
      </div>
    </div>

    <p
      class="font-sans text-center text-3xl lg:text-2xl text-blue-800 w-60 lg:w-full mx-auto font-normal pt-9 mb-3 lg:mb-4"
    >
      Access our library of free lessons
    </p>
    <div class="flex flex-col items-center mx-14">
      <ButtonLink
        data-testid="button-interiors-landing"
        type="submit"
        class="text-sm border-transparent !text-white bg-yellow-800 w-full sm:w-auto"
        href="/free-lessons"
        variant="bronze"
      >
        WATCH NOW
      </ButtonLink>
    </div>
  </div>
</template>

<script>
  import { defineComponent, ref, computed, onMounted } from 'vue'
  import ButtonLink from '@/Components/Shared/ButtonLink.vue'
  import PillButton from './PillButton.vue'
  import CourseCard from './CourseCard.vue'
  import Swiper from 'swiper/bundle'
  import 'swiper/swiper-bundle.css'

  export default defineComponent({
    name: 'Learn',
    components: {
      ButtonLink,
      PillButton,
      CourseCard
    },
    props: {
      headline: {
        type: String,
        default: 'What you\'ll learn'
      },
      description: {
        type: String,
        default:
          'Take on your own interior design projects with expert guidance from our on demand, bitesized lessons.'
      },
      pills: {
        type: Array,
        default: () => [
          { name: 'Colour theory' },
          { name: 'Room layouts' },
          { name: 'Budgeting' },
          { name: 'Colour schemes' },
          { name: 'Finding inspiration' },
          { name: 'Sourcing' },
          { name: 'Finishes' },
          { name: 'Full renovations' },
          { name: 'Kitchen design' },
          { name: 'Styling & decoration' },
          { name: 'Working with clients' },
          { name: 'DIY projects' },
          { name: 'Lighting' },
          { name: 'Fabrics' },
          { name: 'Managing budgets' },
          { name: 'Working with builders' }
        ]
      }
    },
    setup(props) {
      const modifiedPills = computed(() => props.pills)

      const courses = ref([
        {
          id: 1,
          title: 'Learn colour theory',
          description:
            'From fabric to paint, understand the art of colour selection and create an endless number of palettes for your home and projects.',
          imagePath: '/color-theory.png',
          lessons: 79,
          courses: 3,
          duration: '15 hours'
        },
        {
          id: 3,
          title: 'Discover classic interior design',
          description:
            'Create a timeless home you’ll love for years to come. Discover how to achieve harmony in your interiors by learning the fundamental principles of interior design.',
          imagePath: '/1a.jpg',
          lessons: 224,
          courses: 7,
          duration: '44 hours'
        },
        {
          id: 2,
          title: 'Learn to style any space',
          description:
            'From art collections to cutlery, discover how to add the finishing touches that will make your house a home. ',
          imagePath: '/room.jpg',
          lessons: 62,
          courses: 3,
          duration: '8 hours'
        },
        // {
        //   id: 5,
        //   title: 'Manage a renovation like a professional',
        //   description:
        //     'Our courses reveal the process used by professional interior designers when managing their own renovations, from budgeting, to sourcing to managing tradespeople. ',
        //   imagePath: '/images/learn.webp',
        //   lessons: 10,
        //   courses: 6,
        //   duration: '8h 2m'
        // },
        {
          id: 4,
          title: 'Create your own soft furnishings and decorations',
          description:
            'From art collections to cutlery, discover how to add the finishing touches that will make your house a home.',
          imagePath: '/images/softf.webp',
          lessons: 237,
          courses: 7,
          duration: '43 hours'
        }
      ])

      const randomizedCourses = ref(shuffleArray([...courses.value]))

      function shuffleArray(array) {
        for (let i = array.length - 1; i > 0; i--) {
          const j = Math.floor(Math.random() * (i + 1))
          ;[array[i], array[j]] = [array[j], array[i]]
        }
        return array
      }

      const swiperContainer = ref(null)

      onMounted(() => {
        if (window.innerWidth >= 640) {
          new Swiper(swiperContainer.value, {
            slidesPerView: 1,
            spaceBetween: 20,
            navigation: {
              nextEl: '.swiper-button-next',
              prevEl: '.swiper-button-prev'
            },
            breakpoints: {
              640: {
                slidesPerView: 1
              },
              768: {
                slidesPerView: 1
              },
              1024: {
                slidesPerView: 1
              }
            }
          })
        }
      })

      return {
        modifiedPills,
        randomizedCourses,
        swiperContainer
      }
    }
  })
</script>

<style scoped>
  .custom-swiper-button::after {
    @apply text-blue-800 text-2xl;
  }

  .custom-swiper-button:hover::after {
    @apply text-blue-700;
  }

  .custom-swiper {
    padding: 0 4px; /* Add padding to make room for the buttons */
  }

  .navigation-container {
    height: 48px;
    margin-top: 42px;
  }

  .custom-swiper-button {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    width: 22px; /* Half the width for mobile */
    height: 23px; /* Half the height for mobile */
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .custom-swiper-button svg {
    width: 100%;
    height: 100%;
  }

  .swiper-button-prev {
    left: 0;
  }

  .swiper-button-next {
    right: 0;
  }

  .custom-swiper-button::after {
    display: none; /* Hide default Swiper arrow */
  }

  .swiper-pagination {
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  /* Optional: Add hover effect */
  .custom-swiper-button:hover svg path {
    stroke: #4a5568; /* Change color on hover */
  }

  /* Media query for larger screens */
  @media (min-width: 768px) {
    .custom-swiper {
      padding: 0 20px; /* Increase padding for larger screens */
    }

    .custom-swiper-button {
      width: 44px; /* Full width for larger screens */
      height: 46px; /* Full height for larger screens */
    }
  }

  .custom-bullet {
    width: 20px;
    height: 2px;
    background-color: #d1d5db; /* Light gray for inactive bullets */
    margin: 0 4px;
    cursor: pointer;
    opacity: 1;
    transition: all 0.3s ease;
  }

  .custom-bullet-active {
    background-color: #0e1e38; /* Dark color for active bullet */
    width: 30px; /* Slightly longer for active bullet */
    opacity: 1;
  }

  .description {
    white-space: pre-line;
  }

  @keyframes loop-scroll {
    0% {
      transform: translateX(0);
    }
    100% {
      transform: translateX(-100%);
    }
  }

  .animate-loop-scroll {
    animation: loop-scroll 60s linear infinite;
    display: inline-flex;
  }

  .loop-scroll:hover .animate-loop-scroll {
    animation-play-state: paused;
  }

  .overflow-hidden {
    width: 100%;
  }

  .animate-loop-scroll {
    padding-right: 4rem;
  }

  .navigation-arrow {
    transform: scale(0.6);
    transform-origin: center;
  }

  .custom-swiper-button {
    width: 48px;
    height: 48px;
    background: #ffffff;
  }
</style>
