<template>
  <button
    :disabled="loading"
    :name="name"
    :class="[
      service && 'button--' + service + '-pay',
      {'opacity-70 hover:bg-blue-800 cursor-not-allowed': loading },
      {'w-full': block }
    ]"
    @click="$emit('click')"
    >

    <template v-if="loading">
      <CircleSpinner class="text-white" />
    </template>

    <span><slot /></span>

    <img loading="lazy" :src="serviceMark()" :alt="name" class="ml-2">
  </button>
</template>

<script>
  import CircleSpinner from '@/Components/Icons/CircleSpinner'

  /**
   * PaymentButton component for Create Academy
   * @displayName PaymentButton
   */
  export default {
    name: 'PaymentButton',

    components: {
      CircleSpinner
    },

    props: {
      /**
       * The color of the button
       * @values apple, google, card
       */
      service: {
        type: String,
        default: 'card'
      },
      /**
       * Ability for the button to ocupy the all parent
       */
      block: {
        type: Boolean,
        default: false
      },
      /**
       * When loading a spinner will appear during the timing of that state
       */
      loading: {
        type: Boolean,
        default: false
      },
      /**
       * The name attribute of the button
       */
      name: {
        type: String,
        default: null
      }
    },

    methods: {
      serviceMark() {
      if (this.service === 'apple') {
        return require('@/assets/images/ApplePayButtonMark.svg')
      }
      else if (this.service === 'google') {
        return require('@/assets/images/GooglePayButtonMark.svg')
      }
      else if (this.service === 'card') {
        return require('@/assets/images/ChromePayButtonMark.svg')
      } else {
        return require('@/assets/images/ChromePayButtonMark.svg')
      }
    }
  }
}
</script>

<docs>
  ### ✎ Apple Pay Button

  ```jsx
    <PaymentButton service="apple"> Buy with </Button>
  ```
  ---

  ### ✎ Google Play Button

  ```jsx
    <PaymentButton service="google"> Buy with </Button>
  ```
  ---

  ### ✎ Pay with Chrome Saved Cards Button

  ```jsx
    <PaymentButton service="card"> Pay with a saved card </Button>
  ```
  ---
</docs>
