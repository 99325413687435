<template>
  <div
    class="tab-menu--centered z-10 w-full fixed top-20"
    :class="{ 'top-32': showGeneralAnnouncement }"
  >
    <Link
      data-testid="link-profile"
      :class="active($routes.profile_users())"
      :href="$routes.profile_users()"
      class="tab-menu__link"
    >
      {{ $I18n.t('profile', i18nScope) }}
    </Link>

    <Link
      data-testid="link-password"
      :class="active($routes.password_users())"
      :href="$routes.password_users()"
      class="tab-menu__link"
    >
      {{ $I18n.t('password', i18nScope) }}
    </Link>

    <Link
      data-testid="link-notifications"
      :class="active($routes.notifications_users())"
      :href="$routes.notifications_users()"
      class="tab-menu__link"
    >
      {{ $I18n.t('notifications', i18nScope) }}
    </Link>

    <Link
      data-testid="link-purchases"
      :class="active($routes.orders())"
      :href="$routes.orders()"
      class="tab-menu__link"
    >
      {{ $I18n.t('purchases', i18nScope) }}
    </Link>
  </div>
</template>

<script>
  import generalAnnouncement from '@/mixins/generalAnnouncement'

  import { Link } from '@inertiajs/inertia-vue'

  export default {
    mixins: [generalAnnouncement],

    created() {
      this.i18nScope = { scope: 'pages.users.tabs' }
    },

    components: {
      Link
    },

    methods: {
      active(linkPath) {
        return {
          'tab-menu__active': this.$page.url.startsWith(linkPath)
        }
      }
    }
  }
</script>

<style scoped>
  .tab-menu__active {
    /* This will override the default color */
    border-top-color: theme('colors.yellow.800') !important; /* or any color you want */
  }
</style>
