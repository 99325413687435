<template>
  <div class="mb-6">
    <div class="flex flex-row justify-between item-center mb-4">
      <p class="font-normal">{{ $I18n.t('breakdown.total_amount', i18nScope) }}</p>
      <p data-goptimize-total-amount data-testid="breakdown-orderable-amount-total">{{ geoMonetize(geoPrice.price/100, geoPrice.currencySymbol, 2)}}</p>
    </div>

    <div class="flex flex-row justify-between opacity-100">
      <div class="flex flex-row flex-wrap items-start">
        <p class="font-normal mr-2">{{ $I18n.t('breakdown.discount_applied', i18nScope) }}</p>

        <template v-if="showDiscountTag">
          <Tags v-if="promotionBanner" data-testid="order-summary-promotion-banner" class="mb-2 mr-2" color="success" size="small" lowerCase>
            <span class="overline--medium overline--widest">{{ promotionBanner }}</span>
          </Tags>
          <Tags v-if="voucherCode" data-testid="order-summary-promotion-banner" class="mb-2 mr-2" color="success" size="small" lowerCase>
            <span class="overline--medium overline--widest">{{ voucherCode }}</span>
          </Tags>
        </template>

      </div>
      <p data-goptimize-discount :data-goptimize-price="discount" data-testid="breakdown-orderable-discount">{{ geoHumanizedDiscount }}</p>
      <!-- <p data-goptimize-discount :data-goptimize-price="discount" data-testid="breakdown-orderable-discount">{{ discount }}</p> -->
    </div>

    <OrderGrandTotal :geoPrice="geoPrice" :discount="geoDiscount" :i18n-scope="i18nScope" class="mt-6 pt-6 border-t border-blue-800 border-opacity-50" />
  </div>
</template>

<script>
import OrderGrandTotal from '@/Components/Orders/OrderGrandTotal'
import Tags from '@/Components/Shared/Tags'
import monetize from '@/mixins/monetize'

export default {
  mixins: [
    monetize
  ],

  components: {
    OrderGrandTotal,
    Tags
  },

  props: {
    discount: {
      type: Number,
      default: 0
    },
    geoPrice: {
      type: Object,
      required: true
    },
    geoProps: {
      type: Object,
      required: false
    },
    showDiscountTag: {
      type: Boolean,
      default: true
    },
    promotionBanner: {
      type: String,
      default: ''
    },
    voucherCode: {
      type: String
    },
    i18nScope: {
      type: Object,
      required: true
    }
  },

  computed: {
    geoDiscount() {
      return this.discount * (this.geoProps ? this.geoProps.exchangeRate : 1)
    },
    geoHumanizedDiscount(){
      try {
        return this.geoMonetize(this.geoDiscount / 100, this.geoPrice.currencySymbol, 2)
      } catch (error) {
        return this.geoMonetize(this.discount / 100, this.geoPrice.currencySymbol, 2)
      }

    }
  }
}
</script>

