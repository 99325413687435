<template>
  <p>
    <template v-if="course.releaseDate">
      <span :data-testid="`${testId}-release-date`">
        {{ $I18n.t('live_on', i18nScope) }} {{ course.releaseDate }}
      </span>
    </template>
    <template v-else>
      <span :data-testid="`${testId}-pre-launch-tag`">{{ statusTag }} </span>
    </template>
  </p>
</template>

<script>
  export default {
    props: {
      course: {
        type: Object,
        required: true
      },
      i18nScope: {
        type: Object,
        required: true
      },
      testId: {
        type: String,
        required: false,
        default: 'course'
      }
    },
    computed: {
      statusTag() {
        switch (this.course.status) {
          case 'live_shortly':
          case 'coming_soon':
            return this.$I18n.t('live_shortly', this.i18nScope)
          default:
            return ''
        }
      }
    }
  }
</script>
