<template>
  <div>
    <div class="customInputGroup relative">
      <InputGroup
        testid="onboarding_checkout_code"
        placeholder="Enter discount code"
        :inputClass="'custom-input-class'"
        type="code"
        autofocus
        :errors="errors"
        v-model.trim="voucherCode"
        :successMessage="successMessage"
        @keydown.enter.native="onEnter"
        autocapitalize="off"
      />
      <a
        :disabled="disabled"
        @click="onButtonClick"
        class="link--small link--blue cursor-pointer underline text-base font-normal tracking-wide absolute right-0 top-0"
        :class="{ 'cursor-not-allowed': disabled }"
      >
        {{ validated ? 'Applied' : 'Apply' }}
      </a>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import InputGroup from '@/Components/Shared/Forms/InputGroup'

  export default {
    components: {
      InputGroup
    },

    props: {
      validateVoucherUrl: {
        type: String,
        default: '/basket/orders/validate_discounts'
      }
    },

    created() {
      this.i18nScope = { scope: 'pages.orders.new' }
    },

    mounted() {
      // force validation for gtm purposes
      this.validateDiscounts()
    },

    data() {
      return {
        voucherCode: '',
        validated: false,
        changed: false,
        errors: undefined,
        validating: false
      }
    },

    watch: {
      voucherCode() {
        if (this.validated) {
          this.validated = false
          this.validateDiscounts()
        }

        this.changed = true
      }
    },

    computed: {
      disabled() {
        return this.validating || !this.changed || !this.voucherCode.length
      },

      successMessage() {
        return this.validated ? 'Coupon Accepted' : null
      }
    },

    methods: {
      async onButtonClick(event) {
        if (this.disabled) {
          event.preventDefault()
          return
        }

        this.changed = false

        const result = await this.validateDiscounts(this.voucherCode.trim())

        if (result.valid) {
          this.validated = true
          this.errors = undefined
          window.location.reload()
        } else {
          this.errors = [result.reason]
        }
      },

      async validateDiscounts(code = '') {
        this.validating = true
        try {
          const response = await axios.put(this.validateVoucherUrl, {
            voucher: { code }
          })
          return response.data
        } catch (error) {
          console.error('Voucher validation failed:', error)
          return { valid: false, reason: 'Failed to validate voucher. Please try again.' }
        } finally {
          this.validating = false
        }
      },

      onEnter(e) {
        e.preventDefault()
        this.onButtonClick()
      }
    }
  }
</script>
