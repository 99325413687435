<template>
  <div class="flex flex-col p-6 bg-white max-w-md h-full border border-gray-200 rounded">
    <div class="mb-6">
      <img src="/images/5stars.svg" alt="Five Star Rating" class="w-auto h-6" />
    </div>
    <p class="text-lg font-medium mb-4 flex-grow">
      {{ quote }}
    </p>
    <p class="text-base font-semibold text-gray-700">
      {{ author }}
    </p>
  </div>
</template>

<script lang="ts">
  import { defineComponent } from 'vue'

  export default defineComponent({
    name: 'TestimonialCard',
    props: {
      quote: {
        type: String,
        required: true
      },
      author: {
        type: String,
        required: true
      }
    }
  })
</script>

<style scoped>
  .shadow-md {
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.06);
  }
</style>
