<template>
  <div class="md:w-8/12">
    <form @submit.prevent="submit" novalidate="true">
      <InputGroup
        v-if="!withRandomPw"
        testid="current-password"
        type="password"
        v-model="form.user.current_password"
        :errors="errors.current_password"
        :label="$I18n.t('form.inputs.current_password.label', i18nScope)"
        :placeholder="$I18n.t('form.inputs.current_password.placeholder', i18nScope)"
      />

      <InputGroup
        testid="password"
        type="password"
        v-model="form.user.password"
        :errors="errors.password"
        :label="$I18n.t('form.inputs.password.label', i18nScope)"
        :placeholder="$I18n.t('form.inputs.password.placeholder', i18nScope)"
      />

      <InputGroup
        testid="password-confirmation"
        type="password"
        v-model="form.user.password_confirmation"
        :errors="errors.password_confirmation"
        :label="$I18n.t('form.inputs.password_confirmation.label', i18nScope)"
        :placeholder="$I18n.t('form.inputs.password_confirmation.placeholder', i18nScope)"
      />

      <Button
        data-testid="button-submit"
        class="mt-8"
        type="submit"
        :variant="buttonVariant"
      >
        {{ $I18n.t('form.button', i18nScope) }}
      </Button>
    </form>
  </div>
</template>

<script>
import dirtyForm from '@/mixins/dirtyForm'
import confirmLeave from '@/mixins/confirmLeave'

import Button from '@/Components/Shared/Button'
import InputGroup from '@/Components/Shared/Forms/InputGroup'

export default {
  mixins: [
    dirtyForm('form.user'),
    confirmLeave
  ],

  created() {
    this.i18nScope = { scope: 'pages.users.password' }

    // Used by the confirmLeave mixin
    this.formSubmitPath = [this.$routes.update_password_users()]
  },

  components: {
    Button,
    InputGroup
  },

  props: {
    withRandomPw: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      // used for client side errors
      localErrors: {},

      form: this.$inertia.form({
        user: {
          current_password: null,
          password: null,
          password_confirmation: null
        }
      })
    }
  },

  computed: {
    // Form errors
    errors() {
      return this.$page.props.errors || this.localErrors
    },

    buttonVariant() {
      return this.formDirty ? 'blue' : 'inactive'
    },

    requiredFields() {
      const fields = ['password', 'password_confirmation']
      if (!this.withRandomPw) fields.push('current_password')

      return fields
    }
  },

  methods: {
    // Set client side validation errors
    // Returns:
    // - true if there is at least one error
    // - false if there are no errors
    setLocalErrors() {
      let invalidForm = false
      this.localErrors = {}

      for (const requiredField of this.requiredFields) {
        if (!this.form.user[requiredField]) {
          this.localErrors[requiredField] = [this.$I18n.t('activerecord.errors.messages.blank_generic')]
          invalidForm = true
        }
      }

      if (invalidForm) this.$page.props.flash.alert = this.$I18n.t('controllers.users.update_profile.error')

      return invalidForm
    },

    submit() {
      if (this.setLocalErrors()) return

      this.form.post(this.$routes.update_password_users(), {
        preserveScroll: true,
        onSuccess: () => {
          this.form.reset()
          this.$nextTick(() => { this.formDirty = false })
        }
      })
    }
  }
}
</script>
