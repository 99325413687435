<template>
  <div
    class="z-50 absolute top-0 w-full lg:w-3/6 xl:w-5/12 h-screen max-h-screen container lg:px-12 flex flex-col py-0 bg-grayscale-100 transition-all duration-400 lg:shadow-md"
    :class="[
      currentPanel ? 'right-0' : '-right-full',
      showGeneralAnnouncement ? 'pb-20 md:pb-14' : 'pb-0'
    ]"
  >
    <div class="flex flex-row justify-between items-center py-4 lg:py-6">
      <template v-if="backButtonActive">
        <div @click="$emit('clickBack')">
          <Icon name="Chevron" class="menu-icon chevron-left" />
        </div>
      </template>

      <p data-testid="menu-panel-name" class="subheading--medium text-blue-800">{{ name }}</p>

      <div class="cursor-pointer" @click="$emit('clickClose')">
        <Icon name="Close" class="menu-icon" />
      </div>
    </div>

    <div class="py-4" :class="classContainer">
      <slot />
    </div>
  </div>
</template>

<script>
import generalAnnouncement from '@/mixins/generalAnnouncement'

import Icon from '@/Components/Shared/Icon'

export default {
  mixins: [
    generalAnnouncement
  ],

  components: {
    Icon
  },

  props: {
    currentPanel: {
      type: Boolean,
      required: true
    },
    backButtonActive: {
      type: Boolean,
      required: false
    },
    name: {
      type: String,
      required: true
    },
    classContainer: {
      type: String,
      required: false
    }
  }
}
</script>
