var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
  _vm.label ? 'divider--has-text' : 'divider',
  _vm.color && 'divider--' + _vm.color,
  _vm.height && 'divider--height-' + _vm.height,
  _vm.width && 'divider--width-' + _vm.width,
  _vm.spacing && 'divider--space-' + _vm.spacing
]},[(_vm.label)?[_c('span',[_vm._v(_vm._s(_vm.label))])]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }