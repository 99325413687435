<template>
  <div v-if="message" class="px-4 sm:px-12 py-3 sm:py-4 text-center">
    <p class="text-center text-xl md:text-2xl italic font-normal leading-normal font-sans max-w-xl mx-auto"
       :class="[isMembershipGift ? 'text-blue-500' : 'text-red-500']">
      {{ message }}
    </p>
  </div>
</template>

<script>
import useGiftType from './useGiftType'

export default {
  mixins: [useGiftType],
  
  props: {
    message: {
      type: String,
      default: ''
    },
    campaign: {
      type: Object,
      required: true
    }
  }
}
</script> 