<template>
  <span data-testid="general-announcement-campaign-text-body">{{ genAnnouncement.campaignTextBody }}</span>
</template>

<script>
export default {
  props: {
    genAnnouncement: {
      type: Object,
      required: true
    }
  }
}
</script>
