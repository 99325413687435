<template>
  <div
    data-testid="course-talent"
    class="relative md:px-3 mb-6"
    :class="YearLearning ? 'px-1 w-full md:w-1/2 lg:w-1/4' : 'px-0 w-full md:w-1/2 lg:w-1/3'"
  >
    <div :data-testid="`course-talent-${course.id}`">
      <Tags
        v-if="enrolled && course.hasNewLessons"
        :data-testid="`course-talent-${course.id}-new-lessons`"
        color="success"
        size="small"
        class="z-10 absolute top-2 left-4 sm:left-6"
      >
        <span class="overline--medium overline--widest">{{
          $I18n.t('new_lessons', i18nScope)
        }}</span>
      </Tags>

      <div
        class="relative w-full overflow-hidden mb-4"
        :class="YearLearning ? ' h-52 lg:h-64' : 'h-64'"
      >
        <!-- Sale Tag -->
        <SaleTag
          v-if="showSaleTag && !enrolled && !comingSoon && !YearLearning"
          :course="course"
          class="right-4 min-w-160"
        />

        <div
          v-if="YearLearning && course.activeSale"
          class="absolute top-2 right-2 px-3 py-1 bg-blue-800 text-grayscale-100 text-sm leading-4 font-normal text-center"
        >
          {{ course.activeSale.discountValue }}% off
          <span class="">everything</span>
        </div>

        <div
          :data-testid="`course-talent-${course.id}-image-cta`"
          @click="onTalentClick()"
          class="h-full text-blue-800 cursor-pointer bg-gray-400"
          v-if="YearLearning"
        >
          <img
            loading="lazy"
            :data-testid="`course-talent-${course.id}-image`"
            :data-src="require(`@/assets/images/year-learning/${course.slug}.webp`)"
            :alt="`${course.name} ${$I18n.t('thumbnail', i18nScope)}`"
            class="lazyload image-cover"
          />
        </div>

        <div
          :data-testid="`course-talent-${course.id}-image-cta`"
          @click="onTalentClick()"
          class="h-full text-blue-800 cursor-pointer bg-gray-400"
          v-else
        >
          <img
            loading="lazy"
            :data-testid="`course-talent-${course.id}-image`"
            :data-src="course.image"
            :alt="`${course.name} ${$I18n.t('thumbnail', i18nScope)}`"
            class="lazyload image-cover"
          />
        </div>

        <!-- OWNED COURSE -->
        <div v-if="owned">
          <Tags
            :data-testid="`course-talent-${course.id}-owned-tag`"
            color="blue"
            class="absolute top-0 right-6 flex flex-col items-center"
          >
            <Icon name="Finished" class="text-xl my-2" />
            <p class="overline--medium overline--widest">
              <span class="whitespace-pre-wrap">{{ $I18n.t('owned_course', i18nScope) }}</span>
            </p>
          </Tags>
        </div>
      </div>

      <div
        :data-testid="`course-talent-${course.id}-cta`"
        @click="onTalentClick()"
        class="cursor-pointer"
      >
        <div class="flex flex-row no-wrap justify-between">
          <component
            :is="headingLevel"
            :data-testid="`course-talent-${course.id}-teacher-name`"
            class="title-medium text-blue-800 mb-0"
          >
            <div class="subheading mb-1">{{ course.teacher.name }}</div>
            {{ course.name }}</component
          >
          <span v-if="course.isAGift" class="font-medium text-lg tracking-wide">{{
            course.campaign.humanizedPrice
          }}</span>
          <Icon v-else name="Arrow" class="text-2xl" />
        </div>

        <div v-if="!comingSoon && !YearLearning" class="subheading flex flex-row">
          <div class="">{{ courseLessonCount }} lessons</div>
          <div class="mx-1">/</div>
          <div class="">{{ courseDuration }} hours</div>
          <!-- <div class="ml-1 text-red-700">for {{monetisedGeoPrice}}</div> -->
          <div v-if="!enrolled && !isSubscribed" class="ml-1 text-black">
            for {{ monetisedGeoPrice(finalPrice) }}
          </div>
        </div>

        <div
          v-if="(enrolled && preLaunch) || earlyBird"
          :data-testid="`course-talent-${course.id}-release-date`"
          class="mt-2"
        >
          <PreLaunchTag
            :course="course"
            :i18nScope="i18nScope"
            class="overline--medium text-yellow-800"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import statuses from '@/mixins/statuses'
  import currentUser from '@/mixins/currentUser'
  import generalAnnouncement from '@/mixins/generalAnnouncement'
  import saleTag from '@/mixins/saleTag'
  import gtm from '@/mixins/gtm'
  import mixpanel from '@/mixins/mixpanel'
  // AB Testing bits
  import abTest from '@/mixins/abTest'

  import { Link } from '@inertiajs/inertia-vue'

  import ButtonLink from '@/Components/Shared/ButtonLink'
  import Icon from '@/Components/Shared/Icon'
  import PurchaseButton from '@/Components/Shared/Courses/PurchaseButton'
  import SaleTag from '@/Components/Shared/Campaigns/SaleTag'
  import Tags from '@/Components/Shared/Tags'
  import TrailerButton from '@/Components/Shared/Trailer/TriggerButton'
  import PreLaunchTag from '@/Components/Shared/Courses/PreLaunchTag'
  import monetize from '@/mixins/monetize'

  export default {
    mixins: [statuses, currentUser, generalAnnouncement, saleTag, gtm, mixpanel, abTest, monetize],

    components: {
      Link,
      ButtonLink,
      Icon,
      PurchaseButton,
      SaleTag,
      Tags,
      TrailerButton,
      PreLaunchTag
    },

    props: {
      course: {
        type: Object,
        required: true
      },
      brightcove: {
        type: Object,
        required: false
      },
      headingLevel: {
        type: String,
        default: 'h2'
      },
      gtmPageName: {
        type: String,
        required: true
      },
      gtmSectionName: {
        type: String,
        required: true
      },
      gtmPosition: {
        type: Number,
        required: true
      },
      showPurchaseButton: {
        type: Boolean,
        default: false
      },
      YearLearning: {
        type: Boolean,
        default: false
      }
    },

    created() {
      this.i18nScope = { scope: 'pages.home.talents' }
    },

    computed: {
      basePrice() {
        return this.course.geoPrice.price || this.course.unformatted_price
      },
      saleReduction() {
        if (this.activeSale) {
          return (this.basePrice * this.activeSale.discountValue) / 100
        }
        return 0 // no sale
      },
      finalPrice() {
        return this.basePrice - this.saleReduction
      },
      activeSale() {
        return this.course.activeSale
      },
      courseLink() {
        if (this.enrolled && (this.published || this.earlyBird)) {
          return this.$routes.course_lessons(this.course.slug)
        } else {
          return this.$routes.course(this.course.slug)
        }
      },
      courseDuration() {
        return this.course.stats && this.course.stats.duration
          ? Math.round(this.course.stats.duration / 3600000)
          : 0
      },
      courseLessonCount() {
        return this.course.stats && this.course.stats.lessons ? this.course.stats.lessons : 0
      },
      owned() {
        return this.isOwned(this.course.id)
      },
      freeLessonRoute() {
        if (!this.course.freeLessonSlug) {
          return false
        }

        return this.$routes.course_lesson(this.course.slug, this.course.freeLessonSlug)
      },
      mixpanelEventData() {
        return {
          page: window.location.pathname,
          component: this.gtmSectionName,
          course: this.course.name,
          teacher: this.course.teacher.name
        }
      }
    },

    methods: {
      monetisedGeoPrice(price, decimalPlaces = 2) {
        decimalPlaces =
          this.course.geoPrice && this.course.geoPrice.currency === 'USD' ? 0 : decimalPlaces
        return this.geoMonetize(
          price,
          this.course.geoPrice ? this.course.geoPrice.currencySymbol : '£',
          decimalPlaces
        )
      },
      onTalentClick() {
        this.gtmAddProductListingImpressionClick(
          this.gtmPageName,
          this.gtmSectionName,
          this.course,
          this.gtmPosition
        )

        this.trackEvent('go_to_course_page', this.mixpanelEventData)

        this.$inertia.visit(this.courseLink)
      },

      onFreeLessonClick() {
        this.trackEvent('click_free_lesson', this.mixpanelEventData)
      }
    }
  }
</script>
