<template>
  <p
    v-if="successMessage"
    class="font-normal text-sm text-success mt-1"
    :data-testid="`success-${testid}`"
    v-html="successMessage"
  />
</template>

<script>
/**
 * InputSuccess component for Create Academy
 * @displayName InputSuccess
 */
export default {
  name: 'InputSuccess',

  props: {
    testid: String,
    successMessage: String
  }
}
</script>


<docs>
  ### ✎ Input Success
  This component is usually used attachaed to a `input-group` component like a `input` or `checkbox`.

  ```jsx
    <InputSuccess :successMessage="'This is a success message'" />
  ```
</docs>
