<template>
  <div>
    <div
      class="overflow-hidden md:px-20 bg-cream max-md:hidden mx-auto flex justify-center"
      role="banner"
    >
      <div class="flex">
        <div class="flex flex-col md:w-1/2 pt-8 pb-2">
          <div class="flex flex-col my-auto text-center font-normal">
            <div
              class="text-xl sm:text-center md:text-left font-sans font-normal text-black"
              style="line-height: 61px; letter-spacing: 3px"
            >
              GIVE THE GIFT OF
            </div>
            <div
              class="text-6xl font-medium leading-tight text-blue-800 md:text-left font-serif"
              style="line-height: 72px"
            >
              Creativity
            </div>
            <img
              loading="lazy"
              :src="imageUrl('gift-card-mobile-blue.png')"
              alt="Gift card"
              class="block md:hidden w-11/12 mx-auto"
            />
            <div class="w-5/6 mx-auto md:w-auto md:mx-0">
              <div class="flex justify-center md:block">
                <ButtonLink
                  href="/gifts"
                  variant="red"
                  class="md:mt-9 mt-4 whitespace-nowrap w-full md:w-auto mb-6"
                  style="letter-spacing: 2.4px"
                >
                  SHOP GIFT VOUCHERS
                </ButtonLink>
              </div>
            </div>
          </div>
        </div>
        <div class="flex flex-col">
          <img
            loading="lazy"
            :src="imageUrl('giftcardhero.webp')"
            alt="Gift voucher promotional image showcasing creative products"
            class="hidden md:block object-contain grow w-full aspect-[1.3]"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import ButtonLink from '@/Components/Shared/ButtonLink'
  import cdn from '@/mixins/cdn'

  export default {
    name: 'GiftVoucherBanner',
    components: {
      ButtonLink
    },
    mixins: [cdn],

    data() {
      return {
        isMobile: window.innerWidth < 768
      }
    },

    mounted() {
      window.addEventListener('resize', this.checkMobile)
    },

    beforeDestroy() {
      window.removeEventListener('resize', this.checkMobile)
    },

    methods: {
      checkMobile() {
        this.isMobile = window.innerWidth < 768
      }
    }
  }
</script>
