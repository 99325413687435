<template>
  <div>
    <MetaTags :metaTags="metaTags" />

    <div data-testid="banner-section">
      <SplitBanner :category="category" :backgroundColor="backgroundColor" />
      <!-- <Banner
        :uid="category.slug"
        :image-src="category.bannerImagePath"
        :image-alt="category.name"
        :video-id="category.bannerVideoId"
        :video-auto-play="true"
        :brightcove="brightcove.sliderPlayer"
        height="medium"
        content-align="center"
      >
        <h1 data-testid="category-name" class="banner__title">{{ category.name }} {{ $I18n.t('courses', i18nScope) }}</h1>
        <p data-testid="category-description" class="banner__subtitle">{{ category.description }}</p>
      </Banner> -->
    </div>

    <template v-if="!isSubscribed">
      <BannerStrip v-if="bannerStrip.active" :bannerStrip="bannerStrip" />
    </template>

    <CampaignAnnouncement v-if="showBuyOneGiftOne" />

    <section v-if="category.flatCourseList && courses.length">
      <div class="py-16 flex flex-col md:flex-row md:flex-wrap w-full px-16">
        <CategoryCourseTeaser
          v-for="(course, index) in courses"
          :key="course.id"
          :course="course"
          :brightcove="brightcove.lessonPlayer"
          :class="classNames"
          :headingLevel="headingLevel"
          :gtmPageName="currentPage"
          :gtmSectionName="gtmSectionExploreCourses"
          :gtmPosition="index + 1"
        />
      </div>
    </section>

    <section v-else>
      <template v-for="(theme, index) in themes">
        <div
          :key="theme.id"
          :class="[
            index % 2 === 0 ? 'bg-white' : backgroundColor,
            'bg-opacity-5',
          ]"
        >
          <div class="container">
            <h2 class="title-largest mb-8">{{ theme.name }}</h2>
            <!-- <div>{{ theme.description }}</div> -->

            <div
              class="flex flex-col md:flex-row md:flex-wrap w-full mx-0 md:-mx-3"
            >
              <CategoryCourseTeaser
                v-for="(course, index) in theme.courses"
                :key="course.id"
                :course="course"
                :brightcove="brightcove.lessonPlayer"
                :class="classNames"
                :headingLevel="headingLevel"
                :gtmPageName="currentPage"
                :gtmSectionName="gtmSectionExploreCourses"
                :gtmPosition="index + 1"
              />
            </div>
          </div>
        </div>
      </template>
    </section>
    <template v-if="!isSubscribed">
      <CategoryMembershipBanner
        v-if="category.showMembershipBanner"
        :category="category"
        :membershipPlan="membershipPlan"
        :geoProps="geoProps"
        component="Category Promotional Banner"
      />
    </template>
    <template v-if="!isSubscribed">
      <!-- Seasonal Banner -->
      <SeasonalBanner
        v-if="seasonalBanner.active"
        :seasonalBanner="seasonalBanner"
      />
    </template>

    <template v-if="upcomingCourses.length && category.showUpcomingCourses">
      <div class="container">
        <!-- Upcoming Courses -->
        <SerifHeader
          :title="'Upcoming Courses'"
          headingLevel="h3"
          content-align="center"
        />

        <Upcoming
          :courses="upcomingCourses"
          :brightcove="brightcove.lessonPlayer"
          :gtmPageName="currentPage"
          :gtmSectionName="gtmSectionUpcomingCourses"
        />
      </div>
    </template>

    <GiftingBanner v-if="category.showGiftBanner" />
  </div>
</template>

<script>
import { metaTags } from '@/mixins/metaTags'
import brightcove from '@/mixins/brightcove'
import currentUser from '@/mixins/currentUser'
import statuses from '@/mixins/statuses'
import featureFlags from '@/mixins/featureFlags'
import gtm from '@/mixins/gtm'
import mixpanel from '@/mixins/mixpanel'

import MetaTags from '@/Components/Shared/MetaTags'
import SerifHeader from '@/Components/Shared/SectionHeader/Serif'
import CategoryCourseTeaser from '@/Components/Shared/Categories/CategoryCourseTeaser'
import Upcoming from '@/Components/Shared/Upcoming/Index'
import GiftingBanner from '@/Components/Shared/Gifts/Banner'
import CampaignAnnouncement from '@/Components/Shared/Campaigns/CampaignAnnouncement'
import BannerStrip from '@/Components/Shared/Campaigns/CategoryBannerStrip'
import SeasonalBanner from '@/Components/Shared/SeasonalBanner'

import Layout from '@/Layouts/TransparentLayout'
import SplitBanner from '@/Components/Shared/Categories/SplitBanner.vue'
import monetize from '@/mixins/monetize'
import CategoryMembershipBanner from '@/Components/Shared/Categories/CategoryMembershipBanner'

export default {
  layout: Layout,

  mixins: [
    metaTags,
    brightcove,
    currentUser,
    statuses,
    featureFlags,
    gtm,
    mixpanel,
    monetize
  ],

  components: {
    MetaTags,
    SerifHeader,
    CategoryCourseTeaser,
    Upcoming,
    GiftingBanner,
    CampaignAnnouncement,
    BannerStrip,
    SeasonalBanner,
    SplitBanner,
    CategoryMembershipBanner
  },

  props: {
    category: {
      type: Object,
      required: true
    },
    themes: {
      type: Array,
      default: () => {
        return []
      }
    },
    membershipPlan: {
      type: Object,
      default: null
    },
    courses: {
      type: Array,
      default: () => {
        return []
      }
    },
    upcomingCourses: {
      type: Array,
      default: () => {
        return []
      }
    },
    relatedCategories: {
      type: Array,
      default: () => {
        return []
      }
    },
    about: {
      type: Object,
      default: () => ({})
    },
    seasonalBanner: {
      type: Object,
      default: () => ({})
    },
    bannerStrip: {
      type: Object,
      default: () => ({})
    },
    geoProps: {
      type: Object,
      required: true
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.home.section_titles' }
  },

  mounted() {
    this.gtmAddProductListingImpressions(this.currentPage, [
      {
        name: this.gtmSectionExploreCourses,
        impressions: this.filteredTalentCourses
      },
      {
        name: this.gtmSectionUpcomingCourses,
        impressions: this.upcomingCourses
      }
    ])
  },

  methods: {
    getGeoPrice(gbpBasePrice) {
      return this.geoMonetize(
        gbpBasePrice * this.geoProps.exchangeRate,
        this.geoProps.currencySymbol,
        2
      )
    },
    getTailwindColorClass(color) {
      const tailwindColorHash = {
        red: 'bg-red-800',
        green: 'bg-green-500',
        yellow: 'bg-yellow-800',
        blue: 'bg-blue-500'
      }
      return color ? tailwindColorHash[color.toLowerCase()] : 'bg-green-500'
    }
  },

  computed: {
    backgroundColor() {
      return this.getTailwindColorClass(this.category.primaryColour)
    },
    filteredTalentCourses() {
      return this.themes.flatMap((theme) =>
        theme.courses.filter((course) => !this.isComingSoon(course))
      )
    },
    currentPage() {
      return 'PLP'
    },
    gtmSectionExploreCourses() {
      return 'Explore our Courses'
    },
    gtmSectionUpcomingCourses() {
      return `Upcoming ${this.category.name} Courses`
    },
    gtmSectionExploreCategories() {
      return 'Explore our Categories'
    },
    showBannerStrip() {
      return !this.showBuyOneGiftOne && true
    }
  }
}
</script>
