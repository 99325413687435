<template>
  <div data-testid="subscription-confirmation-modal" class="bg-grayscale-100 p-8">
    <div class="absolute top-6 right-6">
      <button data-testid="subscription-confirmation-modal-close" @click="closeModal">
        <Icon name="close" class="text-2xl text-blue-800" />
      </button>
    </div>
    <div class="flex flex-col items-center text-center">
      <Icon name="Success" class="title-xl text-blue-800 mb-2" />
      <h1 data-testid="subscription-confirmation-modal-title" class="title-medium text-blue-800 mb-6">{{ $I18n.t('title', i18nScope) }}</h1>
      <p class="mb-6">{{ $I18n.t('first_paragraph', i18nScope) }}</p>

      <p class="mb-6" v-html="$I18n.t('second_paragraph', i18nScope)"></p>

      <Button
        data-testid="subscription-confirmation-modal-button"
        @click="closeModal"
        class="mx-auto"
      >
        {{ $I18n.t('button', i18nScope) }}
      </Button>
    </div>
  </div>
</template>

<script>
import Icon from '@/Components/Shared/Icon'
import Button from '@/Components/Shared/Button'

export default {
  components: {
    Icon,
    Button
  },

  created() {
    this.i18nScope = { scope: 'pages.home.subscription_confirmation_modal' }
  },

  data() {
    return {
      showSignIn: false
    }
  },

  methods: {
    closeModal() {
      this.$emit('close')
    }
  }
}
</script>
