import ConfirmationModal from '@/Components/Subscriptions/ConfirmationModal'

export const subscriptionConfirmationModal = {
  mounted() {
    this.$once(
      'hook:destroyed',
      this.$inertia.on('navigate', () => {
        if (window.location.pathname === '/membership-confirmation') {
          this.openSubscriptionConfirmationModal()
        }
      })
    )
  },

  methods: {
    openSubscriptionConfirmationModal() {
      this.$modal.show(ConfirmationModal,
        {},
        {
          width: '95%',
          maxWidth: 500
        }
      )
    }
  }
}

export default subscriptionConfirmationModal
