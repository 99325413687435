<template>
  <div class="hidden w-full lg:flex flex-row justify-between mb-4">
    <p class="subheading">
      <Link data-testid="curriculum-course-name" :href="$routes.course(course.slug)" @click="onCourseClick" class="link--blue underline"> {{ course.name }}</Link>
      <span class="mx-1">/</span>
      <span data-testid="player-section-lesson-title">{{ lesson.title }}</span>
    </p>

    <div class="flex flex-row">
      <p class="subheading">
        <span data-testid="curriculum-current-lesson" class="subheading--medium">{{ $I18n.t('lesson', i18nScope) }} {{ course.curriculum.currentLessonNumber }}</span>
        <span data-testid="curriculum-num-lessons">{{ $I18n.t('of', i18nScope) }} {{ course.curriculum.lessonsCount }}</span>
      </p>
    </div>
  </div>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue'

import mixpanel from '@/mixins/mixpanel'

export default {
  mixins: [
    mixpanel
  ],

  components: {
    Link
  },

  props: {
    course: {
      type: Object,
      required: true
    },
    lesson: {
      type: Object,
      required: true
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.courses.lessons.show' }
  },

  methods: {
    onCourseClick() {
      this.trackEvent('go_to_course_page', {
        page: window.location.pathname,
        component: 'Free Lesson Header',
        course: this.course.name,
        teacher: this.course.teacher.fullName
      })
    }
  }
}
</script>
