<template>
  <footer class="w-full bg-blue-800">
    <div class="relative px-6 py-8 lg:pt-14 lg:pb-12 mx-auto max-w-screen-2xl md:px-12 lg:px-16">
      <div class="flex flex-col gap-y-8 lg:flex-row items-start">
        <div class="w-full lg:w-1/2 xl:w-2/5 flex flex-col items-center lg:items-start sm:pr-6">
          <!-- <div class="flex flex-row mb-8">
            <img
              loading="lazy"
              :src="spearLogo"
              :alt="$I18n.t('logos.spear', i18nScope)"
              class="w-32 h-16"
            />
          </div> -->

          <Logotype
            testid="link-ca-logo-footer"
            class="h-10 w-auto text-grayscale-100 mb-2 sm:mb-4"
          />

          <p
            class="text-white text-base font-normal text-center lg:text-left mb-6 lg:mb-12 w-72 max-w-full"
          >
            Helping you build your creative confidence and live a more fulfilled life.
          </p>

          <div class="flex flex-row flex-nowrap">
            <a
              v-for="social in socialLinks"
              :key="social.name"
              :href="social.href"
              @click="gtmAddSocialInteraction(social.name)"
              rel="noopener"
              target="_blank"
              class="flex flex-row items-center text-white hover:text-gray-300 transition duration-150 ease-in-out"
              :aria-label="$I18n.t(`link_labels.${social.name.toLowerCase()}`, i18nScope)"
            >
              <Icon :name="social.name" class="text-xl mr-8 sm:mr-10" :class="social.iconClass" />
            </a>
          </div>
        </div>

        <div class="flex gap-x-3 sm:gap-x-6 lg:gap-x-9 w-full lg:w-1/2 xl:w-3/5 justify-between">
          <!-- Explore -->
          <div class="flex w-1/3 flex-col xl:pl-9">
            <p class="font-serif font-medium text-base lg:text-2xl text-grayscale-100 mb-3 md:mb-4">
              Explore
            </p>

            <Link
              v-for="link in exploreLinks"
              :key="link.href"
              :href="link.href"
              @navigate="addFooterClickToGtm"
              class="text-white text-sm lg:text-base hover:text-gray-300 mb-2 lg:mb-3 transition duration-150 ease-in-out"
            >
              {{ link.text }}
            </Link>
          </div>

          <div class="w-1/3 flex flex-col xl:pl-9">
            <!-- Links -->
            <p class="font-serif font-medium text-base lg:text-2xl text-grayscale-100 mb-3 md:mb-4">
              Links
            </p>

            <template v-for="link in links">
              <a
                v-if="link.external"
                :key="link.href"
                :href="link.href"
                target="_blank"
                rel="noopener noreferrer"
                @click="addFooterClickToGtm"
                class="text-white text-sm lg:text-base hover:text-gray-300 mb-2 lg:mb-3 transition duration-150 ease-in-out"
              >
                {{ link.text }}
              </a>

              <Link
                v-else
                :key="link.href"
                :href="link.href"
                @navigate="addFooterClickToGtm"
                class="text-white text-sm lg:text-base hover:text-gray-300 mb-2 lg:mb-3 transition duration-150 ease-in-out"
              >
                {{ link.text }}
              </Link>
            </template>
          </div>

          <div class="w-1/3 flex flex-col xl:pl-9">
            <p class="font-serif font-medium text-base lg:text-2xl text-grayscale-100 mb-3 md:mb-4">
              Support
            </p>

            <a
              v-for="link in supportLinks"
              :key="link.href"
              :href="link.href"
              class="text-white text-sm lg:text-base hover:text-gray-300 mb-2 lg:mb-3 transition duration-150 ease-in-out cursor-pointer"
              @click="addFooterClickToGtm($event)"
            >
              {{ link.text }}
            </a>
          </div>
        </div>
      </div>
      <div class="h-px w-full bg-white opacity-20 my-8 lg:my-10"></div>
      <div class="flex flex-col-reverse lg:flex-row justify-between items-center gap-y-6">
        <p
          class="text-grayscale-100 font-normal text-sm lg:text-base mb-0 text-center lg:text-left"
        >
          {{ $I18n.t('copyright', { ...i18nScope, year: year }) }}
          {{ $I18n.t('register', i18nScope) }}
          <span class="font-normal">{{ $I18n.t('company_no', i18nScope) }}</span>
        </p>
        <img
          width="314"
          height="24"
          class="w-auto h-6 max-w-full"
          src="@/assets/images/backgrounds/banner/payments.png"
          alt="payment modes"
        />
      </div>
    </div>
  </footer>
</template>

<script>
  import gtm from '@/mixins/gtm'
  import cookies from '@/mixins/cookies'
  import { Link } from '@inertiajs/inertia-vue'
  import Logotype from '@/Components/Shared/Logotype'
  import Icon from '@/Components/Shared/Icon'

  /**
   * Footer component for Create Academy
   * @displayName Footer
   */
  export default {
    name: 'Footer',

    mixins: [gtm, cookies],

    components: {
      Link,
      Icon,
      Logotype
    },

    computed: {
      year() {
        return new Date().getFullYear()
      },
      exploreLinks() {
        return [
          {
            href: '/courses',
            text: 'All courses'
          },
          {
            href: this.$routes.category('online-home-and-interiors-courses'),
            text: 'Interior design courses'
          },
          {
            href: this.$routes.category('online-gardening-and-floristry-courses'),
            text: 'Gardening courses'
          },
          {
            href: this.$routes.category('online-food-and-cooking-courses'),
            text: 'Food and Nutrition courses'
          },
          {
            href: this.$routes.category('online-arts-and-craft-courses'),
            text: 'Arts and Craft courses'
          },
          {
            href: this.$routes.category('best-selling-courses'),
            text: 'Bestsellers'
          }
        ]
      },
      links() {
        return [
          {
            href: this.$routes.root(),
            text: this.$I18n.t('home', this.i18nScope)
          },
          {
            href: this.$routes.about_us(),
            text: this.$I18n.t('about_us', this.i18nScope)
          },
          {
            href: this.$routes.gifts(),
            text: this.$I18n.t('gifts', this.i18nScope)
          },
          {
            href: 'https://blog.createacademy.com',
            text: this.$I18n.t('blog', this.i18nScope),
            external: true
          },
          {
            href: 'http://business.createacademy.com/for-teams',
            text: this.$I18n.t('business', this.i18nScope),
            external: true
          },
          {
            href: 'https://page.createacademy.com/affiliate-program',
            text: this.$I18n.t('affiliate', this.i18nScope),
            external: true
          }
        ]
      },
      supportLinks() {
        return [
          {
            href: '/contact-us',
            text: this.$I18n.t('contact', this.i18nScope)
          },
          {
            href: this.$routes.support(),
            text: this.$I18n.t('faqs', this.i18nScope)
          },
          {
            href: this.$routes.privacy_policy(),
            text: this.$I18n.t('privacy_policy', this.i18nScope)
          },
          {
            href: this.$routes.terms_of_use(),
            text: this.$I18n.t('terms', this.i18nScope)
          },
          {
            href: this.$routes.disclaimer(),
            text: this.$I18n.t('disclaimer', this.i18nScope)
          }
        ]
      }
    },

    data() {
      return {
        socialLinks: [
          {
            name: 'Facebook',
            href: 'https://www.facebook.com/createacademyofficial'
          },
          {
            name: 'Instagram',
            href: 'https://www.instagram.com/createacademyofficial'
          },
          {
            name: 'Pinterest',
            href: 'https://www.pinterest.co.uk/CreateAcademyOfficial'
          },
          {
            name: 'Linkedin',
            href: 'https://www.linkedin.com/company/createacademyofficial/',
            iconClass: 'text-xl'
          }
        ],
        spearLogo: require('@/assets/images/spear.svg'),
        i18nScope: { scope: 'pages.footer' }
      }
    },

    methods: {
      gtmAddSocialInteraction(socialName) {},
      addFooterClickToGtm(e) {
        this.gtmAddFooterNavigation(e.target.innerText)
        if (e && e.preventDefault && e.target.getAttribute('target') === '_blank') {
          e.preventDefault()
        }
        if (e.target.getAttribute('target') === '_blank') {
          window.open(e.target.href, '_blank')
        }
      }
    }
  }
</script>
