<template>
  <div>
    <MetaTags :metaTags="metaTags" />

    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <BlogHeader :categories="categories" :currentCategory="null" />

      <div class="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
        <article
          v-for="post in posts"
          :key="post.id"
          class="flex flex-col bg-white shadow-sm overflow-hidden hover:shadow-lg transition-shadow"
        >
          <div class="h-64">
            <img
              v-if="post.featured_image"
              :src="post.featured_image"
              :alt="post.title"
              class="w-full h-full object-cover"
            />
            <div v-else class="w-full h-full bg-gray-100 flex items-center justify-center">
              <span class="text-gray-400">No image</span>
            </div>
          </div>

          <div class="flex-1 p-6">
            <div class="mb-2">
              <Link
                v-if="post.post_category"
                :href="`/blog/categories/${post.post_category.slug}`"
                class="text-sm font-medium text-blue-600 hover:text-blue-800"
              >
                {{ post.post_category.name }}
              </Link>
            </div>

            <h2 class="font-serif text-xl font-medium text-blue-800 mb-3 line-clamp-2">
              <Link :href="`/blog/${post.slug}`" class="hover:text-blue-600">
                {{ post.title }}
              </Link>
            </h2>

            <div class="text-sm text-gray-600 mb-4">
              {{ formatDate(post.published_at) }} | {{ post.reading_time }} min read
            </div>

            <div class="text-gray-700 mb-4 line-clamp-3" v-html="truncate(post.content, 150)"></div>

            <Link
              :href="`/blog/${post.slug}`"
              class="inline-flex items-center text-blue-600 hover:text-blue-800"
            >
              Read more
              <svg class="ml-1 w-4 h-4" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                <path
                  stroke-linecap="round"
                  stroke-linejoin="round"
                  stroke-width="2"
                  d="M9 5l7 7-7 7"
                />
              </svg>
            </Link>
          </div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import Layout from '@/Layouts/Layout'
  import MetaTags from '@/Components/Shared/MetaTags'
  import BlogHeader from '@/Components/Blog/Header'
  import { Link } from '@inertiajs/inertia-vue'

  export default {
    layout: Layout,

    components: {
      MetaTags,
      BlogHeader,
      Link
    },

    props: {
      posts: Array,
      categories: Array,
      metaTags: Object
    },

    methods: {
      formatDate(date) {
        return moment(date).format('MMMM D, YYYY')
      },

      truncate(text, length = 200) {
        if (!text) return ''
        if (text.length <= length) return text
        return text.substring(0, length) + '...'
      }
    }
  }
</script>

<style>
  .line-clamp-2 {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }

  .line-clamp-3 {
    display: -webkit-box;
    -webkit-line-clamp: 3;
    -webkit-box-orient: vertical;
    overflow: hidden;
  }
</style>
