<template>
  <div class="w-full mt-2">
    <div class="w-full flex flex-col md:flex-row md:justify-between md:items-center">
      <p class="mb-4 md:mb-0">{{ file_name }}</p>
      <a :href="file_url" @click="onDownloadClick" class="button button--blue-outline button--small link--blue">{{ cta_name }}</a>
    </div>

    <Divider color="light" spacing="lg" block />
  </div>
</template>


<script>
import mixpanel from '@/mixins/mixpanel'

import Divider from '@/Components/Shared/Divider'

const JANE_SCOTTER_WORKBOOK_DOWNLOAD_EVENT = 'jane-scotter-workbook-download'

export default {
  created() {
    this.i18nScope = { scope: 'pages.courses.lessons.show' }
  },

  mixins: [
    mixpanel
  ],

  components: {
    Divider
  },

  data() {
    return {
      file_name: 'Plan your plot using Jane\'s crop planner',
      cta_name: 'Download Crop Planner',
      file_url: 'https://drive.google.com/uc?export=download&id=11Ix5buqWeZDZ4a3OQ5hmUfgrFNYt_5fj'
    }
  },

  methods: {
    onDownloadClick() {
      this.trackEvent(JANE_SCOTTER_WORKBOOK_DOWNLOAD_EVENT)
    }
  }
}
</script>
