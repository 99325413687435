<template>
  <span>
    <span data-testid="general-announcement-coupon-pre-text">{{ genAnnouncement.couponPreText }}</span>
    <span data-testid="general-announcement-coupon-name" class="underline">{{ genAnnouncement.couponName }}</span>
  </span>
</template>

<script>

export default {
  props: {
    genAnnouncement: {
      type: Object,
      required: true
    }
  }
}
</script>

