var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"box",class:[
    _vm.color && 'box--' + _vm.color,
    _vm.spacing && 'box--space-' + _vm.spacing
]},[(_vm.title)?_c('h3',{staticClass:"subheading--medium mb-4",class:[
      { 'text-yellow-800' : _vm.color == 'light' },
      { 'text-grayscale-700' : _vm.color == 'dark' },
    ]},[_vm._v("\n    "+_vm._s(_vm.title)+"\n  ")]):_vm._e(),_vm._v(" "),_vm._t("default")],2)
}
var staticRenderFns = []

export { render, staticRenderFns }