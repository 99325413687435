export const overflowLogic = {
  methods: {
    setScrollOverflow(overflow) {
      document.documentElement.style.overflow = overflow
    }
  },

  beforeDestroy() {
    this.setScrollOverflow('auto')
  }
}

export default overflowLogic
