<template>
  <div class="w-full lg:w-8/12 lg:pr-12">
    <slot />

    <h3 class="subheading--medium text-yellow-800 mb-6">{{ $I18n.t('summary_title', i18nScope) }}</h3>

    <OrderSummaryRow
      v-for="cartItem in orderableItems"
      :key="cartItem.id"
      :cart-item="cartItem"
      :link-to-course="true"
      :show-gift-info="true"
      :enable-buy-one-gift-one="false"
      :class="{'flex flex-col': isCartItemCampaign(cartItem)}"
      :recipient-info-message="$I18n.t('recipient_info', i18nScope)"
      :no-recipient-message="$I18n.t('no_recipient', i18nScope)"
    >
      <template v-slot:with_recipient>
        {{ $I18n.t('recipient_info', i18nScope) }}
      </template>
      <template v-slot:without_recipient>
        <span class="font-normal">
          {{ $I18n.t('no_recipient', i18nScope) }}
        </span>
      </template>
    </OrderSummaryRow>
  </div>
</template>

<script>
import OrderSummaryRow from '@/Components/Orders/OrderSummaryRow'
import cartItemTypes from '@/mixins/cartItemTypes'

export default {
  mixins: [
    cartItemTypes
  ],

  components: {
    OrderSummaryRow
  },

  props: {
    order: {
      type: Object,
      required: true
    }
  },

  computed: {
    componentName() {
      return `${this.order.orderableType}Card`
    },

    orderableItems() {
      const { orderable, orderableType } = this.order
      if (orderableType === 'Cart') return orderable.cartItems

      // We pass recipientEmail and recipientMessage
      // because for legacy Gift Orders, this information
      // is stored in the Order object instead of the CartItem object
      return [{
        item: orderable,
        itemType: this.order.orderableType,
        humanizedAmount: this.order.humanized_amount,
        recipientEmail: this.order.recipient_email,
        recipientMessage: this.order.recipient_message
      }]
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.users.purchase_item' }
  }
}
</script>
