export const getS3Url = filename => {
  const bucket = window.ENV?.S3_BUCKET_NAME
  const region = window.ENV?.AWS_REGION

  if (!bucket || !region) {
    console.error('S3 bucket or region is missing:', { bucket, region })
    return null
  }

  return `https://${bucket}.s3.${region}.amazonaws.com/interior-category-landing/${filename}`
}
