<template>
  <div class="container">
    <MetaTags :metaTags="metaTags" />

    <!-- bump -->
    <LessonHeader :course="course" :lesson="lesson" />

    <div class="flex flex-row flex-wrap mb-4 lg:mb-6">
      <Lesson360TourSection v-if="embedID" :embedID="embedID" :course="course" :lesson="lesson" gtmPageName="Lessons" />
      <PlayerSection v-else :course="course" :lesson="lesson" :membershipPlan="membershipPlan"
        :lessonEnrollments="lessonEnrollments" :enableUpsell="true" :brightcove="brightcove" gtmPageName="Lessons" />

      <Curriculum :course="course" :lesson="lesson" :lessonEnrollments="lessonEnrollments" />
    </div>

    <LessonTabMenu :course="course" :lesson="lesson" :isMobile="isMobile" />
  </div>
</template>

<script>
import { metaTags } from '@/mixins/metaTags'
import urlParam from '@/mixins/urlParam'
import currentUser from '@/mixins/currentUser'
import gtm from '@/mixins/gtm'

import MetaTags from '@/Components/Shared/MetaTags'
import LessonHeader from '@/Components/Courses/Lessons/LessonHeader'
import LessonTabMenu from '@/Components/Courses/Lessons/LessonTabMenu'

export default {
  mixins: [
    metaTags,
    urlParam,
    currentUser,
    gtm
  ],

  created() {
    if (this.isFreeLessonGtmEvent) {
      const authParam = this.getUrlParam('authentication')
      this.gtmAddFreeLesson(
        'Authentication - ' + authParam,
        this.course.teacher.fullName,
        true
      )
    }
  },

  computed: {
    currentPath() {
      return window.location.pathname
    },
    embedID() {
      return this.currentPath.split('/')
        .find(part => part.includes('360-tour-'))?.split('360-tour-').pop() ?? null
    },
    isFreeLessonGtmEvent() {
      const freeLessonParam = this.getUrlParam('free_lesson')
      return freeLessonParam && !this.enrolled && this.lesson.free
    }
  },

  components: {
    MetaTags,
    LessonHeader,
    Curriculum: () => import('@/Components/Courses/Lessons/Curriculum'),
    Lesson360TourSection: () => import('@/Components/Courses/Lessons/Lesson360TourSection'),
    PlayerSection: () => import('@/Components/Courses/Lessons/PlayerSection'),
    LessonTabMenu
  },

  props: {
    course: {
      type: Object,
      required: true
    },
    lesson: {
      type: Object,
      required: true
    },
    lessonEnrollments: {
      type: Array,
      required: true
    },
    membershipPlan: {
      type: Object,
      default: null
    },
    brightcove: {
      type: Object,
      required: true
    },
    isMobile: {
      type: Boolean,
      default: false
    }
  }
}
</script>
