<template>
  <div>
    <div class="mb-8">
      <h1 class="title-large text-blue-800 mb-4">{{ $I18n.t('title', i18nScope) }}</h1>

      <TextNote>
        {{ $I18n.t('more_info', i18nScope) }}
        <a
          data-testid="link-more-info"
          target="_blank"
          :href="$routes.our_new_platform()"
        >
          {{ $I18n.t('more_info_link', i18nScope) }}
        </a>
        {{ $I18n.t('more_info_end', i18nScope) }}
      </TextNote>
    </div>

    <form @submit.prevent="submit" novalidate="true">
      <InputGroup
        testid="email"
        data-signup-input="email"
        v-model="form.user.email"
        :errors="form.errors.email"
        :label="$I18n.t('form.inputs.email.label', i18nScope)"
        :placeholder="$I18n.t('form.inputs.email.placeholder', i18nScope)"
        type="email"
        autofocus
        autocapitalize="off"
      />

      <InputGroup
        testid="password"
        v-model="form.user.password"
        :errors="form.errors.password"
        :label="$I18n.t('form.inputs.password.label', i18nScope)"
        :placeholder="$I18n.t('form.inputs.password.placeholder', i18nScope)"
        type="password"
      />

      <Checkbox
        testid="terms_and_privacy"
        class="mt-4"
        v-model="form.user.terms_and_privacy"
        :errors="form.errors.terms_and_privacy"
      >
        {{ $I18n.t('conditions.agree', i18nScope) }}
        <a :href="$routes.terms_of_use()" target="_blank">{{ $I18n.t('conditions.terms', i18nScope) }}</a>
        {{ $I18n.t('conditions.and', i18nScope) }}
        <a :href="$routes.privacy_policy()" target="_blank">{{ $I18n.t('conditions.policy', i18nScope) }}</a>
      </Checkbox>

      <Checkbox
        testid="consent_migration"
        class="mt-4"
        v-model="form.user.consent_migration"
        :errors="form.errors.consent_migration"
      >
        {{ $I18n.t('consent_migration', i18nScope) }}
      </Checkbox>

      <Button
        data-testid="button-submit"
        :loading="form.processing"
        type="submit"
        class="mt-4"
        block
      >
        {{ $I18n.t('form.button', i18nScope) }}
      </Button>
    </form>

    <div class="text-center mt-8">
      <TextNote>
        {{ $I18n.t('migrated_yet', i18nScope) }}
        <Link
          data-testid="link-signin"
          :href="$routes.new_user_session()"
        >
          {{ $I18n.t('sign_in', i18nScope) }}
        </Link>
      </TextNote>
    </div>
  </div>
</template>

<script>
import mixpanel from '@/mixins/mixpanel'
import { Link } from '@inertiajs/inertia-vue'

import InputGroup from '@/Components/Shared/Forms/InputGroup'
import Button from '@/Components/Shared/Button'
import Checkbox from '@/Components/Shared/Forms/Checkbox'
import TextNote from '@/Components/Shared/TextNote'

export default {
  mixins: [
    mixpanel
  ],

  components: {
    InputGroup,
    Button,
    Checkbox,
    Link,
    TextNote
  },

  props: {
    email: String
  },

  created() {
    this.i18nScope = { scope: 'pages.auth.migrate' }
  },

  mounted() {
    document.querySelector('[data-signup-input="email"]').addEventListener('click', this.emailInputGroupClick)
  },

  data() {
    return {
      form: this.$inertia.form({
        user: {
          email: this.email,
          password: null,
          terms_and_privacy: false,
          consent_migration: false
        }
      })
    }
  },

  methods: {
    submit() {
      const path = this.$routes.user_migration()
      this.form.post(path, {
        onSuccess: json => {
          this.$emit('success')

          this.trackSignUp(json?.props?.auth?.user)
          this.trackEvent('user_migrated')
        }
      })
    },

    emailInputGroupClick(e) {
      if (e.target.dataset.link === 'sign_in') { // if the click is on the sign in link
        this.$emit('toggleSignInUp')
      }
    }
  }
}
</script>
