<template>
  <div class="bg-gray-50">
    <div class="container mx-auto px-4 py-8 2xl:py-8">
      <MetaTags :metaTags="metaTags" />
      <!-- Title & Subhead -->
      <div class="text-center px-4 md:px-0 mb-8">
        <div class="title-largest mb-1">Customise your gift</div>
      </div>

      <div
        class="flex flex-col justify-center mb-0 w-full sm:w-4/5 lg:w-2/3 xl:w-1/2 mx-auto max-w-2xl"
      >
        <div class="bg-white rounded-lg shadow-sm p-6 sm:p-8">
          <GiftForm
            :campaigns="campaigns"
            :preSelectedCampaign="preSelectedCampaign"
            :gtmPageName="currentPage"
          />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
  import { metaTags } from '@/mixins/metaTags'
  import MetaTags from '@/Components/Shared/MetaTags'
  // import Radio from '@/Components/Shared/Forms/Radio'
  // import InputGroup from '@/Components/Shared/Forms/InputGroup'
  // import TextArea from '@/Components/Shared/Forms/TextArea'
  import GiftForm from '@/Components/Shared/Gifts/Form'

  export default {
    mixins: [metaTags],

    components: {
      MetaTags,
      // Radio,
      // InputGroup,
      // TextArea,
      GiftForm
    },

    props: {
      campaigns: {
        type: Array,
        default: () => []
      },
      preSelectedCampaign: {
        type: String,
        required: true
      }
    },

    computed: {
      currentPage() {
        return 'Gifts'
      }
    },

    created() {
      this.i18nScope = { scope: 'pages.gifts' }
    }
  }
</script>
