<template>
  <li class="col-span-12 md:col-span-6 lg:col-span-4 h-full">
     <Link
      :href="$routes.course_lesson(lesson.course.slug, lesson.slug)"
      >
      <div class="relative   h-auto flex-shrink-0">
        <div>
          <div class="flex relative w-full h-64">
            <!-- THUMBNAIL -->
            <img loading="lazy" :src="lesson.poster" class="lazyload image-cover">

            <!-- PLAY BUTTON -->
            <div  class="flex justify-center items-center absolute inset-0 cursor-pointer bg-grayscale-100 bg-opacity-0 hover:bg-opacity-30 transition-colors">
              <div class="bg-grayscale-100 bg-opacity-80 w-16 h-16 rounded-full flex justify-center items-center">
                <Icon name="PlayFull" class="text-blue-800 text-2xl relative left-0.5" />
              </div>
            </div>
            <Tags
              v-if="!preLaunch"
              :data-testid="`${lesson.course.id}-lesson-duration`"
              class="absolute bottom-2 right-2 p-0.5 opacity-80"
              color="inverse"
              size="xs"
            >
              <span class="overline--medium">{{ lesson.duration }}</span>
            </Tags>

            <Tags
              v-if="preLaunch"
              class="absolute bottom-2 left-2 flex flex-row items-center"
              color="highlight"
              size="small"
            >
              <PreLaunchTag
                :course="lesson.course"
                :i18nScope="i18nScope"
                :testId="`lesson-prelaunch-${lesson.course.id}`"
                class="overline--medium overline--widest"
              />
            </Tags>

          </div>

          <div class="mt-3 text-grayscale-700">
            <!-- CURRENT LESSON -->
            <template>
              <h3  class="flex flex-row items-start font-medium mb-2">
                <Icon name="Unfinished" class="text-black flex-shrink-0 text-xs  mr-2" />
                {{ lesson.title }}
              </h3>
            </template>

            <!-- COURSE INFO -->
            <div class="flex flex-row flex-nowrap justify-between">
              <div class="flex flex-col">
                <p class="font-normal">
                  {{ lesson.course.name }}
                </p>
                <p  class="text-sm">{{ lesson.instructor.name }}</p>
              </div>
            <Link v-if="!preLaunch" :data-testid="`lesson-search-watching-${lesson.course.id}-course-page-link`" :href="$routes.course(lesson.course.slug)">
              <Icon name="Info" class="text-blue-500 text-xl" />
            </Link>
            </div>

          </div>
        </div>
      </div>
   </Link>
  </li>
</template>

<script>
import statuses from '@/mixins/statuses'
import currentUser from '@/mixins/currentUser'
import Icon from '@/Components/Shared/Icon.vue'
import { Link } from '@inertiajs/inertia-vue'
import Tags from '@/Components/Shared/Tags'
import PreLaunchTag from '@/Components/Shared/Courses/PreLaunchTag'




export default {
  mixins: [
    statuses,
    currentUser
  ],
  props: ['lesson'],
  components: { Icon, Link, Tags, PreLaunchTag },
  created() {
    this.i18nScope = { scope: 'pages.home.continue_watching' }
  }
}

</script>
