<template>
  <div>
    <h2 class="title-medium text-blue-800 mb-6 mt-6">{{ $I18n.t('sections.billing_information_title', i18nScope) }}</h2>

    <div class="flex flex-row flex-wrap">
      <InputGroup
        testid="billing_name"
        v-model="localForm.order.billing_name"
        :errors="errors.billing_name"
        :label="$I18n.t('form.inputs.billing_name.label', i18nScope)"
        :placeholder="$I18n.t('form.inputs.billing_name.placeholder', i18nScope)"
        :required="true"
        class="w-full"
      />

      <InputGroup
        testid="billing_address"
        v-model="localForm.order.billing_address"
        :errors="errors.billing_address"
        :label="$I18n.t('form.inputs.billing_address.label', i18nScope)"
        :placeholder="$I18n.t('form.inputs.billing_address.placeholder', i18nScope)"
        class="w-full"
      />

      <InputGroup
        testid="billing_city"
        v-model="localForm.order.billing_city"
        :errors="errors.billing_city"
        :label="$I18n.t('form.inputs.billing_city.label', i18nScope)"
        :placeholder="$I18n.t('form.inputs.billing_city.placeholder', i18nScope)"
        class="w-1/2 pr-2 sm:w-1/3"
      />

      <InputGroup
        testid="billing_post_code"
        v-model="localForm.order.billing_post_code"
        :errors="errors.billing_post_code"
        :label="$I18n.t('form.inputs.billing_post_code.label', i18nScope)"
        :placeholder="$I18n.t('form.inputs.billing_post_code.placeholder', i18nScope)"
        :required="true"
        class="w-1/2 sm:w-1/3 sm:pr-2"
      />

      <SelectGroup
        testid="billing_country"
        v-model="localForm.order.billing_country"
        :options="countries"
        :errors="errors.billing_country"
        :label="$I18n.t('form.inputs.billing_country.label', i18nScope)"
        :placeholder="$I18n.t('form.inputs.billing_country.placeholder', i18nScope)"
        :required="true"
        class="w-full sm:w-1/3"
      />
    </div>
  </div>
</template>

<script>
import InputGroup from '@/Components/Shared/Forms/InputGroup'
import SelectGroup from '@/Components/Shared/Forms/SelectGroup'

export default {
  components: {
    InputGroup,
    SelectGroup
  },

  data() {
    return {
      localForm: this.form
    }
  },

  props: {
    errors: {
      type: Object,
      required: true
    },
    form: {
      type: Object,
      required: true
    },
    i18nScope: {
      type: Object,
      required: true
    },
    countries: {
      type: Array,
      required: true
    }
  }
}
</script>
