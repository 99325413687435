<template>
  <div>
    <MetaTags :metaTags="metaTags" />

    <!-- Feature Banner -->
    <Slider
      v-if="!isSubscribed"
      :brightcove="brightcove.sliderPlayer"
      :slides="slides"
      sliderId="home-banner-slider"
    />

    <template v-if="!isSubscribed">
      <!-- Press References -->
      <div class="mt-12">
        <SerifHeader title="As Seen In" content-align="center" aria-label="talent" />
      </div>
      <PressReferences :pressReferences="pressReferences" sliderId="home-press-references-slider" />

      <!-- <Divider spacing="xl" /> -->
    </template>

    <GiftVoucherBanner />

    <!-- Continue Watching Section -->
    <ContinueWatchingSection v-if="currentUser" />

    <FeaturedCollectionSection
      :brightcove="brightcove.lessonPlayer"
      :gtmPageName="currentPage"
      :membershipPlan="membershipPlan"
    />

    <div v-if="!isSubscribed" class="container">
      <!-- Seasonal Banner -->
      <SeasonalBanner
        v-if="seasonalBanner.active && !currentUser"
        :seasonalBanner="seasonalBanner"
      />

      <!-- Membership Banner -->
      <CategoryMembershipBanner
        v-if="category.showMembershipBanner"
        :category="category"
        :membershipPlan="membershipPlan"
        :geoProps="geoProps"
        :customImage="true"
        component="Home Promotional Banner"
      />

      <Divider v-if="!seasonalBanner.active && !category.showMembershipBanner" spacing="xl" />

      <div class="w-full h-px inline-block pt-12 lg:pt-16"></div>
      <!-- Course Slider Section -->
      <CourseSliderSection
        v-if="!currentUser"
        :categories="categories"
        :brightcove="brightcove"
        :gtmPageName="currentPage"
        :membershipPlan="membershipPlan"
      />
    </div>

    <!-- Gift Banner -->
    <GiftingBanner v-if="!currentUser" />

    <div class="container pb-20 pt-8 lg:pt-14">
      <!-- Categories Section -->
      <CategorySection v-if="!currentUser" :categories="categories" :gtmPageName="currentPage" />

      <!-- Upcoming Courses Section -->
      <UpcomingCourseSection :gtmPageName="currentPage" />

      <template v-if="!currentUser">
        <Testimonials />
        <Divider spacing="xl" />
      </template>

      <template v-if="!currentUser">
        <SerifHeader :title="$I18n.t('faq', i18nScope)" content-align="center" />

        <Faq :gtmPageName="currentPage" class="max-w-5xl mx-auto" />

        <ButtonLink
          data-testid="all-courses-button"
          href="/courses"
          class="mt-8 mx-auto"
          variant="yellow"
        >
          Explore All courses
        </ButtonLink>
      </template>
    </div>
  </div>
</template>

<script>
  import { metaTags } from '@/mixins/metaTags'
  import currentUser from '@/mixins/currentUser'
  import statuses from '@/mixins/statuses'
  import subscriptionConfirmationModal from '@/mixins/subscriptionConfirmationModal'
  import mixpanelMixin from '@/mixins/mixpanel'
  import ButtonLink from '@/Components/Shared/ButtonLink'
  import Divider from '@/Components/Shared/Divider'
  import Faq from '@/Components/Shared/Faq/Index'
  import GiftingBanner from '@/Components/Shared/Gifts/Banner'
  import MetaTags from '@/Components/Shared/MetaTags'
  import PressReferences from '@/Components/Shared/PressReferences/Index'
  import SeasonalBanner from '@/Components/Shared/SeasonalBanner'
  import SerifHeader from '@/Components/Shared/SectionHeader/Serif'
  import Slider from '@/Components/Shared/Slider'
  import Testimonials from '@/Components/Shared/Testimonials/Show'
  import CategoryMembershipBanner from '@/Components/Shared/Categories/CategoryMembershipBanner'
  import { InertiaLink } from '@inertiajs/inertia-vue'
  import GiftVoucherBanner from '@/Components/Shared/GiftVoucherBanner'

  export default {
    mixins: [metaTags, currentUser, statuses, subscriptionConfirmationModal, mixpanelMixin],

    components: {
      ButtonLink,
      Divider,
      Faq,
      GiftingBanner,
      MetaTags,
      PressReferences,
      SeasonalBanner,
      SerifHeader,
      Slider,
      InertiaLink,
      Testimonials,
      CategoryMembershipBanner,
      ContinueWatchingSection: () =>
        import('@/Components/Shared/Dashboard/ContinueWatchingSection'),
      FeaturedCollectionSection: () =>
        import('@/Components/Shared/Dashboard/FeaturedCollectionSection'),
      CourseSliderSection: () => import('@/Components/Shared/Dashboard/CourseSliderSection'),
      CategorySection: () => import('@/Components/Shared/Dashboard/CategorySection'),
      UpcomingCourseSection: () => import('@/Components/Shared/Dashboard/UpcomingCourseSection'),
      GiftVoucherBanner
    },

    props: {
      slides: {
        type: Array,
        default: () => []
      },
      brightcove: {
        type: Object,
        required: true
      },
      categories: {
        type: Array,
        default: () => []
      },
      category: {
        type: Object,
        default: () => ({})
      },
      about: {
        type: Object
      },
      pressReferences: {
        type: Array,
        default: () => []
      },
      seasonalBanner: {
        type: Object,
        default: () => ({})
      },
      collections: {
        type: Array,
        default: () => []
      },
      geoProps: {
        type: Object,
        required: true
      },
      membershipPlan: {
        type: Object,
        default: null
      }
    },

    data() {
      return {}
    },

    computed: {
      currentPage() {
        return 'Home'
      }
    },

    methods: {
      visibilityChanged(isVisible, entry) {
        const key = entry.target.getAttribute('aria-label')
        this.visibility[key] = isVisible
      },
      trackHomeView() {
        this.trackEvent('Home Viewed: User Home', {
          isSubscribed: this.isSubscribed,
          hasCurrentUser: !!this.currentUser
        })
      }
    },
    mounted() {
      sessionStorage.clear()
      this.trackHomeView()
    },
    created() {
      this.i18nScope = { scope: 'pages.home.section_titles' }
    }
  }
</script>
