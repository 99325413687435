export const stringHelpers = {
  methods: {
    truncateByWords(string, maxWords) {
      const array = string.trim().split(' ')
      const wordCount = array.length
      let result = array.splice(0, maxWords).join(' ')

      if(wordCount > maxWords) {
          result += '...'
      }
      return result
    }
  }
}

export default stringHelpers
