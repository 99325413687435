<template>
  <div class="flex flex-col lg:flex-row">
    <div class="w-full lg:w-60 lg:px-4">
      <MenuItem :body="$I18n.t('offers.title', i18nScope)" text>
        <div class="flex flex-col py-2 lg:pb-0">
          <a
            v-for="offer in offerList"
            :key="offer.name"
            :data-testid="`navbar-link-offer-${screen}-${offer.name}`"
            :href="offer.url"
            rel="noopener"
            :target="[ offer.external_url? '_blank' : '_self' ]"
            class="menu-sub-link">
              {{ $I18n.t(`offers.links.${offer.name}`, i18nScope) }}
          </a>
        </div>
      </MenuItem>
    </div>
    <div class="w-full lg:w-80 lg:px-4">
      <MenuItem :body="$I18n.t('edit.title', i18nScope)" text>
        <div class="flex flex-col py-2 lg:pb-0">
          <a
            v-for="edit in edits"
            :key="edit.name"
            :data-testid="`navbar-link-edit-${screen}`"
            :href="edit.url"
            rel="noopener"
            :target="[ edit.external_url? '_blank' : '_self' ]"
            class="menu-sub-link">
              {{ $I18n.t(`edit.links.${edit.name}`, i18nScope) }}
          </a>
        </div>
      </MenuItem>
    </div>
  </div>
</template>


<script>
import currentUser from '@/mixins/currentUser'

import MenuItem from '@/Components/Shared/Objects/Navbar/Mobile/MenuItem'

export default {
  mixins: [
    currentUser
  ],

  components: {
    MenuItem
  },

  props: {
    screen: {
      type: String,
      default: 'desktop'
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.navbar.seasonal' }
  },

  data() {
    return {
      offers: [
        {
          name: 'bundle',
          url: 'https://collections.createacademy.com/christmas-offers',
          external_url: true
        },
        {
          name: 'vouchers',
          url: '/gifts',
          external_url: false
        }
      ],

      offersSubs: [
        {
          name: 'vouchers',
          url: '/gifts',
          external_url: false
        }
      ],

      edits: [
        {
          name: 'article_1',
          url: 'https://blog.createacademy.com/christmas-wine-pairings-with-helen-mcginn-and-the-bib-wine-company/',
          external_url: true
        },
        {
          name: 'article_2',
          url: 'https://blog.createacademy.com/diy-block-print-christmas-napkins-tutorial-with-molly-mahon/ ',
          external_url: true
        },
        {
          name: 'article_3',
          url: 'https://blog.createacademy.com/the-land-gardeners-how-to-force-bulbs/',
          external_url: true
        },
        {
          name: 'article_4',
          url: 'https://blog.createacademy.com/thomasina-miers-festive-spiced-nuts-recipe/',
          external_url: true
        },
        {
          name: 'article_5',
          url: 'https://blog.createacademy.com/thomasina-miers-sloe-ginger-margaritas-recipe/',
          external_url: true
        }
      ]
    }
  },

  computed: {
    offerList() {
      return this.isSubscribed ? this.offersSubs : this.offers
    }
  }
}
</script>
