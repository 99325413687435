<template>
  <div class="container px-4 lg:px-16 py-12 lg:py-14 flex flex-col">
    <div class="flex flex-col mb-6 lg:mb-12 gap-1 lg:gap-3">
      <h2 class="text-3xl lg:text-4xl font-serif font-medium text-black text-center">More Ways To Get Creative</h2>
      <p class="text-center text-base lg:text-xl text-black font-normal">Ready to express yourself? Take a look at more courses across a range of categories. </p>
    </div>
    <div class="w-full relative">
      <div class="flex flex-wrap -mx-1 lg:-mx-3.5 gap-y-2">
        <div
          v-for="(square, index) in squares"
          :key="index"
          class="w-1/2 lg:w-1/4 px-1 lg:px-3.5 mb-0 lg:mb-0"
        >
          <Link
            :href="square.link"
            class="block relative hover:opacity-90 transition-opacity"
          >
            <div class="relative h-60 lg:h-112">
              <img
                :src="square.imageSrc"
                :alt="square.altText"
                class="w-full h-full object-cover"
              />
              <div class="absolute inset-0 bottom-2 lg:bottom-8 flex items-end ">
                <h3
                  class="text-black  flex items-start  bg-grayscale-100 justify-center sm:text-black py-3 lg:py-5 text-xs lg:text-base  customTracking font-brandon font-medium text-center w-full"
                >
                  {{ square.title.toUpperCase() }}
                </h3>
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import { defineComponent } from 'vue'
  import { Link } from '@inertiajs/inertia-vue'

  export default defineComponent({
    name: 'FourSquares',
    components: {
      Link
    },
    props: {
      squares: {
        type: Array,
        required: true
      }
    }
  })
</script>

<style scoped>
  .pb-full {
    padding-bottom: 100%;
  }
  @media(max-width:640px) {
    .pb-full {
      padding-bottom: 0%;
    }
  }
</style>
