<template>
  <form @submit.prevent="submit" novalidate="true">
    <div class="flex flex-row flex-wrap">
      <div class="w-full xs:w-8/12 mt-6 xs:mt-0 order-2 xs:order-1">
        <h3 class="subheading--medium text-yellow-800 mb-6">My details</h3>

        <InputGroup
          testid="name"
          v-model="form.user.account_attributes.name"
          :errors="form.errors['account.name']"
          :label="$I18n.t('form.inputs.name.label', i18nScope)"
          :placeholder="$I18n.t('form.inputs.name.placeholder', i18nScope)"
          block
        />

        <SelectGroup
          testid="age"
          v-model="form.user.account_attributes.age"
          :options="account.age_options"
          :errors="form.errors['account.age']"
          :label="$I18n.t('form.inputs.age.label', i18nScope)"
          :placeholder="$I18n.t('form.inputs.age.placeholder', i18nScope)"
          block
        />

        <SelectGroup
          testid="employment"
          v-model="form.user.account_attributes.employment"
          :options="account.employment_options"
          :errors="form.errors['account.employment']"
          :label="$I18n.t('form.inputs.employment.label', i18nScope)"
          :placeholder="$I18n.t('form.inputs.employment.placeholder', i18nScope)"
          block
        />

        <CheckboxGroup
          testid="interest"
          :selectedOptions.sync="form.user.account_attributes.interests"
          :options="account.interests_options"
          :label="$I18n.t('form.inputs.interests.label', i18nScope)"
          :errors="form.errors['account.interests']"
          class="py-4"
        />

        <InputGroup
          testid="email"
          v-model="form.user.email"
          :errors="form.errors.email"
          :label="$I18n.t('form.inputs.email.label', i18nScope)"
          :placeholder="$I18n.t('form.inputs.email.placeholder', i18nScope)"
          type="email"
          autocapitalize="off"
          block
        />

        <Button
          data-testid="button-submit"
          :loading="form.processing"
          :variant="buttonVariant"
          type="submit"
          class="hidden md:inline-block mt-4"
        >
          {{ $I18n.t('form.button', i18nScope) }}
        </Button>
      </div>

      <div class="w-full xs:w-4/12 order-1 xs:order-2 xs:pl-4 xs:text-center md:pl-10">
        <h3 class="subheading--medium text-yellow-800 mb-6">{{ $I18n.t('profile_picture', i18nScope) }}</h3>

        <div class="relative w-36 h-36 md:w-48 md:h-48 m-auto ring-2 ring-neutral-100">
          <img loading="lazy" data-testid="account-details-avatar" :src="currentUser.account.avatar_path" :alt="$I18n.t('account_avatar', i18nScope)" class="image-cover rounded-sm">

          <InputAvatar
            testid="avatar"
            v-model="form.user.account_attributes.avatar"
            :errors="form.errors['account.avatar']"
            accept="image/*"
            @input="onAvatarChange"
          />
        </div>

        <Link
          v-if="account.avatarAttached"
          data-testid="link-remove-avatar"
          :href="$routes.avatar_users()"
          method="delete"
          as="button"
          class="w-full text-center text-danger underline mt-4"
        >
          {{ $I18n.t('remove_avatar', i18nScope) }}
        </Link>
      </div>
    </div>
  </form>
</template>

<script>
import currentUser from '@/mixins/currentUser'
import dirtyForm from '@/mixins/dirtyForm'
import confirmLeave from '@/mixins/confirmLeave'
import mixpanel from '@/mixins/mixpanel'

import { Link } from '@inertiajs/inertia-vue'
import InputGroup from '@/Components/Shared/Forms/InputGroup'
import InputAvatar from '@/Components/Shared/Forms/InputAvatar'
import SelectGroup from '@/Components/Shared/Forms/SelectGroup'
import CheckboxGroup from '@/Components/Shared/Forms/CheckboxGroup'
import Button from '@/Components/Shared/Button'

export default {
  mixins: [
    currentUser,
    mixpanel,
    dirtyForm('form.user'),
    confirmLeave
  ],

  created() {
    this.i18nScope = { scope: 'pages.users.profile' }

    // Used by the confirmLeave mixin
    this.formSubmitPath = [this.$routes.update_profile_users()]
  },

  components: {
    Link,
    InputGroup,
    InputAvatar,
    SelectGroup,
    CheckboxGroup,
    Button
  },

  props: {
    account: {
      type: Object,
      required: true
    }
  },

  data() {
    // Here we have to get the currentUser directly from the props instead
    // of using the currentUser computed property, because the data is evaluated
    // before computed properties are.
    const authUser = this.$page.props.auth.user

    return {
      form: this.$inertia.form({
        user: {
          email: authUser.email,
          account_attributes: {
            id: authUser.account?.id,
            name: authUser.account?.name,
            age: this.account.age,
            interests: this.account.interests,
            employment: this.account.employment,
            avatar: undefined
          }
        }
      }),
      savedAvatarUrl: authUser.account?.avatar_path
    }
  },

  computed: {
    buttonVariant() {
      return this.formDirty ? 'blue' : 'inactive'
    }
  },

  methods: {
    onAvatarChange(file) {
      if (file) {
        this.currentUser.account.avatar_path = URL.createObjectURL(file)
      }
      else {
        this.currentUser.account.avatar_path = this.savedAvatarUrl
      }
    },

    submit() {
      this.form.post(this.$routes.update_profile_users(), {
        preserveScroll: true,
        onSuccess: () => {
          this.form.user.account_attributes.avatar = undefined

          const { name, age, employment, interests } = this.form.user.account_attributes
          this.trackProfile({ '$name': name, age, employment, interests })

          this.$nextTick(() => { this.formDirty = false })
        }
      })
    }
  }
}
</script>
