<template>
  <div class="flex flex-col items-center pb-10">
    <div class="w-full max-w-full px-4 xl:px-0 xl:max-w-screen-lg">
      <div class="flex items-center justify-center max-w-screen-lg w-full my-6">
        <Logotype class="w-auto h-7 navbar__logo" color="#000" />
      </div>
      <div class="flex items-center justify-center gap-6 mb-9">
        <div class="bg-blue-300 h-1.5 w-44 sm:w-112 md:w-full rounded-md relative">
          <span class="absolute left-0 rounded-md top-0 h-full w-full bg-blue-800"></span>
        </div>
      </div>
      <div class="flex flex-col gap-5 lg:gap-0 w-full">
        <div class="flex flex-col gap-5 lg:gap-3 w-full">
          <h4
            class="my-0 lg:my-8 lg:mb-12 font-serif text-3xl leading-9 lg:text-4xl lg:leading-10 font-medium text-black text-center"
          >
            Log in to Your Account
          </h4>
        </div>
        <div class="flex flex-col gap-2 w-full max-w-sm mx-auto">
          <InputGroup
            testid="onboarding_signup_email"
            label="EMAIL ADDRESS"
            placeholder="Enter your email address"
            type="email"
            autofocus
            :disabled="true"
            v-model="form.user.email"
            autocapitalize="off"
          />
          <InputGroup
            testid="onboarding_signup_password"
            label="PASSWORD"
            placeholder="Enter your password"
            type="password"
            v-model="form.user.password"
            :errors="form.errors.password"
            autofocus
            autocapitalize="off"
          />
          <div class="max-w-15 mx-auto mt-5 lg:mt-6">
            <a class="button button--blue w-full text-base mx-auto" @click="submit"> Login</a>
            <div class="w-full text-center mt-6">
              <a class="link--small link--yellow underline" :href="$routes.new_user_password()">
                Forgot your password?</a
              >
            </div>
            <!-- <Divider color="neutral-400" class="w-full" label="or"></Divider>
              <SocialButtons
                :formAuthenticityToken="formAuthenticityToken"
                :i18nScope="i18nScope"
                :queryParameters="queryParameters"
              /> -->
          </div>
          <div class="w-full text-center pb-10 lg:pb-0">
            <TextNote class="text-grayscale-900 text-base font-normal mt-4 w-full text-center">
              {{ $I18n.t('not_a_member', i18nScope) }}

              <CustomLink data-testid="link-signup" class="underline">
                {{ $I18n.t('sign_up', i18nScope) }}
              </CustomLink>
            </TextNote>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import Layout from '@/Layouts/ReducedLayout'
  import Logotype from '@/Components/Shared/Logotype'
  import authForm from '@/mixins/authForm'

  const STORAGE_NAME = 'ca_sign_in_email'

  export default {
    mixins: [authForm],

    layout: Layout,

    components: {
      Logotype
    },

    props: {
      user: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        form: {
          user: {
            email: this.user.email,
            password: null,
            remember_me: true
          },
          extra_action: this.extraAction,
          errors: {}
        }
      }
    },

    methods: {
      submit() {
        const { email, password } = this.form.user
        this.form.errors = {}

        const actualPageComponent = this.$page.component

        // Validate required fields before sending the request
        if (email && password) {
          this.$inertia.post(this.$routes.user_session(), this.form, {
            headers: {
              'X-NEXT-PATH': this.getQueryParam('redirect_to') ?? this.next
            },
            onSuccess: page => {
              // If we stayed in the same page, means sign in failed, so we return
              if (!this.isModal && page.component === actualPageComponent) return

              // Otherwise sign in succeeded, we we save the email to the local storage
              localStorage?.setItem(STORAGE_NAME, email)
              this.$emit('success')

              this.trackEvent('user_signed_in', this.mixpanelCartData(this.orderable))
            }
          })
        }

        if (!email) {
          this.form.errors.email = [
            this.$I18n.t('activerecord.errors.models.user.attributes.email.blank')
          ]
        }
        if (!password) {
          this.form.errors.password = [
            this.$I18n.t('activerecord.errors.models.user.attributes.password.blank')
          ]
        }
      }
    },

    created() {
      this.i18nScope = { scope: 'pages.auth.sign_in' }
    }
  }
</script>
