const mixpanelMixin = {
  computed: {
    mixpanelToken() {
      return this.$page.props.mixpanel.token
    }
  },

  methods: {
    async loadMixpanel() {
      if (!this.mixpanelToken) return
      if (this._mixpanel) return this._mixpanel

      // load mixpanel dynamically so it's fetched in a separate chunk
      const mixpanel = await import('mixpanel-browser')
      this._mixpanel = mixpanel.default

      // https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpanelinit
      const { debug } = this.$page.props.mixpanel
      this._mixpanel.init(this.mixpanelToken, { debug })

      return this._mixpanel
    },

    identify(userId) {
      if (!this.mixpanelToken) return

      this.loadMixpanel().then(mixpanel => {
        // https://developer.mixpanel.com/docs/javascript#identify
        mixpanel.identify(userId)
      })
    },

    trackSignUp(user) {
      if (!this.mixpanelToken || !user) return

      this.loadMixpanel().then(mixpanel => {
        // https://developer.mixpanel.com/docs/javascript#alias
        mixpanel.alias(user.id)
        this.trackProfile({ '$email': user.email })
      })
    },

    trackProfile(profile) {
      if (!this.mixpanelToken) return

      this.loadMixpanel().then(mixpanel => {
        // https://developer.mixpanel.com/docs/javascript#setting-profile-properties
        mixpanel.people.set(profile)
      })
    },

    trackLogout() {
      if (!this.mixpanelToken) return

      this.loadMixpanel().then(mixpanel => {
        // https://developer.mixpanel.com/docs/javascript#call-reset-at-logout
        mixpanel.reset()
      })
    },

    trackEvent(eventName, eventData) {
      if (!this.mixpanelToken) return

      this.loadMixpanel().then(mixpanel => {
        // https://developer.mixpanel.com/docs/javascript#track-events
        mixpanel.track(eventName, eventData)
      })
    },

    timeEvent(eventName) {
      if (!this.mixpanelToken) return

      this.loadMixpanel().then(mixpanel => {
        // https://developer.mixpanel.com/docs/javascript-full-api-reference#mixpaneltime_event
        mixpanel.time_event(eventName)
      })
    }

  }
}

export default mixpanelMixin
