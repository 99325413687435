<template>
  <div>
    <MetaTags :metaTags="metaTags" />

    <Tabs />

    <div class="md:mt-14 container-sm">
      <h1 class="title-large text-blue-800 mb-8">{{ $I18n.t('title', i18nScope) }}</h1>

      <ProfileForm :account="account" />

      <div class="md:w-8/12">
        <template v-if="membershipPlan">
          <Divider spacing="lg" />

          <MembershipDetails :membershipPlan="membershipPlan" />
        </template>

        <Divider spacing="lg" />

        <SocialAccounts :identities="account.identities" />

        <Divider spacing="lg" />

        <AccountManagement />
      </div>
    </div>
  </div>
</template>

<script>
import { metaTags } from '@/mixins/metaTags'
import currentUser from '@/mixins/currentUser'

import MetaTags from '@/Components/Shared/MetaTags'
import Tabs from '@/Components/User/Tabs'
import ProfileForm from '@/Components/User/ProfileForm'
import SocialAccounts from '@/Components/User/SocialAccounts'
import AccountManagement from '@/Components/User/AccountManagement'

import Divider from '@/Components/Shared/Divider'
import MembershipDetails from '../../Components/User/MembershipDetails.vue'

export default {
  mixins: [metaTags, currentUser],

  created() {
    this.i18nScope = { scope: 'pages.users.profile' }
  },

  components: {
    MetaTags,
    Tabs,
    ProfileForm,
    SocialAccounts,
    AccountManagement,
    Divider,
    MembershipDetails
},

  props: {
    account: {
      type: Object,
      required: true
    },
    membershipPlan: {
      type: Object,
      required: true
    }
  }
}
</script>
