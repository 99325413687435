export const cookies = {
  methods: {
    getCookie(name) {
      const value = `; ${document.cookie}`
      const parts = value.split(`; ${name}=`)
      if (parts.length === 2) return parts.pop().split(';').shift()
    }
  },

  computed: {
    showMembershipOffer() {
      return this.getCookie('x-ca-promo') === 'membership'
    }
  }
}

export default cookies
