<template>
  <div class="flex flex-col items-center justify-center">
    <div class="mb-6 w-full">
      <h1 data-testid="signup-form-title" class="title-largest text-left text-blue-800 mb-0">{{ contentText.title }}</h1>
      <p class="font-normal text-left">{{ contentText.subTitle }}</p>
    </div>

    <form @submit.prevent="submit" novalidate="true" class="w-full loginInput flex flex-col">
      <InputGroup
        testid="name"
        data-signup-input="name"
        v-model="form.user.account_attributes.name"
        :errors="form.errors.name"
        :label="$I18n.t('form.inputs.name.label', i18nScope)"
        :placeholder="$I18n.t('form.inputs.name.placeholder', i18nScope)"
        type="text"
        autofocus
        autocapitalize="on"
      />

      <InputGroup
        testid="email"
        data-signup-input="email"
        v-model="form.user.email"
        :errors="form.errors.email"
        :label="$I18n.t('form.inputs.email.label', i18nScope)"
        :placeholder="$I18n.t('form.inputs.email.placeholder', i18nScope)"
        type="email"
        autocapitalize="off"
      />

      <InputGroup
        testid="password"
        v-model="form.user.password"
        :errors="form.errors.password"
        :label="$I18n.t('form.inputs.password.label', i18nScope)"
        :placeholder="$I18n.t('form.inputs.password.placeholder', i18nScope)"
        type="password"
      />

      <Checkbox
        testid="terms_and_privacy"
        v-model="form.user.terms_and_privacy"
        :errors="form.errors.terms_and_privacy"
        class=" text-gray-900 font-normal text-base text-left"
      >
        {{ $I18n.t('checkbox_label', i18nScope) }}
      </Checkbox>

      <!-- <Checkbox
        testid="marketing"
        v-model="form.user.account_attributes.notifications_enabled"
        :errors="form.errors['account.notifications_enabled']"
        class="text-sm"
      >
        {{ $I18n.t('marketing', i18nScope) }}
      </Checkbox> -->

      <!-- <p class=" text-gray-900 font-medium text-sm text-left">{{ $I18n.t('terms_text', i18nScope) }}</p> -->

      <Button
        data-testid="button-submit"
        :loading="form.processing"
        type="submit"
        class="mx-auto w-full"
        block
        variant="yellow"
      >
      {{ $I18n.t('button_text', i18nScope) }}
      </Button>
    </form>

    <TextNote class="text-grayscale-900 text-base font-normal mt-5 w-full text-center">
      {{ $I18n.t('have_account', i18nScope) }}
      <CustomLink
        data-testid="link-signin"
        class="underline"
        :href="$routes.new_user_session()"
        :fake="isModal"
        @click="$emit('toggleSignInUp')"
      >
        {{ $I18n.t('sign_in', i18nScope) }}
      </CustomLink>
    </TextNote>

    <Divider color="neutral" class="w-full" label="or"></Divider>

    <SocialButtons
      :formAuthenticityToken="formAuthenticityToken"
      :i18nScope="i18nScope"
      :queryParameters="queryParameters"
    />
    <div>
      <TextNote class="text-grayscale-900 text-sm font-normal mt-4 w-full text-center">
        {{ $I18n.t('conditions.agree', i18nScope) }}
        <a class="underline" :href="$routes.terms_of_use()" target="_blank">{{ $I18n.t('conditions.terms', i18nScope) }}</a>
        {{ $I18n.t('conditions.and', i18nScope) }}
        <a class="underline" :href="$routes.privacy_policy()" target="_blank">{{ $I18n.t('conditions.policy', i18nScope) }}</a>
      </TextNote>
    </div>
  </div>
</template>

<script>
import cart from '@/mixins/cart'
import gtm from '@/mixins/gtm'
import mixpanel from '@/mixins/mixpanel'
import authForm from '@/mixins/authForm'
import Checkbox from '@/Components/Shared/Forms/Checkbox'

const CONTENT_TEXT = {
  default: {
    title: 'Create an account',
    subTitle: null
  },
  free_lessons: {
    title: 'Watch Free Lessons',
    subTitle: 'Create an account and join our mailing list to access our entire library of free lessons.'
  },
  gifts: {
    title: 'You got a creative gift!',
    subTitle: 'When you complete your account you\'ll see your gift waiting for you.'
  }
}

export default {
  mixins: [
    cart,
    gtm,
    authForm,
    mixpanel
  ],

  components: {
    Checkbox
  },

  props: {
    contentTextKey: {
      type: String,
      default: 'default'
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.auth.sign_up' }
  },

  mounted() {
    document.querySelector('[data-signup-input="email"]').addEventListener('click', this.emailInputGroupClick)
  },

  data() {
    return {
      form: this.$inertia.form({
        user: {
          email: null,
          password: null,
          terms_and_privacy: false,
          account_attributes: {
            name: null,
            notifications_enabled: true,
            notifications_required: this.notificationsRequired
          }
        },
        extra_action: this.extraAction
      })
    }
  },

  computed: {
    contentText() {
      return CONTENT_TEXT[this.contentTextKey]
    }
  },

  methods: {
    getCookie(name) {
      const value = '; ' + document.cookie
      const parts = value.split('; ' + name + '=')
      if (parts.length === 2) return parts.pop().split(';').shift()
    },

    getGA() {
      return this.getCookie('_ga')
    },

    getFBC() {
      return this.getCookie('_fbc')
    },

    getFBP() {
      return this.getCookie('_fbp')
    },

    validName(name) {
      return name?.length > 0
    },

    validEmail(email){
      var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      return re.test(email)
    },

    validPassword(password) {
      return password?.length >= 6
    },

    validNotificationsEnabled(notificationsEnabled, notificationsRequired) {
      if (!notificationsRequired) { return true }

      return notificationsEnabled
    },

    submit() {
      const { email, password, terms_and_privacy } = this.form.user
      // const { name, notifications_enabled, notifications_required } = this.form.user.account_attributes
      const { name } = this.form.user.account_attributes
      this.form.errors = {}

      // captured reporting cookies
      const gaCookie = this.getGA()
      const fbcCookie = this.getFBC()
      const fbpCookie = this.getFBP()

      const path = this.$routes.user_registration()
      const isValidName = this.validName(name)
      const isValidEmail = this.validEmail(email)
      const isValidPassword = this.validPassword(password)
      // const isValidNotificationsEnabled = this.validNotificationsEnabled(notifications_enabled, notifications_required)

      // if (name && isValidName && email && isValidEmail && isValidPassword && terms_and_privacy && isValidNotificationsEnabled) {
      if (name && isValidName && email && isValidEmail && isValidPassword && terms_and_privacy) {

        // attach reporting cookies
        this.form._ga = gaCookie
        this.form._fbc = fbcCookie
        this.form._fbp = fbpCookie

        this.form.post(path, {
          headers: {
            'X-NEXT-PATH': this.getQueryParam('redirect_to') ?? this.next
          },
          onSuccess: json => {
            this.$emit('success')

            const mixpanelPayLoad = { ...this.mixpanelCartData(this.orderable), ...{notifications_enabled: this.form.user.account_attributes.notifications_enabled}}

            this.trackSignUp(json.props.auth.user)
            this.trackEvent('user_signed_up', mixpanelPayLoad)
            // add GTM FB-targeting event
            this.gtmAddRegistrationTracking(json.props.auth.user)
          }
        })
      }
      if (!name) {
        this.form.errors.name = [this.$I18n.t('activerecord.errors.models.user.attributes.name.blank')]
      }
      if (!email) {
        this.form.errors.email = [this.$I18n.t('activerecord.errors.models.user.attributes.email.blank')]
      }
      else if (!isValidEmail) {
        this.form.errors.email = [this.$I18n.t('activerecord.errors.models.user.attributes.email.invalid')]
      }
      if (!password) {
        this.form.errors.password = [this.$I18n.t('activerecord.errors.models.user.attributes.password.blank')]
      }
      else if (!isValidPassword){
        this.form.errors.password = [this.$I18n.t('activerecord.errors.models.user.attributes.password.short')]
      }
      if (!terms_and_privacy) {
        this.form.errors.terms_and_privacy = [this.$I18n.t('activerecord.errors.models.user.attributes.terms_and_privacy.accept')]
      }
      // if (!isValidNotificationsEnabled) {
      //   this.form.errors['account.notifications_enabled'] = [this.$I18n.t('activerecord.errors.models.account.attributes.notifications_enabled.inclusion')]
      // }
    },

    emailInputGroupClick(e) {
      if (e.target.dataset.link === 'sign_in') { // if the click is on the sign in link
        this.$emit('toggleSignInUp')
      }
    }
  }
}
</script>
