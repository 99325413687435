<template>
  <div class="container mx-auto px-0 md:px-4 py-8">
    <MetaTags :metaTags="metaTags" />

    <!-- Title & Subhead -->
    <div class="text-center px-4 md:px-0 mb-6">
      <SerifHeader
        title="All Access Membership"
        headingLevel="h1"
        content-align="center"
        titleSize="large"
        color="green"
      />
      <p class="text-lg font-normal font-light max-w-sm mx-auto -mt-6">Unlock your creativity and enjoy over 150 hours of inspiring lessons in interiors, gardening, craft and food.</p>
    </div>

    <!-- Plan Options -->
    <div class="w-full sm:w-3/4 mx-auto bg-yellow-100 py-16 px-6 lg:px-24 xl:px-40 mb-10">
      <div class="border-b-2 border-green-900 mb-12 relative h-4">
        <p class="text-green-900 whitespace-nowrap font-medium uppercase inline-block absolute left-1/2 transform -translate-x-1/2 px-6 bg-yellow-100">{{ fromCaption }}</p>
      </div>

      <div class="flex flex-col md:flex-row justify-center text-center flex-wrap gap-6 mb-16">
        <div v-for="plan in membershipPlans" :key="plan.id" class="bg-green-900 flex-1  px-6 py-9">
          <div class="border-white/10 pricing-card-header pb-4 mb-4 border-b ">
            <h2 class="text-white heading--widest bigheading--medium mb-2">{{plan.name}}</h2>
            <div class="text-white text-lg h-14">{{plan.description}}</div>
          </div>
          <p v-if="hasDiscount" :class="{'h-7 no-lineThrough': !hasDiscount}" class="text-grayscale-300 text-lg mb-2 font-extralight text-lineThrough"><span class="font-normal">{{ currency(plan.price) }}</span></p>
          <p class="text-white title-xl font-normal mb-2 ">{{currency(plan.discountedPrice)}}</p>
          <p class="text-white subheading--widest subheading--medium mb-4 ">{{ plan.period === 'monthly' ? 'PER MONTH' : 'PER YEAR' }}</p>
          <p v-if="hasDiscount" :class="{'h-4': !hasDiscount}" class="text-white overline mb-6">{{ 'THANKS TO YOUR LOYALTY DISCOUNT' }}</p>
          <template>
            <Button
              :data-testid="`membership-plan-select-${plan.slug}`"
              @click="planClick(plan)"
              size="small"
              type="button"
              class="mx-auto bg-greenBtn"
            >
              Select {{ plan.period }}
            </Button>
          </template>
        </div>
      </div>

      <!-- Membership Includes -->
      <section>
        <h2 class="text-xl text-green-900 font-medium text-center mb-6">{{ isAnnuallyCommited ? 'ANNUAL ' : '' }} MEMBERSHIP INCLUDES</h2>
        <ul class="max-w-max mx-auto mt-4 space-y-2">
          <li v-for="statement in includesStatements" :key="statement.id" class="flex">
            <Icon name="CircleCheck" class="text-base fill_none mr-4" />
            <span class="text-left font-normal text-grayscale-900 flex-1">{{ statement.text }}</span>
          </li>
        </ul>
      </section>
    </div>

    <!-- Video Player -->
    <section class="bg-white p-6 w-full sm:w-4/5 lg:w-2/3 xl:w-1/2 mx-auto">
      <SerifHeader
        title="Meet Your Instructors"
        headingLevel="h2"
        content-align="center"
        titleSize="large"
        color="green"
      />

      <VideoPlayer
        data-testid='instructor-video-player'
        class='mb-4 lg:mb-8'
        :isAutoPlay='false'
        :options="{ accountId: 6222962662001, playerId: brightcove.lessonPlayer.playerId, videoId: 6322144530112, embedId: 'default' }"
        :mixpanel="{ type: 'landing', videoId: 6322144530112 }"
      />
    </section>

    <!-- FAQs -->
    <section class="bg-white p-6 w-full sm:w-4/5 lg:w-2/3 xl:w-1/2 mx-auto">
      <SerifHeader
        title="FAQs"
        headingLevel="h2"
        content-align="center"
        titleSize="large"
        color="green"
      />
      <dl class="space-y-6 mt-4">
        <div v-for="faq in faqs" :key="faq.id">
          <dt class="font-serif font-medium text-lg mb-2">{{ faq.question }}</dt>
          <dd class="font-normal">{{ faq.answer }}</dd>
        </div>
      </dl>
    </section>
  </div>
</template>

<script>
import axios from 'axios'

import SerifHeader from '@/Components/Shared/SectionHeader/Serif'
import Button from '@/Components/Shared/Button'
import Icon from '@/Components/Shared/Icon'
import MetaTags from '@/Components/Shared/MetaTags'
import VideoPlayer from '@/Components/Shared/VideoPlayer'

import { metaTags } from '@/mixins/metaTags'
import monetize from '@/mixins/monetize'
import mixpanel from '@/mixins/mixpanel'

const faqs_ac = [
  {
    id: 1,
    question: 'What does my membership include?',
    answer: 'As an Annual Member of Create Academy, you’ll have unlimited access to all courses and workbooks.'
  },
  {
    id: 2,
    question: 'How will I get access to the courses?',
    answer: 'You’ll access our full course library via your Create Academy account - so you’ll need to log in or create an account when joining Annual Membership.'
  },
  {
    id: 3,
    question: 'How long does my membership last?',
    answer: 'Annual Membership renews every year, and our payment plans give you the option to pay upfront for a year, or to spread your payments out into 12 monthly instalments.'
  },
  {
    id: 4,
    question: 'When will I be charged?',
    answer: 'If you choose an annual payment plan, you’ll pay on the day you join membership, for the year ahead. If you opt for a monthly payment plan, you’ll pay in 12 monthly instalments on or around the day of the month your membership started. For example, if you begin your membership on 16th February, your next payment date would be on or around 16th March.'
  },
  {
    id: 5,
    question: 'How do I cancel my membership?',
    answer: 'To cancel your membership, simply email info@createacademy.com and a member of the team will process your cancellation within 48 hours.'
  }
]

const faqs_noac = [
  {
    id: 1,
    question: 'What does my membership include?',
    answer: 'As a Member of Create Academy, you’ll have unlimited access to all courses and workbooks.'
  },
  {
    id: 2,
    question: 'How will I get access to the courses?',
    answer: 'You’ll access our full course library via your Create Academy account - so you’ll need to log in or create an account when joining Annual Membership.'
  },
  {
    id: 3,
    question: 'How long does my membership last?',
    answer: 'Membership is ongoing until you decide to cancel, and you can choose if you’d prefer to pay yearly or month to month.'
  },
  {
    id: 4,
    question: 'When will I be charged?',
    answer: 'If you choose an annual payment plan, you’ll pay on the day you join membership, for the year ahead. If you opt for a monthly payment plan, you’ll pay on a rolling monthly basis on or around the day of the month your membership started. For example, if you begin your membership on 16th February, your next payment date would be on or around 16th March.'
  },
  {
    id: 5,
    question: 'How do I cancel my membership?',
    answer: 'To cancel your membership, simply email info@createacademy.com and a member of the team will process your cancellation within 48 hours.'
  }
]

export default {
  mixins: [metaTags, monetize, mixpanel],
  components: {
    SerifHeader,
    Button,
    Icon,
    VideoPlayer,
    MetaTags
  },
  props: {
    brightcove: {
      type: Object,
      required: true
    },
    coupon: {
      type: Object
    },
    isAnnuallyCommited: {
      type: Boolean,
      required: true
    },
    membershipPlans: {
      type: Array
    }
  },
  methods: {
    currency(price) {
      return this.monetize(price * 100).replace(/\.00$/,'')
    },
    async planClick(plan) {
      // track membership landing click
      this.trackEvent('membership_landing_click', {
        planId: plan.id,
        planName: plan.name,
        planPeriod: plan.period,
        planPrice: plan.price
      })

      // stripe checkout
      await this.stripeCheckout(plan.id)
    },
    async stripeCheckout(planId) {
      try {
        const response = await axios.post(this.$routes.membership_checkout_stripe(), { plan: { id: planId }, from: 'membership_testing' })

        if (response.data.status === 'success') {
          window.location.href = response.data.url
        }
      } catch (e) {
        if (e.response.status === 401) {
          window.location.href = `${this.$routes.new_user_session()}?redirect_to=${encodeURIComponent(window.location.pathname)}`
        }
      }
    }
  },
  computed: {
    fromCaption() {
      const lastPlan = this.membershipPlans[this.membershipPlans.length - 1]

      return `from ${this.currency(lastPlan.price)} / ${lastPlan.period === 'monthly' ? 'Month' : 'Year'}`
    },
    includesStatements() {
      return [
        {
          id: 1,
          text: 'Full access to all courses, workbooks and resources'
        },
        {
          id: 2,
          text: '900+ lessons from 30 world-renowned instructors'
        },
        {
          id: 3,
          text: 'Instant access to new courses on the day of release'
        },
        {
          id: 4,
          text: 'New learning content added each month'
        }
      ]
    },
    faqs() {
      return this.isAnnuallyCommited ? faqs_ac : faqs_noac
    },
    hasDiscount() {
      return this.coupon && this.coupon.discountPercent > 0
    }
  },
  created() {
    this.i18nScope = { scope: 'pages.membership' }
  }
}
</script>
