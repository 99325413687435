<template>
  <div>
    <PlayerModal data-testid="trailer-modal" :title="course.teacher.name" :subtitle="course.name"
      :video-id="course.trailer" :brightcove="brightcove" :mixpanel="{
        type: 'trailer',
        videoId: course.trailer,
        courseName: course.name
      }" :gtmPageName="gtmPageName" :gtmSectionName="gtmSectionName" @close="$emit('close')" ref="playerModalRef">

      <PurchaseButton @showUpsell="onShowUpsell" :course="course" :inCart="itemInCart" :gtmPageName="gtmPageName"
        :gtmSectionName="gtmSectionName" :enableUpsell="true" @success="onSuccess" inverse ref="purchaseButton" />

      <div v-if="(this.course.purchasable || this.course.releaseDate) && this.enableUpsellModal"
        class="flex flex-col gap-2 hidden">
        <SubscribeButton ref="subscribeButton" :membershipPlan="this.membershipPlan" :buttonBorder="true"
          component="Course & Trailer CTA" />

        <div style="max-width: 289px" class="font-normal text-lg">
          Access all courses with an annual subscription.
        </div>
      </div>

    </PlayerModal>

    <UpsellOfferModal ref="upsellModal" :membershipPlan="membershipPlan" :headerImage="headerImageUrl"
      @accept-upsell-offer="handleAcceptUpsell" @decline-upsell-offer="handleDeclineUpsell" @show="pauseVideo"
      @hide="resumeVideo" />
  </div>

</template>

<script>
import statuses from '@/mixins/statuses'
import brightcove from '@/mixins/brightcove'
import gtm from '@/mixins/gtm'

import PlayerModal from '@/Components/Shared/Trailer/Player'
import PurchaseButton from '@/Components/Shared/Courses/PurchaseButton'
import SubscribeButton from '@/Components/Shared/Courses/SubscribeButton'
import UpsellOfferModal from '../Courses/UpsellOfferModal.vue'
import upsellMixin from '../../../mixins/upsellMixin'
import mixpanel from '@/mixins/mixpanel'

export default {
  mixins: [
    mixpanel,
    brightcove,
    gtm,
    statuses,
    upsellMixin
  ],

  components: {
    PurchaseButton,
    SubscribeButton,
    PlayerModal,
    UpsellOfferModal
  },

  data() {
    return {
      itemInCart: this.course.inCart,
      headerImageUrl: this.randomCourseCategoryHeaderImage(this.course.categories) || ''
    }
  },

  props: {
    course: {
      type: Object,
      required: true
    },
    gtmPageName: {
      type: String,
      required: true
    },
    gtmSectionName: {
      type: String,
      required: true
    },
    enableUpsellModal: {
      type: Boolean,
      default: false,
      required: false
    },
    membershipPlan: {
      type: Object,
      default: null
    }
  },

  methods: {
    onShowUpsell() {
      if (this.enableUpsellModal) {
        this.showUpsellOfferModal()
      }
    },

    pauseVideo() {
      this.$refs.playerModalRef.pauseVideo()

    },
    resumeVideo() {
      this.$refs.playerModalRef.playVideo()
    },
    onSuccess() {
      this.itemInCart = true
    }
  },
  created() {
    this.i18nScope = { scope: 'pages.courses.show' }
  }
}
</script>
