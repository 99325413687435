export const monetize = {
  methods: {
    monetize(price) {
      return `£${(price / 100).toFixed(2)}`
    },
    monetizeInt(price) {
      return `£${(price / 100).toFixed(0)}`
    },
    geoMonetize(price, currencySymbol = '£', decimalPlaces = 0) {
      const numericPrice = +price
      const formattedPrice = numericPrice.toFixed(decimalPlaces)
      return `${currencySymbol}${formattedPrice}`
    },
    geoMonetizeRound(price, currencySymbol = '£', decimalPlaces = 2) {
      const numericPrice = +price
      if (parseInt(numericPrice) === parseFloat(numericPrice)) {
        return `${currencySymbol}${parseInt(numericPrice)}`
      }
      const formattedPrice = numericPrice.toFixed(decimalPlaces)
      return `${currencySymbol}${formattedPrice}`
    },
    unmonetize(monetizedPrice) {
      return monetizedPrice.replace(/[^\d.]/g, '')
    }
  }
}

export default monetize
