<template>
  <div class="menu-item pb-4">
    <template v-if="external">
      <a
        :data-testid="testid"
        class="flex flex-row justify-between items-center py-1 cursor-pointer"
        target="_blank"
        :href="href"
        :aria-label="body"
      >
        <span class="subheading--medium text-blue-800">{{ body }}</span>
        <template v-if="toggler">
          <Icon name="Chevron" class="text-blue-800 chevron-right" />
        </template>
      </a>
    </template>
    <template v-else-if="text">
      <p class="subheading--medium text-blue-800 py-1">{{ body }}</p>
    </template>
    <template v-else>
      <component
        :is="componentValue"
        :data-testid="testid"
        :href="href"
        @click="$emit('click')"
        :aria-label="body"
        class="flex flex-row justify-between items-center py-1 cursor-pointer"
      >
        <span class="subheading--medium text-blue-800">{{ body }}</span>
        <template v-if="toggler">
          <Icon name="Chevron" class="text-blue-800 chevron-right" />
        </template>
      </component>
    </template>

    <Divider color="light" spacing="xs" />

    <slot />
  </div>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue'

import Divider from '@/Components/Shared/Divider'
import Icon from '@/Components/Shared/Icon'

export default {
  components: {
    Link,
    Divider,
    Icon
  },

  props: {
    body: {
      type: String
    },
    href: {
      type: String
    },
    toggler: {
      type: Boolean,
      default: false
    },
    text: {
      type: Boolean,
      default: false
    },
    external: {
      type: Boolean,
      default: false
    },
    testid: {
      type: String,
      required: false
    }
  },

  computed: {
    componentValue() {
     return this.href ? Link : 'div'
    }
  }
}
</script>
