<template>
  <ImageWrapper background="sign-up">
    <MetaTags :metaTags="metaTags" />

    <SignUpForm
      @toggleSignInUp="toggle"
      :extraAction="extraAction"
      contentTextKey="free_lessons"
    />
  </ImageWrapper>
</template>

<script>
import { metaTags } from '@/mixins/metaTags'

import Layout from '@/Layouts/ReducedLayout'

import MetaTags from '@/Components/Shared/MetaTags'
import ImageWrapper from '@/Components/Shared/Objects/ImageWrapper'
import SignUpForm from '@/Components/Auth/SignUpForm'

export default {
  layout: Layout,

  mixins: [metaTags],

  components: {
    MetaTags,
    ImageWrapper,
    SignUpForm
  },

  data() {
    return {
      extraAction: {
        type: 'signup-page'
      }
    }
  },

  methods: {
    toggle() {
      this.$inertia.visit(this.$routes.new_user_session())
    }
  }
}
</script>
