<template>
  <div class="input-group" :required="required">
    <label
      v-if="label"
      class="label"
      :for="id"
    >{{ label }}</label>

    <select
      :data-testid="testid"
      :value="value"
      v-bind="$attrs"
      @input="$emit('input', $event.target.value)"
      class="input"
      :class="{ 'error': errors.length }"
    >
      <option value="" disabled selected>{{ placeholder }}</option>
      <option v-for="option in options" :value="option.value" :key="option.value">
        {{ option.text }}
      </option>
    </select>

    <Icon name="Chevron" class="text-xs text-blue-800 absolute top-8 right-2" />

    <InputError :testid="testid" :errors="errors" />
  </div>
</template>

<script>
import Icon from '@/Components/Shared/Icon'
import InputError from '@/Components/Shared/Forms/InputError'

/**
 * SelectGroup component for Create Academy
 * @displayName SelectGroup
 */
export default {
  name: 'SelectGroup',

  components: {
    Icon,
    InputError
  },

  props: {
    /**
     * The id for the input
     */
    id: {
      type: String,
      default() {
        return `select-input-${this._uid}`
      }
    },
    /**
     * The name for the input
     */
    name: {
      type: String,
      default: null
    },
    /**
     * The value for the input
     */
    value: {
      type: String,
      default: null
    },
    /**
     * The label for the input - this should be more short and informative.
     */
    label: {
      type: String,
      default: null
    },
    /**
     * The placeholder for the select input - this should be more explanatory of the desired input information
     */
    placeholder: {
      type: String,
      default: null
    },
    /**
     * Required field
     */
    required: {
      type: Boolean,
      default: false
    },
    /**
     * The select options
     */
    options: {
      type: Array,
      default: () => []
    },
    errors: {
      type: Array,
      default: () => []
    },
    testid: String
  }
}
</script>


<docs>
  ### ✎ Regular Select Box

  ```jsx
    <SelectGroup
      placeholder="Select your country"
      label="Country"
    ></SelectGroup>
  ```
</docs>
