<template>
  <div :class="containerClassNames" v-bind="$attrs">
    <Card v-if="firstCourseIsUpsellOffer" :title="upsellOfferCourse.ctaTitle" :ctaTitle="upsellOfferCourse.ctaTitle"
      :ctaSubtitle="upsellOfferCourse.ctaSubtitle" :description="upsellOfferCourse.description" :class="[
        'flex-grid-3'
      ]" />
    <Course v-for="(course, index) in coursesWithoutUpsellOffer" :key="course.id" :course="course"
      :brightcove="brightcove" :class="classNames" :headingLevel="headingLevel" :gtmPageName="gtmPageName"
      :gtmSectionName="gtmSectionName" :gtmPosition="index + 1" :show-purchase-button="showPurchaseButton"
      :membershipPlan="membershipPlan" :abTestVariants="abTestVariants" />
  </div>
</template>

<script>
import gtm from '@/mixins/gtm'
import abTest from '@/mixins/abTest'
import Course from '@/Components/Shared/Talents/Show'
import Card from '../Card/Card.vue'

export default {
  mixins: [
    gtm,
    abTest
  ],

  components: {
    Course,
    Card
  },

  props: {
    courses: {
      type: Array,
      default: () => []
    },
    compact: {
      type: Boolean,
      default: false
    },
    brightcove: {
      type: Object,
      required: true
    },
    headingLevel: {
      type: String,
      default: 'h2'
    },
    gtmPageName: {
      type: String,
      required: true
    },
    gtmSectionName: {
      type: String,
      required: true
    },
    showPurchaseButton: {
      type: Boolean,
      default: false
    },
    membershipPlan: {
      type: Object || null,
      default: null,
      required: false
    },
    isSlider: {
      type: Boolean,
      default: false
    }
  },

  mounted() {

  },

  computed: {

    coursesWithoutUpsellOffer() {
      return this.courses.filter(course => !course.isUpsellOffer)
    },
    upsellOfferCourse() {
      return this.courses.find(course => course.isUpsellOffer)
    },
    firstCourseIsUpsellOffer() {
      return this.courses?.length > 0 && this.courses[0]?.isUpsellOffer || false
    },
    containerClassNames() {
      return [
        this.isSlider ? 'swiper-wrapper' : 'flex flex-row flex-wrap'
      ]
    },

    classNames() {
      return [
        this.isSlider ? 'swiper-slide w-full sm:w-1/2 md:w-1/3 xl:w-1/4' : 'flex-grid-3',
        { 'flex-grid-3__compact': this.compact }
      ]
    }
  }
}
</script>
