const cartItemTypes = {
  // Used when we have a cartItem prop
  computed: {
    cartItemCourse() {
      return this.cartItem?.itemType === 'Course'
    },
    cartItemCampaign() {
      return this.cartItem?.itemType === 'Campaign'
    }
  },

  // Used when we don't have a cartItem prop but have a cartItem object
  methods: {
    isCartItemCourse(cartItem) {
      return cartItem?.itemType === 'Course'
    },
    isCartItemCampaign(cartItem) {
      return cartItem?.itemType === 'Campaign'
    }
  }
}

export default cartItemTypes
