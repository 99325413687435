<template>
  <Button
    block
    class="panel-form__submit"
    data-testid="cart-checkout-button"
    :disabled="empty"
    @click="onCheckoutClick"
  >
    <template v-if="currentUser">
      {{ $I18n.t('checkout_button.signed_in', i18nScope) }}
    </template>
    <template v-else>
      {{ $I18n.t('checkout_button.signed_out', i18nScope) }}
    </template>
  </Button>
</template>

<script>
import button from '@/mixins/button'
import cart from '@/mixins/cart'
import currentUser from '@/mixins/currentUser'
import mixpanel from '@/mixins/mixpanel'

import Button from '@/Components/Shared/Button'
import AuthModal from '@/Components/Shared/AuthModal'

export default {
  components: {
    Button
  },

  mixins: [
    button,
    cart,
    mixpanel,
    currentUser
  ],

  props: {
    i18nScope: {
      type: Object,
      required: true
    }
  },

  computed: {
    checkoutLink() {
      return this.$routes.new_cart_order()
    },

    empty() {
      return this.orderable.cartItems.length === 0
    }
  },

  methods: {
    toggleSummary() {
      this.isToggleSummary = !this.isToggleSummary
    },

    openSignInModal() {
      this.$modal.show(AuthModal,
        {
          next: this.checkoutLink,
          notificationsRequired: false
        },
        {
          width: '95%',
          maxWidth: 500
        }
      )
    },

    onCheckoutClick() {
      if (this.currentUser) {
        this.$inertia.visit(this.checkoutLink)
      } else {
        this.openSignInModal()
      }
    }
  }
}
</script>
