<template>
  <div
    v-if="isVisible"
    class="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z[999]"
    @click="hide"
  >
    <div class="bg-neutral-100 w-[1024px] flex overflow-hidden relative">
      <img
        loading="lazy"
        src="/images/upselloffermodal/x-01.png"
        alt="close"
        class="object-contain w-6 aspect-square absolute top-1 right-1 cursor-pointer"
        style="top: 4px; right: 4px"
        @click="handleDecline"
      />
      <main
        class="flex overflow-hidden flex-col h-full px-5 pt-3 pb-10 text-white bg-green-dark max-w-2xl md:pl-5 flex-grow justify-center"
      >
        <section class="flex flex-col items-center w-full">
          <h1
            class="mt-14 text-4xl leading-tight text-center font-medium tracking-wider md:mt-10 md:max-w-full mx-auto"
          >
            ACCESS ALL COURSES
          </h1>
          <p class="mt-8 text-2xl text-center font-light w-[409px] md:ml-2.5">
            Enjoy this course and 42 more for just
            <span class="font-medium">{{ formattedPrice }}/month</span>
          </p>
          <Link
            class="px-6 py-3 mt-8 mx-auto button btn-bright-yellow inlineBlockLink max-w-full uppercase"
            @click="handleSubscribe"
          >
            <span class="text-base"> SUBSCRIBE NOW </span>
          </Link>
        </section>
        <img
          loading="lazy"
          src="/images/upselloffermodal/Upsell_Modal_imagegrid.webp"
          alt=""
          class="object-contain w-full aspect-[2.22] mt-10 max-w-full"
        />
      </main>
    </div>
  </div>
</template>

<script>
  import { Link } from '@inertiajs/inertia-vue'
  export default {
    name: 'UpsellOfferModal',
    props: {
      membershipPlan: {
        type: Object,
        required: true
      },
      headerImage: {
        type: String,
        required: true
      }
    },
    computed: {
      saleReduction() {
        return this.membershipPlan && this.membershipPlan?.discountPercent ? true : false
      },
      formattedPrice() {
        if (!this.membershipPlan?.geoPriceDetails?.monthlyPrice) return ''
        return this.membershipPlan.geoPriceDetails.monthlyPrice
      }
    },
    data() {
      return {
        isVisible: false,
        benefits: [
          'Unlimited access to all courses and resources',
          'Over 1000 immersive lessons to nurture your creativity',
          'Expert instruction from the best designers, makers, growers and chefs',
          'New content added each month to help you develop your skills'
        ]
      }
    },
    methods: {
      handleBackdropClick(event) {
        if (event.target === event.currentTarget) {
          this.hide()
        }
      },
      show() {
        this.isVisible = true
        this.$emit('show')
      },
      hide() {
        this.isVisible = false
        this.$emit('hide')
      },
      handleSubscribe() {
        this.hide()
        this.$emit('accept-upsell-offer')
      },
      handleDecline() {
        this.hide()
        this.$emit('decline-upsell-offer')
      }
    }
  }
</script>

<style scoped>
  .fixed {
    z-index: 9999 !important;
  }

  .btn-bright-yellow {
    background-color: #efa73c;
    color: #000;
    border: 0px;
  }

  .bg-green-dark {
    background-color: #013318;
  }

  .bg-navy-blue {
    background-color: #18336c;
  }

  .text-navy-blue {
    color: #18336c;
  }

  .offer-title {
    display: flex;
    height: 42.915px;
    flex-direction: column;
    justify-content: center;
    align-self: stretch;
    color: var(--blue-800, #0e1e38);
    text-align: center;
    font-family: 'Saol CA';
    font-size: 40px;
    font-style: normal;
    font-weight: 500;
    line-height: 36px;
  }

  .offer-subtitle {
    align-self: stretch;
    color: var(--blue-800, #0e1e38);
    text-align: center;
    font-family: 'Brandon Grotesque';
    font-size: 22px;
    font-style: normal;
    font-weight: 390;
    line-height: normal;
  }

  .offer-subtitle strong {
    color: var(--blue-800, #0e1e38);
    font-family: 'Brandon Grotesque';
    font-size: 22px;
    font-style: normal;
    font-weight: 420;
    line-height: normal;
  }

  .offer-header-image {
    width: 712px;
    height: 247px;
    flex-shrink: 0;
  }

  .offer-button {
    width: 300px;
    padding: var(--Main-System-12px, 12px) var(--Main-System-24px, 24px);
    justify-content: center;
    align-self: center;
    align-items: center;
    gap: var(--Main-System-8px, 8px);
  }

  .offer-button-bg-primary {
    color: white;
    background: var(--blue-500, #18336c);
    border: 1px solid var(--blue-800, #0e1e38);
  }

  .offer-button-bg-primary:hover {
    --tw-bg-opacity: 1;
    background-color: rgba(232, 235, 240, var(--tw-bg-opacity));
    --tw-border-opacity: 1;
    border-color: rgba(232, 235, 240, var(--tw-border-opacity));
    --tw-text-opacity: 1;
    color: rgba(24, 51, 108, var(--tw-text-opacity));
  }

  .offer-button-bg-secondary {
    color: var(--blue-800, #0e1e38);
    border: 1px solid var(--blue-800, #0e1e38);
    background: #fff;
  }

  .offer-button-bg-secondary:hover {
    --tw-text-opacity: 1;
    color: rgba(209, 213, 219, var(--tw-text-opacity));
  }

  .benefits-list {
    margin-left: 1rem;
    margin-bottom: 2.5rem;
  }

  .benefits-list li {
    color: #000;
    font-family: 'Brandon Grotesque';
    font-size: 16px;
    font-style: normal;
    font-weight: 390;
    line-height: normal;
  }

  @media (max-width: 639px) {
    .offer-title {
      font-size: 30px;
      line-height: 1.2;
      height: auto;
    }

    .offer-subtitle {
      font-size: 18px;
    }

    .offer-subtitle strong {
      font-size: 18px;
    }

    .benefits-list {
      margin-left: 0.5rem;
      margin-bottom: 1.5rem;
    }

    .benefits-list li {
      font-size: 14px;
    }

    .offer-button {
      width: 100%;
    }
  }
</style>
