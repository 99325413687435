<template>
  <div>
    <h3 class="subheading--medium text-yellow-800 mb-6">{{ $I18n.t('title', i18nScope) }}</h3>

    <div class="flex flex-row justify-between mb-4">
      <div class="flex flex-row items-center">
        <span>{{ $I18n.t('name', i18nScope) }}</span>
      </div>

      <span data-testid="connected-google" class="text-gray-500 capitalize"
        >{{ membershipPlan.slug }} Plan</span
      >
    </div>

    <div class="flex flex-row justify-between mb-4">
      <div class="flex flex-row items-center">
        <span>{{ $I18n.t('status', i18nScope) }}</span>
      </div>

      <span data-testid="connected-google" class="text-gray-500">{{ membershipPlan.status }}</span>
    </div>

    <div class="flex flex-row justify-between mb-4">
      <div class="flex flex-row items-center">
        <span>{{ $I18n.t('start_date', i18nScope) }}</span>
      </div>

      <span data-testid="connected-google" class="text-gray-500">{{
        membershipPlan.start_date
      }}</span>
    </div>

    <div v-if="membershipPlan.expiry_date" class="flex flex-row justify-between mb-4">
      <div class="flex flex-row items-center">
        <span>{{
          $I18n.t(membershipPlan.slug == 'lifetime' ? 'end_date' : 'expiry_date', i18nScope)
        }}</span>
      </div>

      <span data-testid="connected-google" class="text-gray-500">{{
        membershipPlan.expiry_date
      }}</span>
    </div>

    <div v-if="membershipPlan.cancelled_at" class="flex flex-row justify-between mb-4">
      <div class="flex flex-row items-center">
        <span>{{ $I18n.t('cancelled_date', i18nScope) }}</span>
      </div>

      <span data-testid="connected-google" class="text-gray-500">{{
        membershipPlan.cancelled_at
      }}</span>
    </div>
  </div>
</template>

<script>
  export default {
    created() {
      this.i18nScope = { scope: 'pages.users.profile.membership_details' }
    },

    props: {
      membershipPlan: {
        type: Object,
        required: true
      }
    }
  }
</script>
