<template>
  <div :id="containerUniqueId" class="w-full h-full">
    <!-- Placeholder for mobile screens -->
    <div
      v-if="!isDesktop"
      @click="openVideoModal"
      class="relative w-full h-full cursor-pointer block md:hidden"
    >
      <img :src="thumbnailUrl" alt="Video Thumbnail" class="w-full h-full object-cover" />
      <span
        v-if="!isPlaying"
        class="absolute right-1.5 bottom-1.5 font-normal bg-blue-300 py-1 px-3 text-xs text-blue-800"
        >{{ instaId }}</span
      >
      <div class="absolute inset-0 flex items-center justify-center">
        <button class="p-4 outline-none focus:outline-none hover:outline-none">
          <svg
            class="w-9 h-9"
            width="53"
            height="59"
            viewBox="0 0 53 59"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M51.4983 26.9277L4.38722 0.377712C3.47444 -0.135587 2.36144 -0.123787 1.46044 0.401312C0.553556 0.932311 0 1.89991 0 2.95011V56.05C0 57.1002 0.553556 58.0678 1.46044 58.5988C1.91978 58.8643 2.43211 59 2.94444 59C3.43911 59 3.93967 58.8761 4.38722 58.6224L51.4983 32.0725C52.4229 31.5474 53 30.568 53 29.5001C53 28.4322 52.4229 27.4528 51.4983 26.9277Z"
              fill="white"
            />
          </svg>
        </button>
      </div>
    </div>

    <!-- Video Modal -->
    <div
      :class="{
        'fixed inset-0 bg-black bg-opacity-80 z-50': !isDesktop && isModalOpen,
        relative: isDesktop
      }"
      class="w-full h-full flex items-center justify-center"
    >
      <div class="relative w-full h-full">
        <!-- Close Button for mobile screens -->
        <button
          v-if="!isDesktop && isModalOpen"
          @click="closeVideoModal"
          class="absolute top-4 right-4 text-white z-10"
        >
          <svg
            class="w-6 h-6"
            width="20"
            height="21"
            viewBox="0 0 20 21"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M18.6654 1.5L1.33203 19.5M18.6654 19.5L1.33203 1.5"
              stroke="black"
              stroke-linecap="square"
            />
          </svg>
        </button>
        <video-js
          :id="playerUniqueId"
          :data-video-id="videoId"
          :data-account="accountId"
          :data-player="playerId"
          data-embed="default"
          data-application-id
          controls
          playsinline
          class="w-full h-full object-cover video-js-custom"
        ></video-js>
        <span
          v-if="!isPlaying"
          class="absolute right-3 font-normal bottom-3 bg-blue-300 py-1 px-3 text-base text-blue-800"
          >{{ instaId }}</span
        >
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'VerticalVideo',
    props: {
      videoId: {
        type: String,
        required: true
      },
      referenceId: {
        type: String,
        required: true
      },
      imageId: {
        type: String,
        required: true
      },
      accountId: {
        type: String,
        required: true
      },
      playerId: {
        type: String,
        required: true
      },
      uniqueId: {
        type: String,
        required: true
      },
      instaId: {
        type: String,
        required: true
      }
    },
    data() {
      return {
        player: null,
        containerUniqueId: `container-${this.uniqueId}`,
        playerUniqueId: `player-${this.uniqueId}`,
        isModalOpen: false,
        isDesktop: window.innerWidth >= 768,
        isPlaying: false,
        thumbnailUrl: null
      }
    },
    mounted() {
      this.loadVideoJsScript().then(() => {
        this.initializePlayer()
        this.loadThumbnail()
      })

      window.addEventListener('resize', this.updateScreenSize)
    },
    beforeDestroy() {
      window.removeEventListener('resize', this.updateScreenSize)
      if (this.player) {
        this.player.dispose()
      }
    },
    methods: {
      loadThumbnail() {
        console.log('Loading thumbnail for:', {
          videoId: this.videoId,
          referenceId: this.referenceId,
          imageId: this.imageId,
          accountId: this.accountId,
          url: `https://cf-images.eu-west-1.prod.boltdns.net/v1/static/${this.accountId}/${this.referenceId}/${this.imageId}/1280x720/match/image.jpg`
        })

        this.thumbnailUrl = `https://cf-images.eu-west-1.prod.boltdns.net/v1/static/${this.accountId}/${this.referenceId}/${this.imageId}/1280x720/match/image.jpg`
      },
      openVideoModal() {
        if (!this.isDesktop) {
          this.isModalOpen = true
          this.$nextTick(() => {
            if (this.player) {
              this.player.play().catch(error => {
                console.warn('Video playback failed:', error)
              })
            }
          })
        }
      },
      closeVideoModal() {
        if (this.player) {
          this.player.pause()
          this.player.currentTime(0)
        }
        this.isModalOpen = false
      },
      loadVideoJsScript() {
        return new Promise(resolve => {
          if (window.bc && window.videojs) {
            resolve()
            return
          }
          const script = document.createElement('script')
          script.src = `//players.brightcove.net/${this.accountId}/${this.playerId}_default/index.min.js`
          script.onload = resolve
          document.head.appendChild(script)
        })
      },
      initializePlayer() {
        this.$nextTick(() => {
          if (window.bc && window.videojs) {
            this.player = window.bc(this.playerUniqueId)
            this.setupPlayerEvents()
          } else {
            console.error('Brightcove player is not defined')
          }
        })
      },
      setupPlayerEvents() {
        if (this.player) {
          this.player.on('play', () => {
            this.player.muted(false)
            this.player.volume(1)
            this.$emit('video-play')
            this.isPlaying = true
          })

          this.player.on('pause', () => {
            this.isPlaying = false
          })

          this.player.on('loadedmetadata', () => {
            this.player.muted(false)
            this.player.volume(1)
          })
        }
      },
      updateScreenSize() {
        this.isDesktop = window.innerWidth >= 768
      }
    }
  }
</script>

<style scoped>
  .video-js-custom .vjs-big-play-button {
    background-color: rgba(0, 0, 0, 0.45) !important;
    transition: background-color 0.3s ease !important;
  }

  .video-js-custom:hover .vjs-big-play-button,
  .video-js-custom .vjs-big-play-button:focus {
    background-color: rgba(229, 229, 229, 0.8) !important;
  }

  .video-js-custom .vjs-play-progress,
  .video-js-custom .vjs-volume-level {
    background-color: rgba(229, 229, 229, 0.8) !important;
  }

  .video-js-custom .vjs-progress-holder {
    background-color: rgba(255, 255, 255, 0.3) !important;
  }

  .video-js-custom .vjs-load-progress {
    background-color: rgba(255, 255, 255, 0.5) !important;
  }

  .video-js-custom .vjs-slider-bar {
    background-color: rgba(229, 229, 229, 0.8) !important;
  }
</style>
