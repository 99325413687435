const productTypes = {
  methods: {
    isCourse(product) {
      return product?.type?.toLowerCase() === 'course'
    },
    isCampaign(product) {
      return product?.type?.toLowerCase() === 'campaign'
    }
  }
}

export default productTypes
