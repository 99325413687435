<template>
  <MenuPanel
    :name="$I18n.t('seasonal.title', i18nScope)"
    :currentPanel="isToggleSeasonalMenu"
    @clickBack="$emit('toggleSeasonalMenu')"
    @clickClose="$emit('toggleSeasonalMenu') && $emit('toggleGeneralMenu') "
    class="overflow-y-scroll"
    backButtonActive
  >
    <SeasonalList screen="mobile" />
  </MenuPanel>
</template>

<script>
import SeasonalList from '@/Components/Shared/Objects/Navbar/SeasonalList'
import MenuPanel from '@/Components/Shared/Objects/Navbar/Mobile/MenuPanel'

export default {
  components: {
    SeasonalList,
    MenuPanel
  },

  props: {
    categories: Array,

    isToggleSeasonalMenu: {
      type: Boolean,
      required: true
    }
  },
  created() {
    this.i18nScope = { scope: 'pages.navbar' }
  }
}
</script>
