<template>
  <div class="input-avatar-group" :required="required">
    <label
      class="avatar-trigger"
      :for="id"
    >
      <Icon name="Upload" class="text-xl text-blue-800 opacity-50" />
    </label>
    <input
      :id="id"
      :name="name"
      :data-testid="`input-${testid}`"
      :hidden="hidden"
      :accept="accept"
      @change="change"
      ref="file"
      type="file"
    >
    <InputError :testid="testid" :errors="errors" />
  </div>
</template>

<script>
import Icon from '@/Components/Shared/Icon'

import InputError from '@/Components/Shared/Forms/InputError'

/**
 * InputAvatar component for Create Academy
 * @displayName InputAvatar
 */
export default {
  components: {
    Icon,
    InputError
  },

  props: {
    /**
     * The id for the input
     */
    id: {
      type: String,
      default() {
        return `file-input-${this._uid}`
      }
    },
    /**
     * The name for the input
     */
    name: {
      type: String,
      default: null
    },
    /**
     * The value for the input
     */
    value: File,
    /**
     * The placeholder for the input - this should be more explanatory of the desired input information
     */
    placeholder: {
      type: String,
      default: null
    },
    accept: {
      type: String,
      default: null
    },
    required: {
      type: Boolean,
      default: false
    },
    hidden: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: () => []
    },
    testid: String
  },

  watch: {
    value(value) {
      if (!value) {
        this.$refs.file.value = ''
      }
    }
  },

  methods: {
    filesize(size) {
      let i = Math.floor(Math.log(size) / Math.log(1024))
      return (
        (size / Math.pow(1024, i)).toFixed(2) * 1 +
        ' ' +
        ['B', 'kB', 'MB', 'GB', 'TB'][i]
      )
    },
    browse() {
      this.$refs.file.click()
    },
    change(e) {
      this.$emit('input', e.target.files[0])
    },
    remove() {
      this.$emit('input', null)
    }
  }
}
</script>
