<template>
  <section class="w-full relative">
    <div class="container px-4 lg:px-16 py-12 lg:py-14 flex flex-col">
      <div class="testimonial-grid mt-6" ref="swiperContainer">
        <div class="swiper-wrapper items-stretch">
          <div
            class="swiper-slide h-auto"
            v-for="(testimonial, index) in testimonials"
            :key="index"
          >
            <TestimonialColumn v-bind="testimonial" />
          </div>
        </div>
        <!-- <div class="swiper-pagination"></div> -->
      </div>
    </div>
  </section>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted, computed } from 'vue'
import TestimonialColumn from './TestimonialColumn.vue'
import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'
import { getS3Url } from '../../../s3utils'

export default defineComponent({
  name: 'TestimonialGrid',
  components: {
    TestimonialColumn
  },
  setup() {
    const swiperContainer = ref(null)

    const testimonials = computed(() => [
      {
        videoSrc: getS3Url('nicola.mp4'),
        placeholderImage: '/images/nicola.jpg',
        quote: 'I love how the courses take me off of social media and teach me something.',
        author: 'Gwen, US',
        logoSrc: 'stars.svg',
        isVideoAutoplay: false,
        isVideoFirst: false
      },
      {
        videoSrc: getS3Url('laura.mp4'),
        placeholderImage: '/images/laura.jpg',
        quote: 'The production values, editing, etc. of all the courses are excellent.',
        author: 'Celia, US',
        logoSrc: 'stars.svg',
        isVideoAutoplay: false,
        isVideoFirst: true
      },
      {
        videoSrc: getS3Url('jo.mp4'),
        placeholderImage: '/images/jo.jpg',
        quote: 'It allows the opportunity of learning from some wonderfully talented people.',
        author: 'Di, UK',
        logoSrc: 'stars.svg',
        isVideoAutoplay: false,
        isVideoFirst: false
      },
      {
        videoSrc: getS3Url('gina.mp4'),
        placeholderImage: '/images/gina.jpg',
        quote: 'Create Academy has helped me with every corner of my home.',
        author: 'Meri, Aus',
        logoSrc: 'stars.svg',
        isVideoAutoplay: false,
        isVideoFirst: true
      }
    ])
    console.log('Testimonials data:', testimonials)

    onMounted(() => {
      new Swiper(swiperContainer.value, {
        slidesPerView: 1.1,
        spaceBetween: 20,
        pagination: {
          el: '.swiper-pagination'
        },
        breakpoints: {
          768: {
            slidesPerView: 4,
            spaceBetween: 20,
            enabled: false
          }
        }
      })
    })

    return {
      swiperContainer,
      testimonials
    }
  }
})
</script>

<style scoped>
@media (min-width: 768px) {
  .testimonial-grid {
    display: flex;
    gap: 20px;
  }
  .testimonial-grid .swiper-slide {
    height: auto !important;
    width: 24% !important;
  }
}
</style>