<template>
  <div class="navbar-mobile mr-6">
    <!-- Menu -->
    <div class="navbar-mobile__menu">
      <div @click="toggleGeneralMenu" class="menu__toggler flex items-center">
        <Icon name="Menu" class="text-white" />
      </div>

      <GeneralMenu
        :categories="categories"
        :isToggleGeneralMenu="isToggleGeneralMenu"
        :seasonalIsActive="seasonalIsActive"
        @toggleGeneralMenu="toggleGeneralMenu"
        @toggleSeasonalMenu="toggleSeasonalMenu"
        @toggleAccountMenu="toggleAccountMenu"
      />

      <SeasonalMenu
        v-if="seasonalIsActive"
        :categories="categories"
        :isToggleSeasonalMenu="isToggleSeasonalMenu"
        @toggleGeneralMenu="toggleGeneralMenu"
        @toggleSeasonalMenu="toggleSeasonalMenu"
      />

      <AccountMenu
        v-if="currentUser"
        :isToggleAccountMenu="isToggleAccountMenu"
        @toggleGeneralMenu="toggleGeneralMenu"
        @toggleAccountMenu="toggleAccountMenu"
      />
    </div>
  </div>
</template>

<script>
import currentUser from '@/mixins/currentUser'
import overflowLogic from '@/mixins/overflowLogic'
import { Inertia } from '@inertiajs/inertia'

import Icon from '@/Components/Shared/Icon'
import GeneralMenu from '@/Components/Shared/Objects/Navbar/Mobile/GeneralMenu'
import SeasonalMenu from '@/Components/Shared/Objects/Navbar/Mobile/SeasonalMenu'
import AccountMenu from '@/Components/Shared/Objects/Navbar/Mobile/AccountMenu'

export default {
  mixins: [currentUser, overflowLogic],

  components: {
    Icon,
    GeneralMenu,
    SeasonalMenu,
    AccountMenu
  },

  props: {
    categories: Array,

    seasonalIsActive: {
      type: Boolean,
      required: true
    }
  },

  data() {
    return {
      // toggle to open/close global menu in mobile
      isToggleGeneralMenu: false,

      // toggle to show seasonal menu
      isToggleSeasonalMenu: false,

      // toggle to show account menu
      isToggleAccountMenu: false
    }
  },

  methods: {
    toggleGeneralMenu() {
      this.isToggleGeneralMenu = !this.isToggleGeneralMenu
    },

    toggleSeasonalMenu() {
      this.isToggleSeasonalMenu = !this.isToggleSeasonalMenu

      if (this.isToggleSeasonalMenu) {
        this.setScrollOverflow('hidden')
      } else {
        this.setScrollOverflow('auto')
      }
    },

    toggleAccountMenu() {
      this.isToggleAccountMenu = !this.isToggleAccountMenu
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.navbar' },

    this.removeNavigateListener = Inertia.on('navigate', () => {
      this.isToggleGeneralMenu = false

      if (this.isToggleSeasonalMenu) {
        this.isToggleSeasonalMenu = false
      }

      if (this.isToggleAccountMenu) {
        this.isToggleAccountMenu = false
      }
    })
  },

  beforeDestroy() {
    this.removeNavigateListener()
  }
}
</script>
