export default {
  props: {
    abTestVariants: {
      type: Object,
      default: () => ({})
    }
  },
  computed: {
    courseActionsVariant() {
      return this.getVariant('course-actions')
    }
  },
  methods: {
    // Check if a specific test is active
    isTestActive(testName) {
      // First, check if abTestVariants is a valid object
      if (this.abTestVariants && typeof this.abTestVariants === 'object') {
        return Object.prototype.hasOwnProperty.call(this.abTestVariants, testName)
      }
      return false // Return false if abTestVariants is not a valid object
    },
    // Get the variant for a specific test
    getVariant(testName) {
      if (this.isTestActive(testName)) {
          return this.abTestVariants[testName]
      }
      return null // or a default variant
    },
    // Get the active test and variant
    // for tracking purposes
    activeAbTest (targetTest) {
      if (this.isTestActive(targetTest)) {
        return {
          name: targetTest,
          variant: this.abTestVariants[targetTest]
        }
      }
      return null
    },
    // Method to log events to Mixpanel
    logAbTestEvent(eventName, eventData = {}) {
      // Implementation for logging to Mixpanel
      // Use eventData here if needed
    }
  }
}