<template>
  <Head>
    <title>{{ metaTags.title }}</title>
    <meta name="description" :content="metaTags.description">
    <meta v-if="metaTags.noindex" name="robots" content="noindex" />

    <link v-if="!disableCanonical" rel="canonical" :href="canonicalUrl" />
    <script
      v-if="metaTags.schema"
      type="application/ld+json">
      {{ metaTags.schema }}
    </script>
  </Head>
</template>

<script>
import { metaTags } from '@/mixins/metaTags'

import { Head } from '@inertiajs/inertia-vue'

export default {
  mixins: [metaTags],

  props: {
    disableCanonical: {
      type: Boolean,
      default: false
    }
  },

  components: {
    Head
  },

  computed: {
    canonicalUrl() {
      let url = document.location.protocol + '//' + document.location.hostname + document.location.pathname
      if (url.slice(-1) === '/') {
        url = url.slice(0, -1)
      }

      return url
    }
  }
}
</script>
