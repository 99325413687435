var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{class:[
    'flex',
    'flex-col',
    'lg:flex-row',
    'items-stretch',
    'backgroundState',
  ],style:({ backgroundImage: `url(${_vm.imageUrl})` }),attrs:{"data-testid":"access-all-course-banner"}},[_c('div',{staticClass:"container py-14 lg:py-28 relative"},[_c('div',{staticClass:"banner-content flex flex-col justify-center text-left gap-2 lg:gap-4 w-full lg:w-1/2 lg:mb-0"},[_c('div',{staticClass:"mb-2"},[_c('div',{staticClass:"mb-2 lg:mb-4 font-medium text-3xl lg:text-4xl text-black font-serif capitalize"},[_vm._v("\n          "+_vm._s(_vm.title)+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"font-normal text-base lg:text-xl text-black",attrs:{"data-testid":"monthly-price"}},[_vm._v("\n        Get unlimited access for just "),_c('span',{staticClass:"font-medium"},[_vm._v(_vm._s(_vm.getGeoPrice(_vm.membershipPlan.monthlyPrice))+"/month*")])])]),_vm._v(" "),_c('div',{staticClass:"flex flex-col gap-1 lg:gap-2"},_vm._l((_vm.membershipBannerPoints),function(point,index){return _c('div',{key:index,staticClass:"flex flex-row items-top font-normal text-left"},[_c('Icon',{staticClass:"text-black text-base mr-3 mt-1",attrs:{"name":"Check"}}),_vm._v(" "),_c('p',{staticClass:"text-black text-sm lg:text-base"},[_vm._v(_vm._s(point))])],1)}),0),_vm._v(" "),(_vm.subText)?_c('p',{staticClass:"text-black font-normal text-base lg:text-xl w-112 max-w-full"},[_vm._v("Why not try before you buy? Create a free account and access over 50 lessons.")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"mt-2 lg:mt-4 text-left"},[_c('div',{staticClass:"inline-block text-center w-full xs:w-auto"},[_c('Link',{staticClass:"button button--red inlineBlockBtn w-full xs:w-56 max-w-full",attrs:{"data-testid":"start-learning-button","href":_vm.$routes.onboarding()},on:{"click":_vm.trackSubscribeButton}},[_c('span',{staticClass:"flex gap-1 items-center justify-center"},[_vm._v("Subscribe\n            ")])])],1)])])]),_vm._v(" "),_c('div',{staticClass:"block md:hidden"},[_c('img',{staticClass:"object-cover w-full",attrs:{"src":_vm.respimageUrl,"alt":"banner mobile image"}})])])
}
var staticRenderFns = []

export { render, staticRenderFns }