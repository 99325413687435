import InputGroup from '@/Components/Shared/Forms/InputGroup'
import Button from '@/Components/Shared/Button'
import SocialButtons from '@/Components/Auth/SocialButtons'
import TextNote from '@/Components/Shared/TextNote'
import Divider from '@/Components/Shared/Divider'
import CustomLink from '@/Components/Shared/CustomLink'

export default {
  components: {
    CustomLink,
    InputGroup,
    Button,
    SocialButtons,
    TextNote,
    Divider
  },

  props: {
    next: {
      type: String,
      default: ''
    },
    extraAction: {
      type: Object
    },
    queryParameters: {
      type: String,
      default:''
    },
    notificationsRequired: {
      type: Boolean,
      default: true
    }
  },

  computed: {
    isModal() {
      return !this.$page.component.startsWith('Auth/')
    },
    formAuthenticityToken() {
      return this.$page.props.auth.formAuthenticityToken
    }
  },

  methods: {
    getQueryParam(param) {
      const params = window.location.search.substring(1).split('&')

      for (let i = 0; i < params.length; i++) {
        const pair = params[i].split('=')

        if (pair[0] == param) {
          return decodeURIComponent(pair[1])
        }
      }
    }
  }
}
