<template>
  <div data-testid="course-upcoming-section" v-if="courses.length > 0" class="flex flex-row flex-wrap">
    <Course
      v-for="(course, index) in courses"
      :key="course.id"
      :course="course"
      :gtmPageName="gtmPageName"
      :gtmSectionName="gtmSectionName"
      :gtmPosition="index + 1"
      class="flex-grid-3"
    />
  </div>
</template>

<script>
import gtm from '@/mixins/gtm'
import statuses from '@/mixins/statuses'
import currentUser from '@/mixins/currentUser'

import Course from '@/Components/Shared/Upcoming/Show'

export default {
  mixins: [
    statuses,
    currentUser,
    gtm
  ],

  components: {
    Course
  },
  props: {
    courses: {
      type: Array,
      default: () => []
    },
    gtmPageName: {
      type: String,
      required: true
    },
    gtmSectionName: {
      type: String,
      required: true
    }
  }
}
</script>
