import currentUser from '@/mixins/currentUser'
import abTest from '@/mixins/abTest'

const generalAnnouncement = {
  mixins: [
    currentUser,
    abTest
  ],

  data() {
    return {
      componentsToExclude: [
        'Auth/SignIn',
        'Auth/SignUp'
      ]
    }
  },

  computed: {
    aBubbleGum() {
      // return this.$page.props.abTestVariants
      return this.genAnnouncementObj.abTestVariants
    },
    showGeneralAnnouncement() {  
      // Don't show if the campaign is not active
      if (!this.$page.props.generalAnnouncement?.campaignActive) { return false }

      // Don't show if the currentUser = isSubscribed
      if (this.isSubscribed) { return false }

      // Don't show if this is one of the page components where we should hide the banner
      if (this.componentsToExclude.includes(this.$page.component)) { return false }

      // Don't show if this is one of the urls where we should hide the banner
      if (this.genAnnouncementObj.excludedUrls.includes(this.$page.url)) { return false }

      // Don't show if the campaign has a start date AND current time is lower than the start date
      if (this.genAnnouncementObj.campaignStartDate && Date.now() < Date.parse(this.genAnnouncementObj.campaignStartDate)) { return false }
      // Don't show if the campaign has an end date AND current time is higher than the end date
      if (this.genAnnouncementObj.campaignEndDate && Date.now() > Date.parse(this.genAnnouncementObj.campaignEndDate)) { return false }

      // New condition for AB tests
      try {
        if (this.getVariant('course_actions') == 2) {
          // Parse the URL and check if the pathname is not the root ('/')
          const urlPath = new URL(this.$page.url, window.location.origin).pathname
          if (urlPath !== '/') {
            return false
          }
        } 
      }
      catch(e) {
        // console.log('Error in generalAnnouncement.js: ', e)
      }
      
      // OK, fine, show the banner
      return true
    },

    genAnnouncementObj() {
      return this.$page.props.generalAnnouncement
    },

    campaignBackgroundColor() {
      // AB test condition
      if (this.getVariant('course_actions') == 2) {
        return 'bg-red-800'
      }
      return `bg-${this.$page.props.generalAnnouncement?.campaignBackgroundColor}`
    },

    campaignTextColor() {
      return `text-${this.$page.props.generalAnnouncement?.campaignTextColor}`
    },

    showSignupWall() {
      return !this.currentUser && this.genAnnouncementObj.signupWallActive
    }
  }
}

export default generalAnnouncement
