<template>
  <div class="container">
    <MetaTags :metaTags="metaTags" />

    <div class=" flex w-full flex-col min-h-full">
      <h1 class="title-xl text-left text-grayscale-900 mb-6">
        <span class="text-grayscale-400">Results for</span> {{ query }}
      </h1>
      <search-form @search="handleSearch" :query="query" :key="query"></search-form>
      <div
        v-if="isLoading"
        class="p-4 h-32 flex items-center justify-center text-xl font-normal"
      >
        Loading...
      </div>
      <div v-else class="flex flex-col w-full">
        <div
          class="flex items-center justify-start border-b border-neutral-100 gap-x-2 md:gap-x-9 mb-8"
        >
          <div
            class="heading--medium-tabs border-b-2 border-transparent"
            :class="{ 'border-grayscale-900': tabSelection === 'courses' }"
            @click="handleTab('courses')"
          >
            Courses
          </div>
          <div
            v-if="currentUser"
            class="heading--medium-tabs border-b-2 border-transparent"
            :class="{ 'border-grayscale-900': tabSelection === 'lessons' }"
            @click="handleTab('lessons')"
          >
            Lessons
          </div>
          <div
            class="heading--medium-tabs border-b-2 border-transparent"
            :class="{ 'border-grayscale-900': tabSelection === 'instructors' }"
            @click="handleTab('instructors')"
          >
            Instructors
          </div>
        </div>
        <ul class="grid grid-cols-12 gap-7" v-if="tabSelection === 'lessons'">
          <template v-if="lessons.count == 0">
            <div class=" h-80 flex items-center justify-center col-span-12">
              <p class="text-xl font-normal">No results</p>
            </div>
          </template>
          <template v-else>
            <lesson-teaser
              v-for="lesson in lessons.results"
              :key="lesson.id"
              :lesson="lesson"
            ></lesson-teaser>
          </template>
        </ul>
        <ul class="grid grid-cols-12 gap-7" v-if="tabSelection === 'courses'">
          <template v-if="courses.count == 0">
            <div class=" h-80 flex items-center justify-center col-span-12">
              <p class="text-xl font-normal">No results</p>
            </div>
          </template>
          <template v-else>
            <course-teaser
              v-for="course in courses.results"
              :key="course.id"
              :course="course"
            ></course-teaser>
          </template>
        </ul>
        <ul
          class="grid grid-cols-12 gap-x-7 gap-y-12"
          v-if="tabSelection === 'instructors'"
        >
          <template v-if="instructors.count == 0">
            <div class=" h-80 flex items-center justify-center col-span-12">
              <p class="text-xl font-normal">No results</p>
            </div>
          </template>
          <template v-else>
            <intructor-teaser
              v-for="instructor in instructors.results"
              :key="instructor.id"
              :instructor="instructor"
            ></intructor-teaser>
          </template>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import { metaTags } from '@/mixins/metaTags'

import currentUser from '@/mixins/currentUser'
import axios from 'axios'
import MetaTags from '@/Components/Shared/MetaTags'
import SearchForm from '@/Components/Search/SearchForm'
import LessonTeaser from '@/Components/Search/LessonTeaser'
import CourseTeaser from '@/Components/Search/CourseTeaser'
import IntructorTeaser from '@/Components/Search/InstructorTeaser'
import mixpanel from '@/mixins/mixpanel'

export default {
  mixins: [metaTags, mixpanel, currentUser],

  components: { MetaTags, SearchForm, LessonTeaser, CourseTeaser, IntructorTeaser },

  data() {
    return {
      lessons: [],
      courses: [],
      instructors: [],
      isLoading: false,
      selectedTab: 'courses',
      query: ''
    }
  },
  props: {
    searchKey: {
      type: String
    },
    tab: {
      type: String,
      default: 'courses'
    }
  },  
  mounted() {
    if (this.searchKey) {
      this.query = this.searchKey
      this.search(this.searchKey)
    }
    
    const path = window.location.pathname.split('/')
    const tab = path[path.length - 1]
    const validTabs = this.currentUser ? ['courses', 'lessons', 'instructors'] : ['courses', 'instructors']
    
    if (validTabs.includes(tab)) {
      this.selectedTab = tab
    } else {
      this.$inertia.replace(`/search/courses?q=${this.query}`)
    }
  },
  methods: {
    async search(query) {
      this.query = query
      this.isLoading = true
      const response = await axios.post('/search',{ q:query })
      this.trackSearchEvent() // track search event
      this.lessons = response.data.results.lessons
      this.courses = response.data.results.courses
      this.instructors = response.data.results.instructors
      this.isLoading = false
    },
    trackSearchEvent() {
      this.trackEvent('search_event', {
        facet: this.selectedTab,
        query: this.searchKey,
        form: 'page',
        location: '/search'
      })
    },
    handleTab(value) {
      if (this.selectedTab === value) {
        return
      }

      this.selectedTab = value
      if (this.query !== this.searchKey) {
        this.search(this.query)
      }

      // Use Inertia's replace method
      const newUrl = `/search/${value}?q=${this.query}`
      this.$inertia.replace(newUrl)
    },
    handleSearch(query) {
      this.query = query
      this.search(query)
      const newUrl = `/search/${this.selectedTab}?q=${query}`
      this.$inertia.replace(newUrl)
      // this.$inertia.visit(`/search?q=${query}`)
    }
  },
  computed: {
    tabSelection() {
      return this.selectedTab
    }
  }
}
</script>
