<template>
  <form submit.prevent novalidate="true">
    <div class="flex flex-col">
      <div class="flex flex-col mb-8 max-w-xl w-full mx-auto">
        <h3 class="text-sm uppercase text-blue-800 mb-1 font-medium">
          you are gifting a
        </h3>
        <p class="text-lg text-grayscale-500 font-normal tracking-wide">
          <span v-if="selectedCampaign.category === 'membership_gift'">
              1 year All Access Membership (subscription starts when code is redeemed)
            </span>
          <span v-else>
            {{ selectedCampaign.humanizedPrice }} - Digital Gift Card
          </span>
        </p>
      </div>
      <!-- <div v-if="!preSelectedCampaign" class="mb-8 max-w-xl w-full mx-auto">
        <h3 class="text-sm uppercase text-blue-800 mb-1 font-medium">
          {{ $I18n.t('form.headings.card_value', i18nScope) }}
        </h3>

        <SelectGroup
          testid="input-campaign"
          v-model="form.campaign"
          :options="campaignsOptions"
          :errors="form.errors.campaign"
          :label="$I18n.t('form.inputs.card_value.label', i18nScope)"
          :placeholder="
            $I18n.t('form.inputs.card_value.placeholder', i18nScope)
          "
        />
      </div> -->

      <div class="max-w-xl w-full mx-auto mb-4">
        <h3 class="text-sm uppercase text-blue-800 mb-8 font-medium">
          Where shall we send this gift?
        </h3>

        <div class="flex flex-col gap-2 flex-wrap">
          <Radio
            testid="input-campaign-delivery-self"
            v-model="delivery"
            option="self"
            :label="$I18n.t('form.inputs.delivery_options.self', i18nScope)"
            @change="onDeliveryChange"
            class=""
          />

          <Radio
            testid="input-campaign-delivery-recipient"
            v-model="delivery"
            option="recipient"
            :label="
              $I18n.t('form.inputs.delivery_options.recipient', i18nScope)
            "
            @change="onDeliveryChange"
            class="mb-0"
          />
        </div>
      </div>

      <div class="flex flex-col gap-4 max-w-xl w-full mx-auto mb-8">
        <!-- RECIPIENT EMAIL ONLY SHOWS WHEN RADIO FOR EMAIL TO RECIPIENT IS SELECTED -->
        <InputGroup
          v-show="showRecipientEmail"
          testid="input-recipient-email"
          v-model="form.recipient.email"
          :errors="form.errors.recipient_email"
          label="recipient email"
          placeholder="Email"
          class="customLabel"
        />

        <!-- RECIPIENT MESSAGE APPEARS FOR BOTH OPTIONS -->
        <TextArea
          testid="input-recipient-message"
          v-model="form.recipient.message"
          :errors="form.errors.recipient_message"
          :maxLength="200"
          label="gift message"
          placeholder="Your message will be written on a gift-card email"
          class="customLabel"
        />
      </div>

      <div class="flex flex-row flex-wrap gap-x-6 gap-y-4">
        <Button
          v-if="!isModal"
          data-testid="button-add-campaign-preview-another"
          :loading="form.processing"
          @click="clickOnPreview()"
          variant="light-blue"
          type="button"
          inverse
          class="w-full sm:flex-1"
          :buttonDisabled="!this.form.campaign"
        >
          {{ $I18n.t('form.actions.preview', i18nScope) }}
        </Button>

        <Button
          data-testid="button-add-campaign-submit-checkout"
          :loading="form.processing"
          @click="submit()"
          type="button"
          class="w-full sm:flex-1"
        >
          Proceed to Checkout
        </Button>
      </div>
    </div>
  </form>
</template>

<script>
import gtm from '@/mixins/gtm'
import cart from '@/mixins/cart'
import mixpanel from '@/mixins/mixpanel'

import Button from '@/Components/Shared/Button'
import GiftPreviewModal from '@/Components/Shared/Gifts/PreviewModal'
import InputGroup from '@/Components/Shared/Forms/InputGroup'
import Radio from '@/Components/Shared/Forms/Radio'
// import SelectGroup from '@/Components/Shared/Forms/SelectGroup'
import TextArea from '@/Components/Shared/Forms/TextArea'

export default {
  components: {
    Button,
    InputGroup,
    Radio,
    // SelectGroup,
    TextArea
  },

  mixins: [gtm, cart, mixpanel],

  props: {
    campaigns: {
      type: Array,
      required: true,
      default: () => []
    },
    preSelectedCampaign: {
      type: String
    },
    isModal: {
      type: Boolean,
      required: false
    },
    gtmPageName: {
      type: String,
      required: true
    },
    gtmSectionName: {
      type: String
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.gifts' }
  },

  data() {
    return {
      form: this.$inertia.form({
        campaign: this.preSelectedCampaign,
        recipient: {
          email: null,
          message: null
        },
        errors: {}
      }),
      delivery: 'self',
      tempRecipientEmail: null
    }
  },

  methods: {
    submit(addAnotherGift = false) {
      const { campaign } = this.form
      const { email } = this.form.recipient

      this.form.errors = {}

      const isValidEmail = !this.showRecipientEmail || email

      // Validate required fields before sending the request
      if (campaign && isValidEmail) {
        this.form.post(this.$routes.cart_cart_items(), {
          preserveScroll: true,
          onSuccess: () => {
            this.addToCartToGtm()
            this.trackAddCampaign()

            if (addAnotherGift) {
              this.resetForm()
            } else {
              this.$inertia.visit(this.$routes.cart())
            }
          }
        })
      }

      if (!campaign) {
        this.form.errors.campaign = [
          this.$I18n.t('form.inputs.card_value.empty_error', this.i18nScope)
        ]
      }

      if (!isValidEmail) {
        this.form.errors.recipient_email = [
          this.$I18n.t(
            'form.inputs.recipient_email.empty_error',
            this.i18nScope
          )
        ]
      }
    },

    resetForm() {
      this.form.reset()
      this.delivery = 'self'
      this.tempRecipientEmail = null
    },

    onDeliveryChange() {
      // When we change the delivery radio from 'recipient' to 'self', we store the recipient
      // email value in a temporary variable so that recipient email isn't sent when the delivery
      // is 'self', but we put it back when the delivery radio changes back to 'recipient'
      if (this.showRecipientEmail) {
        this.form.recipient.email = this.tempRecipientEmail
      } else {
        this.tempRecipientEmail = this.form.recipient.email
        this.form.recipient.email = null
      }
    },

    addToCartToGtm() {
      this.gtmAddToCart('gift_modal', this.selectedCampaign)
    },

    getUserType() {
      return this.currentUser ? 'authed' : 'anonymous'
    },

    trackAddCampaign() {
      this.trackEvent('add_campaign_to_basket', {
        page: window.location.pathname,
        component: 'Gift Form',
        items: [this.selectedCampaign.name],
        ...this.mixpanelCartData(this.orderable)
      })
    },

    clickOnPreview() {
      this.trackEvent('customise_gift_preview', {
        page: window.location.pathname,
        campaignName: this.selectedCampaign.name,
        userType: this.getUserType()
      })

      this.$modal.show(
        GiftPreviewModal,
        {
          campaign: this.selectedCampaign,
          message: this.form.recipient.message
        },
        {
          adaptive: false,
          reset: true,
          width: 700,
          maxWidth: 700,
          height: 'auto',
          maxHeight: 880,
          classes: 'v--modal-voucher-preview voucher-custom-modal'
        }
      )
    }
  },

  computed: {
    selectedCampaign() {
      return this.campaigns.find(
        (campaign) => campaign.slug === this.form.campaign
      )
    },

    campaignsOptions() {
      return this.campaigns.map((campaign) => {
        return { text: campaign.humanizedPrice, value: campaign.slug }
      })
    },

    showRecipientEmail() {
      return this.delivery === 'recipient'
    },

    purchaseAndCheckoutButtonCopy() {
      const i18nKey = this.preSelectedCampaign
        ? 'form.actions.basket'
        : 'form.actions.checkout'

      return this.$I18n.t(i18nKey, this.i18nScope)
    }
  }
}
</script>