<template>
  <div class="cart-summary">
    <h2 v-if="hasTitle" class="title-large text-blue-800 mb-8" >
      {{ $I18n.t('sections.order_summary_title', i18nScope) }}
    </h2>

    <OrderSummaryRow
      v-for="cartItem in orderable.cartItems"
      :showGiftInfo="showGiftInfo"
      :allowRemoval="allowRemoval"
      :cartItem="cartItem"
      :key="cartItem.id"
      :gtmPageName="gtmPageName"
      :activeSale="activeSale"
    />
  </div>
</template>

<script>
import OrderSummaryRow from '@/Components/Orders/OrderSummaryRow'

export default {
  components: {
    OrderSummaryRow
  },

  computed: {
    activeSale() {
      return this.orderable.activeSale
    }
  },

  props: {
    hasTitle: {
      type: Boolean,
      required: false
    },
    orderable: {
      type: Object,
      required: true
    },
    i18nScope: {
      type: Object,
      required: true
    },
    showGiftInfo: {
      type: Boolean,
      default: true
    },
    allowRemoval: {
      type: Boolean,
      required: false
    },
    gtmPageName: {
      type: String,
      required: true
    }
  }
}
</script>
