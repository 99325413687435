<template>
  <ImageWrapper background="setup-account">
    <MetaTags :metaTags="metaTags" />

    <h1 class="title-large text-blue-800 mb-6">{{ $I18n.t('title', i18nScope) }}</h1>

    <p class="mb-4">{{ $I18n.t('description', i18nScope) }}</p>

    <p class="mb-8">{{ $I18n.t('instructions', i18nScope) }}</p>

    <Button data-testid="button-link" @click="onClick" class="mt-6" block>
      {{ $I18n.t('cta', i18nScope) }}
    </Button>
  </ImageWrapper>
</template>

<script>
import { metaTags } from '@/mixins/metaTags'

import Layout from '@/Layouts/ReducedLayout'

import MetaTags from '@/Components/Shared/MetaTags'
import ImageWrapper from '@/Components/Shared/Objects/ImageWrapper'
import Button from '@/Components/Shared/Button'

export default {
  layout: Layout,

  mixins: [metaTags],

  created() {
    this.i18nScope = { scope: 'pages.migration.confirmation_page' }
  },

  components: {
    MetaTags,
    ImageWrapper,
    Button
  },

  methods: {
    onClick() {
      this.$inertia.visit(this.$routes.root({ anchor: 'my-courses' }))
    }
  }
}
</script>
