<template>
  <div class="max-w-5xl mx-auto px-4 py-8 font-sans">
    <header class="text-center mb-8">
      <h1 class="text-3xl text-gray-800">Finding Your Style - Part 1</h1>
    </header>

    <div class="flex flex-col lg:flex-row lg:space-x-8">
      <section class="lg:w-2/3 space-y-6">
        <p class="text-gray-700">
          Knowing what you don't like is as useful as knowing what you do like. Accept your own
          style: some things just won't work for you in the same way as they do for other people,
          just as other things will work for you in a way they don't work for other people.
        </p>

        <p class="text-gray-700">
          It is important to find your taste rather than trying to copy other people. Pinterest is a
          great tool in helping you better visualise what you are after but doesn't need to be
          referred to throughout the entire project.
        </p>

        <h2 class="text-2xl text-gray-800 mt-8 mb-4">Your Assignment</h2>
        <p class="text-gray-700">
          Make a list of a few interior details from your childhood that still resonate with you.
        </p>

        <h2 class="text-2xl text-gray-800 mt-8 mb-4">Discover more</h2>
        <p class="text-gray-700">
          Discover how Rita used Pinterest to curate a variety of ideas and draw North Farm together
          <a
            href="https://www.pinterest.co.uk/CreateAcademyOfficial/how-to-curate-design-ideas-with-rita-konig/"
            target="_blank"
            >here</a
          >
        </p>

        <h2 class="text-2xl text-gray-800 mt-8 mb-4">Room Design Notes</h2>
        <ul class="list-disc pl-6 text-gray-700 space-y-2">
          <li>
            Black & white sofa fabric:
            <a
              href="https://shop.ninacampbell.com/products/jacquet-pachinko-charcoal-ivory-fabric "
              target="_blank"
              >Pachinko by Nina Campbell</a
            >
          </li>
          <li>Pink cushions: Indira Rouge by Raoul Textiles</li>
          <li>Light blue & green ikat cushions bought in Turkey</li>
          <li>Vintage sofa: David Bedale Antiques in a vintage fabric</li>
          <li>Vintage tables: Sibyl Colefax & John Fowler</li>
          <li>White bobbly jug: Astier de Villatte</li>
          <li>A similar Victorian match striker can be bought from Lucy Cope</li>
        </ul>
      </section>

      <aside class="lg:w-1/3 mt-8 lg:mt-0">
        <div class="bg-gray-100 border-l-4 border-blue-500 p-6 sticky top-8">
          <h2 class="text-xl font-semibold text-gray-800 mb-2">Key Question</h2>
          <p class="text-gray-700 mb-4">
            How do I ensure an eclectic collection of 'stuff' does not look too busy, or too
            cluttered?
          </p>
          <blockquote class="pl-4 border-l-2 border-blue-500 italic">
            <p class="text-gray-600">
              "I am self-professed lover of clutter and pattern. However, when decorating a room I
              feel it is important to ask yourself how you want to use your space. Think about the
              functions, for example, if it's a kitchen is it simply to just cook in or does it
              double as a dining area. Be honest with yourself and don't try to get room to fulfil
              too many functions. This is one of the best ways to avoid too much clutter."
            </p>
            <footer class="text-gray-500 mt-2">– RK</footer>
          </blockquote>
        </div>
      </aside>
    </div>

    <section class="mt-12">
      <h2 class="text-2xl text-gray-800 mb-6">A Quick Guide to Using Pinterest</h2>
      <h3 class="text-xl text-gray-800 mb-4">Step 1. Understanding Pinterest terminology</h3>
      <dl class="space-y-4">
        <div>
          <dt class="text-gray-800">Pin</dt>
          <dd class="text-gray-700 ml-4">
            Pins are bookmarks on Pinterest used to save images you like. A pin contains an image, a
            description, and a link.
          </dd>
        </div>
        <div>
          <dt class="text-gray-800">Repin</dt>
          <dd class="text-gray-700 ml-4">
            A repin is sharing other persons pin on your board, this is done by pressing 'save'.
          </dd>
        </div>
        <div>
          <dt class="text-gray-800">Board</dt>
          <dd class="text-gray-700 ml-4">
            The pins that you save are placed on boards. Boards can be named and arranged on your
            profile the way you best see fit.
          </dd>
        </div>
        <div>
          <dt class="text-gray-800">Section</dt>
          <dd class="text-gray-700 ml-4">
            A section is a subset of your board (almost like a board within a board), allowing you
            to categorise things further.
          </dd>
        </div>
        <div>
          <dt class="text-gray-800">Profile</dt>
          <dd class="text-gray-700 ml-4">
            Your profile displays your various boards and you can see who is following you and whom
            you are following.
          </dd>
        </div>
      </dl>
      <p class="mt-6">
        Read our full blog post on how to make a Pinterest board to curate your own design ideas
        <a href="https://www.pinterest.co.uk/CreateAcademyOfficial/">here</a>.
      </p>
    </section>
  </div>
</template>

<script>
  export default {
    name: 'InteriorDesignGuide'
  }
</script>

<style scoped>
  @media (min-width: 1024px) {
    .sticky {
      position: sticky;
      top: 2rem;
    }
  }
</style>
