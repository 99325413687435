/*
File generated by js-routes 1.4.14
Based on Rails 7.0.8.7 routes of CreateAcademy::Application
 */

(function() {
  var DeprecatedGlobbingBehavior, NodeTypes, ParameterMissing, ReservedOptions, SpecialOptionsKey, UriEncoderSegmentRegex, Utils, error, result,
    hasProp = {}.hasOwnProperty,
    slice = [].slice;

  ParameterMissing = function(message, fileName, lineNumber) {
    var instance;
    instance = new Error(message, fileName, lineNumber);
    if (Object.setPrototypeOf) {
      Object.setPrototypeOf(instance, Object.getPrototypeOf(this));
    } else {
      instance.__proto__ = this.__proto__;
    }
    if (Error.captureStackTrace) {
      Error.captureStackTrace(instance, ParameterMissing);
    }
    return instance;
  };

  ParameterMissing.prototype = Object.create(Error.prototype, {
    constructor: {
      value: Error,
      enumerable: false,
      writable: true,
      configurable: true
    }
  });

  if (Object.setPrototypeOf) {
    Object.setPrototypeOf(ParameterMissing, Error);
  } else {
    ParameterMissing.__proto__ = Error;
  }

  NodeTypes = {"GROUP":1,"CAT":2,"SYMBOL":3,"OR":4,"STAR":5,"LITERAL":6,"SLASH":7,"DOT":8};

  DeprecatedGlobbingBehavior = false;

  SpecialOptionsKey = "_options";

  UriEncoderSegmentRegex = /[^a-zA-Z0-9\-\._~!\$&'\(\)\*\+,;=:@]/g;

  ReservedOptions = ['anchor', 'trailing_slash', 'subdomain', 'host', 'port', 'protocol'];

  Utils = {
    configuration: {
      prefix: "",
      default_url_options: {"trailing_slash":false},
      special_options_key: "_options",
      serializer: null
    },
    default_serializer: function(object, prefix) {
      var element, i, j, key, len, prop, s;
      if (prefix == null) {
        prefix = null;
      }
      if (object == null) {
        return "";
      }
      if (!prefix && !(this.get_object_type(object) === "object")) {
        throw new Error("Url parameters should be a javascript hash");
      }
      s = [];
      switch (this.get_object_type(object)) {
        case "array":
          for (i = j = 0, len = object.length; j < len; i = ++j) {
            element = object[i];
            s.push(this.default_serializer(element, prefix + "[]"));
          }
          break;
        case "object":
          for (key in object) {
            if (!hasProp.call(object, key)) continue;
            prop = object[key];
            if ((prop == null) && (prefix != null)) {
              prop = "";
            }
            if (prop != null) {
              if (prefix != null) {
                key = prefix + "[" + key + "]";
              }
              s.push(this.default_serializer(prop, key));
            }
          }
          break;
        default:
          if (object != null) {
            s.push((encodeURIComponent(prefix.toString())) + "=" + (encodeURIComponent(object.toString())));
          }
      }
      if (!s.length) {
        return "";
      }
      return s.join("&");
    },
    serialize: function(object) {
      var custom_serializer;
      custom_serializer = this.configuration.serializer;
      if ((custom_serializer != null) && this.get_object_type(custom_serializer) === "function") {
        return custom_serializer(object);
      } else {
        return this.default_serializer(object);
      }
    },
    clean_path: function(path) {
      var last_index;
      path = path.split("://");
      last_index = path.length - 1;
      path[last_index] = path[last_index].replace(/\/+/g, "/");
      return path.join("://");
    },
    extract_options: function(number_of_params, args) {
      var last_el, options;
      last_el = args[args.length - 1];
      if ((args.length > number_of_params && last_el === void 0) || ((last_el != null) && "object" === this.get_object_type(last_el) && !this.looks_like_serialized_model(last_el))) {
        options = args.pop() || {};
        delete options[this.configuration.special_options_key];
        return options;
      } else {
        return {};
      }
    },
    looks_like_serialized_model: function(object) {
      return !object[this.configuration.special_options_key] && ("id" in object || "to_param" in object);
    },
    path_identifier: function(object) {
      var property;
      if (object === 0) {
        return "0";
      }
      if (!object) {
        return "";
      }
      property = object;
      if (this.get_object_type(object) === "object") {
        if ("to_param" in object) {
          if (object.to_param == null) {
            throw new ParameterMissing("Route parameter missing: to_param");
          }
          property = object.to_param;
        } else if ("id" in object) {
          if (object.id == null) {
            throw new ParameterMissing("Route parameter missing: id");
          }
          property = object.id;
        } else {
          property = object;
        }
        if (this.get_object_type(property) === "function") {
          property = property.call(object);
        }
      }
      return property.toString();
    },
    clone: function(obj) {
      var attr, copy, key;
      if ((obj == null) || "object" !== this.get_object_type(obj)) {
        return obj;
      }
      copy = obj.constructor();
      for (key in obj) {
        if (!hasProp.call(obj, key)) continue;
        attr = obj[key];
        copy[key] = attr;
      }
      return copy;
    },
    merge: function() {
      var tap, xs;
      xs = 1 <= arguments.length ? slice.call(arguments, 0) : [];
      tap = function(o, fn) {
        fn(o);
        return o;
      };
      if ((xs != null ? xs.length : void 0) > 0) {
        return tap({}, function(m) {
          var j, k, len, results, v, x;
          results = [];
          for (j = 0, len = xs.length; j < len; j++) {
            x = xs[j];
            results.push((function() {
              var results1;
              results1 = [];
              for (k in x) {
                v = x[k];
                results1.push(m[k] = v);
              }
              return results1;
            })());
          }
          return results;
        });
      }
    },
    normalize_options: function(parts, required_parts, default_options, actual_parameters) {
      var i, j, key, len, options, part, parts_options, result, route_parts, url_parameters, use_all_parts, value;
      options = this.extract_options(parts.length, actual_parameters);
      if (actual_parameters.length > parts.length) {
        throw new Error("Too many parameters provided for path");
      }
      use_all_parts = actual_parameters.length > required_parts.length;
      parts_options = {};
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        use_all_parts = true;
        if (this.indexOf(parts, key) >= 0) {
          parts_options[key] = value;
        }
      }
      options = this.merge(this.configuration.default_url_options, default_options, options);
      result = {};
      url_parameters = {};
      result['url_parameters'] = url_parameters;
      for (key in options) {
        if (!hasProp.call(options, key)) continue;
        value = options[key];
        if (this.indexOf(ReservedOptions, key) >= 0) {
          result[key] = value;
        } else {
          url_parameters[key] = value;
        }
      }
      route_parts = use_all_parts ? parts : required_parts;
      i = 0;
      for (j = 0, len = route_parts.length; j < len; j++) {
        part = route_parts[j];
        if (i < actual_parameters.length) {
          if (!parts_options.hasOwnProperty(part)) {
            url_parameters[part] = actual_parameters[i];
            ++i;
          }
        }
      }
      return result;
    },
    build_route: function(parts, required_parts, default_options, route, full_url, args) {
      var options, parameters, result, url, url_params;
      args = Array.prototype.slice.call(args);
      options = this.normalize_options(parts, required_parts, default_options, args);
      parameters = options['url_parameters'];
      result = "" + (this.get_prefix()) + (this.visit(route, parameters));
      url = Utils.clean_path(result);
      if (options['trailing_slash'] === true) {
        url = url.replace(/(.*?)[\/]?$/, "$1/");
      }
      if ((url_params = this.serialize(parameters)).length) {
        url += "?" + url_params;
      }
      url += options.anchor ? "#" + options.anchor : "";
      if (full_url) {
        url = this.route_url(options) + url;
      }
      return url;
    },
    visit: function(route, parameters, optional) {
      var left, left_part, right, right_part, type, value;
      if (optional == null) {
        optional = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return this.visit(left, parameters, true);
        case NodeTypes.STAR:
          return this.visit_globbing(left, parameters, true);
        case NodeTypes.LITERAL:
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
          return left;
        case NodeTypes.CAT:
          left_part = this.visit(left, parameters, optional);
          right_part = this.visit(right, parameters, optional);
          if (optional && ((this.is_optional_node(left[0]) && !left_part) || ((this.is_optional_node(right[0])) && !right_part))) {
            return "";
          }
          return "" + left_part + right_part;
        case NodeTypes.SYMBOL:
          value = parameters[left];
          delete parameters[left];
          if (value != null) {
            return this.encode_segment(this.path_identifier(value));
          }
          if (optional) {
            return "";
          } else {
            throw new ParameterMissing("Route parameter missing: " + left);
          }
          break;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    encode_segment: function(segment) {
      return segment.replace(UriEncoderSegmentRegex, function(str) {
        return encodeURIComponent(str);
      });
    },
    is_optional_node: function(node) {
      return this.indexOf([NodeTypes.STAR, NodeTypes.SYMBOL, NodeTypes.CAT], node) >= 0;
    },
    build_path_spec: function(route, wildcard) {
      var left, right, type;
      if (wildcard == null) {
        wildcard = false;
      }
      type = route[0], left = route[1], right = route[2];
      switch (type) {
        case NodeTypes.GROUP:
          return "(" + (this.build_path_spec(left)) + ")";
        case NodeTypes.CAT:
          return "" + (this.build_path_spec(left)) + (this.build_path_spec(right));
        case NodeTypes.STAR:
          return this.build_path_spec(left, true);
        case NodeTypes.SYMBOL:
          if (wildcard === true) {
            return "" + (left[0] === '*' ? '' : '*') + left;
          } else {
            return ":" + left;
          }
          break;
        case NodeTypes.SLASH:
        case NodeTypes.DOT:
        case NodeTypes.LITERAL:
          return left;
        default:
          throw new Error("Unknown Rails node type");
      }
    },
    visit_globbing: function(route, parameters, optional) {
      var left, right, type, value;
      type = route[0], left = route[1], right = route[2];
      value = parameters[left];
      delete parameters[left];
      if (value == null) {
        return this.visit(route, parameters, optional);
      }
      value = (function() {
        switch (this.get_object_type(value)) {
          case "array":
            return value.join("/");
          default:
            return value;
        }
      }).call(this);
      if (DeprecatedGlobbingBehavior) {
        return this.path_identifier(value);
      } else {
        return encodeURI(this.path_identifier(value));
      }
    },
    get_prefix: function() {
      var prefix;
      prefix = this.configuration.prefix;
      if (prefix !== "") {
        prefix = (prefix.match("/$") ? prefix : prefix + "/");
      }
      return prefix;
    },
    route: function(parts_table, default_options, route_spec, full_url) {
      var j, len, part, parts, path_fn, ref, required, required_parts;
      required_parts = [];
      parts = [];
      for (j = 0, len = parts_table.length; j < len; j++) {
        ref = parts_table[j], part = ref[0], required = ref[1];
        parts.push(part);
        if (required) {
          required_parts.push(part);
        }
      }
      path_fn = function() {
        return Utils.build_route(parts, required_parts, default_options, route_spec, full_url, arguments);
      };
      path_fn.required_params = required_parts;
      path_fn.toString = function() {
        return Utils.build_path_spec(route_spec);
      };
      return path_fn;
    },
    route_url: function(route_defaults) {
      var hostname, port, protocol, subdomain;
      if (typeof route_defaults === 'string') {
        return route_defaults;
      }
      hostname = route_defaults.host || Utils.current_host();
      if (!hostname) {
        return '';
      }
      subdomain = route_defaults.subdomain ? route_defaults.subdomain + '.' : '';
      protocol = route_defaults.protocol || Utils.current_protocol();
      port = route_defaults.port || (!route_defaults.host ? Utils.current_port() : void 0);
      port = port ? ":" + port : '';
      return protocol + "://" + subdomain + hostname + port;
    },
    has_location: function() {
      return (typeof window !== "undefined" && window !== null ? window.location : void 0) != null;
    },
    current_host: function() {
      if (this.has_location()) {
        return window.location.hostname;
      } else {
        return null;
      }
    },
    current_protocol: function() {
      if (this.has_location() && window.location.protocol !== '') {
        return window.location.protocol.replace(/:$/, '');
      } else {
        return 'http';
      }
    },
    current_port: function() {
      if (this.has_location() && window.location.port !== '') {
        return window.location.port;
      } else {
        return '';
      }
    },
    _classToTypeCache: null,
    _classToType: function() {
      var j, len, name, ref;
      if (this._classToTypeCache != null) {
        return this._classToTypeCache;
      }
      this._classToTypeCache = {};
      ref = "Boolean Number String Function Array Date RegExp Object Error".split(" ");
      for (j = 0, len = ref.length; j < len; j++) {
        name = ref[j];
        this._classToTypeCache["[object " + name + "]"] = name.toLowerCase();
      }
      return this._classToTypeCache;
    },
    get_object_type: function(obj) {
      if (this.jQuery && (this.jQuery.type != null)) {
        return this.jQuery.type(obj);
      }
      if (obj == null) {
        return "" + obj;
      }
      if (typeof obj === "object" || typeof obj === "function") {
        return this._classToType()[Object.prototype.toString.call(obj)] || "object";
      } else {
        return typeof obj;
      }
    },
    indexOf: function(array, element) {
      if (Array.prototype.indexOf) {
        return array.indexOf(element);
      } else {
        return this.indexOfImplementation(array, element);
      }
    },
    indexOfImplementation: function(array, element) {
      var el, i, j, len, result;
      result = -1;
      for (i = j = 0, len = array.length; j < len; i = ++j) {
        el = array[i];
        if (el === element) {
          result = i;
        }
      }
      return result;
    },
    namespace: function(root, namespace, routes) {
      var index, j, len, part, parts;
      parts = namespace ? namespace.split(".") : [];
      if (parts.length === 0) {
        return routes;
      }
      for (index = j = 0, len = parts.length; j < len; index = ++j) {
        part = parts[index];
        if (index < parts.length - 1) {
          root = (root[part] || (root[part] = {}));
        } else {
          return root[part] = routes;
        }
      }
    },
    configure: function(new_config) {
      return this.configuration = this.merge(this.configuration, new_config);
    },
    config: function() {
      return this.clone(this.configuration);
    },
    make: function() {
      var routes;
      routes = {
// about_us => /about-us(.:format)
  // function(options)
  about_us: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"about-us",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// add_cart_bundle => /basket/collections/:slug/add(.:format)
  // function(slug, options)
  add_cart_bundle: Utils.route([["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"basket",false],[2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"add",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// add_cart_collection => /basket/collections/:id/add(.:format)
  // function(id, options)
  add_cart_collection: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"basket",false],[2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"add",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// add_lesson_to_collection_user_collections => /saved_lessons/add_lesson_to_collection(.:format)
  // function(options)
  add_lesson_to_collection_user_collections: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"saved_lessons",false],[2,[7,"/",false],[2,[6,"add_lesson_to_collection",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// add_subscription_selected_member_tag_admin_scripts => /admin/scripts/add_subscription_selected_member_tag(.:format)
  // function(options)
  add_subscription_selected_member_tag_admin_scripts: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"scripts",false],[2,[7,"/",false],[2,[6,"add_subscription_selected_member_tag",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// add_users_for_new_courses_admin_scripts => /admin/scripts/add_users_for_new_courses(.:format)
  // function(options)
  add_users_for_new_courses_admin_scripts: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"scripts",false],[2,[7,"/",false],[2,[6,"add_users_for_new_courses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_about_section => /admin/about_sections/:id(.:format)
  // function(id, options)
  admin_about_section: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"about_sections",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_about_sections => /admin/about_sections(.:format)
  // function(options)
  admin_about_sections: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"about_sections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_admin_user => /admin/admin_users/:id(.:format)
  // function(id, options)
  admin_admin_user: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"admin_users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_admin_users => /admin/admin_users(.:format)
  // function(options)
  admin_admin_users: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"admin_users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_banner_strip => /admin/banner_strips/:id(.:format)
  // function(id, options)
  admin_banner_strip: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"banner_strips",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_banner_strips => /admin/banner_strips(.:format)
  // function(options)
  admin_banner_strips: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"banner_strips",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_bundle => /admin/bundles/:id(.:format)
  // function(id, options)
  admin_bundle: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bundles",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_bundle_item => /admin/bundle_items/:id(.:format)
  // function(id, options)
  admin_bundle_item: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bundle_items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_bundles => /admin/bundles(.:format)
  // function(options)
  admin_bundles: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bundles",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_buy_one_gift_one_emails => /admin/buy_one_gift_one_emails(.:format)
  // function(options)
  admin_buy_one_gift_one_emails: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"buy_one_gift_one_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_campaign => /admin/campaigns/:id(.:format)
  // function(id, options)
  admin_campaign: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_campaigns => /admin/campaigns(.:format)
  // function(options)
  admin_campaigns: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"campaigns",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_cart => /admin/carts/:id(.:format)
  // function(id, options)
  admin_cart: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"carts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_cart_drawer => /admin/cart_drawers/:id(.:format)
  // function(id, options)
  admin_cart_drawer: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"cart_drawers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_cart_drawers => /admin/cart_drawers(.:format)
  // function(options)
  admin_cart_drawers: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"cart_drawers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_cart_item => /admin/cart_items/:id(.:format)
  // function(id, options)
  admin_cart_item: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"cart_items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_carts => /admin/carts(.:format)
  // function(options)
  admin_carts: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"carts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_categories => /admin/categories(.:format)
  // function(options)
  admin_categories: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_category => /admin/categories/:id(.:format)
  // function(id, options)
  admin_category: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_collection => /admin/collections/:id(.:format)
  // function(id, options)
  admin_collection: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_collection_course => /admin/collection_courses/:id(.:format)
  // function(id, options)
  admin_collection_course: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"collection_courses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_collection_courses => /admin/collection_courses(.:format)
  // function(options)
  admin_collection_courses: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"collection_courses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_collections => /admin/collections(.:format)
  // function(options)
  admin_collections: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"collections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_comment => /admin/comments/:id(.:format)
  // function(id, options)
  admin_comment: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_comments => /admin/comments(.:format)
  // function(options)
  admin_comments: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"comments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_course => /admin/courses/:id(.:format)
  // function(id, options)
  admin_course: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_course_enrollment => /admin/course_enrollments/:id(.:format)
  // function(id, options)
  admin_course_enrollment: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"course_enrollments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_course_enrollments => /admin/course_enrollments(.:format)
  // function(options)
  admin_course_enrollments: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"course_enrollments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_course_objective => /admin/course_objectives/:id(.:format)
  // function(id, options)
  admin_course_objective: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"course_objectives",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_course_objectives => /admin/course_objectives(.:format)
  // function(options)
  admin_course_objectives: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"course_objectives",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_courses => /admin/courses(.:format)
  // function(options)
  admin_courses: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"courses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_enquiries => /admin/enquiries(.:format)
  // function(options)
  admin_enquiries: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"enquiries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_enquiry => /admin/enquiries/:id(.:format)
  // function(id, options)
  admin_enquiry: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"enquiries",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_exchange_rate => /admin/exchange_rates/:id(.:format)
  // function(id, options)
  admin_exchange_rate: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"exchange_rates",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_exchange_rates => /admin/exchange_rates(.:format)
  // function(options)
  admin_exchange_rates: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"exchange_rates",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_experiment_results => /admin/experiment_results(.:format)
  // function(options)
  admin_experiment_results: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"experiment_results",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_featured_section => /admin/featured_sections/:id(.:format)
  // function(id, options)
  admin_featured_section: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"featured_sections",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_featured_sections => /admin/featured_sections(.:format)
  // function(options)
  admin_featured_sections: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"featured_sections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_general_announcement => /admin/general_announcements/:id(.:format)
  // function(id, options)
  admin_general_announcement: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"general_announcements",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_general_announcements => /admin/general_announcements(.:format)
  // function(options)
  admin_general_announcements: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"general_announcements",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_gift => /admin/gifts/:id(.:format)
  // function(id, options)
  admin_gift: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"gifts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_gift_section => /admin/gift_sections/:id(.:format)
  // function(id, options)
  admin_gift_section: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"gift_sections",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_gift_sections => /admin/gift_sections(.:format)
  // function(options)
  admin_gift_sections: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"gift_sections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_gifts => /admin/gifts(.:format)
  // function(options)
  admin_gifts: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"gifts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_identities => /admin/identities(.:format)
  // function(options)
  admin_identities: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"identities",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_identity => /admin/identities/:id(.:format)
  // function(id, options)
  admin_identity: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"identities",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_lesson => /admin/lessons/:id(.:format)
  // function(id, options)
  admin_lesson: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"lessons",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_lesson_enrollment => /admin/lesson_enrollments/:id(.:format)
  // function(id, options)
  admin_lesson_enrollment: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"lesson_enrollments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_lesson_enrollments => /admin/lesson_enrollments(.:format)
  // function(options)
  admin_lesson_enrollments: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"lesson_enrollments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_lesson_plan => /admin/lesson_plans/:id(.:format)
  // function(id, options)
  admin_lesson_plan: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_lesson_plans => /admin/lesson_plans(.:format)
  // function(options)
  admin_lesson_plans: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"lesson_plans",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_lessons => /admin/lessons(.:format)
  // function(options)
  admin_lessons: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"lessons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_membership => /admin/memberships/:id(.:format)
  // function(id, options)
  admin_membership: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"memberships",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_membership_coupon => /admin/membership_coupons/:id(.:format)
  // function(id, options)
  admin_membership_coupon: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"membership_coupons",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_membership_coupons => /admin/membership_coupons(.:format)
  // function(options)
  admin_membership_coupons: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"membership_coupons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_membership_payment => /admin/membership_payments/:id(.:format)
  // function(id, options)
  admin_membership_payment: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"membership_payments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_membership_payments => /admin/membership_payments(.:format)
  // function(options)
  admin_membership_payments: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"membership_payments",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_membership_plan => /admin/membership_plans/:id(.:format)
  // function(id, options)
  admin_membership_plan: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"membership_plans",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_membership_plans => /admin/membership_plans(.:format)
  // function(options)
  admin_membership_plans: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"membership_plans",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_membership_selection => /admin/membership_selections/:id(.:format)
  // function(id, options)
  admin_membership_selection: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"membership_selections",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_membership_selections => /admin/membership_selections(.:format)
  // function(options)
  admin_membership_selections: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"membership_selections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_memberships => /admin/memberships(.:format)
  // function(options)
  admin_memberships: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"memberships",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_order => /admin/orders/:id(.:format)
  // function(id, options)
  admin_order: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_orders => /admin/orders(.:format)
  // function(options)
  admin_orders: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_post => /admin/posts/:id(.:format)
  // function(id, options)
  admin_post: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"posts",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_post_categories => /admin/post_categories(.:format)
  // function(options)
  admin_post_categories: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"post_categories",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_post_category => /admin/post_categories/:id(.:format)
  // function(id, options)
  admin_post_category: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"post_categories",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_posts => /admin/posts(.:format)
  // function(options)
  admin_posts: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"posts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_press_reference => /admin/press_references/:id(.:format)
  // function(id, options)
  admin_press_reference: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"press_references",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_press_references => /admin/press_references(.:format)
  // function(options)
  admin_press_references: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"press_references",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_root => /admin(.:format)
  // function(options)
  admin_root: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// admin_sale => /admin/sales/:id(.:format)
  // function(id, options)
  admin_sale: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sales",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_sale_tag => /admin/sale_tags/:id(.:format)
  // function(id, options)
  admin_sale_tag: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sale_tags",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_sale_tags => /admin/sale_tags(.:format)
  // function(options)
  admin_sale_tags: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sale_tags",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_sales => /admin/sales(.:format)
  // function(options)
  admin_sales: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sales",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_seasonal_banner => /admin/seasonal_banners/:id(.:format)
  // function(id, options)
  admin_seasonal_banner: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"seasonal_banners",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_seasonal_banners => /admin/seasonal_banners(.:format)
  // function(options)
  admin_seasonal_banners: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"seasonal_banners",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_section => /admin/sections/:id(.:format)
  // function(id, options)
  admin_section: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_section_plan => /admin/section_plans/:id(.:format)
  // function(id, options)
  admin_section_plan: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"section_plans",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_section_plans => /admin/section_plans(.:format)
  // function(options)
  admin_section_plans: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"section_plans",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_sections => /admin/sections(.:format)
  // function(options)
  admin_sections: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_setting => /admin/settings/:id(.:format)
  // function(id, options)
  admin_setting: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_settings => /admin/settings(.:format)
  // function(options)
  admin_settings: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"settings",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_tag => /admin/tags/:id(.:format)
  // function(id, options)
  admin_tag: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"tags",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_teachable_migration => /admin/teachable_migrations/:id(.:format)
  // function(id, options)
  admin_teachable_migration: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"teachable_migrations",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_teachable_migrations => /admin/teachable_migrations(.:format)
  // function(options)
  admin_teachable_migrations: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"teachable_migrations",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_teachable_stage2_invites => /admin/teachable_stage2_invites(.:format)
  // function(options)
  admin_teachable_stage2_invites: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"teachable_stage2_invites",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_teachable_stage3_invites => /admin/teachable_stage3_invites(.:format)
  // function(options)
  admin_teachable_stage3_invites: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"teachable_stage3_invites",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_teacher => /admin/teachers/:id(.:format)
  // function(id, options)
  admin_teacher: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"teachers",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_teachers => /admin/teachers(.:format)
  // function(options)
  admin_teachers: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"teachers",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_theme => /admin/themes/:id(.:format)
  // function(id, options)
  admin_theme: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"themes",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_themes => /admin/themes(.:format)
  // function(options)
  admin_themes: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"themes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_user => /admin/users/:id(.:format)
  // function(id, options)
  admin_user: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_user_session => /admin/sign_in(.:format)
  // function(options)
  admin_user_session: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_users => /admin/users(.:format)
  // function(options)
  admin_users: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_video => /admin/videos/:id(.:format)
  // function(id, options)
  admin_video: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"videos",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_video_import_log => /admin/video_import_logs/:id(.:format)
  // function(id, options)
  admin_video_import_log: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"video_import_logs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// admin_video_import_logs => /admin/video_import_logs(.:format)
  // function(options)
  admin_video_import_logs: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"video_import_logs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// admin_videos => /admin/videos(.:format)
  // function(options)
  admin_videos: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"videos",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// allocate_admin_membership_selection => /admin/membership_selections/:id/allocate(.:format)
  // function(id, options)
  allocate_admin_membership_selection: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"membership_selections",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"allocate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_courses => /api/courses/:id/related-courses(.:format)
  // function(id, options)
  api_courses: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"related-courses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// api_courses_continue_watching => /api/courses/continue-watching(.:format)
  // function(options)
  api_courses_continue_watching: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[6,"continue-watching",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_courses_course_slider => /api/courses/course-slider(.:format)
  // function(options)
  api_courses_course_slider: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[6,"course-slider",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_courses_featured_collections => /api/courses/featured-collections(.:format)
  // function(options)
  api_courses_featured_collections: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[6,"featured-collections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// api_courses_upcoming => /api/courses/upcoming(.:format)
  // function(options)
  api_courses_upcoming: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"api",false],[2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[6,"upcoming",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// avatar_admin_teacher => /admin/teachers/:id/avatar(.:format)
  // function(id, options)
  avatar_admin_teacher: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"teachers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"avatar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// avatar_admin_user => /admin/users/:id/avatar(.:format)
  // function(id, options)
  avatar_admin_user: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"avatar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// avatar_users => /avatar(.:format)
  // function(options)
  avatar_users: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"avatar",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// banner_image_admin_gift_section => /admin/gift_sections/:id/banner_image(.:format)
  // function(id, options)
  banner_image_admin_gift_section: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"gift_sections",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"banner_image",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// blog => /blog(.:format)
  // function(options)
  blog: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"blog",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// bunny_williams_courses => /bunny-williams-courses(.:format)
  // function(options)
  bunny_williams_courses: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"bunny-williams-courses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// campaign_orders => /campaigns/:campaign_slug/orders(.:format)
  // function(campaign_slug, options)
  campaign_orders: Utils.route([["campaign_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"campaign_slug",false],[2,[7,"/",false],[2,[6,"orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cancel_admin_membership => /admin/memberships/:id/cancel(.:format)
  // function(id, options)
  cancel_admin_membership: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"memberships",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// cart => /basket(.:format)
  // function(options)
  cart: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"basket",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// cart_cart_item => /basket/basket_items/:id(.:format)
  // function(id, options)
  cart_cart_item: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"basket",false],[2,[7,"/",false],[2,[6,"basket_items",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// cart_cart_items => /basket/basket_items(.:format)
  // function(options)
  cart_cart_items: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"basket",false],[2,[7,"/",false],[2,[6,"basket_items",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// cart_order_index => /basket/orders/new(.:format)
  // function(options)
  cart_order_index: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"basket",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// category => /:slug(.:format)
  // function(slug, options)
  category: Utils.route([["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// christmas => /christmas(.:format)
  // function(options)
  christmas: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"christmas",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// confirmation_campaign_orders => /campaigns/:campaign_slug/orders/confirmation(.:format)
  // function(campaign_slug, options)
  confirmation_campaign_orders: Utils.route([["campaign_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"campaign_slug",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// confirmation_cart_order_index => /basket/orders/confirmation(.:format)
  // function(options)
  confirmation_cart_order_index: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"basket",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// confirmation_course_orders => /courses/:course_slug/orders/confirmation(.:format)
  // function(course_slug, options)
  confirmation_course_orders: Utils.route([["course_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"course_slug",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// confirmation_user_migration => /setup-account-confirmation(.:format)
  // function(options)
  confirmation_user_migration: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup-account-confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// contact_us => /contact-us(.:format)
  // function(options)
  contact_us: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"contact-us",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// course => /courses/:slug(.:format)
  // function(slug, options)
  course: Utils.route([["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// course_lesson => /courses/:course_slug/lessons/:slug(.:format)
  // function(course_slug, slug, options)
  course_lesson: Utils.route([["course_slug",true],["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"course_slug",false],[2,[7,"/",false],[2,[6,"lessons",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// course_lessons => /courses/:course_slug/lessons(.:format)
  // function(course_slug, options)
  course_lessons: Utils.route([["course_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"course_slug",false],[2,[7,"/",false],[2,[6,"lessons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// course_orders => /courses/:course_slug/orders(.:format)
  // function(course_slug, options)
  course_orders: Utils.route([["course_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"course_slug",false],[2,[7,"/",false],[2,[6,"orders",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// courses => /courses(.:format)
  // function(options)
  courses: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"courses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// customise_gifts => /gifts/customise(.:format)
  // function(options)
  customise_gifts: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"gifts",false],[2,[7,"/",false],[2,[6,"customise",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// delete_user_users => /delete_user(.:format)
  // function(options)
  delete_user_users: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"delete_user",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// destroy_admin_user_session => /admin/sign_out(.:format)
  // function(options)
  destroy_admin_user_session: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// destroy_lesson_by_id_user_collections => /saved_lessons/destroy_lesson_by_id(.:format)
  // function(options)
  destroy_lesson_by_id_user_collections: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"saved_lessons",false],[2,[7,"/",false],[2,[6,"destroy_lesson_by_id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// destroy_lesson_collection_user_collections => /saved_lessons/destroy_lesson_collection(.:format)
  // function(options)
  destroy_lesson_collection_user_collections: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"saved_lessons",false],[2,[7,"/",false],[2,[6,"destroy_lesson_collection",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// destroy_user_session => /sign_out(.:format)
  // function(options)
  destroy_user_session: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sign_out",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// disclaimer => /disclaimer(.:format)
  // function(options)
  disclaimer: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"disclaimer",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// documents_admin_lesson => /admin/lessons/:id/documents(.:format)
  // function(id, options)
  documents_admin_lesson: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"lessons",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"documents",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_about_section => /admin/about_sections/:id/edit(.:format)
  // function(id, options)
  edit_admin_about_section: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"about_sections",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_admin_user => /admin/admin_users/:id/edit(.:format)
  // function(id, options)
  edit_admin_admin_user: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"admin_users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_bundle => /admin/bundles/:id/edit(.:format)
  // function(id, options)
  edit_admin_bundle: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bundles",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_campaign => /admin/campaigns/:id/edit(.:format)
  // function(id, options)
  edit_admin_campaign: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_cart_drawer => /admin/cart_drawers/:id/edit(.:format)
  // function(id, options)
  edit_admin_cart_drawer: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"cart_drawers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_category => /admin/categories/:id/edit(.:format)
  // function(id, options)
  edit_admin_category: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_collection => /admin/collections/:id/edit(.:format)
  // function(id, options)
  edit_admin_collection: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_collection_course => /admin/collection_courses/:id/edit(.:format)
  // function(id, options)
  edit_admin_collection_course: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"collection_courses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_comment => /admin/comments/:id/edit(.:format)
  // function(id, options)
  edit_admin_comment: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_course => /admin/courses/:id/edit(.:format)
  // function(id, options)
  edit_admin_course: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_course_enrollment => /admin/course_enrollments/:id/edit(.:format)
  // function(id, options)
  edit_admin_course_enrollment: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"course_enrollments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_course_objective => /admin/course_objectives/:id/edit(.:format)
  // function(id, options)
  edit_admin_course_objective: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"course_objectives",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_enquiry => /admin/enquiries/:id/edit(.:format)
  // function(id, options)
  edit_admin_enquiry: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"enquiries",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_featured_section => /admin/featured_sections/:id/edit(.:format)
  // function(id, options)
  edit_admin_featured_section: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"featured_sections",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_general_announcement => /admin/general_announcements/:id/edit(.:format)
  // function(id, options)
  edit_admin_general_announcement: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"general_announcements",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_gift => /admin/gifts/:id/edit(.:format)
  // function(id, options)
  edit_admin_gift: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"gifts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_gift_section => /admin/gift_sections/:id/edit(.:format)
  // function(id, options)
  edit_admin_gift_section: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"gift_sections",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_lesson => /admin/lessons/:id/edit(.:format)
  // function(id, options)
  edit_admin_lesson: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"lessons",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_lesson_enrollment => /admin/lesson_enrollments/:id/edit(.:format)
  // function(id, options)
  edit_admin_lesson_enrollment: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"lesson_enrollments",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_lesson_plan => /admin/lesson_plans/:id/edit(.:format)
  // function(id, options)
  edit_admin_lesson_plan: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_membership => /admin/memberships/:id/edit(.:format)
  // function(id, options)
  edit_admin_membership: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"memberships",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_membership_plan => /admin/membership_plans/:id/edit(.:format)
  // function(id, options)
  edit_admin_membership_plan: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"membership_plans",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_membership_selection => /admin/membership_selections/:id/edit(.:format)
  // function(id, options)
  edit_admin_membership_selection: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"membership_selections",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_post => /admin/posts/:id/edit(.:format)
  // function(id, options)
  edit_admin_post: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"posts",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_post_category => /admin/post_categories/:id/edit(.:format)
  // function(id, options)
  edit_admin_post_category: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"post_categories",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_press_reference => /admin/press_references/:id/edit(.:format)
  // function(id, options)
  edit_admin_press_reference: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"press_references",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_sale => /admin/sales/:id/edit(.:format)
  // function(id, options)
  edit_admin_sale: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sales",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_sale_tag => /admin/sale_tags/:id/edit(.:format)
  // function(id, options)
  edit_admin_sale_tag: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sale_tags",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_seasonal_banner => /admin/seasonal_banners/:id/edit(.:format)
  // function(id, options)
  edit_admin_seasonal_banner: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"seasonal_banners",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_section => /admin/sections/:id/edit(.:format)
  // function(id, options)
  edit_admin_section: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_section_plan => /admin/section_plans/:id/edit(.:format)
  // function(id, options)
  edit_admin_section_plan: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"section_plans",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_setting => /admin/settings/:id/edit(.:format)
  // function(id, options)
  edit_admin_setting: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_teacher => /admin/teachers/:id/edit(.:format)
  // function(id, options)
  edit_admin_teacher: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"teachers",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_theme => /admin/themes/:id/edit(.:format)
  // function(id, options)
  edit_admin_theme: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"themes",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_user => /admin/users/:id/edit(.:format)
  // function(id, options)
  edit_admin_user: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_video => /admin/videos/:id/edit(.:format)
  // function(id, options)
  edit_admin_video: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"videos",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_admin_video_import_log => /admin/video_import_logs/:id/edit(.:format)
  // function(id, options)
  edit_admin_video_import_log: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"video_import_logs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"edit",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// edit_user_password => /set_password(.:format)
  // function(options)
  edit_user_password: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"set_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// emulate_admin_user => /admin/users/:id/emulate(.:format)
  // function(id, options)
  emulate_admin_user: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"emulate",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// export_admin_orders => /admin/orders/export(.:format)
  // function(options)
  export_admin_orders: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// export_admin_users => /admin/users/export(.:format)
  // function(options)
  export_admin_users: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"export",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// field_test_engine => /field_test
  // function(options)
  field_test_engine: Utils.route([], {}, [2,[7,"/",false],[6,"field_test",false]]),
// field_test_engine.experiment => /field_test/experiments/:id(.:format)
  // function(id, options)
  field_test_engine_experiment: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"field_test",false]],[7,"/",false]],[2,[6,"experiments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// field_test_engine.membership => /field_test/memberships/:id(.:format)
  // function(id, options)
  field_test_engine_membership: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"field_test",false]],[7,"/",false]],[2,[6,"memberships",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// field_test_engine.legacy_participant => /field_test/participants/:id(.:format)
  // function(id, options)
  field_test_engine_legacy_participant: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"field_test",false]],[7,"/",false]],[2,[6,"participants",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// field_test_engine.participant => /field_test/participants(.:format)
  // function(options)
  field_test_engine_participant: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"field_test",false]],[7,"/",false]],[2,[6,"participants",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// field_test_engine.root => /field_test/
  // function(options)
  field_test_engine_root: Utils.route([], {}, [2,[2,[7,"/",false],[6,"field_test",false]],[7,"/",false]]),
// forgot_password => /forgot_password(.:format)
  // function(options)
  forgot_password: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"forgot_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// free_campaign_orders => /campaigns/:campaign_slug/orders/free(.:format)
  // function(campaign_slug, options)
  free_campaign_orders: Utils.route([["campaign_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"campaign_slug",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"free",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// free_cart_order_index => /basket/orders/free(.:format)
  // function(options)
  free_cart_order_index: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"basket",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"free",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// free_course_orders => /courses/:course_slug/orders/free(.:format)
  // function(course_slug, options)
  free_course_orders: Utils.route([["course_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"course_slug",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"free",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// free_lessons => /free-lessons(.:format)
  // function(options)
  free_lessons: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"free-lessons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// general_subscription_users => /general_subscription(.:format)
  // function(options)
  general_subscription_users: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"general_subscription",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// getlesson_user_collections_user_collections => /saved_lessons/getlesson_user_collections(.:format)
  // function(options)
  getlesson_user_collections_user_collections: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"saved_lessons",false],[2,[7,"/",false],[2,[6,"getlesson_user_collections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// getuser_collections_user_collections => /saved_lessons/getuser_collections(.:format)
  // function(options)
  getuser_collections_user_collections: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"saved_lessons",false],[2,[7,"/",false],[2,[6,"getuser_collections",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// gift => /gifts/:code(.:format)
  // function(code, options)
  gift: Utils.route([["code",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"gifts",false],[2,[7,"/",false],[2,[3,"code",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// gifts => /gifts(.:format)
  // function(options)
  gifts: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"gifts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// good_job => /good_job
  // function(options)
  good_job: Utils.route([], {}, [2,[7,"/",false],[6,"good_job",false]]),
// good_job.root => /good_job/
  // function(options)
  good_job_root: Utils.route([], {}, [2,[2,[7,"/",false],[6,"good_job",false]],[7,"/",false]]),
// good_job.mass_update_jobs => /good_job/jobs/mass_update(.:format)
  // function(options)
  good_job_mass_update_jobs: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"good_job",false]],[7,"/",false]],[2,[6,"jobs",false],[2,[7,"/",false],[2,[6,"mass_update",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// good_job.discard_job => /good_job/jobs/:id/discard(.:format)
  // function(id, options)
  good_job_discard_job: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"good_job",false]],[7,"/",false]],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"discard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// good_job.force_discard_job => /good_job/jobs/:id/force_discard(.:format)
  // function(id, options)
  good_job_force_discard_job: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"good_job",false]],[7,"/",false]],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"force_discard",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// good_job.reschedule_job => /good_job/jobs/:id/reschedule(.:format)
  // function(id, options)
  good_job_reschedule_job: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"good_job",false]],[7,"/",false]],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"reschedule",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// good_job.retry_job => /good_job/jobs/:id/retry(.:format)
  // function(id, options)
  good_job_retry_job: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"good_job",false]],[7,"/",false]],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"retry",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// good_job.jobs => /good_job/jobs(.:format)
  // function(options)
  good_job_jobs: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"good_job",false]],[7,"/",false]],[2,[6,"jobs",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// good_job.job => /good_job/jobs/:id(.:format)
  // function(id, options)
  good_job_job: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"good_job",false]],[7,"/",false]],[2,[6,"jobs",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// good_job.metrics_primary_nav => /good_job/jobs/metrics/primary_nav(.:format)
  // function(options)
  good_job_metrics_primary_nav: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"good_job",false]],[7,"/",false]],[2,[6,"jobs",false],[2,[7,"/",false],[2,[6,"metrics",false],[2,[7,"/",false],[2,[6,"primary_nav",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// good_job.metrics_job_status => /good_job/jobs/metrics/job_status(.:format)
  // function(options)
  good_job_metrics_job_status: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"good_job",false]],[7,"/",false]],[2,[6,"jobs",false],[2,[7,"/",false],[2,[6,"metrics",false],[2,[7,"/",false],[2,[6,"job_status",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// good_job.batches => /good_job/batches(.:format)
  // function(options)
  good_job_batches: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"good_job",false]],[7,"/",false]],[2,[6,"batches",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// good_job.batch => /good_job/batches/:id(.:format)
  // function(id, options)
  good_job_batch: Utils.route([["id",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"good_job",false]],[7,"/",false]],[2,[6,"batches",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// good_job.enqueue_cron_entry => /good_job/cron_entries/:cron_key/enqueue(.:format)
  // function(cron_key, options)
  good_job_enqueue_cron_entry: Utils.route([["cron_key",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"good_job",false]],[7,"/",false]],[2,[6,"cron_entries",false],[2,[7,"/",false],[2,[3,"cron_key",false],[2,[7,"/",false],[2,[6,"enqueue",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// good_job.enable_cron_entry => /good_job/cron_entries/:cron_key/enable(.:format)
  // function(cron_key, options)
  good_job_enable_cron_entry: Utils.route([["cron_key",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"good_job",false]],[7,"/",false]],[2,[6,"cron_entries",false],[2,[7,"/",false],[2,[3,"cron_key",false],[2,[7,"/",false],[2,[6,"enable",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// good_job.disable_cron_entry => /good_job/cron_entries/:cron_key/disable(.:format)
  // function(cron_key, options)
  good_job_disable_cron_entry: Utils.route([["cron_key",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"good_job",false]],[7,"/",false]],[2,[6,"cron_entries",false],[2,[7,"/",false],[2,[3,"cron_key",false],[2,[7,"/",false],[2,[6,"disable",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// good_job.cron_entries => /good_job/cron_entries(.:format)
  // function(options)
  good_job_cron_entries: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"good_job",false]],[7,"/",false]],[2,[6,"cron_entries",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// good_job.cron_entry => /good_job/cron_entries/:cron_key(.:format)
  // function(cron_key, options)
  good_job_cron_entry: Utils.route([["cron_key",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"good_job",false]],[7,"/",false]],[2,[6,"cron_entries",false],[2,[7,"/",false],[2,[3,"cron_key",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// good_job.processes => /good_job/processes(.:format)
  // function(options)
  good_job_processes: Utils.route([["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"good_job",false]],[7,"/",false]],[2,[6,"processes",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// good_job.frontend_module => /good_job/frontend/modules/:name(.:format)
  // function(name, options)
  good_job_frontend_module: Utils.route([["name",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"good_job",false]],[7,"/",false]],[2,[6,"frontend",false],[2,[7,"/",false],[2,[6,"modules",false],[2,[7,"/",false],[2,[3,"name",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// good_job.frontend_static => /good_job/frontend/static/:name(.:format)
  // function(name, options)
  good_job_frontend_static: Utils.route([["name",true],["format",false]], {}, [2,[2,[2,[7,"/",false],[6,"good_job",false]],[7,"/",false]],[2,[6,"frontend",false],[2,[7,"/",false],[2,[6,"static",false],[2,[7,"/",false],[2,[3,"name",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// healthcheck => /healthcheck(.:format)
  // function(options)
  healthcheck: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"healthcheck",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// image_desktop_admin_seasonal_banner => /admin/seasonal_banners/:id/image_desktop(.:format)
  // function(id, options)
  image_desktop_admin_seasonal_banner: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"seasonal_banners",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"image_desktop",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// image_mobile_admin_seasonal_banner => /admin/seasonal_banners/:id/image_mobile(.:format)
  // function(id, options)
  image_mobile_admin_seasonal_banner: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"seasonal_banners",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"image_mobile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// image_tablet_admin_seasonal_banner => /admin/seasonal_banners/:id/image_tablet(.:format)
  // function(id, options)
  image_tablet_admin_seasonal_banner: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"seasonal_banners",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"image_tablet",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// instructor => /instructors/:slug(.:format)
  // function(slug, options)
  instructor: Utils.route([["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"instructors",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// instructors => /instructors(.:format)
  // function(options)
  instructors: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"instructors",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// invite_admin_teachable_stage2_invites => /admin/teachable_stage2_invites/invite(.:format)
  // function(options)
  invite_admin_teachable_stage2_invites: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"teachable_stage2_invites",false],[2,[7,"/",false],[2,[6,"invite",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// invite_admin_teachable_stage3_invites => /admin/teachable_stage3_invites/invite(.:format)
  // function(options)
  invite_admin_teachable_stage3_invites: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"teachable_stage3_invites",false],[2,[7,"/",false],[2,[6,"invite",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// launch_day_admin_teachable_stage3_invites => /admin/teachable_stage3_invites/launch_day(.:format)
  // function(options)
  launch_day_admin_teachable_stage3_invites: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"teachable_stage3_invites",false],[2,[7,"/",false],[2,[6,"launch_day",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// lesson_enrollment => /lesson_enrollments/:id(.:format)
  // function(id, options)
  lesson_enrollment: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_enrollments",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// lesson_is_bookmarked => /lesson_is_bookmarked(.:format)
  // function(options)
  lesson_is_bookmarked: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"lesson_is_bookmarked",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// membership_cancel => /membership/cancel(.:format)
  // function(options)
  membership_cancel: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"membership",false],[2,[7,"/",false],[2,[6,"cancel",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// membership_check_gift => /membership/check_gift(.:format)
  // function(options)
  membership_check_gift: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"membership",false],[2,[7,"/",false],[2,[6,"check_gift",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// membership_checkout => /membership/checkout(.:format)
  // function(options)
  membership_checkout: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"membership",false],[2,[7,"/",false],[2,[6,"checkout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// membership_checkout_stripe => /membership/checkout(.:format)
  // function(options)
  membership_checkout_stripe: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"membership",false],[2,[7,"/",false],[2,[6,"checkout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// membership_confirmation => /membership-confirmation(.:format)
  // function(options)
  membership_confirmation: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"membership-confirmation",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// membership_destroy => /membership/:id(.:format)
  // function(id, options)
  membership_destroy: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"membership",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// membership_landing => /membership(.:format)
  // function(options)
  membership_landing: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"membership",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// membership_plan_renewal_redeem_voucher => /membership/renewal_redeem_voucher(.:format)
  // function(options)
  membership_plan_renewal_redeem_voucher: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"membership",false],[2,[7,"/",false],[2,[6,"renewal_redeem_voucher",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// membership_plan_validate_voucher => /membership/:plan_id/validate_voucher(.:format)
  // function(plan_id, options)
  membership_plan_validate_voucher: Utils.route([["plan_id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"membership",false],[2,[7,"/",false],[2,[3,"plan_id",false],[2,[7,"/",false],[2,[6,"validate_voucher",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// membership_plans => /membership/plans(.:format)
  // function(options)
  membership_plans: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"membership",false],[2,[7,"/",false],[2,[6,"plans",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// membership_redeem_gift => /membership/redeem_gift(.:format)
  // function(options)
  membership_redeem_gift: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"membership",false],[2,[7,"/",false],[2,[6,"redeem_gift",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// membership_update_payment_details => /membership/update_payment_details(.:format)
  // function(options)
  membership_update_payment_details: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"membership",false],[2,[7,"/",false],[2,[6,"update_payment_details",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// membership_welcome => /membership/welcome(.:format)
  // function(options)
  membership_welcome: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"membership",false],[2,[7,"/",false],[2,[6,"welcome",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_admin_about_section => /admin/about_sections/new(.:format)
  // function(options)
  new_admin_about_section: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"about_sections",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_admin_user => /admin/admin_users/new(.:format)
  // function(options)
  new_admin_admin_user: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"admin_users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_banner_strip => /admin/banner_strips/new(.:format)
  // function(options)
  new_admin_banner_strip: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"banner_strips",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_bundle => /admin/bundles/new(.:format)
  // function(options)
  new_admin_bundle: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"bundles",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_cart_drawer => /admin/cart_drawers/new(.:format)
  // function(options)
  new_admin_cart_drawer: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"cart_drawers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_category => /admin/categories/new(.:format)
  // function(options)
  new_admin_category: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_collection => /admin/collections/new(.:format)
  // function(options)
  new_admin_collection: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"collections",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_collection_course => /admin/collection_courses/new(.:format)
  // function(options)
  new_admin_collection_course: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"collection_courses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_comment => /admin/comments/new(.:format)
  // function(options)
  new_admin_comment: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"comments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_course => /admin/courses/new(.:format)
  // function(options)
  new_admin_course: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_course_enrollment => /admin/course_enrollments/new(.:format)
  // function(options)
  new_admin_course_enrollment: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"course_enrollments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_course_objective => /admin/course_objectives/new(.:format)
  // function(options)
  new_admin_course_objective: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"course_objectives",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_enquiry => /admin/enquiries/new(.:format)
  // function(options)
  new_admin_enquiry: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"enquiries",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_featured_section => /admin/featured_sections/new(.:format)
  // function(options)
  new_admin_featured_section: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"featured_sections",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_general_announcement => /admin/general_announcements/new(.:format)
  // function(options)
  new_admin_general_announcement: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"general_announcements",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_gift => /admin/gifts/new(.:format)
  // function(options)
  new_admin_gift: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"gifts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_gift_section => /admin/gift_sections/new(.:format)
  // function(options)
  new_admin_gift_section: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"gift_sections",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_lesson => /admin/lessons/new(.:format)
  // function(options)
  new_admin_lesson: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"lessons",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_lesson_enrollment => /admin/lesson_enrollments/new(.:format)
  // function(options)
  new_admin_lesson_enrollment: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"lesson_enrollments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_lesson_plan => /admin/lesson_plans/new(.:format)
  // function(options)
  new_admin_lesson_plan: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"lesson_plans",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_membership => /admin/memberships/new(.:format)
  // function(options)
  new_admin_membership: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"memberships",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_membership_coupon => /admin/membership_coupons/new(.:format)
  // function(options)
  new_admin_membership_coupon: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"membership_coupons",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_membership_payment => /admin/membership_payments/new(.:format)
  // function(options)
  new_admin_membership_payment: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"membership_payments",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_membership_plan => /admin/membership_plans/new(.:format)
  // function(options)
  new_admin_membership_plan: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"membership_plans",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_membership_selection => /admin/membership_selections/new(.:format)
  // function(options)
  new_admin_membership_selection: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"membership_selections",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_post => /admin/posts/new(.:format)
  // function(options)
  new_admin_post: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"posts",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_post_category => /admin/post_categories/new(.:format)
  // function(options)
  new_admin_post_category: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"post_categories",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_press_reference => /admin/press_references/new(.:format)
  // function(options)
  new_admin_press_reference: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"press_references",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_sale => /admin/sales/new(.:format)
  // function(options)
  new_admin_sale: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sales",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_sale_tag => /admin/sale_tags/new(.:format)
  // function(options)
  new_admin_sale_tag: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sale_tags",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_seasonal_banner => /admin/seasonal_banners/new(.:format)
  // function(options)
  new_admin_seasonal_banner: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"seasonal_banners",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_section => /admin/sections/new(.:format)
  // function(options)
  new_admin_section: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sections",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_section_plan => /admin/section_plans/new(.:format)
  // function(options)
  new_admin_section_plan: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"section_plans",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_setting => /admin/settings/new(.:format)
  // function(options)
  new_admin_setting: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"settings",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_teacher => /admin/teachers/new(.:format)
  // function(options)
  new_admin_teacher: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"teachers",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_theme => /admin/themes/new(.:format)
  // function(options)
  new_admin_theme: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"themes",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_user => /admin/users/new(.:format)
  // function(options)
  new_admin_user: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_user_session => /admin/sign_in(.:format)
  // function(options)
  new_admin_user_session: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// new_admin_video => /admin/videos/new(.:format)
  // function(options)
  new_admin_video: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"videos",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_admin_video_import_log => /admin/video_import_logs/new(.:format)
  // function(options)
  new_admin_video_import_log: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"video_import_logs",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_campaign_order => /campaigns/:campaign_slug/orders/new(.:format)
  // function(campaign_slug, options)
  new_campaign_order: Utils.route([["campaign_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"campaign_slug",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_cart_order => /basket/orders/new(.:format)
  // function(options)
  new_cart_order: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"basket",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_course_order => /courses/:course_slug/orders/new(.:format)
  // function(course_slug, options)
  new_course_order: Utils.route([["course_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"course_slug",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"new",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// new_subscription_webhooks_zapier => /webhooks/zapier/new_subscription(.:format)
  // function(options)
  new_subscription_webhooks_zapier: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"zapier",false],[2,[7,"/",false],[2,[6,"new_subscription",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// new_user_migration => /setup-account(.:format)
  // function(options)
  new_user_migration: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup-account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// new_user_password => /forgot_password(.:format)
  // function(options)
  new_user_password: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"forgot_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// new_user_registration => /sign_up(.:format)
  // function(options)
  new_user_registration: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// new_user_session => /sign_in(.:format)
  // function(options)
  new_user_session: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// notifications_users => /notifications(.:format)
  // function(options)
  notifications_users: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"notifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// onboarding => /get-started(.:format)
  // function(options)
  onboarding: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"get-started",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// onboarding_checkout => /get-started/checkout/:token(.:format)
  // function(token, options)
  onboarding_checkout: Utils.route([["token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"get-started",false],[2,[7,"/",false],[2,[6,"checkout",false],[2,[7,"/",false],[2,[3,"token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// onboarding_checkout_redirect => /get-started/checkout(.:format)
  // function(options)
  onboarding_checkout_redirect: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"get-started",false],[2,[7,"/",false],[2,[6,"checkout",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// onboarding_courses => /get-started/courses(.:format)
  // function(options)
  onboarding_courses: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"get-started",false],[2,[7,"/",false],[2,[6,"courses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// onboarding_create_membership => /get-started/create_membership/:token(.:format)
  // function(token, options)
  onboarding_create_membership: Utils.route([["token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"get-started",false],[2,[7,"/",false],[2,[6,"create_membership",false],[2,[7,"/",false],[2,[3,"token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// onboarding_sign_in => /get-started/sign-in/:token(.:format)
  // function(token, options)
  onboarding_sign_in: Utils.route([["token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"get-started",false],[2,[7,"/",false],[2,[6,"sign-in",false],[2,[7,"/",false],[2,[3,"token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// onboarding_sign_up => /get-started/sign-up/:token(.:format)
  // function(token, options)
  onboarding_sign_up: Utils.route([["token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"get-started",false],[2,[7,"/",false],[2,[6,"sign-up",false],[2,[7,"/",false],[2,[3,"token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// onboarding_submit_email => /get-started/submit_email(.:format)
  // function(options)
  onboarding_submit_email: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"get-started",false],[2,[7,"/",false],[2,[6,"submit_email",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// onboarding_success => /get-started/success/:token(.:format)
  // function(token, options)
  onboarding_success: Utils.route([["token",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"get-started",false],[2,[7,"/",false],[2,[6,"success",false],[2,[7,"/",false],[2,[3,"token",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// onboarding_welcome => /get-started/welcome(.:format)
  // function(options)
  onboarding_welcome: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"get-started",false],[2,[7,"/",false],[2,[6,"welcome",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// one_week_notice_admin_teachable_stage2_invites => /admin/teachable_stage2_invites/one_week_notice(.:format)
  // function(options)
  one_week_notice_admin_teachable_stage2_invites: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"teachable_stage2_invites",false],[2,[7,"/",false],[2,[6,"one_week_notice",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// order => /purchases/:id(.:format)
  // function(id, options)
  order: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"purchases",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// orders => /purchases(.:format)
  // function(options)
  orders: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"purchases",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// our_new_platform => /our-new-platform(.:format)
  // function(options)
  our_new_platform: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"our-new-platform",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// password_users => /password(.:format)
  // function(options)
  password_users: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// post => /blog/:slug(.:format)
  // function(slug, options)
  post: Utils.route([["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"blog",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// post_category => /blog/categories/:slug(.:format)
  // function(slug, options)
  post_category: Utils.route([["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"blog",false],[2,[7,"/",false],[2,[6,"categories",false],[2,[7,"/",false],[2,[3,"slug",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// privacy_policy => /privacy-policy(.:format)
  // function(options)
  privacy_policy: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"privacy-policy",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// profile_users => /profile(.:format)
  // function(options)
  profile_users: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"profile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// promo_may24 => /promo-may24(.:format)
  // function(options)
  promo_may24: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"promo-may24",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// promo_uk => /promo-uk(.:format)
  // function(options)
  promo_uk: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"promo-uk",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// promo_us => /promo-us(.:format)
  // function(options)
  promo_us: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"promo-us",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// reset_campaign_orders => /campaigns/:campaign_slug/orders/reset(.:format)
  // function(campaign_slug, options)
  reset_campaign_orders: Utils.route([["campaign_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"campaign_slug",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"reset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// reset_course_orders => /courses/:course_slug/orders/reset(.:format)
  // function(course_slug, options)
  reset_course_orders: Utils.route([["course_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"course_slug",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"reset",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// rita_konig_courses => /rita-konig-courses(.:format)
  // function(options)
  rita_konig_courses: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"rita-konig-courses",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// root => /
  // function(options)
  root: Utils.route([], {}, [7,"/",false]),
// sanitize_non_purchased_course_admin_course_enrollments => /admin/course_enrollments/sanitize_non_purchased_course(.:format)
  // function(options)
  sanitize_non_purchased_course_admin_course_enrollments: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"course_enrollments",false],[2,[7,"/",false],[2,[6,"sanitize_non_purchased_course",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// search => /search(.:format)
  // function(options)
  search: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"search",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// search_with_tab => /search/:tab(.:format)
  // function(tab, options)
  search_with_tab: Utils.route([["tab",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"search",false],[2,[7,"/",false],[2,[3,"tab",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// send_emails_admin_buy_one_gift_one_emails => /admin/buy_one_gift_one_emails/send_emails(.:format)
  // function(options)
  send_emails_admin_buy_one_gift_one_emails: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"buy_one_gift_one_emails",false],[2,[7,"/",false],[2,[6,"send_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// send_live_presale_course_emails_admin_course => /admin/courses/:id/send_live_presale_course_emails(.:format)
  // function(id, options)
  send_live_presale_course_emails_admin_course: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"send_live_presale_course_emails",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// send_reset_password_instructions_admin_user => /admin/users/:id/send_reset_password_instructions(.:format)
  // function(id, options)
  send_reset_password_instructions_admin_user: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"send_reset_password_instructions",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// show_lessons_for_collection_user_collections => /saved_lessons/show_lessons_for_collection(.:format)
  // function(options)
  show_lessons_for_collection_user_collections: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"saved_lessons",false],[2,[7,"/",false],[2,[6,"show_lessons_for_collection",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// sign_up => /sign_up(.:format)
  // function(options)
  sign_up: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// sitemap => /sitemap(.:format)
  // function(options)
  sitemap: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sitemap",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// subscribe_course => /courses/:slug/subscribe(.:format)
  // function(slug, options)
  subscribe_course: Utils.route([["slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"slug",false],[2,[7,"/",false],[2,[6,"subscribe",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// support => /support(.:format)
  // function(options)
  support: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"support",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// terms_of_use => /terms-of-use(.:format)
  // function(options)
  terms_of_use: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"terms-of-use",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// test_meta_event => /test_meta_event(.:format)
  // function(options)
  test_meta_event: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"test_meta_event",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// unlock_admin_user => /admin/users/:id/unlock(.:format)
  // function(id, options)
  unlock_admin_user: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[3,"id",false],[2,[7,"/",false],[2,[6,"unlock",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// update_notifications_for_membership_admin_scripts => /admin/scripts/update_notifications_for_membership(.:format)
  // function(options)
  update_notifications_for_membership_admin_scripts: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"admin",false],[2,[7,"/",false],[2,[6,"scripts",false],[2,[7,"/",false],[2,[6,"update_notifications_for_membership",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// update_notifications_users => /update_notifications(.:format)
  // function(options)
  update_notifications_users: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"update_notifications",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// update_password_users => /update_password(.:format)
  // function(options)
  update_password_users: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"update_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// update_profile_users => /update_profile(.:format)
  // function(options)
  update_profile_users: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"update_profile",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_collection => /saved_lessons/:id(.:format)
  // function(id, options)
  user_collection: Utils.route([["id",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"saved_lessons",false],[2,[7,"/",false],[2,[3,"id",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]),
// user_collections => /saved_lessons(.:format)
  // function(options)
  user_collections: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"saved_lessons",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_facebook_omniauth_authorize => /users/auth/facebook(.:format)
  // function(options)
  user_facebook_omniauth_authorize: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"facebook",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_facebook_omniauth_callback => /users/auth/facebook/callback(.:format)
  // function(options)
  user_facebook_omniauth_callback: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"facebook",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_google_oauth2_omniauth_authorize => /users/auth/google_oauth2(.:format)
  // function(options)
  user_google_oauth2_omniauth_authorize: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"google_oauth2",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// user_google_oauth2_omniauth_callback => /users/auth/google_oauth2/callback(.:format)
  // function(options)
  user_google_oauth2_omniauth_callback: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"users",false],[2,[7,"/",false],[2,[6,"auth",false],[2,[7,"/",false],[2,[6,"google_oauth2",false],[2,[7,"/",false],[2,[6,"callback",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// user_migration => /setup-account(.:format)
  // function(options)
  user_migration: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"setup-account",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_password => /forgot_password(.:format)
  // function(options)
  user_password: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"forgot_password",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_registration => /sign_up(.:format)
  // function(options)
  user_registration: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sign_up",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// user_session => /sign_in(.:format)
  // function(options)
  user_session: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"sign_in",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// validate_discounts_cart_order_index => /basket/orders/validate_discounts(.:format)
  // function(options)
  validate_discounts_cart_order_index: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"basket",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"validate_discounts",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// validate_voucher_campaign_orders => /campaigns/:campaign_slug/orders/validate_voucher(.:format)
  // function(campaign_slug, options)
  validate_voucher_campaign_orders: Utils.route([["campaign_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"campaigns",false],[2,[7,"/",false],[2,[3,"campaign_slug",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"validate_voucher",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// validate_voucher_course_orders => /courses/:course_slug/orders/validate_voucher(.:format)
  // function(course_slug, options)
  validate_voucher_course_orders: Utils.route([["course_slug",true],["format",false]], {}, [2,[7,"/",false],[2,[6,"courses",false],[2,[7,"/",false],[2,[3,"course_slug",false],[2,[7,"/",false],[2,[6,"orders",false],[2,[7,"/",false],[2,[6,"validate_voucher",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]]]),
// vip_preview => /vip-preview(.:format)
  // function(options)
  vip_preview: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"vip-preview",false],[1,[2,[8,".",false],[3,"format",false]],false]]]),
// webhooks_stripe_customer_subscription_deleted => /webhooks/stripe/customer_subscription_deleted(.:format)
  // function(options)
  webhooks_stripe_customer_subscription_deleted: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"stripe",false],[2,[7,"/",false],[2,[6,"customer_subscription_deleted",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// webhooks_stripe_customer_subscription_updated => /webhooks/stripe/customer_subscription_updated(.:format)
  // function(options)
  webhooks_stripe_customer_subscription_updated: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"stripe",false],[2,[7,"/",false],[2,[6,"customer_subscription_updated",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// webhooks_stripe_invoice_payment_succeeded => /webhooks/stripe/invoice_payment_succeeded(.:format)
  // function(options)
  webhooks_stripe_invoice_payment_succeeded: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"stripe",false],[2,[7,"/",false],[2,[6,"invoice_payment_succeeded",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// webhooks_stripe_payment_intent_succeeded => /webhooks/stripe/payment_intent_succeeded(.:format)
  // function(options)
  webhooks_stripe_payment_intent_succeeded: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"webhooks",false],[2,[7,"/",false],[2,[6,"stripe",false],[2,[7,"/",false],[2,[6,"payment_intent_succeeded",false],[1,[2,[8,".",false],[3,"format",false]],false]]]]]]]),
// year_of_learning => /year-of-learning(.:format)
  // function(options)
  year_of_learning: Utils.route([["format",false]], {}, [2,[7,"/",false],[2,[6,"year-of-learning",false],[1,[2,[8,".",false],[3,"format",false]],false]]])}
;
      routes.configure = function(config) {
        return Utils.configure(config);
      };
      routes.config = function() {
        return Utils.config();
      };
      routes.default_serializer = function(object, prefix) {
        return Utils.default_serializer(object, prefix);
      };
      return Object.assign({
        "default": routes
      }, routes);
    }
  };

  result = Utils.make();

  if (typeof define === "function" && define.amd) {
    define([], function() {
      return result;
    });
  } else if (typeof module !== "undefined" && module !== null) {
    try {
      module.exports = result;
    } catch (error1) {
      error = error1;
      if (error.name !== 'TypeError') {
        throw error;
      }
    }
  } else {
    Utils.namespace(this, null, result);
  }

  return result;

}).call(this);
