<template>
  <Link :href="$routes.destroy_user_session()" method="delete" type="button" as="button" @click="trackLogout">Sign out</Link>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue'
import mixpanel from '@/mixins/mixpanel'

export default {
  mixins: [mixpanel],

  components: {
    Link
  }
}
</script>
