<template>
  <div class="comment-input">
    <div class="comment-input-wrapper flex flex-row">
      <div class="comment__avatar w-10 h-10 md:w-16 md:h-16 mr-4 flex-shrink-0">
        <!-- CUSTOMER AVATAR -->
        <img loading="lazy" src="https://cdn.dribbble.com/users/59947/screenshots/15720035/media/89ae3a971ca23b399d75319fce5e4532.jpg?compress=1&resize=600x450" class="w-full h-full object-cover rounded-sm" />
      </div>

      <div class="input-group w-full">
        <!-- COMMENT INPUT -->
        <TextArea class="input" />

        <!-- IMAGE UPLOADER -->
        <Icon name="Upload" class="text-xl text-yellow-500 mr-4 cursor-pointer absolute right-2 top-4" />
      </div>
    </div>

    <div class="flex justify-end">
      <!-- ADD COMMENT BUTTON -->
      <Button size="small">Add Comment</Button>
    </div>
  </div>
</template>

<script>
import Button from '@/Components/Shared/Button'
import Icon from '@/Components/Shared/Icon'

export default {
  components: {
    Button,
    Icon
  }
}
</script>
