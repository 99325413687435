<template>
  <div
    class="block mb-8 lg:mb-10"
    :class="[contentAlign && 'text-' + contentAlign]"
  >
    <component
      :is="headingLevel"
      :class="[setTitleSize, subtitle && 'mb-2', setTextColor]"
      >{{ title }}</component
    >
    <p v-if="subtitle" class="text-xl max-w-prose m-auto" :class="setTextColor">
      {{ subtitle }}
    </p>
  </div>
</template>

<script>
export default {
  props: {
    /* h1, h2, h3, h4 */
    headingLevel: {
      type: String,
      default: 'h2'
    },
    title: {
      type: String,
      default: null,
      required: true
    },
    subtitle: {
      type: String,
      default: null
    },
    /* left, center, right */
    contentAlign: {
      type: String,
      default: 'left'
    },
    /* regular, large */
    titleSize: {
      type: String,
      required: false,
      default: 'regular'
    },
    /* blue, yellow, red, green, black */
    color: {
      type: String,
      required: false,
      default: 'blue'
    }
  },

  computed: {
    setTitleSize() {
      return [
        { 'title-large': this.titleSize == 'regular' },
        { 'title-largest': this.titleSize == 'large' }
      ]
    },

    setTextColor() {
      return [
        { 'text-blue-800': this.color == 'blue' },
        { 'text-red-800': this.color == 'red' },
        { 'text-green-800': this.color == 'green' },
        { 'text-yellow-800': this.color == 'yellow' },
        { 'text-grayscale-900': this.color == 'black' }
      ]
    }
  }
}
</script>
