<template>
  <nav
    data-navbar
    class="navbar top-0 left-0"
    :class="{ 'top-20 sm:top-16 lg:top-12' : showGeneralAnnouncement }"
  >
    <div class="navbar-container--fluid">
      <Link
        :href="backLink"
        class="flex items-center link--blue "
      >
        <Icon name="Chevron" class="chevron-left text-sm mr-1" />
        <span class="subheading--medium">{{ $I18n.t('back_to_cart', i18nScope) }}</span>
      </Link>

      <div class="flex flex-row items-center">
        <Link data-testid="link-ca-logo-root-mobile" :href="$routes.root()" class="link--blue flex mx-auto h-6 w-auto">
          <Monogram class="h-full w-auto" />
        </Link>
        <div class="hidden sm:block h-8 w-px bg-blue-800 mx-4"></div>
        <p class="hidden sm:block subheading--medium text-blue-800">{{ $I18n.t('order_checkout', i18nScope) }}</p>
      </div>

      <div class="profile__avatar w-8 h-8 overflow-hidden mr-2 rounded-sm">
        <img loading="lazy" data-testid="current-user-avatar" :src="currentUser.account.avatar_path" :alt="$I18n.t('account_avatar', i18nScope)" class="image-cover rounded-sm">
      </div>
    </div>
  </nav>
</template>

<script>
import statuses from '@/mixins/statuses'
import currentUser from '@/mixins/currentUser'
import generalAnnouncement from '@/mixins/generalAnnouncement'

import { Link } from '@inertiajs/inertia-vue'

import Monogram from '@/Components/Shared/Monogram'
import Icon from '@/Components/Shared/Icon'

export default {
  mixins: [
    currentUser,
    generalAnnouncement,
    statuses
  ],

  components: {
    Link,
    Monogram,
    Icon
  },

  props: {
    orderable: {
      type: Object,
      required: true
    },
    i18nScope: {
      type: Object,
      required: true
    }
  },

  computed: {
    backLink() {
      return this.$routes.cart()
    }
  }
}
</script>
