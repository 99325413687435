export const cart = {
  computed: {
    orderable() {
      return this.$page.props.cart
    },
    cartSize(){
      return this.orderable?.cartItems?.length || 0
    },
    courseCount() {
      return this.orderable?.cartItems?.filter(item => item.itemType == 'Course')?.length || 0
    },
    cartDrawer() {
      return this.$page.props.cart?.drawer
    }
  },

  methods: {
    mixpanelCartData(cart) {
      const userEmail = cart.user?.email

      return {
        current_url: window.location.href.split('?')[0],
        email: userEmail,
        cart_items: this.mixpanelCartItemsData(cart.cartItems, userEmail),
        cart_size: this.cartSize,
        grand_total: cart?.unformatted_price
      }
    },

    mixpanelCartItemsData(cartItems, userEmail) {
      return cartItems.map(cartItem => {
        const itemType = cartItem.itemType

        return {
          name: cartItem.item.name,
          type: itemType,
          send_to_self: itemType == 'Campaign' ? userEmail === cartItem.recipient_email : null, // is a gift sent to himself
          send_to: cartItem.recipient_email,
          price: cartItem.unformattedPrice
        }
      })
    }
  }
}

export default cart
