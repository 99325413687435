<template>
  <div class="input-group">
    <div
      class="flex flex-row bg-blue-800 border-b-2 border-white   flex-nowrap searchBar-width"
    >
    <form class="flex" @submit.prevent="handleClick()">
      <!-- <button
        data-testid="search-button-submit"
        class="search_field--button flex items-center justify-center"
        @click="handleClick()"
      >
        <Icon name="Search" class="menu-icon text-base" />
      </button> -->
      <input
        :id="id"
        :name="name"
        :data-testid="`input-${testid}`"
        ref="input"
        v-bind="$attrs"
        class="input search_field--input text-white"
        :hidden="hidden"
        :type="type"
        :value="searchKey"
        v-model="searchKey"
        :autofocus="autofocus"
        :autocapitalize="autocapitalize"
        :placeholder="placeholder"
        @input="handleChange($event.target.value)"
      />
      <slot></slot>
      <div class="search_field--close" @click="$emit('click')">
        <Icon name="Close" class="text-white " />
      </div>
      </form>
    </div>
  </div>
</template>

<script>
import mixpanel from '@/mixins/mixpanel'
import Icon from '@/Components/Shared/Icon'

/**
 * SearchGroup component for Create Academy
 * @displayName SearchGroup
 */
export default {
  name: 'AnonSearchGroup',

  components: {
    Icon
  },

  mixins: [
    mixpanel
  ],

  props: {
    /**
     * The id for the input
     */
     id: {
      type: String,
      default: null
    },
    /**
     * The name for the input
     */
    name: {
      type: String,
      default: null
    },
    /**
     * The type of the input
     * @values text, select, text-area, ...
     */
    type: {
      type: String,
      default: 'text'
    },
    /**
     * The value for the input
     */
    value: {
      type: [String, Number],
      default: null
    },
    /**
     * The placeholder for the input - this should be more explanatory of the desired input information
     */
    placeholder: {
      type: String,
      default: null
    },
    hidden: {
      type: Boolean,
      default: false
    },
    autofocus: {
      type: String,
      default: null
    },
    autocapitalize: {
      type: String,
      default: null
    },
    testid: String,
    handleClose: Function

  },

  computed: {
    computedId() {
      return this.id || `text-input-${this._uid}`
    }
  },

  data() {
    return {
      searchKey: ''
    }
  },
  mounted() {
     this.focus()
  },
  methods: {
    trackSearchEvent() {
      this.trackEvent('search_event', {
        facet: 'courses', // always true from navbar
        query: this.searchKey,
        form: 'nav',
        location: window.location.pathname
      })
    },
    focus() {
      this.$refs.input.focus()
    },
    select() {
      this.$refs.input.select()
    },
    setSelectionRange(start, end) {
      this.$refs.input.setSelectionRange(start, end)
    },
    inputNumberOnKeyDown(event) {
      // By default number inputs accept the letter 'e' because it stands for
      // exponential. This prevents our number inputs from accepting the
      // letter 'e'.
      if (event.keyCode === 69) event.preventDefault()
    },
    handleChange(value) {
      this.searchKey = value.trimStart()
    },
    handleClick() {
      this.trackSearchEvent()  // Track the search event
      this.handleClose()
      this.$inertia.visit(`/search?q=${this.searchKey}`)
    }
  }

}
</script>

<docs>
      ### ✎ Regular Input

      ```jsx
        <InputGroup
          placeholder="Insert your email"
          label="Email"
        ></InputGroup>
      ```
      ---

      ### ✎ Error Input

      ```jsx
        <InputGroup
          placeholder="Insert your email"
          label="Email"
          :errors="['The email you inserted is not valid']"
        ></InputGroup>
      ```
      ---

      ### ✎ Date Input

      ```jsx
        <InputGroup
          type="date"
          label="Insert the desired date"
        ></InputGroup>
      ```
      ---

      ### ✎ Number Input

      ```jsx
        <InputGroup
          type="number"
          label="Insert the desired date"
        ></InputGroup>
      ```
      ---

      ### ✎ Password Input

      ```jsx
        <InputGroup
          type="password"
          label="Insert your password"
        ></InputGroup>
      ```
    </docs>
