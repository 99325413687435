<template>
  <div class="bg-blue-100  quoteSlider">
    <div class="container py-16 lg:py-24">
      <div class="flex flex-col mb-0 gap-1 lg:gap-3">
        <p class="text-center leading-10 font-medium text-base text-blue-800 uppercase tracking-widest">IN THE PRESS </p>
      </div>
      <div class="testimonial-grid overflow-hidden mt-4 lg:mt-11" ref="swiperContainer">
        <div class="swiper-wrapper items-stretch">
          <div
            class="swiper-slide h-auto"
            v-for="(testimonial, index) in testimonials"
            :key="index"
          >
            <div class="flex flex-col items-center justify-center w-full mx-auto max-w-4xl  h-full">
              <p class="font-serif text-center text-3xl lg:text-5xl text-blue-800 font-medium mb-4 lg:mb-11 text-balance">
                {{ testimonial.quote }}
              </p>
              <p class="text-sm lg:text-lg mt-3 uppercase font-medium text-blue-800 lg:tracking-wider">
                {{ testimonial.author }}
              </p>
            </div>
          </div>
        </div>
        <div class="swiper-pagination"></div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, computed } from 'vue'
import Swiper from 'swiper/bundle'
import 'swiper/swiper-bundle.css'

export default defineComponent({
  name: 'ReviewSlider',
  props: {
    isHideControls: {
      type: Boolean,
      default: false
    }
  },
  setup() {
    const swiperContainer = ref(null)

    const testimonials = computed(() => [
    {
        quote: '“The go-to website for creative online classes”',
        author: '-THE DAILY TELEGRAPH'
      },
      {
        quote: '“Think of them as the logical next stage in the democratisation of design”',
        author: '-THE SUNDAY TIMES '

      },
      {
        quote: '“These online learning platforms make great team-building gifts”',
        author: '-ARCHITECTURAL DIGEST'

      },
      {

        quote: '“Create Academy is known for its comprehensive, inspiring and accessible courses”',
        author: '-SHEERLUXE'

      }
    ])

    onMounted(() => {
      new Swiper(swiperContainer.value, {
        slidesPerView: 1,
        spaceBetween: 28,
        pagination: {
          el: '.swiper-pagination'
        },
        autoplay: {
          delay: 5000,
          disableOnInteraction: false
        }
      })
    })

    return {
      swiperContainer,
      testimonials
    }
  }
})
</script>

<style scoped>
  .swiper-wrapper {
      align-items: stretch;
  }
  .swiper-wrapper .swiper-slide {
      height: auto !important;
  }
  .swiper-pagination {
    display: flex;
    align-items: center;
    padding: 0 !important;
    gap: 6px;
    position: relative;
    margin-top: 40px;
    justify-content: center;
  }
</style>
