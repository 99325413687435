<template>
  <div class="py-8">
    <h2 class="w-full flex-grow overline--medium text-yellow-800">Subscription Details</h2>

    <div v-if="membershipPlan" class="mt-4 grid grid-cols-2 gap-y-2 font-medium">
      <div>Subscription Plan</div>
      <div class="text-right capitalize">{{ membershipPlan.slug }} Plan</div>

      <div>Subscription Status</div>
      <div class="text-right">{{ membershipPlan.status }}</div>

      <div>Start Date</div>
      <div class="text-right">{{ membershipPlan.start_date }}</div>

      <template v-if="membershipPlan.expiry_date">
        <div>Renewal Date</div>
        <div class="text-right">{{ membershipPlan.expiry_date }}</div>
      </template>

      <template v-if="membershipPlan.cancelled_at">
        <div>Cancelled Date</div>
        <div class="text-right">{{ membershipPlan.cancelled_at }}</div>
      </template>

      <template>
        <div v-if="hasUpcomingPayment">Renewal Price</div>
        <div v-else>Plan Price</div>
        <div class="text-right" v-html="planRate"></div>
      </template>

      <template v-if="showAmountPayable">
        <div>Payable Amount</div>
        <div class="text-right">
          {{ formatPlanRate(payableAmount) }}
        </div>
      </template>

      <template v-if="isCouponApplied">
        <div>Applied Discount Code</div>
        <div class="text-right">{{ membershipPlan.upcoming_payment.coupon_code }}</div>
      </template>
      <template v-else-if="hasUpcomingPayment">
        <div class="col-span-2">Apply Discount Code</div>
        <div class="col-span-2">
          <CouponDetails validateVoucherUrl="/membership/renewal_redeem_voucher" />
        </div>
      </template>

      <div v-if="membershipPlan.can_cancel" class="mt-4 flex">
        <p
          data-testid="membership-cancel-button"
          @click="cancelMembership"
          class="flex items-center justify-center gap-2 border-b-2 border-blue-500 cursor-pointer text-base font-normal tracking-wider text-blue-900 hover:border-blue-900"
          :class="[processing && 'pointer-events-none']"
        >
          <span>Cancel Subscription</span>
          <svg
            width="13"
            height="12"
            viewBox="0 0 13 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M7.33333 1.625L11.5 6M11.5 6L7.33333 10.375M11.5 6L1.5 6"
              stroke="#18336C"
              stroke-width="1.5"
              stroke-linecap="square"
            />
          </svg>
        </p>
      </div>
    </div>

    <div v-else>
      <p data-testid="no-membership" class="my-10 text-lg text-opacity-80">
        {{ $I18n.t('no_membership', i18nScope) }}
      </p>
    </div>
  </div>
</template>

<script>
  import axios from 'axios'
  import mixpanel from '@/mixins/mixpanel'
  import CouponDetails from '@/Components/User/CouponDetails'

  export default {
    mixins: [mixpanel],

    components: {
      CouponDetails
    },

    props: {
      membershipPlan: {
        type: Object,
        default: null
      }
    },

    data() {
      return {
        processing: false
      }
    },

    computed: {
      hasUpcomingPayment() {
        return !!this.membershipPlan.upcoming_payment
      },

      showAmountPayable() {
        return this.totalAmount != this.payableAmount
      },

      planRate() {
        const planPrice = this.membershipPlan.price

        if (!this.membershipPlan.upcoming_payment || planPrice <= this.totalAmount) {
          return this.formatPlanRate(planPrice)
        }

        return `<s>${this.formatPlanRate(planPrice)}</s> ${this.formatPlanRate(this.totalAmount)}`
      },

      totalAmount() {
        return (this.membershipPlan.upcoming_payment?.amount_total ?? 0) / 100
      },

      payableAmount() {
        return (this.membershipPlan.upcoming_payment?.amount_payable ?? 0) / 100
      },

      isCouponApplied() {
        return !!this.membershipPlan.upcoming_payment?.coupon_code
      }
    },

    methods: {
      formatPlanRate(rate) {
        if (this.membershipPlan.slug == 'monthly') {
          return `£${rate}/Month`
        } else if (this.membershipPlan.slug == 'annual') {
          return `£${rate}/Year`
        }

        return `£${rate}`
      },

      async cancelMembership() {
        try {
          this.processing = true

          // Use the mixpanel mixin to track the event
          this.trackEvent('Cancel Subscription Clicked', {
            plan: this.membershipPlan.slug,
            status: this.membershipPlan.status
          })

          const response = await axios.post('/membership/cancel')

          if (response.status == 200) {
            window.churnkey.init('show', response.data.data)
          } else {
            console.error('Failed to fetch')
          }
        } catch (error) {
          console.error('Error fetching:', error)
        } finally {
          this.processing = false
        }
      },

      trackCancelButtonDisplay() {
        if (this.membershipPlan?.can_cancel) {
          this.trackEvent('Cancel Subscription Button Displayed', {
            plan: this.membershipPlan.slug,
            status: this.membershipPlan.status
          })
        }
      }
    },

    mounted() {
      this.trackCancelButtonDisplay()
    },

    created() {
      this.i18nScope = { scope: 'pages.users.purchases' }
    }
  }
</script>
