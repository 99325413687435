<template>
  <div
    class="box"
    :class="[
      color && 'box--' + color,
      spacing && 'box--space-' + spacing
  ]">
    <h3
      v-if="title"
      class="subheading--medium mb-4"
      :class="[
        { 'text-yellow-800' : color == 'light' },
        { 'text-grayscale-700' : color == 'dark' },
      ]"
    >
      {{ title }}
    </h3>

    <slot />
  </div>
</template>

<script>
  /**
   * Box component for Create Academy
   * @displayName Box
   */
  export default {
    name: 'Box',

    props: {
      /**
       * The color of the Box
       * @values light, dark
       */
      color: {
        type: String,
        default: 'light'
      },
      /**
       * The y space that Box ocuppies
       * @values xs, normal, xl
       */
      spacing: {
        type: String,
        default: 'normal'
      },
      /**
       * Title for the box
       */
      title: {
        type: String,
        required: false
      }
    }
  }
</script>

<docs>
  ### ✎ Box
  By default the Box ocuppies full width. This expects that the box sits on a already previously defined width content

  ```jsx
    <div class="flex flex-row">
      <Box class="mx-2">
        <h3 class="subheading--medium text-yellow-500 mb-4">Gift Recipient</h3>
        <p class="font-normal mb-2">agrancho@whitesmith.co</p>
        <p>“Hey Alex, hope you like my gift! My best, From Michelle and Barack”</p>
      </Box>
    </div>
  ```

  ---

    ### ✎ Box Dark

  ```jsx
    <Box color="dark">
      <h3 class="subheading--medium text-yellow-500 mb-4">Gift Recipient</h3>
      <p class="font-normal mb-2">agrancho@whitesmith.co</p>
      <p>“Hey Alex, hope you like my gift! My best, From Michelle and Barack”</p>
    </Box>
  ```

  ---

  ### ✎ Box Iterior Spacing

  ```jsx
    <div class="flex flex-row">
      <Box spacing="xs" class="mx-2">
        <h3 class="subheading--medium text-yellow-500 mb-4">Gift Recipient</h3>
        <p class="font-normal mb-2">agrancho@whitesmith.co</p>
        <p>“Hey Alex, hope you like my gift! My best, From Michelle and Barack”</p>
      </Box>

      <Box class="mx-2">
        <h3 class="subheading--medium text-yellow-500 mb-4">Gift Recipient</h3>
        <p class="font-normal mb-2">agrancho@whitesmith.co</p>
        <p>“Hey Alex, hope you like my gift! My best, From Michelle and Barack”</p>
      </Box>

      <Box spacing="xl" class="mx-2">
        <h3 class="subheading--medium text-yellow-500 mb-4">Gift Recipient</h3>
        <p class="font-normal mb-2">agrancho@whitesmith.co</p>
        <p>“Hey Alex, hope you like my gift! My best, From Michelle and Barack”</p>
      </Box>
    </div>
  ```
</docs>
