<template>
  <div class="w-full">
    <form :action="googleFormAction" method="post"  novalidate="true" class="mb-3">
      <InputGroup
        name="authenticity_token"
        :value="formAuthenticityToken"
        :hidden="true"
        class="hidden"
      />

      <Button
        data-testid="button-google"
        type="submit"
        variant="google"
        block
      >

        {{ $I18n.t('sign_with_google', i18nScope) }}
      </Button>
    </form>

    <form :action="facebookFormAction" method="post" novalidate="true">
      <InputGroup
        name="authenticity_token"
        :value="formAuthenticityToken"
        :hidden="true"
        class="hidden"
      />

      <Button
        data-testid="button-facebook"
        type="submit"
        variant="facebook"
        block
      >
        {{ $I18n.t('sign_with_facebook', i18nScope) }}
      </Button>
    </form>
  </div>
</template>

<script>
import InputGroup from '@/Components/Shared/Forms/InputGroup'
import Button from '@/Components/Shared/Button'
import cookies from '../../mixins/cookies'

export default {
  methods: {
  },
  components: {
    InputGroup,
    Button
  },

  computed: {
    googleFormAction() {
      return `${this.$routes.user_google_oauth2_omniauth_authorize()}${this.queryParameters.replace(/REPLACE_SOCIAL/g, 'Google')}`
    },
    facebookFormAction() {
      return `${this.$routes.user_facebook_omniauth_authorize()}${this.queryParameters.replace(/REPLACE_SOCIAL/g, 'Facebook')}`
    }
  },
    // computed: {
    //   googleFormAction() {

    //     let url = this.$routes.user_google_oauth2_omniauth_authorize()
    //     if (this.redirectUri) {
    //       const encodedRedirectUri = encodeURIComponent(this.redirectUri)
    //       url += `?redirect_uri=${encodedRedirectUri}`
    //       document.cookie = `redirect_uri=${encodedRedirectUri}; path=/;`
    //     }
    //     return url + this.queryParameters.replace(/REPLACE_SOCIAL/g, 'Google')
    //   },
    //   facebookFormAction() {
    //     let url = this.$routes.user_facebook_omniauth_authorize()
    //     if (this.redirectUri) {
    //       const encodedRedirectUri = encodeURIComponent(this.redirectUri)
    //       url += `?redirect_uri=${encodedRedirectUri}`
    //       document.cookie = `redirect_uri=${encodedRedirectUri}; path=/;`
    //     }
    //     return url + this.queryParameters.replace(/REPLACE_SOCIAL/g, 'Facebook')
    //   }
    // },

  props: {
    formAuthenticityToken: {
      type: String,
      required: true
    },
    i18nScope: {
      type: Object,
      required: true
    },
    queryParameters: {
      type: String,
      default:''
    },
    redirectUri: {
    type: String,
    default: null
  }
  }

}
</script>
