export default {
  computed: {
    isMembershipGift() {
      return this.campaign.category === 'membership_gift'
    },
    giftStyles() {
      return {
        border: this.isMembershipGift ? 'border-blue-800' : 'border-red-500',
        text: this.isMembershipGift ? 'text-blue-800' : 'text-red-500',
        background: this.isMembershipGift ? 'bg-blue-300' : 'bg-red-300',
        codeText: this.isMembershipGift ? 'text-black' : 'text-red-500'
      }
    }
  }
} 