const dirtyForm = formKey => ({
  data() {
    return {
      formDirty: false
    }
  },

  watch: {
    [formKey]: {
      deep: true,
      handler() {
        this.formDirty = true
      }
    }
  }
})

export default dirtyForm
