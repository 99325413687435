<template>
  <BaseLayout>

    <FlashMessages />

    <slot />
  </BaseLayout>
</template>

<script>
import FlashMessages from '@/Components/Shared/Objects/FlashMessages'
import BaseLayout from '@/Layouts/BaseLayout'

export default {
  components: {
    BaseLayout,
    FlashMessages
  }
}
</script>
