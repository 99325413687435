<template>
<div v-if="show" class="menu-cart" data-testid="cart-drawer-summary">
  <!--Cart button -->
  <div @click="openDrawer" data-testid="navbar-link-cart" class="relative cursor-pointer">
    <Icon name="Basket" class="text-white" />

    <div
      v-if="cartHasItems"
      data-testid="cart-items-number"
      class="absolute -bottom-2 -right-2 w-5 h-5 bg-yellow-800 rounded-full flex items-center justify-center"
      >
      <span class="text-grayscale-100 text-xs font-medium">{{ cartSize }}</span>
    </div>
  </div>

  <!-- Cart drawer -->
  <MenuPanel
    :name="$I18n.t('title', i18nScope)"
    @clickClose="clickClose"
    :currentPanel="isToggleBasketDrawer"
    class="menu-cart__drawer overflow-hidden"
    classContainer="flex flex-col flex-grow justify-between"
  >
    <template v-if="cartHasItems">
      <div class="flex flex-col drawer-overflow">
        <OrderSummary
          class="text-grayscale-700"
          :orderable="orderable"
          :i18nScope="i18nScope"
          :allowRemoval="true"
          gtmPageName="Navbar"
        />

        <template v-if="!isSubscribed">
          <PromoStrip
            v-if="showPromoStrip"
            :cartDrawer="cartDrawer"
            :courseCount="courseCount"
          />
        </template>
      </div>

      <div class="flex flex-col relative bottom-20 lg:bottom-10 bg-white w-full py-4 border-t border-blue-800 border-opacity-60 mt-6">
        <div v-if="activeSale" class="flex flex-row text-red-700 mb-2">
          <div data-testid="order-summary-sale-label" class="text-red-700 font-normal">{{ activeSale.name }}</div>
          <div data-testid="order-sale-amount" class="ml-auto" >
            - {{ geoMonetize(saleReduction, geoCurrencySymbol, 2) }}
            <!-- £{{ saleReduction }} -->
          </div>
        </div>
        <div class="flex flex-row justify-between text-grayscale-700 mb-4">
          <p data-testid="order-summary-total-label" class="font-bold">{{ $I18n.t('summary_total_amount', i18nScope) }}</p>
          <p data-goptimize-total-amount data-testid="order-summary-amount" class="font-bold">{{ geoMonetize(humanizedAmount, geoCurrencySymbol, 2) }}</p>
        </div>

        <ButtonLink
          block
          @click="trackProceed"
          :href="$routes.cart()"
          class="mb-6"
          data-testid="cart-cart-button"
        >
          {{ $I18n.t('cta', i18nScope) }}
        </ButtonLink>
      </div>
    </template>

    <template v-else>
      <p class="font-normal text-center text-blue-800 text-opacity-70 pt-32">{{ $I18n.t('empty_state', i18nScope) }}</p>
    </template>
  </MenuPanel>
</div>

</template>


<script>
import cart from '@/mixins/cart'
import overflowLogic from '@/mixins/overflowLogic'
import mixpanel from '@/mixins/mixpanel'
import gtm from '@/mixins/gtm'
import currentUser from '@/mixins/currentUser'
import monetize from '@/mixins/monetize'

import Icon from '@/Components/Shared/Icon'
import MenuPanel from '@/Components/Shared/Objects/Navbar/Mobile/MenuPanel'
import OrderSummary from '@/Components/Orders/OrderSummary'
import ButtonLink from '@/Components/Shared/ButtonLink'
import PromoStrip from '@/Components/Shared/Campaigns/CartStrip'

export default {
  mixins: [
    cart,
    mixpanel,
    overflowLogic,
    gtm,
    currentUser,
    monetize
  ],

  components: {
    Icon,
    ButtonLink,
    OrderSummary,
    MenuPanel,
    PromoStrip
  },

  data() {
    return {
      // toggle to open/close cart drawer
      isToggleBasketDrawer: false
    }
  },

  computed: {
    activeSale() {
      return this.orderable.activeSale
    },
    geoCurrencySymbol() {
      return this.orderable.geoPrice.currencySymbol
    },
    saleReduction() {
      if (this.activeSale) {
        return this.orderable.geoPrice.price/100 * this.activeSale.discountValue / 100
      }
      return 0 // no sale
    },
    cartHasItems(){
      return this.cartSize > 0
    },
    humanizedAmount() {
      // Check if there's an active sale and if so, apply the discount
      if (this.activeSale) {
        const discountFactor = 1 - (this.activeSale.discountValue / 100)
        return (this.orderable.geoPrice.price/100 * discountFactor)
      }
      return this.orderable.geoPrice.price / 100
    },
    show() {
      return (this.$page.component !== 'Carts/Show')
    },
    showPromoStrip() {
      return this.courseCount > 0 && this?.cartDrawer?.active && this?.cartDrawer?.bannerStrip?.active
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.carts.drawer' }
  },
  methods: {
    openDrawer(){
      this.isToggleBasketDrawer = true
      this.setScrollOverflow('hidden')
      this.trackEvent('open_cart_drawer', this.mixpanelCartData(this.orderable))

      this.gtmAddViewCart(this.orderable, 'mini basket')
    },

    clickClose() {
      this.isToggleBasketDrawer = false
      this.setScrollOverflow('auto')
      this.trackEvent('close_cart_drawer', this.mixpanelCartData(this.orderable))
    },

    trackProceed(){
      return this.trackEvent('proceed_to_basket', this.mixpanelCartData(this.orderable))
    }
  }
}
</script>
