<template>
  <div>
    <MetaTags :metaTags="metaTags" />

    <Tabs />

    <div class="md:mt-14 container">
      <PurchaseBreakdown :order="order"/>
    </div>
  </div>
</template>

<script>
import { metaTags } from '@/mixins/metaTags'

import MetaTags from '@/Components/Shared/MetaTags'
import Tabs from '@/Components/User/Tabs'
import PurchaseBreakdown from '@/Components/User/PurchaseBreakdown'

export default {
  mixins: [metaTags],

  created() {
    this.i18nScope = { scope: 'pages.users.purchase_item' }
  },

  components: {
    MetaTags,
    Tabs,
    PurchaseBreakdown
  },

  props: {
    order: {
      type: Object,
      required: true
    }
  }
}
</script>
