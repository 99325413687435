import { render, staticRenderFns } from "./FourSquares.vue?vue&type=template&id=1532fb81&scoped=true"
import script from "./FourSquares.vue?vue&type=script&lang=js"
export * from "./FourSquares.vue?vue&type=script&lang=js"
import style0 from "./FourSquares.vue?vue&type=style&index=0&id=1532fb81&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1532fb81",
  null
  
)

export default component.exports