<template>
  <div class="w-full pl-2 lg:px-4">
    <div class="flex flex-col">
      <!-- Existing categories -->
      <Link
        v-for="category in categories"
        :key="category.slug"
        :data-testid="`navbar-category-${category.slug}-link`"
        :href="$routes.category(category.slug)"
        @click="onCategoryLinkClick(category)"
        class="category-link"
      >
        {{ category.name }}
      </Link>
      <!-- "All" link -->
      <Link
        data-testid="navbar-category-all-link"
        :href="$routes.courses()"
        @click="onCategoryLinkClick({ name: 'All', slug: 'all' })"
        class="category-link"
      >
        ALL COURSES
      </Link>
    </div>
  </div>
</template>

<script>
  import { Link } from '@inertiajs/inertia-vue'

  import mixpanel from '@/mixins/mixpanel'

  export default {
    mixins: [mixpanel],

    components: {
      Link
    },

    props: {
      categories: Array
    },

    methods: {
      onCategoryLinkClick(category) {
        this.trackEvent('go_to_category_page', {
          page: window.location.pathname,
          component: 'Nav Bar',
          category: category.name
        })
      }
    }
  }
</script>

<style scoped>
  .category-link {
    @apply text-sm tracking-wider uppercase font-medium lg:text-white lg:hover:text-neutral-400 text-blue-800 py-2 hover:underline w-full whitespace-nowrap;
  }
</style>
