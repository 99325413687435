export const metaTags = {
  data() {
    return {
      metaTags: {
        title: '',
        description: '',
        keywords: ''
      }
    }
  },

  methods: {
    setMetaTags(tags) {
      this.metaTags = {
        ...this.metaTags,
        ...tags
      }
    }
  }
}
