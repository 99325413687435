<template>
  <MenuPanel
    :name="$I18n.t('menu', i18nScope)"
    :currentPanel="isToggleGeneralMenu"
    @clickClose="$emit('toggleGeneralMenu')"
    class="overflow-y-scroll"
  >
    <MenuItem :body="$I18n.t('categories', i18nScope)" class="mb-4" text>
      <CategoriesList :categories="categories" />
    </MenuItem>

    <MenuItem testid="navbar-link-instructors"  :href="$routes.instructors()" :body="$I18n.t('instructors', i18nScope)" />

    <!-- <MenuItem testid="navbar-link-gifts" v-if="!isSubscribed" :href="$routes.membership_landing()" :body="$I18n.t('subscription', i18nScope)" /> -->

    <MenuItem testid="navbar-link-gifts" :href="$routes.gifts()" :body="$I18n.t('gifts', i18nScope)" />

    <MenuItem v-if="showMembershipOffer" testid="navbar-link-membership_offer" external href="https://page.createacademy.com/membership" :body="$I18n.t('membership_offer', i18nScope)" />

    <MenuItem v-if="seasonalIsActive" @click="$emit('toggleSeasonalMenu')" :body="$I18n.t('seasonal.title', i18nScope)" class="mb-2" toggler />

    <template v-if="!isSubscribed">
      <MenuItem testid="navbar-link-onboarding" :href="$routes.onboarding()" body="Subscribe" />
    </template>
    
    <template v-if="currentUser">
      <MenuItem @click="$emit('toggleAccountMenu')" :body="$I18n.t('my_account', i18nScope)" toggler />
    </template>
    <template v-else>
      <!-- <MenuItem testid="navbar-link-signup" :href="$routes.new_user_registration()" :body="$I18n.t('sign_up', i18nScope)" /> -->
      <MenuItem testid="navbar-link-signin" :href="$routes.new_user_session()" :body="$I18n.t('sign_in', i18nScope)" />
    </template>
   
    <SocialIcons />
  </MenuPanel>
</template>


<script>
import currentUser from '@/mixins/currentUser'
import cookies from '@/mixins/cookies'

import CategoriesList from '@/Components/Shared/Objects/Navbar/CategoriesList'
import MenuItem from '@/Components/Shared/Objects/Navbar/Mobile/MenuItem'
import MenuPanel from '@/Components/Shared/Objects/Navbar/Mobile/MenuPanel'
import SocialIcons from '@/Components/Shared/Objects/Navbar/Mobile/SocialIcons'

export default {
  mixins: [
    currentUser,
    cookies
  ],

  components: {
    CategoriesList,
    MenuItem,
    MenuPanel,
    SocialIcons
  },

  props: {
    categories: Array,

    isToggleGeneralMenu: {
      type: Boolean,
      required: true
    },

    seasonalIsActive: {
      type: Boolean,
      required: true
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.navbar' }
  },

  computed: {
    coursesListName() {
      return 'courses_list'
      // return this.isSubscribed ? 'courses_list' : 'free_lessons'
    }
  }
}
</script>
