export const gtmV3 = {
  methods: {
    /********************************************
     * Events builders                          *
     ********************************************/
    gtmV3AddToCart(context, product, section) {
      let list
      if (section) {
        list = `${context} | ${section}`
      } else {
        list = this.gtmGetLastImpressionClicked
      }

      let productJson

      if (this.isCourse(product)) {
        productJson = this.gtmV3GetCourse(product)

      } else if (this.isCampaign(product)) {
        productJson = this.gtmV3GetCampaign(product)
      }


      return {
        event: 'eec.add',
        context: context,
        ecommerce: {
          add: {
            actionField: {list: list},
            products: [productJson]
          }
        }
      }
    },

    gtmV3RemoveFromCart(context, cartItem) {
      return {
        event: 'eec.remove',
        context: context,
        ecommerce: {
          remove: {
            actionField: {list: undefined},
            products: [this.gtmV3GetCartItem(cartItem)]
          }
        }
      }
    },

    gtmV3CheckoutBegin(cart) {
      return {
        event: 'eec.checkout',
        ecommerce: {
          checkout: {
            actionField: {
              step: 1
            },
            products: this.gtmV3GetCartItems(cart)
          }
        }
      }
    },

    gtmV3PurchaseStep(cart, order, voucherPayload) {
      const { cartCoupons, products } = this.gtmGetVoucherData(voucherPayload)

      const totalDiscount = order.voucher_discount / 100.0

      return {
        event: 'eec.purchase',
        ecommerce: {
          currencyCode: 'GBP',
          purchase: {
            actionField: {
              id: order.id,
              affiliation: 'Create Academy Website',
              revenue: order.unformatted_amount || 0.0,
              tax: order.vat,
              shipping: 0.00,
              coupon: cartCoupons.join(),
              quantity:1,
              newcustomer: order.new_customer ? 'yes' : 'no',
              isdiscounted: totalDiscount > 0 ? 'yes' : 'no',
              totaldiscount: totalDiscount
            },
            products: this.gtmV3GetCartItems(cart, products)
          }
        }
      }
    },

    gtmV3ProductListingImpressions(page, sections) {
      let result = []
      let index = 1

      if (sections) {
        sections.forEach((section) => {
          const impressions = section.impressions

          if (impressions) {
            impressions.forEach((impression) => {
              result.push(this.gtmV3BuildProduct(page, section.name, impression, index++))
            })
          }
        })
      }

      return {
        event: 'eec.impressionView',
        ecommerce: {
          impressions: result
        }
      }
    },

    gtmV3ProductListingImpressionClick(page, section, impression, position) {
      const list = `${page} | ${section}`

      return {
        event: 'eec.impressionClick',
        ecommerce: {
          click: {
            actionField: {
              list: list
            },
            products: [this.gtmV3BuildProduct(page, section, impression, position)]
          }
        }
      }
    },

    gtmV3ProductDetailView(product) {
      return {
        event: 'eec.detail',
        ecommerce: {
          detail: {
            actionField: {
              list: this.gtmGetLastImpressionClicked
            },
            products: [{
              id: product.id,
              name: product.name,
              category: product.categories?.join(),
              variant: '',
              brand: product.teacher?.fullName,
              currency: 'GBP',
              price: product.unformatted_price
            }]
          }
        }
      }
    },

    /********************************************
     * Objects builders                         *
     ********************************************/
    gtmV3BuildProduct(page, section, product, position) {
      let impressionJson = {
        name: product.name,
        list: `${page} | ${section}`,
        position: position
      }

      // Attributes specific to courses and gifts
      if (this.isCourse(product)) {
        impressionJson['id'] = product.id
        impressionJson['category'] = product.categories?.join()

      } else if (this.isCampaign(product)) {
        impressionJson['id'] = `Gift-${product.id}`
        impressionJson['category'] = 'gift'
      }

      return impressionJson
    },

    /********************************************
     * Getters                                  *
     ********************************************/
    gtmV3GetCartItems(cart, voucherValidation = {}) {
      if (cart.cartItems) {
        return cart.cartItems.map(cartItem => this.gtmV3GetCartItem(cartItem, voucherValidation))
      }

      return []
    },

    gtmV3GetCartItem(cartItem, voucherValidation) {
      if (this.isCartItemCourse(cartItem)) {
        return this.gtmV3GetCourse(cartItem.item, voucherValidation)
      }
      else if (this.isCartItemCampaign(cartItem)) {
        return this.gtmV3GetCampaign(cartItem.item, voucherValidation)
      }
    },

    gtmV3GetCourse(course, voucherValidation = {}) {
      const { amount, discounted, discountValue, coupons } = this.gtmGetPriceWithDiscountDetails(course, voucherValidation)

      return {
        id: course.id,
        name: course.name,
        category: course.categories?.join(),
        variant: '',
        brand: course.teacher?.fullName,
        price: amount,
        coupon: coupons.join(),
        quantity: 1,
        currency: 'GBP',
        discounted: discounted ? 'yes' : 'no',
        discountvalue: discountValue
      }
    },

    gtmV3GetCampaign(campaign, voucherValidation = {}) {
      const { amount, discounted, discountValue, coupons } = this.gtmGetPriceWithDiscountDetails(campaign, voucherValidation)

      return {
        id: `Gift-${campaign.id}`,
        name: campaign.name,
        category: 'gift',
        variant: '',
        brand: '',
        price: amount,
        coupon: coupons.join(),
        quantity: 1,
        currency: 'GBP',
        discounted: discounted ? 'yes' : 'no',
        discountvalue: discountValue
      }
    }
  }
}

export default gtmV3
