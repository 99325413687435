<template>
  <div class="input-group">
    <input
      :data-testid="`input-${testid}`"
      :id="id"
      :value="checked"
      :checked="checked"
      type="checkbox"
      class="input-checkbox"
      @input="toggleChecked()"
    />

    <label :for="id" :data-testid="`label-${testid}`">
      <span> <slot /> </span>
    </label>

    <InputError :testid="testid" :errors="errors" />
  </div>
</template>

<script>
import InputError from '@/Components/Shared/Forms/InputError'

/**
 * Checkbox component for Create Academy
 * @displayName Checkbox
 */
export default {
  name: 'Checkbox',

  components: {
    InputError
  },

  props: {
    id: {
      type: String,
      default() {
        return `checkbox-input-${this._uid}`
      }
    },
    value: {
      type: Boolean,
      default: false
    },
    errors: {
      type: Array,
      default: () => []
    },
    testid: String
  },

  data() {
    return {
      checked: this.value
    }
  },

  methods: {
    toggleChecked() {
      this.checked = !this.checked
      this.$emit('input', this.checked)
    }
  }
}
</script>

<docs>
  ### ✎ Checkbox

  ```jsx
    <Checkbox> I want to receive offers and promotions</Checkbox>
  ```
</docs>
