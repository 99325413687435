<template>
  <div>
    <MetaTags :metaTags="metaTags" />

    <!-- Hero banner -->
    <div class="bg-red-500 w-full">
      <div class="h-full">
        <div class="flex">
          <!-- Left content - add flex and justify-center -->
          <div class="w-full lg:w-1/2 flex flex-col items-center justify-center gap-6 lg:gap-8 py-8 lg:py-16">
            <img 
              :src="imageUrl('bow.svg')" 
              alt="Decorative bow" 
              class="lg:hidden"
              style="width: 4rem; height: 4rem;"
            />
            <Divider label="Give the gift of" class="divider_custom" />
            <h1
              data-testid="gifts-title"
              class="text-5xl lg:text-6xl lg:mb-4 lineHeight-72 text-blue-100 font-serif font-medium text-center lg:text-left"
            >
              Creativity
            </h1>
            <p
              class="text-center text-blue-100 font-normal text-lg lg:text-2xl lg:leading-9 px-4 md:px-8"
              style="text-wrap: balance;"
            >
              Whether you're searching for that perfect present, wanting to send a gift of
              appreciation or just looking to brighten someone's day, give an experience like no
              other.
            </p>
            <p
              class="text-center lg:text-left text-blue-100 font-normal text-lg lg:text-2xl lg:leading-9"
            >
              What would you like to gift?
            </p>
            <div class="flex items-center gap-6 lg:gap-8" id="membershipSection">
              <div
                class="cursor-pointer relative text-red-100 pb-2 underlineBlue100 gift-text uppercase text-sm lg:text-lg font-normal text-center"
                @click="scrollToSubscriptionSection"
              >
                Subscription
              </div>
              <div
                class="cursor-pointer relative text-red-100 pb-2 underlineBlue100 gift-text uppercase text-sm lg:text-lg font-normal text-center"
                @click="scrollToGiftCardSection"
              >
                Digital Gift Card
              </div>
            </div>
          </div>

          <!-- Right image -->
          <div class="hidden lg:block lg:w-1/2">
            <img :src="imageUrl('gift_hero.webp')" alt="Gift hero image" class="w-full h-full object-cover" />
          </div>
        </div>
      </div>
    </div>

    <!-- Subscription section -->
    <div class="bg-blue-100 w-full" ref="subscriptionSection">
      <div class="container padding-lr">
        <div class="flex flex-col 2xl:px-28 py-0 lg:py-11 gap-8 lg:gap-12">
          <div v-if="activeSale" class="text-center flex items-center justify-center">
            <div
              class="flex items-center justify-center bg-ribbon-blue ribbonBlock bg-contain bg-no-repeat mx-auto"
            >
              <p class="text-red-100 text-sm tracking-wide uppercase font-normal relative -top-1.5">
                {{ activeSale.name }}
              </p>
            </div>
          </div>

          <div class="flex flex-col justify-center items-center">
            <h1
              data-testid="gifts-title"
              class="text-3xl leading-9 mb-1.5 lg:mb-4 lg:text-4xl text-blue-800 font-serif font-medium text-center"
            >
              Gift an
              <span class="text-red-500">{{ subscriptionGiftHeading }}</span>
            </h1>
            <p class="uppercase text-blue-800 text-sm tracking-wide font-medium">
              WHAT’S INCLUDED?
            </p>
          </div>
          <div class="flex flex-wrap items-center justify-center gap-8">
            <div
              class="w-full lg:w-auto max-w-full lg:flex-1 flex items-center justify-center lg:justify-end"
            >
              <img
                loading="lazy"
                class="w-full max-w-lg"
                :src="imageUrl('blue-subscription-gift-card.webp')"
                width="531"
                height="396"
                alt="Gift card"
              />
            </div>
            <ul class="w-full sm:w-auto flex flex-col gap-6 lg:flex-1">
              <li class="flex items-start gap-2">
                <Icon
                  name="Check"
                  class="text-blue-800 min-w-24 relative text-lg lg:leading-8 top-2"
                />
                <span class="text-blue-800 text-lg lg:text-2xl lg:leading-10 font-normal"
                  >Unlimited access to all courses and new releases for a full year</span
                >
              </li>
              <li class="flex items-start gap-2">
                <Icon
                  name="Check"
                  class="text-blue-800 min-w-24 relative text-lg lg:leading-8 top-2"
                />
                <span class="text-blue-800 text-lg lg:text-2xl lg:leading-10 font-normal"
                  >The opportunity to learn from the best designers, makers, growers and chefs</span
                >
              </li>
              <li class="flex items-start gap-2">
                <Icon
                  name="Check"
                  class="text-blue-800 min-w-24 relative text-lg lg:leading-8 top-2"
                />
                <span class="text-blue-800 text-lg lg:text-2xl lg:leading-10 font-normal"
                  >Over 400 hours of learning across 1,000 lessons</span
                >
              </li>
              <li class="flex items-start gap-2">
                <Icon
                  name="Check"
                  class="text-blue-800 min-w-24 relative text-lg lg:leading-8 top-2"
                />
                <span class="text-blue-800 text-lg lg:text-2xl lg:leading-10 font-normal"
                  >New courses and content added every month</span
                >
              </li>
            </ul>
          </div>

          <div class="flex justify-center">
            <ButtonLink
              @click="onGiftSubscriptionClick()"
              :href="$routes.customise_gifts({ campaignId: membershipCampaign.id })"
              variant="light-blue"
            >
              Gift Subscription
            </ButtonLink>

            <div
              v-if="activeSale"
              class="w-24 px-2 flex flex-col justify-center items-center bg-white"
            >
              <div class="flex flex-row items-center">
                <span class="line-through text-xs mr-2">
                  {{ calculateGeoMonetize(membershipCampaign.unformattedPrice) }}
                </span>

                <span class="text-base text-blue-800 font-medium">
                  {{
                    calculateGeoMonetize(
                      calculateSaleReduction(membershipCampaign.unformattedPrice)
                    )
                  }}
                </span>
              </div>

              <div class="text-xs text-red-800 uppercase font-normal text-center -mt-1">
                <span
                  >Save
                  {{
                    calculateGeoMonetize(calculateDiscount(membershipCampaign.unformattedPrice))
                  }}</span
                >
              </div>
            </div>

            <!-- no sale -->
            <div v-else class="w-20 px-2 flex justify-center items-center bg-white">
              <p
                :data-goptimize-course-price="membershipCampaign.unformattedPrice"
                class="heading--medium text-blue-800"
              >
                {{ calculateGeoMonetize(membershipCampaign.unformattedPrice) }}
              </p>
            </div>
          </div>
          <p
            class="text-center -mt-8 text-sm tracking-wider text-black"
            style="font-family: 'Brandon Grotesque'; font-size: 0.9375rem; line-height: 160%"
          >
            Subscription starts when code is redeemed
          </p>
        </div>
      </div>
    </div>

    <div class="bg-red-100 w-full" ref="giftCardSection">
      <div class="container padding-lr">
        <div class="flex flex-col 2xl:px-16 py-0 lg:py-11 gap-4 lg:gap-6">
          <div v-if="activeSale" class="text-center flex items-center justify-center mb-4 lg:mb-6">
            <div
              class="flex items-center justify-center bg-ribbon-gold ribbonBlock bg-contain bg-no-repeat mx-auto"
            >
              <p class="text-red-100 text-sm tracking-wide uppercase font-normal relative -top-1.5">
                {{ activeSale.name }}
              </p>
            </div>
          </div>
          <div class="flex flex-col justify-center items-center">
            <h1
              data-testid="gifts-title"
              class="text-3xl leading-9 mb-4 lg:mb-2 lg:text-4xl text-red-800 font-serif font-medium text-center"
            >
              Digital
              <span class="text-yellow-800">Gift Card </span>
            </h1>
            <p
              class="text-red-800 text-lg lg:text-2xl font-normal max-w-md mx-auto mb-8 lg:mb-10 text-center"
            >
              Gift cards can be redeemed against an annual subscription, or a specific course of
              their choice.
            </p>
            <p class="uppercase text-red-800 text-sm tracking-wide font-medium">
              Select Gift Card Amount
            </p>
          </div>
          <div
            class="flex flex-row flex-wrap items-stretch lg:items-center justify-center gap-3 mb-4 lg:mb-6 w-full"
          >
            <div
              class="w-full sm:w-56 xl:w-72 max-w-full giftCard cursor-pointer"
              v-for="giftCard in giftCards"
              :key="giftCard.slug"
              :class="[isSelectedGiftCard(giftCard.id) && 'active-card']"
              @click="selectGiftCard(giftCard)"
            >
              <div
                class="rounded-lg h-20 lg:h-full overflow-hidden relative border border-red-500 flex flex-col lg:flex-row items-center justify-center py-3 lg:py-8"
                :class="[isSelectedGiftCard(giftCard.id) ? 'bg-red-800' : 'bg-transparent']"
              >
                <p
                  class="text-2xl lg:text-4xl font-medium font-serif"
                  :class="[isSelectedGiftCard(giftCard.id) ? 'text-white' : 'text-red-500 ']"
                >
                  {{ calculateGeoMonetize(giftCard.unformattedPrice) }}
                </p>
                <span
                  class="uppercase text-white text-xs tracking-wide font-normal relative block lg:absolute bottom-0 lg:bottom-3"
                  :class="[isSelectedGiftCard(giftCard.id) ? 'activeGift' : 'hidden ']"
                  >your gift</span
                >
                <div class="giftIconWrapper">
                  <div
                    class="lineTransform"
                    :class="[isSelectedGiftCard(giftCard.id) ? 'bg-white' : 'bg-red-500']"
                  ></div>
                  <template>
                    <Icon
                      name="GiftRibbon"
                      class="ribbonStyle"
                      :class="[isSelectedGiftCard(giftCard.id) && 'fillNone ribbonActive']"
                    />
                    <Icon
                      name="Triangle"
                      class="triangleStyle"
                      :class="[isSelectedGiftCard(giftCard.id) && 'fillNone triangleActive']"
                    />
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="flex justify-center">
            <ButtonLink
              @click="onGiftCardClick()"
              :href="$routes.customise_gifts({ campaignId: selectedGiftCard.id })"
              variant="red"
            >
              GIVE A GIFT CARD
            </ButtonLink>

            <div
              v-if="activeSale"
              class="w-24 px-2 flex flex-col justify-center items-center bg-white"
            >
              <div class="flex flex-row items-center">
                <span class="line-through text-xs mr-2">
                  {{ calculateGeoMonetize(selectedGiftCard.unformattedPrice) }}
                </span>

                <span class="text-base text-red-500 font-medium">
                  {{
                    calculateGeoMonetize(calculateSaleReduction(selectedGiftCard.unformattedPrice))
                  }}
                </span>
              </div>

              <div class="text-xs text-red-800 uppercase font-normal text-center -mt-1">
                <span
                  >Save
                  {{
                    calculateGeoMonetize(calculateDiscount(selectedGiftCard.unformattedPrice))
                  }}</span
                >
              </div>
            </div>

            <!-- no sale -->
            <div v-else class="w-20 px-2 flex justify-center items-center bg-white">
              <p
                :data-goptimize-course-price="selectedGiftCard.unformattedPrice"
                class="heading--medium text-red-500"
              >
                {{ calculateGeoMonetize(selectedGiftCard.unformattedPrice) }}
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-white w-full">
      <div class="container padding-lr">
        <div class="flex flex-col py-0 lg:py-11 gap-8 lg:gap-12">
          <h1
            data-testid="gifts-title"
            class="text-3xl text-center leading-9 lg:text-4xl text-blue-800 font-serif font-medium"
          >
            How gifting works
          </h1>
          <div
            class="flex flex-col md:flex-row items-center flex-wrap justify-center gap-6 lg:gap-8"
          >
            <div class="flex items-center justify-center flex-1 flex-col gap-1 lg:gap-2">
              <Icon name="Star" class="text-blue-800 wh-10" />
              <p
                class="mb-0 lg:mb-1 font-serif text-2xl lg:text-3xl font-medium text-center text-blue-800"
              >
                Select your gift
              </p>
              <p
                class="uppercase text-sm lg:text-lg font-normal tracking-wider text-center text-neutral-500 w-80 max-w-full"
              >
                Give 1 year of subscription or a set gift card AMOUNT
              </p>
            </div>
            <div class="flex items-center justify-center flex-1 flex-col gap-1 lg:gap-2">
              <Icon name="Star" class="text-blue-800 wh-10" />
              <p
                class="mb-0 lg:mb-1 font-serif text-2xl lg:text-3xl font-medium text-center text-blue-800"
              >
                Choose who receives it
              </p>
              <p
                class="uppercase text-sm lg:text-lg font-normal tracking-wider text-center text-neutral-500 w-80 max-w-full"
              >
                Email the gift directly to your recipient or to yourself
              </p>
            </div>
            <div class="flex items-center justify-center flex-1 flex-col gap-1 lg:gap-2">
              <Icon name="Star" class="text-blue-800 wh-10" />
              <p
                class="mb-0 lg:mb-1 font-serif text-2xl lg:text-3xl font-medium text-center text-blue-800"
              >
                Use the code to purchase
              </p>
              <p
                class="uppercase text-sm lg:text-lg font-normal tracking-wider text-center text-neutral-500 w-80 max-w-full"
              >
                Select subscription or a course and start learning
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="bg-blue-300">
      <div class="container lg:py-0">
        <div class="flex flex-col lg:flex-row items-stretch bg-blue-300">
          <!-- Image Block - First for smaller screens, remains on the right for lg and above -->
          <div
            class="cat-image w-full lg:w-1/2 bg-opacity-20 banner-image order-last lg:order-first"
          >
            <img
              loading="lazy"
              :id="`image-gifts-section`"
              alt="section-image"
              :src="imageUrl('autumn_gift.webp')"
              class="w-full h-96 lg:h-full object-cover object-center"
            />
          </div>

          <!-- Text Content - Second for smaller screens, remains on the left for lg and above -->
          <div
            class="banner-content flex flex-col justify-center text-left py-0 lg:py-12 w-full lg:w-1/2 px-0 lg:px-16 mb-6 lg:mb-0"
          >
            <div
              class="mt-4 text-blue-500 title-gigantic transform rotate-180 text-right tracking-tighter mb-0"
            >
              ,,
            </div>
            <div
              class="-mt-7 lg:-mt-6 text-2xl font-serif lg:text-3xl font-medium text-blue-800 mb-4"
            >
              Such great value because you come out with loads of inspiring projects to get you
              started and actionable advice.
            </div>
            <p class="text-blue-500 text-base font-normal tracking-wider uppercase">- Emma, UK</p>
          </div>
        </div>
      </div>
    </div>

    <div class="container padding-lr">
      <div class="w-full py-0 lg:py-11">
        <SerifHeader
          :title="$I18n.t('faqs_title', i18nScope)"
          color="black"
          content-align="center"
        />

        <Faq :gtmPageName="currentPage" :questions="questions" class="max-w-5xl mx-auto" />
      </div>
    </div>
  </div>
</template>

<script>
  import { metaTags } from '@/mixins/metaTags'
  import gtm from '@/mixins/gtm'
  import mixpanel from '@/mixins/mixpanel'
  import monetize from '@/mixins/monetize'
  import statuses from '@/mixins/statuses'
  import cdn from '@/mixins/cdn'

  import ButtonLink from '@/Components/Shared/ButtonLink'
  import Icon from '@/Components/Shared/Icon'
  import Divider from '@/Components/Shared/Divider'
  import Faq from '@/Components/Shared/Faq/Index'
  import MetaTags from '@/Components/Shared/MetaTags'
  import SerifHeader from '@/Components/Shared/SectionHeader/Serif'

  export default {
    mixins: [gtm, metaTags, mixpanel, monetize, statuses, cdn],

    components: {
      ButtonLink,
      Divider,
      Faq,
      MetaTags,
      SerifHeader,
      Icon
    },

    data() {
      return {
        selectedGiftCard: this.giftCards[this.giftCards.length - 1]
      }
    },

    props: {
      geoPrice: {
        type: Object,
        required: true
      },
      activeSale: {
        type: Object,
        required: false
      },
      categories: {
        type: Array,
        default: () => []
      },
      membershipCampaign: {
        type: Object,
        required: true,
        default: () => ({
          id: null,
          name: '',
          unformattedPrice: 0
        })
      },
      giftCards: {
        type: Array,
        default: () => []
      },
      section: {
        type: Object,
        default: () => ({})
      },
      questions: {
        type: Array,
        default: () => [
          {
            q: 'HOW DOES THE RECIPIENT REDEEM THEIR GIFT?',
            a: 'Upon purchase, we’ll email a voucher code and instructions to the recipient (or to you if you select this option). If you’ve purchased a digital gift card, they can simply visit createacademy.com, select the course they’d like to purchase, and enter their code on checkout to apply their gift card amount to their purchase. If you’ve gifted a subscription, we’ll send them the link to our subscription checkout, where they’ll apply their code and create an account.'
          },
          {
            q: 'WHAT COURSE CAN I BUY WITH A DIGITAL GIFT CARD?',
            a: 'If you purchase a digital gift card, the amount selected can be used against the purchase of any course. Once the recipient has the voucher code, they can choose which course to use it on. If they so choose, they can also use the digital gift card amount against the purchase of a subscription.'
          },
          {
            q: 'CAN I CHOOSE WHO WILL RECEIVE THE GIFT EMAIL?',
            a: 'Yes! When purchasing a gift you will be asked if you want to receive the gift code and instructions to your email, or for the code to be emailed directly to your recipient.'
          },
          {
            q: 'WHEN WILL MY GIFT CODE EXPIRE?',
            a: 'Our gift vouchers are valid for 12 months from the date of purchase.'
          },
          {
            q: 'HOW LONG WILL THE RECIPIENT HAVE ACCESS TO THE COURSE(S)?',
            a: 'If your recipient uses their digital gift card to purchase an individual course, they’ll have lifetime access to that course. If they use their digital gift card amount against the purchase of a subscription then they’ll enjoy access to the courses until they choose to cancel. If you’ve gifted a subscription, they’ll enjoy access for one year, after which time their subscription will end.'
          }
        ]
      }
    },

    computed: {
      subscriptionGiftHeading() {
        if (!this.membershipCampaign?.unformattedPrice) {
          return 'Annual Subscription'
        }

        return this.activeSale
          ? `Annual Subscription - Now for ${this.calculateGeoMonetize(
              this.calculateSaleReduction(this.membershipCampaign.unformattedPrice)
            )}`
          : `Annual Subscription for ${this.calculateGeoMonetize(
              this.membershipCampaign.unformattedPrice
            )}`
      },
      currentPage() {
        return 'Gifts'
      },
      gtmSectionGifts() {
        return 'Choose your gift amount'
      },
      gtmSectionExploreCategories() {
        return 'Explore our Categories'
      }
    },

    methods: {
      scrollToSubscriptionSection() {
        const subscriptionSection = this.$refs.subscriptionSection
        // subscriptionSection.style.height =
        //   this.$refs.subscriptionSection.style.height + 60 + 'px'
        subscriptionSection.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start'
        })
      },

      scrollToGiftCardSection() {
        const giftCardSection = this.$refs.giftCardSection
        // giftCardSection.style.height =
        //   this.$refs.giftCardSection.style.height + 60 + 'px'
        giftCardSection.scrollIntoView({
          behavior: 'smooth',
          block: 'center',
          inline: 'start'
        })
      },

      selectGiftCard(giftCard) {
        this.selectedGiftCard = giftCard
      },

      isSelectedGiftCard(giftCardId) {
        return this.selectedGiftCard.id === giftCardId
      },

      calculateGeoMonetize(price) {
        const decimalPlaces = this.geoPrice.currency === 'USD' ? 0 : 2

        return this.geoMonetize(
          price * this.geoPrice.exchangeRate,
          this.geoPrice.currencySymbol,
          decimalPlaces
        ).replace(/\.00$/, '')
      },

      calculateSaleReduction(price) {
        if (!price) return 0
        return price - this.calculateDiscount(price)
      },

      calculateDiscount(price) {
        if (!price) return 0
        if (this.activeSale) {
          return price * (this.activeSale.discountValue / 100.0)
        }
        return 0
      },

      getUserType() {
        return this.currentUser ? 'authed' : 'anonymous'
      },

      onGiftSubscriptionClick() {
        if (!this.membershipCampaign) return

        this.trackEvent('gift_membership', {
          campaignId: this.membershipCampaign.id,
          campaignName: this.membershipCampaign.name,
          campaignPrice: this.membershipCampaign.unformattedPrice,
          sale: this.calculateSaleReduction(this.membershipCampaign.unformattedPrice),
          userType: this.getUserType()
        })
      },

      onGiftCardClick() {
        this.trackEvent('gift_voucher', {
          campaignId: this.selectedGiftCard.id,
          campaignName: this.selectedGiftCard.name,
          campaignPrice: this.selectedGiftCard.unformattedPrice,
          sale: this.calculateSaleReduction(this.selectedGiftCard.unformattedPrice),
          userType: this.getUserType()
        })
      }
    },

    // mounted() {
    //   // Listen for the custom event to scroll to the subscription section
    //   this.$root.$on('scrollToSubscriptionSection', this.scrollToSubscriptionSection)
    // },

    created() {
      this.i18nScope = { scope: 'pages.gifts' }
    }
  }
</script>

<style scoped>
.gift-text {
  letter-spacing: 0.1875rem;
}

.gift-text.underlineBlue100::before {
  left: 0;
  width: 100%;
}
</style>
