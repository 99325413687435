<template>
  <div>
    <slot :name="getSlotName()"></slot>
  </div>
</template>

<script>
export default {
  props: {
    testName: {
      type: String,
      required: true
    },
    variantId: {
      type: [String, Number],
      required: false,
      default: null
    }
  },
  mounted() {},
  methods: {
    getSlotName() {
      // If variantId is defined and a corresponding slot exists, use that variantId
      if (this.variantId !== null && this.$slots[this.variantId.toString()]) {
        return this.variantId.toString()
      }
      // Otherwise, fall back to the default slot
      return 'default'
    },
    logAbTestEvent(eventName, eventData) {
      
      // Implementation for logging to Mixpanel
    }
  },
  computed: {
    isActive() {
      return this.variantId !== null
    }
  }
}
</script>