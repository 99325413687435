<template >
  <div
    class="absolute top-10 -left-4 p-4 bg-blue-800 shadow-sm z-50"
    :class="isCategoriesDropdown ? 'block' : 'hidden'"
  >
    <CategoriesList :categories="categories" />
  </div>
</template>

<script>
import CategoriesList from '@/Components/Shared/Objects/Navbar/CategoriesList'

export default {
  components: {
    CategoriesList
  },

  props: {
    categories: Array,

    isCategoriesDropdown: {
      type: Boolean,
      required: true
    }
  }
}
</script>
