<template>
  <div class="py-8">
    <h2 class="w-full flex-grow overline--medium text-yellow-800">Payment Details</h2>

    <div class="mt-4 grid grid-cols-2 gap-y-1">
      <div class="inline-block w-40 font-medium">Card Number</div>
      <div class="text-right">•••• {{ paymentMethod.card.last4 }}</div>

      <div class="inline-block w-40 font-medium">Expires</div>
      <div class="text-right">
        {{ paymentMethod.card.exp_month }} /
        {{ paymentMethod.card.exp_year }}
      </div>

      <div class="inline-block w-40 font-medium">Type</div>
      <div class="text-right capitalize">
        {{ paymentMethod.card.display_brand }}
      </div>
    </div>

    <div class="mt-4">
      <Button
        data-testid="membership-cancel-button"
        variant="blue"
        @click="updatePaymentDetails"
        :loading="processing"
        >Update Payment Details</Button
      >
    </div>
  </div>
</template>

<script>
  import axios from 'axios'

  import Button from '@/Components/Shared/Button'

  export default {
    components: {
      Button
    },

    props: {
      paymentMethod: {
        type: Object,
        required: true
      }
    },

    data() {
      return {
        processing: false
      }
    },

    methods: {
      async updatePaymentDetails() {
        try {
          this.processing = true

          const response = await axios.post('/membership/update_payment_details')

          if (response.status == 200) {
            window.location.href = response.data.url
          } else {
            console.error('Failed to fetch')
            this.processing = false
          }
        } catch (error) {
          console.error('Error fetching:', error)
          this.processing = false
        }
      }
    },

    created() {
      this.i18nScope = { scope: 'pages.users.purchases' }
    }
  }
</script>
