<template>
  <div
    data-testid="cart-drawer-strip"
    class="w-full pt-6 border-t border-blue-800 border-opacity-20"
  >
    <div class="flex flex-col p-5" :class="[backgroundColor, textColor]">
      <p
        data-testid="cart-drawer-strip-title"
        class="text-blue-800 text-lg font-normal tracking-wider leading-[29px]"
        style="font-family: 'Brandon Grotesque'; font-weight: 420; letter-spacing: 2px"
      >
        {{ title }}
      </p>
      <p
        data-testid="cart-drawer-strip-text"
        class="text-blue-800 text-lg font-light leading-[29px]"
        style="font-family: 'Brandon Grotesque'; font-weight: 330; letter-spacing: 1px"
      >
        {{ text }}
      </p>
      <inertia-link v-if="highlightedText" href="/get-started" @click="trackGetStartedClick">
        <p
          data-testid="cart-drawer-strip-highlightedText"
          class="leading-[29px] mt-4 inline-block"
          style="
            color: var(--yellow-800, #9e6c21);
            font-family: 'Brandon Grotesque';
            font-size: 15px;
            font-weight: 420;
            letter-spacing: 3px;
            border-bottom: 1px solid currentColor;
            padding-bottom: 2px;
          "
        >
          {{ highlightedText }}
        </p>
      </inertia-link>
    </div>
  </div>
</template>

<script>
  import { InertiaLink } from '@inertiajs/inertia-vue'
  import mixpanel from '@/mixins/mixpanel'

  export default {
    components: {
      InertiaLink
    },
    mixins: [mixpanel],
    props: {
      cartDrawer: {
        type: Object,
        required: true
      },
      courseCount: {
        type: Number,
        required: true
      }
    },

    methods: {
      trackGetStartedClick() {
        this.trackEvent('Cart Strip Get Started Click', {
          'Course Count': this.courseCount,
          'Multiple Courses': this.multipleCourses,
          'Banner Title': this.title,
          'Banner Text': this.text,
          'Highlighted Text': this.highlightedText
        })
      }
    },

    computed: {
      bannerStrip() {
        return this.cartDrawer.bannerStrip
      },

      backgroundColor() {
        return `bg-${this.bannerStrip.backgroundColor}`
      },

      textColor() {
        return `text-${this.bannerStrip.textColor}`
      },

      multipleCourses() {
        return this.courseCount > 1
      },

      // Different content based on cart size
      title() {
        if (this.multipleCourses && this.cartDrawer.titleMultipleCourses)
          return this.cartDrawer.titleMultipleCourses

        return this.bannerStrip.title
      },
      text() {
        if (this.multipleCourses && this.cartDrawer.textMultipleCourses)
          return this.cartDrawer.textMultipleCourses

        return this.bannerStrip.text
      },
      highlightedText() {
        if (this.multipleCourses && this.cartDrawer.highlightedTextMultipleCourses)
          return this.cartDrawer.highlightedTextMultipleCourses

        return this.bannerStrip.highlightedText
      }
    }
  }
</script>
