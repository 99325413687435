<template>
  <Link :data-testid="testid" :href="$routes.root()" :aria-label="$I18n.t('link_labels.go_to_homepage', i18nScope)">
    <template v-if="!currentUser">
      <Logotype :class="logoStructure" />
    </template>
    <template v-else>
      <Monogram :class="logoStructure" />
    </template>
  </Link>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue'
import Monogram from '@/Components/Shared/Monogram'
import Logotype from '@/Components/Shared/Logotype'
import currentUser from '@/mixins/currentUser'

export default {
  mixins: [currentUser],

  components: {
    Link,
    Monogram,
    Logotype
  },

  props: {
    testid: String
  },

  created() {
    this.i18nScope = { scope: 'pages.navbar' }
  },

  computed: {
    logoStructure() {
      return [
        'h-7 lg:h-10 w-auto text-blue-800'
      ]
    }
  }
}
</script>
