<template>
  <div data-testid="pressReference">
    <a :data-testid="`pressReference-${pressReference.id}-link`" :href="pressReference.url" @click="onLinkClick" target="_blank" rel="noopener" class="flex justify-center align-center">
      <img loading="lazy" :data-testid="`pressReference-${pressReference.id}-image`" :src="pressReference.image" :alt="$I18n.t('press-reference', i18nScope)" width="170" height="64"  class="w-auto h-full max-h-16">
    </a>
  </div>
</template>

<script>
import gtm from '@/mixins/gtm'

export default {
  mixins: [
    gtm
  ],

  props: {
    pressReference: {
      type: Object,
      required: true
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.home' }
  },

  methods: {
    onLinkClick() {
      this.gtmAddSocialProofInteraction(this.pressReference.name, this.pressReference.url)
    }
  }
}
</script>
