<template>
  <div class="flex flex-row justify-between">
    <div class="flex-col">
      <p class="text-lg font-normal">{{ $I18n.t('breakdown.grand_total', i18nScope) }}</p>
      <p class="text-xs">{{ $I18n.t('breakdown.billed_today', i18nScope) }}</p>
    </div>

    <!-- <p data-goptimize-grandtotal data-testid="breakdown-orderable-grand-total" class="text-lg font-normal">{{ monetizedAmount }}</p> -->
    <p data-goptimize-grandtotal data-testid="breakdown-orderable-grand-total" class="text-lg font-normal">{{ geoMonetize(grandTotal, geoPrice.currencySymbol, 2) }}</p>
  </div>
</template>


<script>
import monetize from '@/mixins/monetize'

export default {
  mixins: [
    monetize
  ],

  props: {
    geoPrice: {
      type: Object,
      required: true
    },

    discount: {
      type: Number,
      default: 0
    },

    amount: {
      type: Number,
      required: false
    },

    humanizedAmount: {
      type: String,
      required: false
    },

    i18nScope: {
      type: Object,
      required: true
    }
  },

  computed: {
    grandTotal() {
      return (this.geoPrice.price - this.discount) / 100
    },  

    monetizedAmount() {
      return this.humanizedAmount || this.monetize(this.amount)
    }
  },

  created() {
    this.paymentServices = require('@/assets/images/PaymentServices.svg')
  }
}
</script>
