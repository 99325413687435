<template>
  <div  class="relative  mb-3 px-0 md:px-3 w-full lg:w-1/2">
    <div>
      <div class="relative w-full overflow-hidden mb-4 h-44">
        <div class="h-full text-blue-800 bg-gray-400">
          <img loading="lazy"
            :data-testid="`onboarding-course-${course.id}-image`"
            :data-src="course.image"
            :alt="`${course.name}`"
            class="lazyload image-cover"
          />
        </div>
      </div>
      <div>
        <div class="flex flex-row no-wrap justify-between">
          <h2  class=" text-2xl font-medium font-serif text-blue-800 mb-1">
            <div class="text-sm text-grayscale-700 font-normal tracking-wide uppercase mb-1 font-sans">{{ course.teacher.name }}</div>
            {{ course.name }}
          </h2>
        </div>
        <div class="text-red-500 text-xs leading-5 tracking-wide font-medium flex flex-row uppercase">
          <div>{{ course.lessons_count }} lessons</div>
          <div class="mx-1">/</div>
          <div>{{ course.readable_duration }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Layout from '@/Layouts/ReducedLayout'
import authForm from '@/mixins/authForm'

export default {
  mixins: [
    authForm
  ],

  layout: Layout,

  props: {
    course: {
      type: Object,
      required: true
    }
  }
}
</script>
