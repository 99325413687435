<template>
  <form
    @submit.prevent="performSearch"
    class="w-full flex items-center justify-between bg-blue-100 rounded-full overflow-hidden mb-6"
  >
    <button type="submit" class="w-12 h-12 outline-none border-0 focus:outline-none">
      <Icon name="search" class="text-lg textGrayscale" />
    </button>
    <input
      @input="handleChange($event.target.value)"
      v-model="localQuery"
      type="text"
      placeholder="Search lessons..."
      class="px-4 pl-2 h-12 flex-1 w-full bg-blue-100 rounded-full focus:border-0 focus:outline-none"
    />
  </form>
</template>

<script>
import Icon from '@/Components/Shared/Icon.vue'

export default {
  data() {
    return {
      localQuery:''
    }
  },
  props: {
    query: {
      type: String
    }

  },
  mounted() {
    this.localQuery = this.query
  },
  methods: {
    performSearch() {
      this.$emit('search', this.localQuery)
    },
    handleChange(value) {
      this.localQuery = value.trimStart()
    }
  },
  components: { Icon }
}
</script>
