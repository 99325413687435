<template>
  <div class="py-8">
    <h2 class="w-full flex-grow overline--medium text-yellow-800">Billing Information</h2>

    <div class="mt-4">
      <div class="flex justify-between">
        <div class="font-medium">Name</div>
        <div class="text-right">{{ paymentMethod.billing_details.name }}</div>
      </div>

      <div class="flex justify-between">
        <div class="font-medium">Email</div>
        <div class="text-right">{{ paymentMethod.billing_details.email }}</div>
      </div>

      <div class="flex justify-between">
        <div class="font-medium">Phone</div>
        <div class="text-right">{{ paymentMethod.billing_details.phone || '&mdash;' }}</div>
      </div>

      <div class="flex justify-between">
        <div class="font-medium">Billing Address</div>
        <div class="text-right">
          <p>{{ paymentMethod.billing_details.address.line1 }}</p>
          <p>{{ paymentMethod.billing_details.address.line2 }}</p>
          <p>{{ address }}</p>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    props: {
      paymentMethod: {
        type: Object,
        required: true
      }
    },

    computed: {
      address() {
        const { line1, line2, ...rest } = this.paymentMethod.billing_details.address

        return [rest.city, rest.postal_code, rest.state, rest.country].filter(a => a).join(', ')
      }
    },

    created() {
      this.i18nScope = { scope: 'pages.users.purchases' }
    }
  }
</script>
