import UpsellOfferModal from '@/Components/Shared/Courses/UpsellOfferModal'

export default {
  components: {
    UpsellOfferModal
  },
  
  methods: {
    randomCourseCategoryHeaderImage(categories) {
      if (!categories || categories.length === 0) {
        return null
      }
      const randomIndex = Math.floor(Math.random() * categories.length)
      return categories[randomIndex]?.image_thumbnail_path
    },

    showUpsellOfferModal() {
      const dontShowUpsellOfferModalAgain = localStorage.getItem('dontShowUpsellOfferModalAgain')
      if (dontShowUpsellOfferModalAgain !== 'true') {
        this.$refs.upsellModal.show()
      } else {
        this.handlePurchase()
      }
    },

    handlePurchase() {
      this.$refs.purchaseButton.purchaseWithoutUpsell()
    },

    handleAcceptUpsell() {
      this.$refs.subscribeButton.subscribe()
      this.$refs.upsellModal.hide()

      if(this?.trackEvent){
        this.trackEvent('accept_upsell', {
          page: window.location.pathname,
          component: this.gtmSectionName,
          course: this.course.name,
          teacher: this.course.teacher.fullName
        })
      }
    },

    handleDeclineUpsell() {
      this.$refs.upsellModal.hide()
      localStorage.setItem('dontShowUpsellOfferModalAgain', 'true')
      this.trackEvent('decline_upsell', {
        page: window.location.pathname,
        component: this.gtmSectionName,
        course: this.course.name,
        teacher: this.course.teacher.fullName
      })
      this.handlePurchase()
    }
  }
}
