export const gtmV4 = {
  methods: {
    /********************************************
     * Events builders                          *
     ********************************************/
    gtmV4AddToCart(context, product) {
      return {
        event: 'add_to_cart',
        context: context,
        ecommerce: {
          items: [this.gtmV4BuildProduct(product)]
        }
      }
    },

    gtmV4RemoveFromCart(context, cartItem) {
      const eventContext = context === 'Navbar' ? 'mini basket' : 'basket page'

      return {
        event: 'remove_from_cart',
        context: eventContext,
        ecommerce: {
          items: [this.gtmV4GetCartItem(cartItem)]
        }
      }
    },

    gtmV4AddViewCart(cart, cartType) {
      return {
        event: 'view_cart',
        cart_type: cartType,
        ecommerce: {
          items: this.gtmV4GetCartItems(cart)
        }
      }
    },

    gtmV4CheckoutBegin(cart) {
      return {
        event: 'begin_checkout',
        ecommerce: {
          items: this.gtmV4GetCartItems(cart)
        }
      }
    },

    gtmV4PaymentInfo(cart, paymentType) {
      return {
        event: 'add_payment_info',
        ecommerce: {
          payment_type: paymentType,
          items: this.gtmV4GetCartItems(cart)
        }
      }
    },

    gtmV4PurchaseStep(cart, order, voucherPayload) {
      const { cartCoupons, products } = this.gtmGetVoucherData(voucherPayload)

      const totalDiscount = order.voucher_discount / 100.0

      return {
        event: 'purchase',
        ecommerce: {
          currency: 'GBP',
          value: order.unformatted_amount || 0.0,
          tax: order.vat,
          shipping: 0.00,
          affiliation: 'Create Academy Website',
          transaction_id: order.id,
          coupon: cartCoupons.join(),
          newcustomer: order.new_customer ? 'yes' : 'no',
          isdiscounted: totalDiscount > 0 ? 'yes' : 'no',
          totaldiscount: totalDiscount,
          items: this.gtmV4GetCartItems(cart, products)
        }
      }
    },

    gtmV4ProductListingImpressions(page, sections) {
      return {
        event: 'view_item_list',
        ecommerce: {
          items: this.gtmV4BuildImpressionsWithSections(page, sections)
        }
      }
    },

    gtmV4ProductListingImpressionClick(page, section, impression, index) {
      return {
        event: 'select_item',
        ecommerce: {
          items: [this.gtmV4BuildImpression(page, section, impression, index)]
        }
      }
    },

    gtmV4ProductDetailView(product) {
      return {
        event: 'view_item',
        ecommerce: {
          items: [this.gtmV4BuildProduct(product)]
        }
      }
    },

    /********************************************
     * Objects builders                         *
     ********************************************/
     gtmV4BuildImpressionsWithSections(page, sections) {
      let result = []
      let index = 1

      if (sections) {
        sections.forEach((section) => {
          const impressions = section.impressions

          if (impressions) {
            impressions.forEach((impression) => {
              result.push(this.gtmV4BuildImpression(page, section.name, impression, index++))
            })
          }
        })
      }

      return result
    },

    gtmV4BuildImpression(page, section, impression, index) {
      const itemListName = `${page} | ${section}`
      const itemListId = `${page.toLowerCase().replace(/ /g, '_')}_${section.toLowerCase().replace(/ /g, '_')}`

      let impressionJson = {
        item_list_id: itemListId,
        item_list_name: itemListName,
        index: index
      }

      let productJson = this.gtmV4BuildProduct(impression)

      return { ...impressionJson, ...productJson }
    },

    gtmV4BuildProduct(product) {
      // Attributes common to courses and gifts
      let impressionJson = {
        item_name: product.name,
        price: product.unformatted_price,
        item_variant: '',
        quantity: 1
      }

      // Attributes specific to courses and gifts
      if (this.isCourse(product)) {
        impressionJson['item_id'] = product.id
        impressionJson['item_brand'] = product.teacher?.fullName

        // GA4 supports a maximum of 5 category leves, with the following format:
        // {
        //  item_category: 'CAT. LEVEL 1',
        //  item_category2: 'CAT. LEVEL 2',
        //  item_category3: 'CAT. LEVEL 3',
        //  item_category4: 'CAT. LEVEL 4',
        //  item_category5: 'CAT. LEVEL 5'
        // }
        // If there are more than 5 categories, we concatenate the remaining ones
        // in the item_category5 field
        const maxIndex = 4
        product.categories.every((category, index) => {
          if (index === 0) { // item_category
            impressionJson['item_category'] = category
          }
          else if (index > 0 && index < maxIndex) { // item_category2 to item_category4
            impressionJson[`item_category_${index + 1}`] = category
          } else { // item_category5
            impressionJson[`item_category_${index + 1}`] = product.categories.slice(index).join(',')
            return false
          }

          return true
        })

      } else if (this.isCampaign(product)) {
        impressionJson['item_id'] = `Gift-${product.id}`
        impressionJson['item_category'] = 'gift'
        impressionJson['item_brand'] = ''
      }

      return impressionJson
    },

    /********************************************
     * Getters                                  *
     ********************************************/
    gtmV4GetCartItems(cart, voucherValidation = {}) {
      let result = []

      cart.cartItems.forEach(cartItem => {
        const gtmCartItem = this.gtmV4GetCartItem(cartItem, voucherValidation)

        // If cartItem already exists in the array, increase quantity
        // Else, add to the array
        const index = result.findIndex(element => element.item_id === gtmCartItem.item_id)
        if (index > -1) {
          result[index].quantity++
        } else {
          result.push(gtmCartItem)
        }
      })

      return result
    },

    gtmV4GetCartItem(cartItem, voucherValidation = {}) {
      const product = cartItem.item

      const { amount, discounted, discountValue, coupons } = this.gtmGetPriceWithDiscountDetails(product, voucherValidation)

      let productJson = this.gtmV4BuildProduct(product, this.isCartItemCampaign(cartItem))
      productJson['price'] = amount
      productJson['item_coupon'] = coupons.join()
      productJson['discounted'] = discounted ? 'yes' : 'no'
      productJson['discountvalue'] = discountValue

      return productJson
    }
  }
}

export default gtmV4
