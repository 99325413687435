<template>
  <div>
    <h3 class="subheading--medium text-yellow-800 mb-6">{{ $I18n.t('title', i18nScope) }}</h3>

    <p data-testid="request-data-copy">
      {{ $I18n.t('description.body', i18nScope) }}
      <a data-testid="request-data-link" href="mailto:info@createacademy.com" class="font-normal">{{ $I18n.t('description.email', i18nScope) }}</a>.
    </p>

    <form v-if="false" @submit.prevent="submit" novalidate="true">
      <!-- The delete account was temporary removed by CA team request -->
      <Button
        data-testid="delete-account-button-submit"
        variant="danger"
        type="submit"
        size="small"
        class="mt-6"
        :loading="form.processing"
      >
        {{ $I18n.t('delete_account', i18nScope) }}
      </Button>
    </form>

  </div>
</template>

<script>
import Button from '@/Components/Shared/Button'

export default {
  created() {
    this.i18nScope = { scope: 'pages.users.profile.account_management' }
  },

  components: {
    Button
  },

  data() {
    return {
      form: this.$inertia.form()
    }
  },

  methods: {
    submit() {
      this.form.post(this.$routes.delete_user_users(), {
        preserveScroll: true,
        onBefore: () => confirm(this.$I18n.t('delete_account_confirm', this.i18nScope))
      })
    }
  }
}
</script>
