<template>
  <div data-testid="seasonal-banner" class="mt-6 mb-14 lg:mt-14">
    <!-- Not sure if it makes sense to be an <a> or a <ButtonLink> -->
    <div data-testid="seasonal-banner-cta" @click="onClick" class="cursor-pointer">
      <picture>
        <source media="(min-width: 1024px)" :srcset="seasonalBanner.imageDesktop">
        <source media="(min-width: 640px)" :srcset="seasonalBanner.imageTablet">

        <img loading="lazy" :src="seasonalBanner.imageMobile" data-testid="seasonal-banner-image" :alt="seasonalBanner.imageAltTag" class="w-full h-auto">
      </picture>
    </div>
  </div>
</template>

<script>
import mixpanel from '@/mixins/mixpanel'

export default {
  mixins: [
    mixpanel
  ],

  props: {
    seasonalBanner: {
      type: Object,
      required: true
    }
  },

  methods: {
    onClick() {
      // Tag seasonal banner click on mixpanel
      if (this.seasonalBanner?.mixpanel?.tag) {
        this.trackEvent(this.seasonalBanner.mixpanel.event, {
          tag: this.seasonalBanner.mixpanel.tag,
          page: window.location.pathname
        })
      }

      // Open the seasonal banner CTA
      window.open(this.seasonalBanner.cta, this.ctaWindowOpenParam)
    }
  },

  computed: {
    ctaWindowOpenParam() {
      return this.seasonalBanner.ctaOpenNewTab ? '_blank' : '_self'
    }
  }
}
</script>
