<template>
  <div>
    <MetaTags :metaTags="metaTags" />

    <div class="max-w-7xl mx-auto px-4 sm:px-6 lg:px-8 py-8">
      <BlogHeader :categories="categories" :currentCategory="currentCategoryId" />

      <div class="max-w-4xl mx-auto">
        <article>
          <header class="mb-8">
            <h1 class="font-serif text-4xl text-blue-800 font-medium mb-4">{{ post.title }}</h1>
            <div class="text-gray-600">
              {{ formatDate(post.published_at) }} | {{ post.reading_time }} min read
              <span v-if="post.post_category">
                |
                <Link
                  :href="`/blog/categories/${post.post_category.slug}`"
                  class="text-blue-600 hover:text-blue-800"
                >
                  {{ post.post_category.name }}
                </Link>
              </span>
            </div>
          </header>

          <div v-if="post.featured_image" class="mb-8">
            <img :src="post.featured_image" :alt="post.title" class="w-full rounded-lg shadow-lg" />
          </div>

          <div class="prose prose-lg max-w-none" v-html="post.content"></div>
        </article>
      </div>
    </div>
  </div>
</template>

<script>
  import moment from 'moment'
  import Layout from '@/Layouts/Layout'
  import MetaTags from '@/Components/Shared/MetaTags'
  import BlogHeader from '@/Components/Blog/Header'
  import { Link } from '@inertiajs/inertia-vue'

  export default {
    layout: Layout,

    components: {
      MetaTags,
      BlogHeader,
      Link
    },

    props: {
      post: {
        type: Object,
        required: true
      },
      categories: {
        type: Array,
        required: true
      },
      metaTags: {
        type: Object,
        required: true
      }
    },

    computed: {
      currentCategoryId() {
        return this.post.post_category ? this.post.post_category.id : null
      }
    },

    methods: {
      formatDate(date) {
        return moment(date).format('MMMM D, YYYY')
      }
    }
  }
</script>
