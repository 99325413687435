<template>
  <div>
    <MetaTags :metaTags="metaTags" />
    <Tabs />

    <div class="md:mt-14 container-sm">
      <h1 class="title-large text-blue-800 mb-8">{{ $I18n.t('title', i18nScope) }}</h1>

      <UpdatePasswordForm :withRandomPw="withRandomPw"/>
    </div>

  </div>
</template>

<script>
import { metaTags } from '@/mixins/metaTags'

import MetaTags from '@/Components/Shared/MetaTags'
import UpdatePasswordForm from '@/Components/User/UpdatePasswordForm'
import Tabs from '@/Components/User/Tabs'

export default {
  mixins: [metaTags],

  created() {
    this.i18nScope = { scope: 'pages.users.password' }
  },

  props: {
    withRandomPw: {
      type: Boolean,
      required: true
    }
  },

  components: {
    MetaTags,
    UpdatePasswordForm,
    Tabs
  }
}
</script>
