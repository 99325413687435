<template>
  <ImageWrapper background="sign-in">
    <MetaTags :metaTags="metaTags" />

    <SignInForm contentTextKey="free_lessons" />
  </ImageWrapper>
</template>

<script>
import { metaTags } from '@/mixins/metaTags'

import Layout from '@/Layouts/ReducedLayout'

import MetaTags from '@/Components/Shared/MetaTags'
import ImageWrapper from '@/Components/Shared/Objects/ImageWrapper'
import SignInForm from '@/Components/Auth/SignInForm'

export default {
  layout: Layout,

  mixins: [metaTags],

  components: {
    MetaTags,
    ImageWrapper,
    SignInForm
  }
}
</script>
