<template>
  <div>
    <div class=" flex items-center justify-center h-full relative -right-1">
      <i
        @click="handleClick()"
        class="flex items-center justify-center cursor-pointer"
      >
        <Icon
          v-if="isBookmarked"
          :data-testid="''"
          name="BookMarked"
          class="text-2xl fillBlueColor"
        />
        <Icon
          v-else
          :data-testid="''"
          name="BookMark"
          class="text-2xl fill_none "
        />
      </i>
    </div>
    <div v-if="showModal" class="menu-dropdown z-60" @click.self="handleClose">
      <div class="modal-popup customModal" v-if="showModal">
        <div class="model-header relative">
          <div class="flex flex-col">
            <h4 class="text-lg font-medium text-left uppercase tracking-wider">Collections</h4>
            <p class="text-sm font-normal text-left">
              Organise your saved lessons here
            </p>
          </div>
          <div
            class="flex gap-x-1 items-center justify-center cursor-pointer group"
            @click="openDialog()"
          >
            <span class="relative">
              <Icon
                :data-testid="''"
                name="plus"
                class="text-sm text-yellow-800 relative -top-0.5"
              />
            </span>
            <p
              class="text-base font-normal  text-yellow-800 group-hover:underline"
            >
              New
            </p>
          </div>
        </div>
        <div
          class="flex gap-x-5 px-4 py-5 items-center justify-between cursor-pointer bg-gray-100 bg-opacity-50"
          @click="handleSelectCollections(handleUserCollectionDefault())"
        >
          <div class="flex items-center gap-x-2">
            <Icon name="CollectionLock" class="text-xs text-gray-600" />
            <p class="text-base font-medium ">
              {{ `${handleUserCollectionDefault().name}` }}
            </p>
          </div>
          <span
            class="w-4 h-4 rounded-full border border-grayscale-500 flex items-center justify-center"
            :class="{ ' bg-black': handleUserCollectionDefault().checked }"
          >
            <Icon
              v-show="handleUserCollectionDefault().checked"
              :data-testid="''"
              name="CollectionCheck"
              class="checkIcon fillNone"
            />
          </span>
        </div>
        <div class="modal-content relative">
          <div class="menu-scroll-content">
            <div
              class="flex gap-x-5 px-4 py-2 items-center justify-between cursor-pointer"
              @click="handleSelectCollections(collection)"
              v-for="collection in userCollections"
              :key="collection.id"
              v-show="collection.slug !== 'default_collection'"
            >
              <div class="flex items-center gap-x-2">
                <p class="text-base font-normal capitalize">
                  {{ `${collection.name}` }}
                </p>
              </div>
              <span
                class="w-4 h-4 rounded-full border border-grayscale-300 flex items-center justify-center"
                :class="{ ' bg-black': collection.checked }"
              >
                <Icon
                  v-show="collection.checked"
                  :data-testid="''"
                  name="CollectionCheck"
                  class="checkIcon fillNone"
                />
              </span>
            </div>
          </div>
          <div class="flex items-center justify-center gap-4 my-4">
            <button
              @click="handleClose"
              class="button--small button--blue text-white uppercase"
            >
              Done
            </button>
          </div>
          <template>
            <div
              v-if="userCollections.length < 1"
              class=" h-14 flex items-center justify-center w-full "
            >
              <p class="text-sm font-normal text-gray-700">
                Tip: You can create custom collections
              </p>
            </div>
          </template>
        </div>
      </div>
    </div>
    <dialog-box
      ref="dialogBox"
      :title="'Create a New Collection'"
      :description="'Organise your saved lessons into personalised collections'"
      :lessonId="lessonId"
      :edit="false"
      :fromLesson="fromLesson"
      :handleRefresh="handleRefresh"
    >
    </dialog-box>
  </div>
</template>

<script>
import Icon from '../Icon.vue'
import DialogBox from '@/Components/Shared/SavedLessons/DialogBox'
import { Inertia } from '@inertiajs/inertia'

import currentUser from '@/mixins/currentUser'
import signInModal from '@/mixins/signInModal'

export default {
  mixins: [
    currentUser,
    signInModal
  ],
  components: {
    Icon,
    DialogBox
  },
  data() {
    return {
      showModal: false,
      userCollections: []
    }
  },
  props: {
    lessonId: {
      type: Number,
      required: true
    },
    isBookmarked: {
      type: Boolean,
      required: true,
      default: false
    },
    fromLesson: {
      type:Boolean
    },
    handleRefresh:Function
  },
  methods: {
    closeModal() {
      this.showModal = false
    },
    openDialog() {
      this.$refs.dialogBox.open()
      this.closeModal()
    },
    handleButtonDisableAction() {
      let checked = this.userCollections.find(item => item.checked)
        ? false
        : true
      return checked
    },
    trackAddCampaign() {
      this.trackEvent('add_campaign_to_basket', {
        page: window.location.pathname
      })
    },
    handleSelectCollections(item) {
      this.userCollections.forEach((element, index) => {
        if (element.id == item.id) {
          this.userCollections[index]['checked'] = !item.checked
          if (item.checked) {
            this.handleAddLesson(item.id)
          } else {
            this.handleRemove(item.id)
          }
        }
      })
    },
    handleUserCollectionDefault() {
      if (this.userCollections?.length > 0) {
        return this.userCollections.find(
          item => item.slug === 'default_collection'
        )
      } else {
        return {}
      }
    },
    handleRemove(id) {
        const path = this.$routes.destroy_lesson_collection_user_collections()
        this.$inertia.delete(`${path}?id=${id}&lesson_id=${this.lessonId}`, {
          preserveScroll: true,
          onSuccess: () => { },
          onError: () => { }
        })

    },
    handleClick() {
      if (!this.currentUser) {
        this.openSignInModal(this.$page.url, 'free_lessons')
      } else {
        this.openModal(this.lessonId)
      }
    },
    openModal(id) {
      fetch(`/saved_lessons/getlesson_user_collections?id=${id}`)
        .then(res => res.json())
        .then(res => {
          this.userCollections = res
          if (!this.isBookmarked) {
            this.userCollections.forEach((item, index) => {
              if (item.slug == 'default_collection') {
                this.handleAddLesson(item.id)
                this.userCollections[index]['checked'] = true
                this.showModal = true
              }
            })
          } else {
            this.showModal = true
          }
        })
    },
    handleClose() {
      if (!this.fromLesson) {
        this.$inertia.visit(window.location.href, { method: 'GET' })
      } else {
        this.handleRefresh()
      }
      this.closeModal()
    },
    handleAddLesson(id) {
        const path = this.$routes.add_lesson_to_collection_user_collections()
        Inertia.post(
          `${path}`,
          {
            lesson_id: this.lessonId,
            user_collection_id: id
          },
          {
            onSuccess: () => {}
          }
        )
    }
  }
}
</script>
