<template>
  <BaseLayout>
    <Navbar />

    <div class=" baseTop min-h-screen">
      <FlashMessages />

      <slot />
    </div>

    <Footer />
  </BaseLayout>
</template>

<script>
import FlashMessages from '@/Components/Shared/Objects/FlashMessages'
import Navbar from '@/Components/Shared/Objects/Navbar'
import Footer from '@/Components/Shared/Objects/Footer'
import BaseLayout from '@/Layouts/BaseLayout'

export default {
  components: {
    BaseLayout,
    FlashMessages,
    Navbar,
    Footer
  }
}
</script>
