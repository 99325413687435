<template>
  <form @submit.prevent="submit" novalidate="true">
    <div class="w-full">
      <h3 class="subheading--medium text-yellow-800 mb-4">{{ $I18n.t('general_notifications.title', i18nScope) }}</h3>
      <Checkbox
        testid="notifications_enabled"
        v-model="form.user.account_attributes.notifications_enabled"
        :errors="form.errors['account.notifications_enabled']"
      >
        {{ $I18n.t('general_notifications.description', i18nScope) }}
      </Checkbox>
      <p class="text-sm mb-8">{{ $I18n.t('general_notifications.disclaimer', i18nScope) }}</p>

      <template v-if="false">
        <h3 class="subheading--medium text-yellow-800 mb-4">{{ $I18n.t('comment_notifications.title', i18nScope) }}</h3>
        <Checkbox
          testid="comment_notifications_enabled"
          v-model="form.user.account_attributes.comment_notifications_enabled"
          :errors="form.errors['account.comment_notifications_enabled']"
        >
          {{ $I18n.t('comment_notifications.description', i18nScope) }}
        </Checkbox>
      </template>

      <Button
        data-testid="button-submit"
        :loading="form.processing"
        :variant="buttonVariant"
        class="mt-8"
      >
        {{ $I18n.t('submit.button', i18nScope) }}
      </Button>
    </div>
  </form>
</template>

<script>
import dirtyForm from '@/mixins/dirtyForm'
import confirmLeave from '@/mixins/confirmLeave'

import Button from '@/Components/Shared/Button'
import Checkbox from '@/Components/Shared/Forms/Checkbox'

export default {
  mixins: [
    dirtyForm('form.user'),
    confirmLeave
  ],

  created() {
    this.i18nScope = { scope: 'pages.users.notifications' }

    // Used by the confirmLeave mixin
    this.formSubmitPath = [this.$routes.update_notifications_users()]
  },

  components: {
    Button,
    Checkbox
  },

  props: {
    account: {
      type: Object,
      required: true
    }
  },

  data() {
    return {
      form: this.$inertia.form({
        user: {
          account_attributes: {
            notifications_enabled: this.account.notifications_enabled,
            comment_notifications_enabled: this.account.comment_notifications_enabled
          }
        }
      })
    }
  },

  computed: {
    buttonVariant() {
      return this.formDirty ? 'blue' : 'inactive'
    }
  },

  methods: {
    submit() {
      this.form.post(this.$routes.update_notifications_users(), {
        preserveScroll: true,
        onSuccess: () => {
          this.$nextTick(() => { this.formDirty = false })
        }
      })
    }
  }
}
</script>
