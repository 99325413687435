<template>
  <div class="navbar-desktop hidden lg:flex flex-row items-center ml-8">

    <!-- Categories Dropdown -->
    <!-- ** list of categories providing core course browsing experience -->
    <div class="relative" >
      <div
        data-testid="navbar-link-categories"
        @click="$emit('toggleCategoriesDropdown')"
        class="cursor-pointer px-4"
      >
        <p class="menu-link flex items-center" :class="{ 'underline' : isCategoriesDropdown }">
          {{ $I18n.t('courses_list', i18nScope)}}
          <Icon name="Chevron" class="text-sm ml-2" :class="{'transform rotate-180 transition-transform duration-300' : isCategoriesDropdown}" />
        </p>
      </div>


      <CategoriesMenu
        :categories="categories"
        :isCategoriesDropdown="isCategoriesDropdown"
      />
       <div class="menu-container" v-show="isCategoriesDropdown" @click.self="$emit('toggleCategoriesDropdown')" ></div>
    </div>

    <!-- Instructors -->
    <Link
      data-testid="navbar-link-gifts"
      :href="$routes.instructors()"
      class="menu-link px-4"
    >
      {{ $I18n.t('instructors', i18nScope)}}
    </Link>

    <!-- <Link
      v-if="!isSubscribed"
      data-testid="navbar-link-gifts"
      :href="$routes.membership_landing()"
      class="menu-link px-4"
      @click="mixpanelTrackEvent()"
    >
      {{ $I18n.t('subscription', i18nScope)}}
    </Link> -->
    <!-- Gifts -->
    <Link
      data-testid="navbar-link-gifts"
      :href="$routes.gifts()"
      class="menu-link px-4"
      @click="mixpanelTrackEvent()"
    >
      {{ $I18n.t('gifts', i18nScope)}}
    </Link>

    <!-- Seasonal Dropdown -->
    <div v-if="seasonalIsActive" class="relative">
      <div
        data-testid="navbar-link-seasonal"
        @click="$emit('toggleSeasonalDropdown')"
        class="cursor-pointer px-4"
      >
        <p class="menu-link flex items-center" :class="{ 'underline' : isSeasonalDropdown }">
          {{ $I18n.t('seasonal.title', i18nScope)}}
          <Icon name="Chevron" class="text-sm ml-2" :class="{'transform rotate-180 transition-transform duration-300' : isSeasonalDropdown}" />
        </p>
      </div>

      <SeasonalMenu
        :isSeasonalDropdown="isSeasonalDropdown"
      />
      <div class="menu-container" v-show="isSeasonalDropdown" @click.self="$emit('toggleSeasonalDropdown')" ></div>
    </div>

    <!-- Membership offer -->
    <a
      data-testid="navbar-link-membership-offer"
      v-if="showMembershipOffer"
      href="https://page.createacademy.com/membership"
      target="_blank"
      class="menu-link px-4"
    >
      {{ $I18n.t('membership_offer', i18nScope)}}
    </a>
  </div>
</template>

<script>
import currentUser from '@/mixins/currentUser'
import cookies from '@/mixins/cookies'
import mixpanel from '@/mixins/mixpanel'

import { Link } from '@inertiajs/inertia-vue'

import Icon from '@/Components/Shared/Icon'
import CategoriesMenu from '@/Components/Shared/Objects/Navbar/Desktop/CategoriesMenu'
import SeasonalMenu from '@/Components/Shared/Objects/Navbar/Desktop/SeasonalMenu'


export default {
  mixins: [
    currentUser,
    cookies,
    mixpanel
  ],

  components: {
    Link,
    Icon,
    CategoriesMenu,
    SeasonalMenu
  },

  props: {
    categories: Array,

    isCategoriesDropdown: {
      type: Boolean,
      required: true
    },

    isSeasonalDropdown: {
      type: Boolean,
      required: true
    },

    seasonalIsActive: {
      type: Boolean,
      required: true
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.navbar' }
  },

  methods: {
    getUserType() {
      return this.currentUser ? 'authed' : 'anonymous'
    },

    mixpanelTrackEvent() {
      this.trackEvent('main_menu_gift', {
        userType: this.getUserType()
      })
    }
  }
}
</script>
