<template>
  <div class="flex justify-center items-center py-6 sm:py-8">
    <img
      class="w-10/12 sm:w-11/12 max-w-lg object-contain mx-auto transition-transform duration-300 ease-in-out hover:scale-105 transform hover:-rotate-1"
      :src="imageUrl(isMembershipGift ? 'subscription-card.webp' : 'red-gift-card.webp')"
      :alt="isMembershipGift ? 'Subscription gift card' : 'Gift card'"
    />
  </div>
</template>

<script>
import useGiftType from './useGiftType'
import cdn from '@/mixins/cdn'

export default {
  mixins: [useGiftType, cdn],
  
  props: {
    campaign: {
      type: Object,
      required: true
    }
  }
}
</script> 