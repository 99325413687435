<template >
  <div
    class="absolute top-10 -right-32 p-4 bg-grayscale-100 shadow-sm"
    :class="isSeasonalDropdown ? 'block' : 'hidden'"
  >
    <SeasonalList screen="desktop" />
  </div>
</template>

<script>
import SeasonalList from '@/Components/Shared/Objects/Navbar/SeasonalList'

export default {
  components: {
    SeasonalList
  },

  props: {
    isSeasonalDropdown: {
      type: Boolean,
      required: true
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.navbar.seasonal' }
  }
}
</script>
