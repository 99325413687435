import Icon from '@/Components/Shared/Icon'

export default {
  components: {
    Icon
  },

  props: {
    /**
     * The color of the button
     * @values blue, red, green, yellow, danger, inactive, facebook, google
     */
    variant: {
      type: String,
      default: 'blue'
    },
    /**
     * The size of the button
     * @values normal, small
     */
    size: {
      type: String,
      default: ''
    },
    /**
     * The outline state of the button
     * @values true, false
     */
     outline: {
      type: Boolean,
      default: false
    },
    /**
     * The inverse state of the button
     * @values true, false
     */
    inverse: {
      type: Boolean,
      default: false
    },
    /**
     * The name of the icon you want to use. It will activate when has an icon
     * @values icon-name
     */
    icon: {
      type: String,
      default: null
    },
    /**
     * Ability for the button to ocupy the all parent
     * @values true, false
     */
    block: {
      type: Boolean,
      default: false
    },
    /**
     * When the button should behave like a block, and have a icon above the text
     * @values true, false
     */
     vertical: {
      type: Boolean,
      default: false
    },
    /**
     * When loading a spinner will appear during the timing of that state
     * @values true, false
     */
    loading: {
      type: Boolean,
      default: false
    },
    /**
     * When the style of the button is disabled
     * @values true, false
     */
    disabled: {
      type: Boolean,
      default: false
    }
  },

  computed: {
    classNames() {
      return [
        this.outline ? ('button--' + this.variant + '-outline') : ('button--' + this.variant),
        {['button--' + this.size] : this.size},
        {'button--inactive': this.loading },
        {'button--inactive': this.disabled },
        {'w-full': this.block },
        {'button--inverse': this.inverse },
        {'button--vertical': this.vertical }
      ]
    },
    buttonDisabled() {
      return this.loading || this.disabled
    }
  }
}
