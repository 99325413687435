<template>
  <button
    class="px-6 py-2.5 tracking-wide sm:tracking-widest bg-white rounded-full text-sm font-medium text-gray-700 hover:bg-gray-300 focus:outline-none cursor-default"
  >
    {{ text }}
  </button>
</template>

<script>
  export default {
    props: {
      text: {
        type: String,
        required: true
      }
    }
  }
</script>
