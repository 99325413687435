<template>
  <article class="bg-white">
    <div class="relative w-full overflow-hidden" style="padding-bottom: 69.07%">
      <img
        :src="image"
        :alt="title"
        class="absolute top-0 left-0 w-full h-full object-cover cursor-pointer"
        @click="$emit('lesson-selected', day)"
      />
      <div v-if="locked">
        <div class="absolute inset-0 locked-gradient"></div>
        <div class="absolute inset-0 flex flex-col items-center justify-center">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 118 118"
            fill="none"
            class="w-16 sm:w-14 md:w-16 mb-3"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M32.0177 35.2298C34.3221 20.4783 45.3423 9.7244 58.0167 9.7244C70.691 9.7244 81.7113 20.4783 84.0156 35.2298C76.7777 29.7805 67.7744 26.5503 58.0167 26.5503C48.2589 26.5503 39.2556 29.7805 32.0177 35.2298ZM26.7829 39.8761C27.282 20.8075 40.7544 4.91699 58.0167 4.91699C75.279 4.91699 88.7513 20.8075 89.2505 39.8761C96.7035 47.6488 101.283 58.1981 101.283 69.817C101.283 93.7125 81.9122 113.084 58.0167 113.084C34.1211 113.084 14.75 93.7125 14.75 69.817C14.75 58.1981 19.3299 47.6488 26.7829 39.8761ZM58.0167 108.276C79.2571 108.276 96.4759 91.0575 96.4759 69.817C96.4759 48.5765 79.2571 31.3577 58.0167 31.3577C36.7762 31.3577 19.5574 48.5765 19.5574 69.817C19.5574 91.0575 36.7762 108.276 58.0167 108.276ZM62.8241 68.5307C65.6979 66.8683 67.6315 63.761 67.6315 60.2022C67.6315 54.8921 63.3268 50.5874 58.0167 50.5874C52.7066 50.5874 48.4019 54.8921 48.4019 60.2022C48.4019 63.761 50.3354 66.8683 53.2093 68.5307V84.2392H62.8241V68.5307Z"
              fill="white"
            />
          </svg>
          <div class="font-sans text-red-100 available-text">
            Available from {{ formatDate(availableDate) }}
          </div>
        </div>
      </div>
    </div>
    <div class="pt-4">
      <div class="mb-2">
        <h2
          class="text-blue-800 font-sans uppercase cursor-pointer hover:underline hover:underline-offset-4"
          style="
            font-size: 1.25rem;
            font-weight: 500;
            line-height: 160%;
            letter-spacing: 0.1875rem;
          "
          @click="$emit('lesson-selected', day)"
        >
          DAY {{ day }}
        </h2>
        <h1
          class="text-blue-800 font-sans-serif mt-1 cursor-pointer hover:underline hover:underline-offset-4"
          style="
            font-size: 1.125rem;
            font-weight: 500;
            line-height: 162%;
            letter-spacing: 0.0225rem;
          "
          @click="$emit('lesson-selected', day)"
        >
          {{ title }}
          <br>
          <span class="font-serif italic">with</span> {{ instructor }}
        </h1>
        <h3
          class="text-yellow-800 mt-1 cursor-pointer hover:opacity-80"
          style="
            font-size: 0.875rem;
            font-weight: 400;
            line-height: 160%;
            letter-spacing: 0.0175rem;
            text-decoration-line: underline;
            text-underline-offset: 4px;
          "
          @click="toggleMaterials"
        >
          You will need
        </h3>
      </div>
      <p
        class="text-blue-800 transition-opacity duration-200"
        :class="{ 'opacity-0': !showMaterials }"
        style="
          font-size: 0.875rem;
          font-weight: 500;
          line-height: 160%;
          letter-spacing: 0.0175rem;
        "
      >
        {{ materials }}
      </p>
    </div>
  </article>
</template>

<script>
  import moment from 'moment'

  export default {
    props: {
      day: { type: Number, required: true },
      title: { type: String, required: true },
      instructor: { type: String, required: true },
      materials: { type: String, required: true },
      image: { type: String, required: true },
      locked: { type: Boolean, default: false },
      availableDate: { type: String, required: false }
    },

    emits: ['lesson-selected'],

    data() {
      return {
        showMaterials: false
      }
    },

    methods: {
      formatDate(date) {
        return moment(date).format('MMMM Do')
      },

      toggleMaterials() {
        this.showMaterials = !this.showMaterials
      }
    }
  }
</script>

<style scoped>
  .locked-gradient {
    background: linear-gradient(180deg, rgba(14, 30, 56, 0) -8.57%, rgba(14, 30, 56, 0.8) 67.84%);
  }

  .available-text {
    font-size: 18px;
    line-height: 170%;
    letter-spacing: 0.36px;
    font-weight: 400;
  }
</style>
