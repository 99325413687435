<template>
    <div class="bg-white lg:bg-neutral-100">
      <div class="container pt-0">
        <!-- <div class="flex flex-col mb-0 gap-1 lg:gap-3">
          <h2 class="text-3xl lg:text-4xl font-serif font-medium text-black text-center capitalize">The Reviews Are In!</h2>
          <p class="text-center text-base lg:text-xl text-black font-normal">What our community of creators has to say about our courses. </p>
        </div> -->
        <div class="testimonial-grid " ref="swiperContainer">
          <div class="swiper-wrapper items-stretch">
            <div
              class="swiper-slide h-auto"
              v-for="(testimonial, index) in testimonials"
              :key="index"
            >
              <div class="flex flex-col px-6 py-8 bg-white border border-blue-300 lg:border-transparent max-w-md h-full">
                <div class="mb-3 lg:mb-1">
                  <img width="140" height="24" src="/images/5stars.svg" alt="Five Star Rating" class="w-auto h-6" />
                </div>
                <p class="text-base lg:text-xl font-medium mb-3 text-black ">
                  {{ testimonial.title }}
                </p>
                <p class="text-sm lg:text-base font-normal mb-3 text-balance">
                  {{ testimonial.quote }}
                </p>
                <!-- <div class="hidden lg:block h-px w-16 border border-blue-800"></div> -->
                <p class="text-sm lg:text-xs mt-3 capitalize tracking-wide lg:uppercase font-medium  text-blue-800 lg:tracking-wider">
                  {{ testimonial.author }}
                </p>
              </div>
            </div>
          </div>
          <!-- Slide navigation -->
          <div class="flex items-center justify-between gap-3 mt-10 lg:mt-20">
            <div data-testid="review-button-prev" class="swiper-prev"><Icon name="SwiperLeft" class="cursor-pointer text-2xl" /></div>
            <div class="flex-1 flex justify-center items-center">
              <img width="227" height="74" class="w-auto h-14 lg:h-16" src="@/assets/images/landing/review-trustpilot.svg" alt="trustpilot rating" />
            </div>
            <div data-testid="review-button-next" class="swiper-next"><Icon name="SwiperRight" class="cursor-pointer text-2xl" /></div>
          </div>
          <!-- <div class="swiper-pagination"></div> -->
        </div>
      </div>
    </div>
  </template>

  <script>
  import { defineComponent, ref, onMounted, computed } from 'vue'
  import Swiper from 'swiper/bundle'
  import 'swiper/swiper-bundle.css'
  import Icon from '@/Components/Shared/Icon'

  export default defineComponent({
    name: 'ReviewSlider',
    components: {
      Icon
    },
    props: {
      isHideControls: {
        type: Boolean,
        default: false
      }
    },
    setup() {
      const swiperContainer = ref(null)

      const testimonials = computed(() => [
      {
          quote: '"Value for money with good courses Value for money with such a good choice of learning with the experts. Great for visual learners!"',
          author: 'LOUISE BRUNSDON, GBR',
          logoSrc: 'stars.svg',
          title: 'Value for money with good courses'
        },
        {
          quote: '“Sarah Raven\'s course is brilliant. Absolutely brilliant. It\'s remarkable that this lovely woman\'s life\'s work is accessible and so easy to follow.It\'s the first of the courses I\'m embarking on after purchasing the annual package. I\'m really pleased. Looking forward to my next course.”',
          author: 'GEORGIA, GBR',
          logoSrc: 'stars.svg',
          title: 'Sarah Raven\'s course is brilliant.'
        },
        {
          quote: '“The class with Tess Newall is everything one could want from this talented young woman. The quality is excellent however more close-ups from overhead needed as opposed to lovely angle (from the side) which makes it difficult to see the tiny detail.”',
          author: 'HELEN RUISI, US',
          logoSrc: 'stars.svg',
          title: 'The class with Tess Newall is…wonderful'
        },
        {

          quote: '"Wonderful content and variety. I signed up for the 1 year subscription a few days ago and I have already been inspired in so many ways. The videos are beautifully presented and I’m looking forward to working my way through them all!"',
          author: 'ANGELA, GBR',
          logoSrc: 'stars.svg',
          title: 'Wonderful content and variety'
        },
        {

          quote: '"I love Create Academy! There is so much talent and inspiration. The new membership feature makes it affordable for all and I love how the courses take me off of social media and teach me something, encouraging me to stop consuming and start creating!"',
          author: 'GWEN, US',
          logoSrc: 'stars.svg',
          title: 'I love Create Academy'
        },
        {

          quote: '"Amazing learning experience!!! I hope you will offer more classes like this. This course gave me more useful content than all of the decorating books I’ve ever bought, combined."',
          author: 'AMANDA, US',
          logoSrc: 'stars.svg',
          title: 'Amazing learning experience!!'
        }
      ])

      onMounted(() => {
        new Swiper(swiperContainer.value, {
          slidesPerView: 1,
          spaceBetween: 28,
          pagination: {
            el: '.swiper-pagination'
          },
          navigation: {
            nextEl: '.swiper-next',
            prevEl: '.swiper-prev'
          },
          autoplay: {
            delay: 5000,
            disableOnInteraction: false
          },
          breakpoints: {
            640: {
              slidesPerView: 2,
              spaceBetween: 20
            },
            1024: {
              slidesPerView: 3,
              spaceBetween: 20
            }
          }
        })
      })

      return {
        swiperContainer,
        testimonials
      }
    }
  })
  </script>

  <style scoped>
    .swiper-wrapper {
        align-items: stretch;
    }
    .swiper-wrapper .swiper-slide {
        height: auto !important;
    }
  </style>
