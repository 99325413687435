<template>
  <div>
    <MetaTags :metaTags="metaTags" />

    <div class="container-sm">
      <h1 data-testid="instructor-title" class="title-xl text-center text-grayscale-900 mb-10">
        {{ $I18n.t('instructor_title', i18nScope) }}
      </h1>
      <p class="text-lg text-center whitespace-pre-line mb-10">{{ $I18n.t('instructor_desc', i18nScope) }}</p>
      <div class="w-48 max-w-full h-px bg-grayscale-900 mx-auto"></div>
    </div>
    <div class="container">
      <div class="flex flex-wrap flex-row gap-y-8 justify-center">
        <template v-for="instructor in instructors">
          <div class="relative flex-grid-3" :key="instructor.id">
            <div class="flex flex-col items-center">
              <Link :data-testid="`${instructor.id}-link`" :href="$routes.instructor(instructor.slug)"
                class="flex flex-col items-center">
                <div class="w-64 h-64 sm:w-72 sm:h-72 md:w-60 md:h-60 xl:w-80 xl:h-80 rounded-full bg-grayscale-300 mb-8">
                  <img loading="lazy" :data-testid="`${instructor.id}-avatar`" :src="instructor.avatar"
                    :alt="$I18n.t('instructor_avatar', i18nScope)"
                    class="image-cover w-64 h-64 sm:w-72 sm:h-72 md:w-60 md:h-60 xl:w-80 xl:h-80 rounded-full" />
                </div>
                <span class="w-full block title-medium text-grayscale-900 text-center mb-0 hover:underline">
                  {{ instructor.name }}
                </span>
                <span class="text-sm font-normal tracking-wide uppercase text-grayscale-500 block text-center w-64 sm:w-72 md:w-60 xl:w-80">
                  {{ instructor.shortByline ? instructor.shortByline : truncateText(instructor.byline) }}
                </span>
              </Link>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>
<script>
import { Link } from '@inertiajs/inertia-vue'
import { metaTags } from '@/mixins/metaTags'

import MetaTags from '@/Components/Shared/MetaTags'

export default {
  mixins: [
    metaTags
  ],

  methods: {
    truncateText(text) {
      return text && text.length > 80 ? text.substring(0, 77) + '...' : text || ''
    }
  },
  components: {
    Link,
    MetaTags
  },

  props: {
    description: {
      type: String,
      default: ''
    },
    title: {
      type: String,
      default: ''
    },
    instructors: {
      type: Array,
      default: () => []
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.static' }
  }
}
</script>
