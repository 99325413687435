<template>
  <div
    class="flex min-h-screen p-4 bg-cover bg-center"
    :class="classObject"
  >
    <div class="w-full lg:w-6/12 max-w-xl m-auto">
      <div class=" p-8 sm:px-20 sm:py-10 bg-grayscale-100 rounded-md">
        <slot />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  /**
   * ImageWrapper component for Create Academy
   * @displayName ImageWrapper
   */
  name: 'ImageWrapper',

  props: {
    /**
     * The background image for the page
     * @values sign-up, sign-in, password, confirmation
     */
    background: {
      type: String,
      default: 'sign-up'
    }
  },
  computed: {
    classObject() {
      return {
        'bg-sign-up': this.background === 'sign-up',
        'bg-sign-in': this.background === 'sign-in',
        'bg-password': this.background === 'password',
        'bg-confirmation': this.background === 'confirmation',
        'bg-error': this.background === 'error',
        'bg-migration': this.background === 'migration',
        'bg-setup-account': this.background === 'setup-account'
      }
    }
  }
}
</script>

<docs>
  ### ✎ How to add a new image
  1. Make sure that the width of the image corresponds to 1400px
  2. Make sure you optimize the image, before adding it to the project (for instance, you can use tools like ImagOptim)
  3. Paste the image inside the images folder, with a name correspondent of where you will be using it
  4. Add the classObject to the ImageWrapper component with this new image
  5. Ready to use!

  ---

  ### ✎ ImageWrapper
  This wrapper allows us to have a good layout for more empty or form based pages.

  ```jsx
    <ImageWrapper background="confirmation">
      <div class="flex flex-col">
        <h1 class="title-large text-blue-800 mb-6 text-left">Your order is complete!</h1>
        <p class="text-lg mb-8">Once we receive your payment you’ll have access to the course. You will also be receiving a confirmation email with the order details</p>

        <Button>Back to my courses</Button>
      </div>
  </ImageWrapper>
  ```

</docs>
