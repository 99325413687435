<template>
  <div
    v-if="show && message"
    :data-testid="testId"
    :class="divClassObject"
    class="flash-message"
    role="alert"
  >
    <div class="flex-grow">
      <Icon :name="iconName" :class="iconColor" class="text-2xl mr-4"></Icon>
      <span class="text-lg text-center text-grayscale-900">{{ message }}</span>
    </div>

    <button data-testid="flash-message-close" @click="reset" class="pt-0 pb-1">
      <Icon name="close" class="text-inactive"></Icon>
    </button>
  </div>
</template>

<script>
import EventBus from '@/event-bus'
import Icon from '@/Components/Shared/Icon'

export default {
  components: {
    Icon
  },

  // If both flash success and alert messages are set,
  // give priority to the alert message
  beforeCreate() {
    const flash = this.$page.props.flash
    if (flash.success && flash.alert) {
      flash.success = null
    }
  },

  data() {
    return {
      show: true,
      resetting: false,
      timeoutFunc: null,
      flash: this.$page.props.flash
    }
  },
  methods: {
    timeoutFlash() {
      this.timeoutFunc = setTimeout(() => {
        if (this.show) this.reset()
      }, 5000)
    },
    reset() {
      this.resetting = true

      clearTimeout(this.timeoutFunc)
      this.show = false
      this.flash.success = ''
      this.flash.alert = ''

      this.$nextTick(() => {
        this.resetting = false
      })
    },
    capitalize(word) {
      return word.charAt(0).toUpperCase() + word.slice(1)
    },
    setFlashMessage(value) {
      this.flash = value
      if (this.resetting) return

      this.show = true
      this.timeoutFlash()
    }
  },
  created() {
    this.timeoutFlash()
  },
  mounted() {
    EventBus.$on('flashMessage', this.setFlashMessage)
  },
  beforeDestroy() {
    EventBus.$off('flashMessage', this.setFlashMessage)
  },
  watch: {
    '$page.props.flash': {
      handler(value) {
        this.setFlashMessage(value)
      },
      deep: true
    }
  },
  computed: {
    divClassObject() {
      return {
        'border-success': this.flash.success,
        'border-danger': this.flash.alert
      }
    },
    iconName() {
      if (this.flash.success) return 'Success'
      if (this.flash.alert) return 'Warning'
      return ''
    },
    iconColor() {
      return {
        'text-success': this.flash.success,
        'text-danger': this.flash.alert
      }
    },
    message() {
      if (this.flash.success) return this.flash.success
      if (this.flash.alert) return this.flash.alert

      return null
    },
    testId() {
      if (this.flash.success) return 'flash-success'
      if (this.flash.alert) return 'flash-alert'

      return ''
    }
  }
}
</script>

<docs>
  ### ✎ Flash messages

  The flash messages are all configured to be responsive to type of error they are presenting. If it's success or error, the icon and colors will automatically respond to it.

</docs>
