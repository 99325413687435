<template>
  <p :class="classObject">
    <slot />
  </p>
</template>

<script>
export default {
  props: {
    type: {
      type: String,
      default: null
    }
  },
  computed: {
    classObject() {
      return {
        'text-sm': this.type === 'small',
        'text-gray-400': this.type === 'faded'
      }
    }
  }
}
</script>

<docs>
  ### ✎ A simple note
  This component is usually used attachaed a `input-group` component like a `input` or `checkbox`.

  ```jsx
    <TextNote>As things move quickly and the design adapts every time we create a new High Fidelity Mockup for a new flow, it's important to keep avoiding legacy code and keep the style guide updated.</TextNote>
  ```

  ----

  ### ✎ A smaller note
  This component is usually used attachaed a `input-group` component like a `input` or `checkbox`.

  ```jsx
    <TextNote type="small">As things move quickly and the design adapts every time we create a new High Fidelity Mockup for a new flow, it's important to keep avoiding legacy code and keep the style guide updated.</TextNote>
  ```

  ----

  ### ✎ A faded note
  This component is usually used attachaed a `input-group` component like a `input` or `checkbox`.

  ```jsx
    <TextNote type="faded">As things move quickly and the design adapts every time we create a new High Fidelity Mockup for a new flow, it's important to keep avoiding legacy code and keep the style guide updated.</TextNote>
  ```
</docs>
