export const urlParam = {
  methods: {
    getUrlParam(param) {
      const urlParams = new URLSearchParams(window.location.search)
      return urlParams.get(param)
    }
  }
}

export default urlParam
