<template>
  <div class="input-group">
    <input
      :id="id"
      type="radio"
      class="input-radio"
      :value="option"
      :checked="option === value"
      @change="$emit('change', option)"
    />

    <label :for="id" :data-testid="`label-${testid}`" class="radio-label">
      <span>{{ label }}</span>
    </label>

    <InputError :testid="testid" :errors="errors" />
  </div>
</template>

<script>
import InputError from '@/Components/Shared/Forms/InputError'

/**
 * Radio component for Create Academy
 * @displayName Radio
 */
export default {
  name: 'Radio',

  model: {
    event: 'change'
  },

  components: {
    InputError
  },

  props: {
    id: {
      type: String,
      default() {
        return `radio-input-${this._uid}`
      }
    },
    option: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    label: {
      type: String,
      required: true
    },
    errors: {
      type: Array,
      default: () => []
    },
    testid: String
  }
}
</script>

<docs>
  ### ✎ Radio Button

  ```jsx
    <Radio>Hey there I'm a radio button</Radio>
  ```
  ---
</docs>
