<template>
  <div>
    <MetaTags :metaTags="metaTags" />
    <div class="container-sm">
      <p class="text-xl  whitespace-pre-line mb-14">Membership offer (B)</p>
    </div>
  </div>
</template>
<script>
import { metaTags } from '@/mixins/metaTags'

import MetaTags from '@/Components/Shared/MetaTags'

export default {
  mixins: [
    metaTags
  ],

  components: {
    MetaTags
  },

  created() {
    this.i18nScope = { scope: 'pages.static' }
  }
}
</script>
