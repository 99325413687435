<template>
  <div :class="['category-banner w-full flex flex-col lg:flex-row lg:items-stretch', backgroundColor]">
  
  <div class="banner-content w-full lg:w-1/2 flex flex-col justify-end py-12">
    <div class="mt-24 lg:mt-0 px-6 lg:px-16 lg:mb-12">
      <h1 class="banner__title text-white">{{ category.name }}</h1>
      <p class="-mt-2 text-xl text-gray-200">
        {{ category.description }}
      </p>
    </div>
  </div>

    <div class="cat-image w-full lg:w-1/2 bg-opacity-20 banner-image h-96 lg:h-auto">
      <img loading="lazy"
      data-testid="banner-image"
      :id="`banner-image-${category.slug}`"
      :alt="`${imageAlt}`"
      :src="!lazySlide ? bannerImage.url : null"
      :data-src="lazySlide ? bannerImage.url : null"
      :srcset="!lazySlide ? bannerImage.srcset : null"
      :data-srcset="lazySlide ? bannerImage.srcset : null"
      class="w-full h-full object-cover object-center"
      :class="classNames"
    />
    </div>

  </div>
</template>

<script>
import mixpanel from '@/mixins/mixpanel'
import imgSrcset from '@/mixins/imgSrcset'

export default {
  mixins: [
    mixpanel,
    imgSrcset
  ],

  components: {
  },

  props: {
    category: {
      type: Object,
      required: true
    },
    backgroundColor: {
      type: String,
      default: 'bg-gray-100'
    }  
  },

  created() {
  },

  computed: {
    bannerImage(){
      if (this.category.bannerImagePath) {
        return this.imgSrcsetBanner(this.category.bannerImagePath)
      } else {
        return { url: null, srcset: [] }
      }
    }
  },
  methods: {
    onLinkClick() {
      this.trackEvent('go_to_category_page', {
        page: window.location.pathname,
        component: this.gtmSectionName,
        category: this.category.name
      })
    }
  }
}
</script>
