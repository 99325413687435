<template>
  <div class="search_field anonSearchBar" :class="{ searchExpand : searchBar }">
    <div
      @click="$emit('open')"
      data-testid="navbar-search-link"
      class="search_field--icon "
      :class="{ searchIconClose: searchBar }"
    >
      <Icon name="Search" class=" text-white" />
    </div>
    <div :class="{ searchOpen: searchBar }" v-if="searchBar" class="search_field--block">
      <SearchGroup
        @click="$emit('close')"
        testid="search"
        placeholder="Search"
        ref="searchGroup"
        :focus="searchBar"
        :handleClose="handleClose"
        type="text"
        autocapitalize="off"
      />
    </div>
  </div>
</template>

<script>
import Icon from '@/Components/Shared/Icon'
import SearchGroup from '@/Components/Shared/Forms/SearchGroup'

export default {
  components: {
    Icon,
    SearchGroup
  },
  props: {
    searchBar: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClose() {
      this.$emit('close')
    }
  }
}
</script>
<style scoped>
.searchExpand {
  flex:1;
}
</style>
