<template>
  <div :class="[
    label ? 'divider--has-text' : 'divider',
    color && 'divider--' + color,
    height && 'divider--height-' + height,
    width && 'divider--width-' + width,
    spacing && 'divider--space-' + spacing
  ]">
    <template v-if="label">
      <span>{{ label }}</span>
    </template>
  </div>
</template>

<script>
  /**
   * Divider component for Create Academy
   * @displayName Divider
   */
  export default {
    name: 'Divider',

    props: {
      /**
       * The label that will be inserted in the middle of the divider
       */
      label: {
        type: String,
        default: null
      },
      /**
       * The color of the divider
       * @values light, dark, red, blue, yellow, green
       */
      color: {
        type: String,
        default: 'dark'
      },
      /**
       * The thickness of the divider
       * @values thick, regular
       */
       height: {
        type: String,
        default: ''
      },
      /**
       * The width of the divider (base style is full)
       * @values half, narrow
       */
      width: {
        type: String,
        default: ''
      },
      /**
       * The y space that divider ocuppies
       * @values xs, normal, lg, xl
       */
      spacing: {
        type: String,
        default: 'normal'
      }
    }
  }
</script>

<docs>
  ### ✎ Divider
  By default the divider ocuppies 3/5 of the full width

  ```jsx
    <Divider></Divider>
  ```

  ---

    ### ✎ Divider Dark

  ```jsx
    <Divider color="dark" block></Divider>
  ```

  ---

  ### ✎ Divider Full Width

  ```jsx
    <Divider block></Divider>
  ```

  ---

  ### ✎ Divider XS

  ```jsx
    <Divider small></Divider>
  ```

  ---

  ### ✎ Divider with text

  ```jsx
    <Divider label="or this option"></Divider>
  ```
</docs>
