export default {
  methods: {
    imageUrl(filename) {
      const { cdn_host } = this.$page.props

      // Just ensure the filename doesn't start with a slash
      const cleanFilename = filename.replace(/^\/+/, '')

      return `https://${cdn_host}/${cleanFilename}`
    }
  }
}
