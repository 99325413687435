<template>
  <div>
    <MetaTags :metaTags="metaTags" />

    <Form
      :orderable="orderable"
      :promotion="promotion"
      :stripePublishableKey="stripePublishableKey"
      :stripePaymentIntentId="stripePaymentIntentId"
      :stripePaymentIntentClientSecret="stripePaymentIntentClientSecret"
      :countries="countries"
      :gtmPageName="currentPage"
      :geoProps="geoProps"
    />
  </div>
</template>

<script>
import { metaTags } from '@/mixins/metaTags'
import cart from '@/mixins/cart'
import mixpanel from '@/mixins/mixpanel'

import Layout from '@/Layouts/ReducedLayout'

import MetaTags from '@/Components/Shared/MetaTags'
import gtm from '@/mixins/gtm'
import Form from '@/Components/Orders/Form'

export default {
  layout: Layout,

  mixins: [metaTags, gtm, cart, mixpanel],

  components: {
    MetaTags,
    Form
  },

  mounted() {
    this.$once(
      'hook:destroyed',
      this.$inertia.on('navigate', () => {
        // prevent the event from being called when the path is different than /basket/orders/new
        if(window.location.pathname === this.$routes.new_cart_order()) {
          this.gtmAddCheckoutBegin(this.orderable) // puts on the datalayer info about the current step of the purchase
        }
      })
    )
    this.trackEvent('proceed_to_checkout', this.mixpanelCartData(this.orderable))
  },

  props: {
    stripePublishableKey: {
      type: String,
      required: true
    },
    stripePaymentIntentId: {
      type: String,
      required: true
    },
    stripePaymentIntentClientSecret: {
      type: String,
      required: true
    },
    promotion: {
      type: Object,
      required: false
    },
    countries: {
      type: Array,
      required: true
    },
    geoProps: {
      type: Object,
      required: false
    }
    // geoProps: {
    //     locale: user_locale,
    //     currency: currency_code,
    //     currencySymbol: currency_symbol,
    //     exchangeRate: exchange_rate
    //   }
  },

  computed: {
    currentPage() {
      return 'Checkout step 1'
    }
  }
}
</script>
