<template>
  <div class="container py-6">
    <MetaTags :metaTags="metaTags" />
    <!-- Content -->
    <section data-testid="" class=" bg-white">
      <div class="flex flex-wrap items-center justify-between w-full gap-y-3">
        <div
          class="text-4xl font-serif capitalize text-left text-blue-800"
        >
          {{ collection.name }}
        </div>
        <button
          @click="openDialog"
          v-show="collection.slug!=='default_collection'"
          class="button button button--blue button--blue"
          data-testid="lesson-new-collection-button"
        >
          Collection Settings
        </button>
      </div>
      <Divider spacing="lg" />
      <div class="grid grid-cols-12 gap-8 lg:gap-12 pt-4 w-full">
        <template v-if="collection.lessons">
          <div
            class=" col-span-12 sm:col-span-6 md:col-span-4 2xl:col-span-3   relative "
            v-for="items in collection.lessons"
            :key="items.id"
          >
            <div  class="relative  h-auto flex-shrink-0">
              <!-- <Tags
                v-if="course.hasNewLessons && !preLaunch"
                :data-testid="`course-continue-watching-${course.id}-new-lessons-tag`"
                class="z-10 absolute top-2 left-2"
                color="success"
                size="small"
              >
                <span class="overline--medium overline--widest">{{ $I18n.t('new_lessons', i18nScope) }}</span>
              </Tags> -->

              <div>
                <Link
                :data-testid="`${items.id}-link`"
                :href="$routes.course_lesson(items.course_slug.slug, items.slug)" class="flex relative w-full h-48">
                  <!-- THUMBNAIL -->
                  <img loading="lazy" :src="items.poster"  class="lazyload image-cover">

                  <!-- PLAY BUTTON -->
                  <div v-if="!preLaunch" class="flex justify-center items-center absolute inset-0 cursor-pointer bg-grayscale-100 bg-opacity-0 hover:bg-opacity-30 transition-colors">
                    <div class="bg-grayscale-100 bg-opacity-80 w-16 h-16 rounded-full flex justify-center items-center">
                      <Icon name="PlayFull" class="text-blue-800 text-xl relative left-0.5" />
                    </div>
                  </div>

                  <!-- LESSON DURATION -->
                  <!-- <Tags
                    v-if="!preLaunch"
                    :data-testid="`course-continue-watching-${course.id}-lesson-duration`"
                    class="absolute bottom-2 right-2 p-0.5 opacity-80"
                    color="inverse"
                    size="xs"
                  >
                    <span class="overline--medium">{{ course.nextLessonToPlay ? course.nextLessonToPlay.duration : '' }}</span>
                  </Tags> -->

                  <!-- PRE-LAUNCH -->
                  <!-- <Tags
                    v-if="preLaunch"
                    class="absolute bottom-2 left-2 flex flex-row items-center"
                    color="highlight"
                    size="small"
                  >
                    <PreLaunchTag
                      :course="course"
                      :i18nScope="i18nScope"
                      :testId="`course-continue-watching-${course.id}`"
                      class="overline--medium overline--widest"
                    />
                  </Tags> -->
                </Link>

                <div class="mt-3 text-grayscale-700">
                  <!-- CURRENT LESSON -->
                  <!-- <template v-if="courseHasProgress">
                    <p v-if="published"  class="flex flex-row items-start font-normal mb-4">
                      <Icon name="Unfinished" class="text-blue-500 flex-shrink-0 mt-0.5 mr-1" />
                      {{ course.nextLessonToPlay ? course.nextLessonToPlay.title : '' }}
                    </p>
                  </template> -->

                  <!-- COURSE PROGRESS -->
                  <!-- <CourseProgress
                    v-if="courseHasProgress && !singleCourse"
                    :data-testid="`course-continue-watching-${course.id}-progress`"
                    :course="course"
                    :progress="course.progress"
                    smallBar
                  /> -->

                  <!-- COURSE INFO -->
                  <div v-if="!singleCourse" class="flex flex-row flex-nowrap justify-between">
                    <Link
                      :data-testid="`${items.id}-link`"
                      :href="$routes.course_lesson(items.course_slug.slug, items.slug)" class="flex flex-col">
                      <p
                        class="font-normal text-black">
                        {{ items.title }}
                      </p>
                      <p  class="text-sm text-black">With {{ items.teacher_name }}</p>
                    </Link>

                    <collection-drop-down ref="menudropdown"
                      :lessonId="items.id" :isBookmarked="true"/>
                  </div>

                </div>
              </div>
            </div>
          </div>
        </template>

        <template v-else>
          <div class=" col-span-12 h-80 flex items-center justify-center">
            <p class="text-lg text-opacity-80 text-center mt-28">No Saved Lessons</p>
          </div>
        </template>
      </div>
    </section>
      <dialog-box
      ref="dialogBox"
      :title="'Edit collection'"
      :edit="true"
      :collection="collection"
      :description="'Rename or delete your collection'"

    >
    </dialog-box>
  </div>
</template>
<script>
import { metaTags } from '@/mixins/metaTags'
import statuses from '@/mixins/statuses'
import currentUser from '@/mixins/currentUser'


import MetaTags from '@/Components/Shared/MetaTags'
import { Link  } from '@inertiajs/inertia-vue'
import Divider from '../../Components/Shared/Divider.vue'
import CollectionDropDown from '../../Components/Shared/SavedLessons/CollectionDropDown.vue'
import DialogBox from '../../Components/Shared/SavedLessons/DialogBox.vue'
import {formatTimeAgo} from '@/mixins/dateFormat'
import Icon from '../../Components/Shared/Icon.vue'

export default {

  mixins: [
    metaTags,
    statuses,
    currentUser
  ],

  components: {
    MetaTags,
    Link,
    Divider,
    CollectionDropDown,
    DialogBox,
    Icon
},
  props: {
    collection: {
      type: Object,
      default: () => {
        return {}
      }
    },
    singleCourse: {
      type: Boolean,
      default: false
    }
  },

  methods: {
    handleRemoveLesson(id) {
      const path = this.$routes.destroy_lesson_by_id_user_collections()
      this.$inertia.delete(`${path}?id=${id}`, {
        preserveScroll: true,
        onSuccess: () => {
          this.$inertia.get()
        },
        onError: () => {
          console.error('Failed to delete Lesson')
        }
      })
    },
    openDialog() {
      this.$refs.dialogBox.open()
    },
     formattedCreatedAt(value) {
      return formatTimeAgo(value)
    }
  },
  computed: {
    linkComponent() {
      return (this.preLaunch && this.singleCourse) ? 'div' : 'Link'
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.user_collection' }
  }
}
</script>
