<template>
  <component :is="componentName" :href="href" @click="$emit('click')" class="cursor-pointer">
    <slot />
  </component>
</template>

<script>
import { Link } from '@inertiajs/inertia-vue'

export default {
  components: {
    Link
  },

  props: {
    fake: {
      type: Boolean,
      default: false
    },
    href: {
      type: String,
      required: false
    }
  },

  computed: {
    componentName() {
      return this.fake ? 'span' : 'Link'
    }
  }
}
</script>
