<template>
  <li class="col-span-12 md:col-span-6 lg:col-span-4 h-full">
    <Link
      :href="$routes.course(course.slug)"
      >
      <div class="relative">
        <div>
          <Tags
            v-if="enrolled && course.hasNewLessons"
            :data-testid="`course-search-talent-${course.id}-new-lessons`"
            color="success"
            size="small"
            class="z-10 absolute top-2 left-4 sm:left-6"
          >
            <span class="overline--medium overline--widest">{{ $I18n.t("new_lessons", i18nScope) }}</span>
          </Tags>
          <div class="relative h-64 w-full overflow-hidden mb-4">
            <!-- Sale Tag -->
            <SaleTag
              v-if="showSaleTag && !enrolled && !comingSoon"
              :course="course"
              class="right-4 min-w-160"
            />
            <div
              class="h-full text-blue-800 cursor-pointer"
            >
              <img loading="lazy"
                :src="course.image"
                class="lazyload image-cover"
              />
            </div>

            <template v-if="!enrolled">
              <!-- ADD COURSE TO BASKET -->
              <!-- <PurchaseButton v-if="showPurchaseButton"
                :course="course"
                :gtmPageName="gtmPageName"
                :gtmSectionName="gtmSectionName"
                size="small"
                class="absolute bottom-4 right-4 z-10"
              /> -->

              <template>
                <!-- UPCOMING COURSE TAG -->
                <Tags
                  v-if="comingSoon"
                  :data-testid="`course-search-talent-${course.id}-coming-soon-tag`"
                  size="large"
                  class="absolute bottom-4 left-0"
                >
                  {{ $I18n.t("coming_soon", i18nScope) }}
                </Tags>
              </template>
            </template>

            <!-- OWNED COURSE -->
            <div v-if="owned">
              <Tags
                :data-testid="`course-search-talent-${course.id}-owned-tag`"
                color="blue"
                class="absolute top-0 right-6 flex flex-col items-center"
              >
                <Icon name="Finished" class="text-xl my-2" />
                <p class="overline--medium overline--widest whitespace-pre-wrap">{{ $I18n.t('owned_course', i18nScope) }}</p>
              </Tags>
            </div>
          </div>

          <div
            class="cursor-pointer"
          >
            <div class="flex flex-row no-wrap justify-between">
              <h3
                class="title-medium text-blue-800 mb-2"
                >{{ course.name }}</h3
              >
              <Icon  name="Arrow" class="text-2xl text-grayscale-900" />
            </div>
            <p

            >
              <span class="italic font-serif mr-1 text-grayscale-900">with</span>
              <span class="subheading text-grayscale-900">{{ course.teacher.name }}</span>
            </p>
            <div
              v-if="enrolled && preLaunch"
              :data-testid="`course-search-talent-${course.id}-release-date`"
              class="mt-2"
            >
              <PreLaunchTag :course="course" :i18nScope="i18nScope" class="overline--medium text-yellow-800" />
          </div>
          </div>
        </div>
      </div>
    </Link>
  </li>
</template>

<script>
import statuses from '@/mixins/statuses'
import currentUser from '@/mixins/currentUser'
import generalAnnouncement from '@/mixins/generalAnnouncement'
import saleTag from '@/mixins/saleTag'
import gtm from '@/mixins/gtm'
import mixpanel from '@/mixins/mixpanel'

import { Link } from '@inertiajs/inertia-vue'

import Icon from '@/Components/Shared/Icon'
// import PurchaseButton from '@/Components/Shared/Courses/PurchaseButton'
import SaleTag from '@/Components/Shared/Campaigns/SaleTag'
import Tags from '@/Components/Shared/Tags'
import PreLaunchTag from '@/Components/Shared/Courses/PreLaunchTag'


export default {
  mixins: [
    statuses,
    currentUser,
    generalAnnouncement,
    saleTag,
    gtm,
    mixpanel
  ],
  props: ['course'],
  components: {
    Link,
    Icon,
    // PurchaseButton,
    SaleTag,
    Tags,
    PreLaunchTag
   },
   created() {
    this.i18nScope = { scope: 'pages.home.talents' }
  },
   computed: {
    courseLink() {
      if (this.enrolled && this.published) {
        return this.$routes.course_lessons(this.course.slug)
      } else {
        return this.$routes.course(this.course.slug)
      }
    },
    owned() {
      return this.isOwned(this.course.id)
    },
    freeLessonRoute() {
      if (!this.course.freeLessonSlug) { return false }

      return this.$routes.course_lesson(this.course.slug, this.course.freeLessonSlug)
    },
    mixpanelEventData() {
      return {
        page: window.location.pathname,
        component: this.gtmSectionName,
        course: this.course.name,
        teacher: this.course.teacher.name
      }
    }
  },
  methods: {
    onFreeLessonClick() {
      this.trackEvent('click_free_lesson', this.mixpanelEventData)
    }
  }

}
</script>
