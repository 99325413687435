<template>
  <div>
    <MetaTags :metaTags="metaTags" />

    <!-- Mobile Header -->
    <div class="md:hidden px-4 pt-10 pb-6" style="background-color: #e8ebf0">
      <h3 class="vip-preview-subtitle text-center">CREATE ACADEMY PRESENTS</h3>
      <h1
        class="hero-title text-[#000] text-center font-medium leading-[32px] tracking-[4.8px] mt-6"
      >
        'How to Design Your Dream Home'
      </h1>
      <h2 class="hero-subtitle text-center mt-4">
        <span
          style="
            color: #000;
            text-align: center;
            font-family: 'Saol CA';
            font-size: 20px;
            font-style: italic;
            font-weight: 500;
            line-height: 160%;
          "
          >with</span
        >
        <span
          style="
            color: #000;
            font-family: 'Brandon Grotesque';
            font-size: 15px;
            font-style: normal;
            font-weight: 420;
            line-height: 160%;
            letter-spacing: 2.25px;
            margin-left: 3px;
          "
        >
          BUNNY WILLIAMS</span
        >
      </h2>
      <div class="w-20 h-[2px] bg-[#0E1E38] opacity-100 mx-auto mt-8 mb-8"></div>
      <h3
        class="vip-preview-subtitle text-[#0E1E38] text-[22px] font-normal leading-[160%] tracking-[3.3px] text-center"
      >
        A VIP PREVIEW
      </h3>
    </div>

    <!-- Hero Banner -->
    <Hero
      :height="'large'"
      :image-src="bannerImage"
      :image-alt="'How to Design Your Dream Home with Bunny Williams'"
      :vertical-align="'end'"
      :uid="'vip-preview'"
      data-testid="vip-preview-hero-section"
      class="hidden md:block"
    >
      <div>
        <h1
          class="text-white text-center mb-3"
          style="
            font-family: 'Brandon Grotesque';
            font-size: 60px;
            font-weight: 450;
            line-height: 75px;
            letter-spacing: 7.2px;
          "
        >
          HOW TO DESIGN <br />YOUR DREAM HOME
        </h1>
        <h2
          class="text-white text-center"
          style="
            font-family: 'Brandon Grotesque';
            font-size: 30px;
            font-weight: 420;
            line-height: 72px;
            letter-spacing: 5px;
          "
        >
          <span class="italic" style="font-family: 'Saol CA', serif">with</span> BUNNY WILLIAMS
        </h2>
      </div>
    </Hero>

    <!-- Mobile Banner -->
    <div class="md:hidden">
      <img
        :src="bannerImage.url"
        :alt="'How to Design Your Dream Home with Bunny Williams'"
        class="w-full h-auto"
      />
    </div>

    <!-- Rest of the content -->
    <main class="bg-[#F7F3ED]" style="background-color: #f7f3ed">
      <!-- A VIP Preview -->
      <div class="container mx-auto px-4 py-10 md:py-[42px]">
        <section>
          <div class="sm:ml-6 md:ml-12">
            <h3
              class="vip-preview-subtitle text-[#0E1E38] text-[22px] font-normal leading-[160%] tracking-[3.3px] hidden sm:block"
            >
              CREATE ACADEMY PRESENTS
            </h3>
            <h1
              class="preview-title text-[#0E1E38] text-4xl md:text-[65px] font-medium leading-none mt-4 hidden sm:block"
            >
              A VIP Preview
            </h1>
          </div>

          <div class="preview-info flex md:ml-12">
            <div class="flex items-start w-full text-[#9E6C21] text-sm font-normal md:mr-20">
              <div class="flex sm:flex-col md:flex-row">
                <div class="flex flex-col sm:flex-row w-full">
                  <div class="w-full sm:w-1/3 pr-4 sm:mb-0">
                    <div class="flex flex-col gap-3 mt-2">
                      <!-- <a href="#course-trailer" class="hidden sm:block">WATCH TRAILER</a>

                      <a href="/courses/bunny-williams" class="sm:text-left text-center block"
                        >WATCH LESSONS</a
                      >
                      <a
                        href="https://drive.google.com/file/d/1Ngf4OTfKsKPcvC0NotHGbX0Z3YC8PfHm/view"
                        class="sm:text-left text-center block"
                        >VIEW PRESS KIT</a
                      > -->
                    </div>
                  </div>
                  <div class="w-full sm:w-2/3">
                    <p
                      class="description-text text-center sm:text-left"
                      style="
                        color: var(--blue-800, #0e1e38);
                        font-family: 'Brandon Grotesque';
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 390;
                        line-height: 160%;
                        letter-spacing: 0.4px;
                      "
                    >
                      Thank you for joining us to celebrate interiors icon Bunny Williams as she
                      launches her debut online video course with Create Academy for Fall/Winter
                      2024.
                    </p>

                    <div class="flex justify-center sm:justify-start mt-8">
                      <Link href="/courses/bunny-williams">
                        <Button class="custom-blue-button" size="large">
                          WATCH VIDEO LESSONS
                        </Button>
                      </Link>
                    </div>

                    <p
                      class="description-text mt-8 text-center sm:text-left"
                      style="
                        color: var(--blue-800, #0e1e38);
                        font-family: 'Brandon Grotesque';
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 390;
                        line-height: 160%;
                        letter-spacing: 0.4px;
                      "
                    >
                      Collating a wealth of knowledge from over 40 years of working in the industry,
                      Bunny's course has been designed for those taking their first steps into
                      interior design, as well as professionals curious to learn how one of the
                      design greats works.
                    </p>
                    <p
                      class="description-text mt-8 text-center sm:text-left"
                      style="
                        color: var(--blue-800, #0e1e38);
                        font-family: 'Brandon Grotesque';
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 390;
                        line-height: 160%;
                        letter-spacing: 0.4px;
                      "
                    >
                      You can also watch the course trailer below.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
      <!-- Course Trailer -->
      <div id="course-trailer" class="container p-0 lg:px-16 lg:py-20 lg:pb-16">
        <div data-testid="course-trailer" class="flex flex-col lg:w-10/12 mx-auto">
          <div v-if="!brightcove || !brightcove.lessonPlayer">
            Brightcove data is not available.
          </div>
          <VideoPlayer
            v-else
            data-testid="course-trailer-video-player"
            class="mb-8"
            :isAutoPlay="false"
            :options="{
              accountId: brightcove.lessonPlayer.accountId,
              playerId: brightcove.lessonPlayer.playerId,
              videoId: 6363540563112,
              embedId: 'default'
            }"
            :mixpanel="{
              type: 'bunny-williams-vip-preview',
              videoId: 6363540563112
            }"
          />
        </div>
        <!-- Download Press Kit -->
        <article class="z-10 self-center mt-4 ml-2.5 max-md:mt-10 max-md:max-w-full px-4">
          <p
            style="
              color: var(--blue-800, #0e1e38);
              font-family: 'Brandon Grotesque';
              font-size: 20px;
              font-style: normal;
              font-weight: 390;
              line-height: 160%;
              letter-spacing: 0.4px;
              text-align: center;
            "
          >
            With unprecedented access to her New York apartment and studio, as well as her legendary
            Connecticut country home,

            <strong class="font-medium">'How To Design Your Dream Home'</strong>
            <span>
              features over five hours of exclusive video content, broken down into 37 bitesize
              lessons that you can watch whenever and wherever you want.
            </span>
          </p>
          <p
            class="mt-4"
            style="
              color: var(--blue-800, #0e1e38);
              font-family: 'Brandon Grotesque';
              font-size: 20px;
              font-style: normal;
              font-weight: 390;
              line-height: 160%;
              letter-spacing: 0.4px;
              text-align: center;
            "
          >
            <span>
              Take a moment to preview some of our favourite course extracts below and find our
            </span>
            <a
              href="https://drive.google.com/file/d/1Ngf4OTfKsKPcvC0NotHGbX0Z3YC8PfHm/view"
              class="text-[#9E6C21] underline"
              tabindex="0"
              >press kit here</a
            >
            <span class="text-[#9E6C21]">.</span>
          </p>
        </article>
        <!-- Offset images -->
        <section
          class="self-end mt-20 w-full max-w-4xl mx-auto max-md:mt-10 max-md:mr-2.5 max-md:max-w-full flex flex-col"
        >
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/518423560288d25134aab415822a724dc0e084bbe49576416c0a9ef890cec037?placeholderIfAbsent=true&apiKey=c666ad20f97e4d32840edeba9e04c0c2"
            alt="Gallery image 1"
            class="object-contain w-4/5 aspect-[0.95] self-start"
          />

          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/b27e5f92d3b22955ff3a4198e8f6f957cc21f44da3b4a98181a1ebd2b202d1f2?placeholderIfAbsent=true&apiKey=c666ad20f97e4d32840edeba9e04c0c2"
            alt="Gallery image 2"
            class="object-contain w-3/5 aspect-[0.98] mt-4 self-end"
          />
        </section>

        <!-- Announcement  -->
        <section class="px-4 max-w-4xl mx-auto">
          <p
            class="self-center mt-14 ml-3 description-text text-center max-md:mt-10 max-md:max-w-xl"
          >
            <strong class="font-medium">'How to Design Your Dream Home'</strong>
            <span>with Bunny Williams</span>
            will be released on November 19th 2024 and is available to watch with a Create Academy
            subscription or single purchase.
          </p>
          <div class="pb-8">
            <Link href="/courses/bunny-williams">
              <Button class="mt-8 mx-auto custom-blue-button" size="large"> Find out more </Button>
            </Link>
          </div>
        </section>
      </div>
    </main>
  </div>
</template>

<script>
  import { metaTags } from '@/mixins/metaTags'
  import currentUser from '@/mixins/currentUser'
  import imgSrcset from '@/mixins/imgSrcset'
  import brightcove from '@/mixins/brightcove'
  import { Link } from '@inertiajs/inertia-vue'
  import cdn from '@/mixins/cdn'

  import MetaTags from '@/Components/Shared/MetaTags'
  import Hero from '@/Components/Shared/Hero'
  import VideoPlayer from '@/Components/Shared/VideoPlayer'
  import Button from '@/Components/Shared/Button'

  export default {
    name: 'VipPreview',

    mixins: [currentUser, metaTags, imgSrcset, brightcove, cdn],

    components: {
      Link,
      MetaTags,
      Hero,
      VideoPlayer,
      Button
    },

    props: {
      category: {
        type: Object,
        default: () => ({})
      },
      geoProps: {
        type: Object,
        required: true
      },
      membershipPlan: {
        type: Object,
        default: null
      },
      brightcove: {
        type: Object,
        default: () => ({})
      },
      metaTags: {
        type: Object,
        required: true
      }
    },

    computed: {
      currentPage() {
        return 'VIP Preview'
      },

      bannerImage() {
        return {
          url: this.imageUrl('vip-preview-background-large.webp'),
          sizes: {
            large: this.imageUrl('vip-preview-background-large.webp'),
            medium: this.imageUrl('vip-preview-background-large.webp'),
            small: this.imageUrl('vip-preview-background-large.webp')
          }
        }
      }
    },

    created() {
      document.title = 'VIP Preview'
    },

    mounted() {
      console.log('Brightcove prop:', this.brightcove)
    }
  }
</script>

<style scoped>
  /* Custom styles that can't be easily replaced with Tailwind classes */
  .hero-title {
    font-family: 'Saol CA', serif;
    font-size: 36px;
  }

  .hero-subtitle {
    font-family: 'Saol CA', serif;
  }

  .vip-preview-subtitle {
    font-family: 'Brandon Grotesque';
    font-size: 0.9375rem;
    font-style: normal;
    font-weight: 420;
    line-height: 160%; /* 1.5rem */
    letter-spacing: 0.14063rem;
  }

  .preview-title {
    font-family: 'Saol CA', serif;
  }

  .description-text {
    font-family: 'Brandon Grotesque', sans-serif;
    font-size: 20px !important;
    line-height: 160% !important;
    letter-spacing: 0.4px !important;
    font-weight: 390 !important;
  }

  @media (max-width: 768px) {
    .hero-title {
      font-size: 36px;
      line-height: 48px;
    }

    .hero-subtitle {
      font-size: 24px;
      line-height: 36px;
    }

    .description-text {
      font-size: 20px !important;
      line-height: 160% !important;
      letter-spacing: 0.4px !important;
      font-weight: 390 !important;
    }
  }

  :deep(.custom-blue-button) {
    background-color: var(--blue-800, #0e1e38) !important;
    color: white !important;
  }
</style>
