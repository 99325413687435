<template>
  <div class="w-full">
    <MetaTags :metaTags="metaTags" />

    <!--Offer announcement  -->
    <div class="w-full bg-blue-500">
      <div class="container mx-auto px-4 lg:px-40 py-3.5 lg:py-5 ">
        <div class="flex items-center justify-center flex-col">
          <p class="font-serif hidden lg:block text-4xl leading-relaxed text-grayscale-100 font-medium">20% off all courses & subscriptions</p>
          <p class=" block lg:hidden text-xs leading-7 tracking-widest mb-2 text-grayscale-100 font-normal">SALE ENDS MAY 28TH</p>
          <p class="uppercase hidden lg:block text-lg custom-tracking font-medium text-grayscale-100">SALE ENDS MAY 28TH</p>
          <p class=" font-serif block lg:hidden text-4xl font-medium text-grayscale-100">20% off</p>
        </div>
      </div>
    </div>

    <!--heading  -->
    <div class="w-full bg-grayscale-100">
      <div class="container px-4 lg:px-40 py-6 lg:py-16 ">
        <div class="flex items-center justify-center flex-col">
          <h4 class="font-serif text-4xl lg:text-6xl mb-3 lg:mb-2  text-blue-500 font-medium text-center">Make Your House a Home</h4>
          <p class="text-lg lg:text-2xl lg:leading-8  font-normal text-grayscale-700 text-center">Celebrate the start of summer this Memorial weekend by taking on a DIY project with our home and interiors courses. This week, you can save 20% on all courses and subscriptions to get you started on your home renovation journey.</p>
        </div>
      </div>
    </div>

    <!-- banner -->
    <div class="w-full bg-blue-300">
      <div class="container mx-auto px-0 pt-0 lg:px-40 pb-14 lg:py-16 ">
        <div class="flex flex-col justify-start items-center">
          <span class="hidden lg:block uppercase tracking-widest text-yellow-800 text-base lg:text-xl leading-8 font-medium mb-4 lg:mb-5">JUST RELEASED</span>
          <div class="relative w-full">
            <!-- <img
              :data-src="this.featureBlockOne.url"
              :srcset="this.featureBlockOne.srcset"
              alt="rita_banner_image_alt"
              class="image-cover lazyload"
            /> -->
            <!-- Video Player -->
            <section class=" w-full">
              <VideoPlayer
                data-testid="instructor-video-player"
                class=""
                :isAutoPlay="false"
                :options="{
                  accountId: 6222962662001,
                  videoId: 6264265187001,
                  playerId:'3BqAJwkg7',
                  embedId: 'default',
                }"
                :mixpanel="{ type: 'landing', videoId: 6264265187001 }"
              />
            </section>
          </div>
          <span class="block lg:hidden uppercase tracking-widest text-yellow-800 text-base lg:text-xl leading-8 font-medium mt-4 lg:mt-0">MOST POPULAR</span>
          <h2 class=" font-serif text-blue-800 px-9 lg:px-0 text-3xl leading-9 lg:text-5xl font-medium mb-4 mt-0 lg:mb-5 lg:mt-14 text-center">Rita Konig’s advanced design course</h2>
          <p class=" text-grayscale-700 font-normal px-9 lg:px-0 text-base leading-7 tracking-wide  lg:text-2xl lg:leading-9   mb-4 lg:mb-8 text-center max-w-3xl mx-auto">Filmed over two years, world-renowned designer Rita Konig’s second course with Create Academy takes you step by step through both her personal renovation project and a beautiful client project.</p>
          <a href="#" @click="onCourseClick('rita-konig-advanced-guide-to-interior-design')" class="button text-sm border-transparent button--yellow">DISCOVER MORE</a>
        </div>
      </div>
    </div>

    <!-- MORE INTERIOR DESIGN COURSES -->
    <div class="w-full relative bg-grayscale-100">
      <div class="container px-4 lg:px-16 py-12 lg:py-24">
        <div class="grid grid-cols-2 gap-8 lg:gap-0 items-center">
          <div class="col-span-2  lg:col-span-1 order-1">
            <div class=" flex-col flex lg:hidden mb-5 pl-5 relative">
              <span class="h-full w-px bg-blue-800 absolute left-0 top-0"></span>
              <span class="text-blue-800 text-sm leading-5 font-medium tracking-widest mb-3  ">MORE INTERIOR DESIGN COURSES</span>
              <h4 class="text-left text-3xl leading-9 font-medium font-serif text-blue-800 ">Become your<br /> own  decorator</h4>
            </div>
            <div class="relative w-full">
              <!-- <img
                :data-src="this.featureBlockOne.url"
                :srcset="this.featureBlockOne.srcset"
                alt="rita_banner_image_alt"
                class="image-cover lazyload"
              /> -->
              <!-- Video Player -->
              <section class=" w-full">
                <VideoPlayer
                  data-testid="instructor-video-player"
                  class=""
                  :isAutoPlay="false"
                  :options="{
                    accountId: 6222962662001,
                    videoId: 6352656100112,
                    playerId:'3BqAJwkg7',
                    embedId: 'default',
                  }"
                  :mixpanel="{ type: 'landing', videoId: 6352656100112 }"
                />
              </section>
            </div>
          </div>
          <div class="col-span-2  lg:col-span-1 order-2 lg:pl-28 flex flex-col items-center lg:items-start">
            <span class="text-blue-800 text-lg leading-10 font-medium tracking-widest mb-3 hidden lg:block">MORE INTERIOR DESIGN COURSES</span>
            <h4 class="text-center lg:text-left text-3xl leading-9 lg:text-5xl font-medium font-serif text-blue-800 hidden lg:block">Become your own  decorator</h4>
            <p class="text-left lg:text-left lg:text-2xl lg:leading-9 text-gray-600 font-normal text-lg mb-5 leading-8 tracking-wide lg:tracking-normal">Learn from the world’s best interior designers so you can take on your house and make it a home.</p>
            <Link href="/online-home-and-interiors-courses" class="button text-sm border-transparent button--yellow w-auto explore-btn">DISCOVER MORE</Link>
          </div>
        </div>
      </div>
    </div>


    <!-- membership banner -->
    <section
      class="bg-blue-500 h-96 p-6 w-full mx-auto flex flex-col justify-center items-center"
      v-if="!isSubscribed"
    >
      <div class="mx-auto max-w-2xl text-center">
        <div
          class="text-xl lg:title-largester mb-6 text-white"
        >
          Save 20% on your first year of learning
        </div>

        <div class="text-white font-medium tracking-widest uppercase my-6">Access all courses for {{ getGeoPrice(membershipPlan.monthlyPrice) }}/month</div>

        <div
          class="flex flex-row flex-wrap w-full md:w-auto justify-center items-center gap-x-6 gap-y-4 mb-2 lg:mb-3"
        >
          <Link
            class="button button--yellow"
            @click="onCheckoutClick(4)"
            :href="currentUser? $routes.membership_checkout():$routes.onboarding()"
          >
            <span class="text-base uppercase">Subscribe Now</span>
          </Link>
        </div>

        <div class="text-white">(Billed Annually)</div>
      </div>
    </section>

    <!-- MORE FLOWER COURSES -->
    <div class="w-full relative bg-green-300">
      <div class="container px-4 lg:px-16 py-12 lg:py-24">
        <div class="grid grid-cols-2 gap-8 lg:gap-0 items-center">
          <div class="col-span-2  lg:col-span-1 order-2 lg:order-1 lg:pr-28 flex flex-col items-center lg:items-start">
            <span class="text-blue-800 text-lg leading-10 font-medium tracking-widest mb-3 hidden lg:block">NEW COURSE</span>
            <h4 class="text-center lg:text-left text-3xl leading-9 lg:text-5xl font-medium font-serif text-blue-800 hidden lg:block">Flower Pressing</h4>
            <p class="text-left lg:text-left lg:text-2xl lg:leading-9 text-gray-600 font-normal text-lg mb-5 leading-8 tracking-wide lg:tracking-normal">Discover the lost art of flower pressing with Melissa Richardson and Amy Fielding, founders of flower pressing studio, JamJar Edit.</p>
            <a href="#" @click="onCourseClick('jamjar-edit')" class="button text-sm border-transparent button--yellow w-auto explore-btn">EXPLORE LESSONS</a>
          </div>
          <div class="col-span-2  lg:col-span-1 order-1 lg:order-2">
            <div class=" flex-col flex lg:hidden mb-5 pl-5 relative">
              <span class="h-full w-px bg-blue-800 absolute left-0 top-0"></span>
              <span class="text-blue-800 text-sm leading-5 font-medium tracking-widest mb-3  ">NEW COURSE</span>
              <h4 class="text-left text-3xl leading-9 font-medium font-serif text-blue-800 ">Flower Pressing</h4>
            </div>
            <div class="relative w-full">
              <!-- <img
                :data-src="this.featureBlockOne.url"
                :srcset="this.featureBlockOne.srcset"
                alt="rita_banner_image_alt"
                class="image-cover lazyload"
              /> -->
              <!-- Video Player -->
              <section class=" w-full">
                <VideoPlayer
                  data-testid="instructor-video-player"
                  class=""
                  :isAutoPlay="false"
                  :options="{
                    accountId: 6222962662001,
                    videoId: 6352473799112,
                    playerId:'3BqAJwkg7',
                    embedId: 'default',
                  }"
                  :mixpanel="{ type: 'landing', videoId: 6352473799112 }"
                />
              </section>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- instructor spotlight -->
    <div class="w-full relative bg-grayscale-100">
      <div class="container px-4 lg:px-16 py-12 lg:py-24">
        <div class="grid grid-cols-2 gap-8 lg:gap-0 items-center">
          <div class="col-span-2  lg:col-span-1 order-1">
            <div class=" flex-col flex lg:hidden mb-5 pl-5 relative">
              <span class="h-full w-px bg-blue-800 absolute left-0 top-0"></span>
              <span class="text-blue-800 text-sm leading-5 font-medium tracking-widest mb-3  ">DIY PROJECT</span>
              <h4 class="text-left text-3xl leading-9 font-medium font-serif text-blue-800 ">Transform<br /> your furniture</h4>
            </div>
            <img
              loading="lazy"
              :data-src="this.projectImage.url"
              :srcset="this.projectImage.srcset"
              alt="project_image_alt"
              class="image-cover lazyload"
            />
          </div>
          <div class="col-span-2  lg:col-span-1 order-2 lg:pl-28 flex flex-col items-center lg:items-start">
            <span class="text-blue-800 text-lg leading-10 font-medium tracking-widest mb-3 hidden lg:block">DIY PROJECT</span>
            <h4 class="text-center lg:text-left text-3xl leading-9 lg:text-5xl font-medium font-serif text-blue-800 hidden lg:block">Transform your furniture</h4>
            <p class="text-left lg:text-left lg:text-2xl lg:leading-9 text-gray-600 font-normal text-lg mb-5 leading-8 tracking-wide lg:tracking-normal">Discover how to up-cycle old furniture into something new with expert guidance from British paint specialist, Annie Sloan.</p>
            <a href="https://page.createacademy.com/furniture-painting" class="button text-sm border-transparent button--yellow w-auto explore-btn">DISCOVER MORE</a>
          </div>
        </div>
      </div>
    </div>


    <template v-if="!isSubscribed">
      <div class="landingCat">
        <CategoryMembershipBanner
          v-if="category.showMembershipBanner"
          :category="category"
          :customImage="true"
          :membershipPlan="membershipPlan"
          :geoProps="geoProps"
          component="Category Promotional Banner"
        />
      </div>
    </template>
  </div>
</template>

<script>
// AB Testing bits
import abTest from '@/mixins/abTest'
import gtm from '@/mixins/gtm'
import monetize from '@/mixins/monetize'
import { metaTags } from '@/mixins/metaTags'
import MetaTags from '@/Components/Shared/MetaTags'
import currentUser from '@/mixins/currentUser'
import VideoPlayer from '@/Components/Shared/VideoPlayer'
import mixpanel from '@/mixins/mixpanel'
import { Link } from '@inertiajs/inertia-vue'
import CategoryMembershipBanner from '@/Components/Shared/Categories/CategoryMembershipBanner'

export default {
  mixins: [
    metaTags,
    currentUser,
    mixpanel,
    abTest,
    monetize,
    gtm
  ],

  components: {
    MetaTags,
    CategoryMembershipBanner,
    VideoPlayer,
    Link
  },
  computed: {
    currentPage() {
      return 'memorial Day Landing Page'
    }
  },
  props: {
     membershipPlan: {
      type: Object,
      required: true
    },

    category: {
      type: Object,
      default: () => ({})
    },
    geoProps: {
      type: Object,
      required: true
    }

  },

  methods: {
    getUserType() {
      return this.currentUser ? 'authed' : 'anonymous'
    },

    getGeoPrice(gbpBasePrice) {
      const decimalPlaces = this.geoProps.currency === 'USD' ? 0 : 2

      return this.geoMonetize(
        gbpBasePrice * this.geoProps.exchangeRate,
        this.geoProps.currencySymbol,
        decimalPlaces
      ).replace(/\.00$/, '')
    },

   onCheckoutClick(buttonOrder) {
      this.trackEvent('membership_checkout', {
        userType: this.getUserType(),
        buttonOrder: buttonOrder
      })
      this.trackEvent('subscribe_button', {
        userType: this.getUserType(),
        page: window.location.pathname,
        component: 'Promo-US CTA'
      })
    },
     onGiftClick(buttonOrder) {
      this.trackEvent('membership_gift', {
        userType: this.getUserType(),
        buttonOrder: buttonOrder
      })
    },
      onCourseClick(courseSlug) {
      this.$inertia.visit(this.$routes.course(courseSlug))
    }
  },
  created() {
    this.i18nScope = { scope: 'pages.static_course' },

    this.projectImage = {
      url: require('@/assets/images/landing/diy-project.jpg'),
      sizes: {
        large: require('@/assets/images/landing/diy-project.jpg'),
        medium: require('@/assets/images/landing/diy-project.jpg'),
        small: require('@/assets/images/landing/diy-project.jpg')
      }
    }
  }
}
</script>
