<template>
  <div>
    <span>{{ timeRemaining }}</span>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // Initial time remaining as a string
      timeRemaining: ''
    }
  },
  props: {
    // Target date and time as a string, e.g., "2024-12-31 23:59:59"
    targetDateTime: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.updateTime()
    // Update the timer every second
    this.interval = setInterval(this.updateTime, 1000)
  },
  beforeUnmount() {
    // Clear the interval when the component is destroyed
    clearInterval(this.interval)
  },
  methods: {
 updateTime() {
    const now = new Date()
    const target = this.parseDate(this.targetDateTime)
    const difference = target.getTime() - now.getTime()

    if (difference <= 0) {
      this.timeRemaining = 'OFFER ENDS TODAY'
      clearInterval(this.interval)
      return
    }

    const days = Math.floor(difference / (1000 * 60 * 60 * 24)).toString().padStart(2, '0')
    const hours = Math.floor((difference % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)).toString().padStart(2, '0')
    const minutes = Math.floor((difference % (1000 * 60 * 60)) / (1000 * 60)).toString().padStart(2, '0')
    const seconds = Math.floor((difference % (1000 * 60)) / 1000).toString().padStart(2, '0')

    this.timeRemaining = `OFFER ENDS  ${days}:${hours}:${minutes}:${seconds}`
  },
  parseDate(input) {
    const parts = input.split(/\D+/)
    if (parts.length !== 6) {
      console.error('Invalid date format')
      return new Date()
    }
    return new Date(Date.UTC(parts[0], parts[1] - 1, parts[2], parts[3], parts[4], parts[5]))
  }
   }
}
</script>
