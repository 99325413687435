var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',[_c('MetaTags',{attrs:{"metaTags":_vm.metaTags}}),_vm._v(" "),_c('div',{staticClass:"bg-yellow-100"},[_c('div',{staticClass:"container pt-4 lg:pt-14 mx-auto flex flex-col items-center"},[_c('div',{ref:"swiperContainer",staticClass:"designer-carousel lesson-banner-swiper"},[_c('div',{staticClass:"swiper-wrapper"},_vm._l((_vm.sliderImages),function(card,index){return _c('div',{key:index,staticClass:"swiper-slide !w-auto"},[_c('a',{staticClass:"block h-full",attrs:{"href":card.href}},[_c('div',{staticClass:"relative overflow-hidden h-80 2xl:h-96"},[_c('img',{staticClass:"w-full h-full object-cover object-center",attrs:{"src":card.image,"alt":card.name}})])])])}),0)]),_vm._v(" "),_vm._m(0)])]),_vm._v(" "),_c('div',{staticClass:"w-full relative py-6 lg:py-11"},[_c('div',{staticClass:"container pt-0 pb-6 lg:pb-11"},[_c('h2',{staticClass:"text-2xl text-center lg:text-left lg:text-3xl text-yellow-800 lg:text-black font-normal lg:font-medium mb-6"},[_vm._v("\n        "+_vm._s(_vm.totalFreeLessons)+" Free Lessons\n      ")]),_vm._v(" "),_c('div',{staticClass:"relative"},[_c('div',{staticClass:"flex overflow-x-auto border-b border-tabBorder scrollRemove"},[_c('button',{class:[
              'py-2.5 px-4 font-medium focus:outline-none flex-none',
              _vm.activeCategory === null
                ? 'border-b-2 border-tabHighlight text-tabHighlight'
                : 'text-grayscale-700 hover:text-grayscale-700'
            ],on:{"click":function($event){_vm.activeCategory = null}}},[_vm._v("\n            All ("+_vm._s(_vm.totalFreeLessons)+")\n          ")]),_vm._v(" "),_vm._l((_vm.sortedCategories),function(category){return _c('button',{key:category.id,class:[
              'py-2.5 px-4 font-medium focus:outline-none flex-none',
              _vm.activeCategory === category.id
                ? 'border-b-2 border-tabHighlight text-tabHighlight'
                : 'text-grayscale-700 hover:text-grayscale-700'
            ],on:{"click":function($event){_vm.activeCategory = category.id}}},[_vm._v("\n            "+_vm._s(category.name)+" ("+_vm._s(category.lessonCount)+")\n          ")])})],2)])]),_vm._v(" "),_c('div',{staticClass:"w-full relative"},_vm._l((_vm.sortedFreeLessonsByCategory),function(category){return _c('div',{key:category.id},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.activeCategory === null || _vm.activeCategory === category.id),expression:"activeCategory === null || activeCategory === category.id"}],attrs:{"id":`category-${category.id}`}},[_c('div',{staticClass:"relative bg-blue-100"},[_c('div',{staticClass:"container py-2 lg:py-6"},[_c('h2',{staticClass:"text-xs leading-7 lg:text-2xl font-medium text-black tracking-widest"},[_vm._v("\n                "+_vm._s(category.name.toUpperCase())+"\n              ")])])]),_vm._v(" "),_c('div',{staticClass:"container py-5 lg:py-8"},[_c('div',{staticClass:"flex flex-col gap-8"},_vm._l((category.courses),function(course){return _c('div',{key:course.id,staticClass:"relative"},[_c('h3',{staticClass:"text-xl font-medium text-blue-800 mb-1.5 lg:mb-0"},[_vm._v("\n                  "+_vm._s(course.name)+"\n                ")]),_vm._v(" "),_c('p',{staticClass:"text-gray-600 font-normal mb-5 lg:mb-4"},[_vm._v("\n                  Instructor: "+_vm._s(course.teacherName)+"\n                ")]),_vm._v(" "),_c('div',{staticClass:"grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-3 lg:gap-5"},_vm._l((course.lessons),function(lesson){return _c('div',{key:lesson.id,staticClass:"bg-white overflow-hidden border border-tabBorder rounded-md"},[_c('div',{staticClass:"cursor-pointer",on:{"click":function($event){return _vm.handleLessonClick(course.slug, lesson.slug)}}},[_c('BrightcovePlayer',{staticClass:"w-full aspect-video pointer-events-none",attrs:{"id":`free-lesson-${lesson.id}`,"options":{
                          videoId: lesson.videoId,
                          ..._vm.brightcove.lessonPlayer,
                          autoplay: false
                        }}})],1),_vm._v(" "),_c('div',{staticClass:"p-4 text-center lg:text-left"},[_c('h4',{staticClass:"text-lg text-center lg:text-left font-medium text-blue-800 mb-2"},[_vm._v("\n                        "+_vm._s(lesson.title)+"\n                      ")]),_vm._v(" "),_c('p',{directives:[{name:"clamp",rawName:"v-clamp",value:(3),expression:"3"}],staticClass:"text-gray-600 font-normal hidden lg:block mb-2 line-clamp-3"},[_vm._v("\n                        "+_vm._s(lesson.description)+"\n                      ")]),_vm._v(" "),_c('a',{staticClass:"text-yellow-800 text-sm lg:text-base hover:underline",attrs:{"href":`/courses/${course.slug}/lessons/${lesson.slug}`}},[_vm._v("\n                        View full lesson\n                      ")])])])}),0)])}),0)])])])}),0)])],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"w-full lg:max-w-4xl pt-12 flex flex-col justify-center items-center"},[_c('div',{staticClass:"text-sm font-semibold"},[_c('span',{staticClass:"mr-1 text-ratingGreen"},[_vm._v("★★★★★")]),_vm._v(" "),_c('span',{staticClass:"text-black text-sm font-medium"},[_vm._v("Rated 4.8/5 on Trustpilot")])]),_vm._v(" "),_c('h1',{staticClass:"text-4xl lg:text-5xl font-serif my-3.5 text-blue-800 text-center"},[_vm._v("\n          Start your learning journey\n        ")]),_vm._v(" "),_c('p',{staticClass:"text-grayscale-700 tracking-wide lg:text-black text-lg lg:text-2xl text-center font-normal"},[_vm._v("\n          Watch a selection of free lessons from each of our courses so you can meet your new\n          instructors and enjoy a sample of what you'll learn on the full course.\n        ")])])
}]

export { render, staticRenderFns }