<template>
  <div data-testid="auth-modal" class="bg-grayscale-100 p-8">
    <div class="absolute top-6 right-6">
      <button data-testid="auth-modal-close" @click="closeModal">
        <Icon name="close" class="text-2xl text-blue-800" />
      </button>
    </div>
    <component
      :is="componentClass"
      v-bind="$attrs"
      @toggleSignInUp="toggle"
      @success="closeModal"
      :notificationsRequired="notificationsRequired"
    />
  </div>
</template>

<script>
import cart from '@/mixins/cart'
import mixpanel from '@/mixins/mixpanel'
import SignInForm from '@/Components/Auth/SignInForm'
import SignUpForm from '@/Components/Auth/SignUpForm'

import Icon from '@/Components/Shared/Icon'

export default {
  mixins: [
    cart,
    mixpanel
  ],

  components: {
    SignInForm,
    SignUpForm,
    Icon
  },

  props: {
    notificationsRequired: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      showSignIn: false
    }
  },

  computed: {
    componentClass() {
      return this.showSignIn ? SignInForm : SignUpForm
    }
  },

  methods: {
    toggle() {
      this.showSignIn = !this.showSignIn
    },

    closeModal() {
      this.$emit('close')
    }
  },

  mounted() {
    this.trackEvent('auth_modal_opened', this.mixpanelCartData(this.orderable))
  }
}
</script>
