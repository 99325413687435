<template>
  <div class="container pt-6 pb-18">
    <MetaTags :metaTags="metaTags" />
    <section data-testid="" class=" bg-white">
      <div class="flex flex-wrap items-center justify-between w-full gap-y-3">
        <div class="text-4xl font-serif text-left text-blue-800">
          {{ $I18n.t('heading', i18nScope) }}
        </div>
        <button
         @click="openDialog"
          class="button button button--blue button--blue"
          data-testid="lesson-new-collection-button"
        >
          New Collection
        </button>
      </div>
      <Divider spacing="lg" />
      <div class="grid grid-cols-12 gap-8 lg:gap-12 pt-4">
        <div
          class="  border-black px-10 py-8 flex flex-col items-center justify-center relative col-span-12 lg:col-span-6"
          :class="{ 'bg-yellow-100': item.slug === 'default_collection', 'border': item.slug !== 'default_collection' }"
          v-for="item in collections"
          :key="item.id"
        >


          <div
            :data-testid="`${item.id}-link`"
            :href="$routes.user_collection(item.id)"
            class=" h-full w-full flex flex-col">
            <div class="flex items-center justify-between mb-12">
              <h4 class="text-left flex items-center uppercase text-lg tracking-wider line-clamp-2 h-14 font-medium mb-0 text-grayscale-900">
                {{ item.name }}
              </h4>
              <div class="flex items-center justify-end gap-x-3">
                <template v-if="!item.lesson_count">
                  <span class="text-sm font-medium m-0 p-1 px-3 flex items-center gap-x-2 border border-black rounded-full uppercase text-grayscale-900">
                    <Icon name="LessonCount" class="text-xs" />
                    <span>0</span>
                    <span>Lessons</span>
                  </span>
                </template>
                <template v-else>
                  <span class="text-sm font-medium m-0 p-1 px-3 flex items-center gap-x-2 border border-black rounded-full uppercase text-grayscale-900">
                    <Icon name="LessonCount" class="text-xs" />
                    <span>{{ item.lesson_count }}</span>
                    <span>Lessons</span>
                  </span>
                </template>
                <template v-if="item.slug !== 'default_collection'">
                  <span v-on:click ="openSettingsDialog(item)" class=" cursor-pointer">
                    <Icon name="Settings" class="text-xl fill_none" />
                  </span>
                </template>
              </div>
            </div>
            <div v-if="!item.lesson_count">
              <div class=" relative">
                <div class="flex justify-between items-stretch  h-40  relative z-20 ">
                  <div class="w-full h-40 dashBorder p-5 px-8 relative flex items-center justify-center">

                      <template v-if="item.slug === 'default_collection'">
                        <div class=" flex justify-center items-center flex-col flex-1">
                          <p class="flex text-2xl font-medium desc-clamp  items-center justify-center mb-2 text-blue-800">No saved lessons </p>
                          <p class="flex text-base text-center font-normal desc-clamp  items-center justify-center text-blue-800">Collections are custom lists to organise your saved lessons.<br />To save a lesson to a collection, click the save icon <Icon name="BookMark" class="text-xs fill_none relative -top-0.5" /> on any lesson. </p>
                        </div>
                      </template>
                      <template v-else>
                        <div class=" flex justify-center items-center flex-1">
                          <p class="flex text-2xl font-medium desc-clamp  items-center justify-center text-blue-800">No saved lessons in this collection </p>
                        </div>
                      </template>
                  </div>
                </div>
              </div>
            </div>
            <div v-else>
              <div class=" relative">
                <div class=" absolute w-8/12 h-full z-10 bg-blue-800 -top-6 left-0"></div>
                <div class=" absolute w-9/12 h-full z-20 bg-card  -top-3 left-3"></div>
                <div class="flex justify-between items-stretch  h-40 shadow relative z-30 ml-6 ">
                  <Link class="w-5/12 relative" :data-testid="`${item.id}-link`" :href="$routes.user_collection(item.id)">
                    <img loading="lazy"
                      :src="item.poster"
                      class="image-cover lazyloaded min-h-full"
                      alt=""
                    />
                  </Link>
                  <div class="w-7/12 bg-white shadow-sm h-40  p-5 relative flex flex-col">
                    <Link :data-testid="`${item.id}-link`" :href="$routes.user_collection(item.id)" class=" flex justify-start items-center flex-1">
                      <p class="flex text-black text-2xl font-medium desc-clamp items-center justify-center">{{ item.lesson_title }}</p>
                    </Link>
                    <div class="flex items-center justify-between">
                      <p class="text-sm font-normal">{{formattedCreatedAt(item.lesson_created_at)  }}</p>
                      <Icon name="BookMarked" class="text-lg" />
                    </div>
                  </div>
                </div>
              </div>
              <div v-if="item.lesson_count" class="flex items-center justify-end mt-6">
                <Link
                :data-testid="`${item.id}-link`"
                :href="$routes.user_collection(item.id)"
                class="flex items-center"
                >
                <span
                  class="text-xs font-medium uppercase underline text-grayscale-900"
                  >View All
                  <Icon name="Chevron" class="text-xs -rotate90 relative -top-0.5" />
                </span>
              </Link>
              </div>
            </div>
          </div>

        </div>
      </div>
    </section>
    <dialog-box
      ref="dialogBox"
      :title="'Create a new collection'"
      :description="'Organise your saved lessons into personalised collections'"

    >
    </dialog-box>
    <div>

    <dialog-box
      ref="collectionBox"
      :title="'Edit collection'"
      :edit="edit"
      :collection="collectionItem"
      :description="'Rename or delete your collection'"
    >
    </dialog-box>
   </div>

  </div>
</template>
<script>
import { metaTags } from '@/mixins/metaTags'

import MetaTags from '@/Components/Shared/MetaTags'
import { Link  } from '@inertiajs/inertia-vue'
import Divider from '../../Components/Shared/Divider.vue'
import DialogBox from '../../Components/Shared/SavedLessons/DialogBox.vue'
import {formatTimeAgo} from '@/mixins/dateFormat'
import Icon from '../../Components/Shared/Icon.vue'
export default {

  mixins: [metaTags],

  data() {
    return {
      collectionItem:{},
      edit:true
    }
  },
  props: {
    collections: {
      type: Array,
      default: () => {
        return []
      }
    }
  },
  components: {
    MetaTags,
    Link,
    Divider,
    DialogBox,
    Icon
  },
  methods: {
    openDialog() {
      this.$refs.dialogBox.open()
    },
     openSettingsDialog(item) {
      this.collectionItem = item
      this.$refs.collectionBox.open(item)
    },

   formattedCreatedAt(value) {
      return formatTimeAgo(value)
   },
     handlePopstate() {
      window.location.reload()
    }
  },
  mounted() {
    window.addEventListener('popstate', this.handlePopstate)
  },
  created() {
    this.i18nScope = { scope: 'pages.user_collection' }
  }
}
</script>
