<template>
  <div class="flex flex-col">
    <Link
      :data-testid="`link-profile-${screen}`"
      :href="$routes.profile_users()"
      class="text-sm tracking-wider uppercase font-medium lg:text-white lg:hover:text-neutral-400 text-blue-800 py-2"
      >{{ $I18n.t('profile', i18nScope) }}</Link
    >
    <Link
      :data-testid="`link-password-${screen}`"
      :href="$routes.password_users()"
      class="text-sm tracking-wider uppercase font-medium lg:text-white lg:hover:text-neutral-400 text-blue-800 py-2"
      >{{ $I18n.t('password', i18nScope) }}</Link
    >
    <Link
      :data-testid="`link-notifications-${screen}`"
      :href="$routes.notifications_users()"
      class="text-sm tracking-wider uppercase font-medium lg:text-white lg:hover:text-neutral-400 text-blue-800 py-2"
      >{{ $I18n.t('notifications', i18nScope) }}</Link
    >
    <Link
      :data-testid="`link-purchases-${screen}`"
      :href="$routes.orders()"
      class="text-sm tracking-wider uppercase font-medium lg:text-white lg:hover:text-neutral-400 text-blue-800 py-2"
      >{{ $I18n.t('purchases', i18nScope) }}</Link
    >
  </div>
</template>

<script>
  import { Link } from '@inertiajs/inertia-vue'

  export default {
    components: {
      Link
    },

    props: {
      screen: {
        type: String,
        default: 'desktop'
      }
    },

    created() {
      this.i18nScope = { scope: 'pages.users.tabs' }
    }
  }
</script>
