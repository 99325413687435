<template>
  <Link
    v-if="currentUser"
    data-testid="navbar-link-signin"
    :href="$routes.profile_users()"
    :aria-label="$I18n.t('link_labels.go_to_account', i18nScope)"
    class="w-8 h-8 rounded-full overflow-hidden ml-6 lg:mr-0"
  >
    <img loading="lazy" :src="currentUser.account.avatar_path" :alt="$I18n.t('link_labels.account_avatar', i18nScope)" class="image-cover">
  </Link>
  <Link
    v-else
    data-testid="navbar-link-signin"
    :href="$routes.new_user_session()"
    :aria-label="$I18n.t('link_labels.sign_in', i18nScope)"
    class="pl-4"
  >
    <Icon name="Profile" class="text-white "/>
  </Link>

</template>


<script>
import currentUser from '@/mixins/currentUser'
import { Link } from '@inertiajs/inertia-vue'

import Icon from '@/Components/Shared/Icon'

export default {
  mixins: [currentUser],

  components: {
    Link,
    Icon
  },

  props: {
  },

  created() {
    this.i18nScope = { scope: 'pages.navbar' }
  }
}
</script>
