<template>
  <div class="w-full">
    <MetaTags :metaTags="metaTags" />
    <!-- banner -->
    <div class="w-full bg-red-800">
      <div class="container mx-auto px-0 lg:px-40 pt-8 pb-0 lg:pt-16 lg:pb-24">
        <div class="flex flex-col justify-start items-center">
          <h2 class=" font-serif text-grayscale-100 px-4 lg:px-0 text-4xl lg:text-6xl font-medium mb-2 lg:mb-4 text-center">Rita Konig</h2>
          <p class=" text-white font-normal text-sm px-4 lg:px-0 tracking-wide lg:text-lg lg:tracking-wider mb-0 lg:mb-2.5 text-center">COURSE 2</p>
          <p class="uppercase text-white font-normal px-4 lg:px-0 text-lg tracking-wide lg:text-3xl lg:tracking-widest mb-4 lg:mb-7 text-center">THE ADVANCED GUIDE TO INTERIOR DESIGN</p>
          <p class=" text-white  text-lg lg:leading-9 px-4 lg:px-0  lg:text-2xl lg:tracking-wide mb-2 lg:mb-5 text-center max-w-screen-lg mx-auto">Filmed over two years, world-renowned designer Rita Konig’s second course with Create Academy takes you step by step through both her personal renovation project and a beautiful client project.</p>
          <p class=" text-yellow-500  text-lg  lg:text-2xl px-4 lg:px-0 lg:tracking-wide mb-0 text-center">Released 23rd April</p>
          <div class="relative mt-8 lg:mt-11 w-full">
            <!-- <img
              :data-src="this.featureBlockOne.url"
              :srcset="this.featureBlockOne.srcset"
              alt="rita_banner_image_alt"
              class="image-cover lazyload"
            /> -->
            <!-- Video Player -->
            <section class=" w-full">
              <CustomVideoPlayer
                :isAutoPlay="false"
                :options="{
                  accountId: 6222962662001,
                  videoId: 6350144170112,
                  playerId:'3BqAJwkg7',
                  embedId: 'default',
                }"
                :mixpanel="{ type: 'landing', videoId: 6350144170112 }"
              />
            </section>
          </div>
        </div>
      </div>
    </div>
    <!-- 1-2-1 -->
    <div class="w-full bg-neutral-100">
      <div class="container mx-auto px-4 lg:px-40 lg:py-24">
        <div class="flex flex-col ">
          <h4 class="text-blue-800 text-4xl lg:text-6xl font-medium font-serif mb-5 lg:mb-8 text-center">20% off Rita’s courses</h4>
          <p class="text-yellow-800 mx-auto font-medium tracking-wider lg:tracking-widest uppercase leading-6 lg:leading-9 text-lg lg:text-2xl mb-5 text-center max-w-xs px-6 md:px-0 md:max-w-full">USE CODE NEWRITA by 30th April for</p>
          <ul class="max-w-max mx-auto space-y-6 lg:space-y-4 mb-7">
            <li v-for="item in includesData" :key="item.id" class="flex">
              <Icon name="Check" class="lg:text-base fill_none iconBlue mr-4 relative lg:top-1" />
              <span class="text-left font-normal text-grayscale-700 flex-1 text-lg leading-6 lg:text-xl">{{ item.text }}</span>
            </li>
          </ul>
          <p class="text-blue-800 lg:text-grayscale-900 font-medium lg:text-2xl lg:tracking-widest text-center text-lg tracking-wide mb-5 lg:mb-7">
               <Timer targetDateTime="2024-4-30 23:59:59" />
          </p>
          <div class="flex justify-center">
            <!-- <SubscribeButton
                :membershipPlan="membershipPlan"
                :buttonBorder="true"
                component="Rita-konig-courses CTA"
              /> -->
              <PurchaseButton
                @click="onCoursePurchaseActionClick('purchase')"
                :course="course"
                :inCart="course.inCart"
                :gtmPageName="currentPage"
                :abTestVariants="abTestVariants"
                gtmSectionName="Rita Konig"
                class="static w-full lg:mr-6"
                block
                :buttonBorder="false"
                :isTeaser="false"
              />
              </div>
            <!-- <p class="text-blue-800 text-xs lg:text-base mt-2 lg:mt-3 text-center font-normal">(Billed annually) </p> -->
        </div>
      </div>
    </div>

    <!-- course outline -->
    <div class="w-full">
      <div class="container px-4 lg:px-40 lg:pt-24 lg:pb-10">
        <div class="w-full flex flex-col gap-8 lg:gap-14">
          <h4 class=" text-3xl leading-9 lg:text-5xl font-medium font-serif text-blue-800 text-center">Course Outline</h4>
          <div class="flex items-center justify-between lg:gap-14">
            <div class="flex flex-col flex-1 gap-8 items-center lg:items-start">
              <div v-for="item in courseOutline" :key="item.id" class="w-full flex flex-col gap-2 lg:gap-3 text-center lg:text-left pb-8 last:border-b-0 last:pb-0 border-b border-blue-300 max-w-lg">
                <p class="uppercase text-yellow-800 text-xl lg:text-2xl font-medium leading-9 lg:leading-9 tracking-widest">{{ item.main }}</p>
                <p class="text-grayscale-700 text-lg lg:text-2xl leading-9 lg:leading-9 font-normal">{{ item.text }}</p>
              </div>
            </div>
            <div class="w-full flex-1 hidden lg:flex justify-end lg:max-w-xl minWidth-img">
              <img
                loading="lazy"
                :data-src="this.featureBlockTwo.url"
                :srcset="this.featureBlockTwo.srcset"
                alt="course_outline_image_alt"
                class="image-cover lazyload"
              />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--  -->
    <div class="w-full relative bg-yellow-100 lg:bg-white">
      <div class="container px-4 lg:px-32">
        <div class="flex flex-col  md:flex-row flex-wrap items-center justify-center lg:gap-8 gap-6">
          <div class="flex flex-col pa-5 lg:gap-6 flex-1">
            <img
              loading="lazy"
              :data-src="this.createStar"
              alt="CA"
              class="mx-auto lazyload mb-6 hidden md:block"
            />
            <p class=" text-3xl leading-9 lg:text-4xl font-medium font-serif text-blue-800 text-center">2 renovation <br />projects</p>
          </div>
          <div class="flex flex-col pa-5 lg:gap-6 flex-1">
            <img
              loading="lazy"
              :data-src="this.createStar"
              alt="CA"
              class="mx-auto lazyload mb-6"
            />
            <p class=" text-3xl leading-9 lg:text-4xl font-medium font-serif text-blue-800 text-center">10 hours <br /> of lessons</p>
          </div>
          <div class="flex flex-col pa-5 lg:gap-6 flex-1">
            <img
              loading="lazy"
              :data-src="this.createStar"
              alt="CA"
              class="mx-auto lazyload mb-6"
            />
            <p class=" text-3xl leading-9 lg:text-4xl font-medium font-serif text-blue-800 text-center">All Rita’s design <br />materials listed</p>
          </div>
        </div>
      </div>
    </div>

    <!-- subscribe -->
    <div class="w-full relative bg-blue-300">
      <div class="container px-4 lg:px-40 lg:py-20">
        <div class="grid grid-cols-2 gap-8 lg:gap-20 items-center">
          <div class="col-span-2  lg:col-span-1 order-2 lg:order-1">
            <img
              loading="lazy"
              :data-src="this.staticImage.url"
              :srcset="this.staticImage.srcset"
              alt="rita_image_alt"
              class="image-cover lazyload"
            />
            <div class="flex lg:hidden flex-col gap-2 lg:gap-2.5 items-center mt-8 justify-center">
              <!-- <SubscribeButton
                :membershipPlan="membershipPlan"
                :buttonBorder="true"
                component="Rita-konig-courses CTA"
              /> -->
              <PurchaseButton
                @click="onCoursePurchaseActionClick('purchase')"
                :course="course"
                :inCart="course.inCart"
                :gtmPageName="currentPage"
                :abTestVariants="abTestVariants"
                gtmSectionName="Rita Konig"
                class="static w-full lg:mr-6"
                block
                :buttonBorder="false"
                :isTeaser="false"
              />
              <!-- <p class="text-blue-800 text-xs lg:text-base text-center font-normal">(Billed annually) </p> -->
            </div>
          </div>
          <div class="col-span-2  lg:col-span-1 order-1 lg:order-2 lg:pl-10">
            <h4 class="text-center lg:text-left text-3xl leading-9 mb-2 lg:mb-5 lg:text-5xl font-medium font-serif text-blue-800">20% off Rita’s courses</h4>
            <!-- <h4 class="text-center lg:text-left text-3xl leading-9 mb-2 lg:mb-5 lg:text-5xl font-medium font-serif text-blue-800">20% off annual subscription <span class="inline-block lg:block"><span class="italic">with</span> Rita</span></h4> -->
            <p class="text-center lg:text-left lg:text-2xl lg:leading-9 text-grayscale-700 font-normal text-lg leading-8 tracking-wide lg:tracking-normal">Get lifetime access to Rita Konig’s brand new course and and save 20% when you buy before 30th April.  <span class="inline-block">Use code NEWRITA</span></p>
            <p class="mt-10 text-center lg:text-left lg:text-lg lg:leading-9 uppercase text-blue-800-700 font-medium text-sm leading-8 tracking-wide lg:tracking-wider">THE ADVANCED GUIDE TO INTERIOR DESIGN</p>
            <div class="hidden lg:flex mt-8 lg:mt-10 flex-col gap-2 lg:gap-3 items-start justify-start">
             <!-- <SubscribeButton
                :membershipPlan="membershipPlan"
                :buttonBorder="true"
                component="Rita-konig-courses CTA"
              /> -->
              <PurchaseButton
                @click="onCoursePurchaseActionClick('purchase')"
                :course="course"
                :inCart="course.inCart"
                :gtmPageName="currentPage"
                :abTestVariants="abTestVariants"
                gtmSectionName="Rita Konig"
                class="static w-full lg:mr-6"
                block
                :buttonBorder="false"
                :isTeaser="false"
              />
              <!-- <p class="text-blue-800 text-xs lg:text-base text-left font-normal">(Billed annually) </p> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- banner -->
    <div class="w-full bg-blue-500">
      <div class="container px-4 lg:px-40 lg:py-20">
        <div class="flex flex-col items-start gap-6 lg:gap-4">
          <div
            class="font-serif text-8xl text-grayscale-100 transform rotate-180 text-right tracking-tighter mb-0"
          >
            ,,
          </div>
          <div class="-mt-8 lg:-mt-12 text-grayscale-100 text-3xl leading-10 customLeading lg:text-4xl font-medium font-serif">
            I absolutely loved Rita’s course!!  So much inspiration & advice.<br /> Highly recommend!
          </div>
          <img
            loading="lazy"
          :data-src="this.ratingStar"
            alt="rita_banner_image_alt"
            class="lazyload w-auto max-w-min"
          />
        </div>
      </div>
    </div>

    <!-- video section -->
    <div class="w-full bg-yellow-100 relative">
      <div class="container px-4 lg:px-40 lg:py-24">
        <div class="flex flex-col gap-4 lg:gap-1.5">
          <p class="text-blue-800 text-lg tracking-wider font-medium text-center lg:mb-3">COURSE 1</p>
          <h4 class="font-serif lg:text-5xl font-medium text-blue-800 text-3xl leading-9 text-center">The Ultimate Guide To Interior Design</h4>
          <p class="text-center lg:text-2xl font-normal grayscale-700 text-lg tracking-wide ">Subscribe now and get instant access to Rita Konig’s first course. Watch the trailer.</p>
          <div class="w-full mt-2.5 lg:mt-12">
            <VideoPlayer
                data-testid="instructor-video-player"
                class=""
                :isAutoPlay="false"
                :options="{
                  accountId: 6222962662001,
                  videoId: 6264265187001,
                  playerId:'3BqAJwkg7',
                  embedId: 'default',
                }"
                :mixpanel="{ type: 'landing', videoId: 6264265187001 }"
              />
          </div>
        </div>
      </div>
    </div>

    <template v-if="!isSubscribed">
      <div class="landingCat">
        <CategoryMembershipBanner
          v-if="category.showMembershipBanner"
          :category="category"
          :customImage="true"
          :imageUrl="imageUrl"
          :membershipPlan="membershipPlan"
          :geoProps="geoProps"
          component="Category Promotional Banner"
        />
      </div>
    </template>
    <!-- terms -->
    <!-- <div class="w-full relative bg-yellow-100">
      <div class="container px-4 lg:px-40 lg:py-20">
        <p class="text-center max-w-screen-xl lg:leading-10  mx-auto lg:text-2xl font-medium text-grayscale-700 text-lg leading-8">Terms & conditions</p>
        <p class="text-center max-w-screen-xl lg:leading-10  mx-auto lg:text-2xl font-normal text-grayscale-700 text-lg leading-8">Free access to the talk with Rita will be granted to all customers who have an active subscription before midnight 16th April BST. The talk will take place in May via zoom and access details will be shared via email ahead of the event. Data and time of the talk is subject to change.</p>
      </div>
    </div> -->
  </div>
</template>

<script>
// AB Testing bits
import abTest from '@/mixins/abTest'
import gtm from '@/mixins/gtm'
import Icon from '@/Components/Shared/Icon'
import { metaTags } from '@/mixins/metaTags'
import MetaTags from '@/Components/Shared/MetaTags'
import currentUser from '@/mixins/currentUser'
import CustomVideoPlayer from '@/Components/Shared/CustomVideoPlayer'
import VideoPlayer from '@/Components/Shared/VideoPlayer'
import Timer from '@/Components/Shared/Timer'
import mixpanel from '@/mixins/mixpanel'
import CategoryMembershipBanner from '@/Components/Shared/Categories/CategoryMembershipBanner'
// import SubscribeButton from '@/Components/Shared/Courses/SubscribeButton'
import LandingAccessAllCourseImage from '@/assets/images/landing/membershipbanner.webp'
import PurchaseButton from '@/Components/Shared/Courses/PurchaseButton'
export default {
  mixins: [metaTags, currentUser, mixpanel, abTest,gtm],
  components: {
    Icon,
    MetaTags,
    CustomVideoPlayer,
    CategoryMembershipBanner,
    // SubscribeButton,
    VideoPlayer,
    Timer,
    PurchaseButton
  },
  props: {
    course: {
      type: Object,
      required: true
    },
    membershipPlan: {
      type: Object,
      default: null
    },
    category: {
      type: Object,
      required: true
    },
    geoProps: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      imageUrl: LandingAccessAllCourseImage
    }
  },
  computed: {
    currentPage() {
      return 'Rita Konig Landing Page'
    },
    includesData() {
      return [
        {
          id: 1,
          text: '£29 off each of Rita’s courses'
        },
        {
          id: 2,
          text: 'Access to over 16 hours of learning'
        },
        {
          id: 3,
          text: 'Downloadable workbooks and resources'
        },
        {
          id: 4,
          text: 'Lifetime access to each course'
        }
      ]
    },
    courseOutline() {
      return [
        {
          id: 1,
          main: 'Rita’s LONDON HOME',
          text: 'Filmed over two years, learn from Rita as she transforms her London flat in this beautiful renovation project.'
        },
        {
          id: 2,
          main: 'NEW YORK PROJECT',
          text: 'Discover how Rita planned and completed a client project in upstate New York.'
        },
        {
          id: 3,
          main: 'Rita’s Design Materials',
          text: 'Get access to the full schedule from Rita’s home renovation including timelines and suppliers.'
        },
        {
          id: 4,
          main: 'LEARN ADVANCED PRINCIPLES',
          text: 'Understand the finer points of planning a renovation and learn how to avoid numerous pitfalls that can come with such a big project. '
        }
      ]
    }
  },
  created() {
    this.i18nScope = { scope: 'pages.static_course' },

    this.featureBlockOne = {
      url: require('@/assets/images/landing/rita-konig-banner.webp'),
      sizes: {
        large: require('@/assets/images/landing/rita-konig-banner.webp'),
        medium: require('@/assets/images/landing/rita-konig-banner.webp'),
        small: require('@/assets/images/landing/rita-konig-banner.webp')
      }
    }

    this.featureBlockTwo = {
      url: require('@/assets/images/landing/course-outline.webp'),
      sizes: {
        large: require('@/assets/images/landing/course-outline.webp'),
        medium: require('@/assets/images/landing/course-outline.webp'),
        small: require('@/assets/images/landing/course-outline.webp')
      }
    }

    this.staticImage = {
      url: require('@/assets/images/landing/rita-koing-image.webp'),
      sizes: {
        large: require('@/assets/images/landing/rita-koing-image.webp'),
        medium: require('@/assets/images/landing/rita-koing-image.webp'),
        small: require('@/assets/images/landing/rita-koing-image.webp')
      }
    }

    this.createStar = require('@/assets/images/CaStar.svg')
    this.ratingStar = require('@/assets/images/landing/rating.png')
  },
  methods: {
    onCoursePurchaseActionClick(actionType) {
      this.trackEvent('course_purchase_action', {
        userType: this.getUserType(),
        action: actionType,
        course: 'The Ultimate Guide to Interior Design',
        abTest: this.activeAbTest(this.targetTest) // associate this event with the active AB test
      })
    }
  }
}
</script>
