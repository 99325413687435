<template>
  <dialog 
    ref="dialog"
    class="fixed inset-0 bg-transparent p-4"
  >
    <div 
      class="fixed inset-0 bg-black transition-opacity duration-300 ease-in-out"
      :class="isOpen ? 'bg-opacity-20' : 'bg-opacity-0'"
      @click="closeModal"
    ></div>
    <div 
      class="bg-yellow-100 w-full max-w-4xl relative mt-0 flex flex-col mx-auto transform transition-all duration-300 ease-in-out border-6"
      :class="[
        isOpen ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-4',
        isMembershipGift ? 'border-blue-800' : 'border-red-500'
      ]"
    >
      <!-- Close button -->
      <button 
        @click="closeModal"
        class="absolute top-4 right-6 text-2xl text-grayscale-500 hover:text-grayscale-700 transition-colors duration-200 z-10 outline-none focus:outline-none border-none"
        aria-label="Close modal"
      >
        ×
      </button>

      <!-- Content section -->
      <div class="flex-grow">
        <GiftHeader :campaign="campaign" />
        <GiftCard :campaign="campaign" />
        <GiftCode :campaign="campaign" :code="voucherCode" />
        <GiftMessage v-if="message" :message="message" :campaign="campaign" />
      </div>

      <GiftFooter :is-membership-gift="isMembershipGift" />
    </div>
  </dialog>
</template>

<script>
import useGiftType from './Preview/useGiftType'
import GiftHeader from './Preview/GiftHeader'
import GiftCard from './Preview/GiftCard'
import GiftCode from './Preview/GiftCode'
import GiftMessage from './Preview/GiftMessage'
import GiftFooter from './Preview/GiftFooter'

export default {
  components: {
    GiftHeader,
    GiftCard,
    GiftCode,
    GiftMessage,
    GiftFooter
  },

  mixins: [useGiftType],

  props: {
    campaign: {
      type: Object,
      required: true
    },
    message: {
      type: String
    },
    code: {
      type: String
    }
  },

  data() {
    return {
      isOpen: false
    }
  },

  methods: {
    showModal() {
      this.$refs.dialog.showModal()
      setTimeout(() => {
        this.isOpen = true
      }, 50)
    },
    closeModal() {
      this.isOpen = false
      setTimeout(() => {
        this.$refs.dialog.close()
        this.$emit('close')
      }, 300)
    }
  },

  mounted() {
    this.showModal()
  },

  computed: {
    voucherCode() {
      return this.code
        ? this.code
        : this.$I18n.t(`${this.previewScope}.code_example`, this.i18nScope)
    },
    previewScope() {
      return this.isMembershipGift ? 'membership_gift_preview' : 'gift_preview'
    }
  },

  created() {
    this.i18nScope = { scope: 'pages.gifts' }
  }
}
</script>

<style>
dialog::backdrop {
  background: transparent;
}

dialog {
  border: none;
  background: transparent;
  max-width: 100vw;
  max-height: 100vh;
  margin: auto;
}

@keyframes dialogFadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

dialog[open] {
  animation: dialogFadeIn 0.3s ease-in-out;
}
</style>
