<template>
  <component
    :is="tagType"
    class="tags"
    :class="[
      size ? 'tags--' + size : '',
      color && 'tags--' + color,
      {'tags--lower' : lowerCase}
    ]"
  >
    <slot />
  </component>
</template>

<script>
  /**
   * Tags component for Create Academy
   * @displayName Tags
   */
  export default {
    name: 'Tags',

    props: {
      tagType: {
        type: String,
        default: 'p'
      },
      /**
       * The color available
       * @values blue, light, inverse, success, danger
       */
      color: {
        type: String,
        default: 'blue'
      },
      /**
       * The sizes available
       * @values xs, small, large
       */
      size: {
        type: String,
        default: ''
      },
      /**
       * Ability to be lower case
       * @values true, false
       */
      lowerCase: {
        type: Boolean,
        default: false
      }
    }
  }
</script>

<docs>
  ### ✎ Tags

  ```jsx
    <div class="flex flex-row flex-wrap">
      <Tags class="mr-4">New Lessons</Tags>
      <Tags class="mr-4" lowerCase>New Lessons</Tags>
    </div>
  ```

  ---

    ### ✎ Tags Colors

  ```jsx
    <div class="flex flex-row flex-wrap">
      <Tags class="mr-4">New Lessons</Tags>
      <Tags class="mr-4" color="light">New Lessons</Tags>
      <Tags class="mr-4" color="inverse">New Lessons</Tags>
      <Tags class="mr-4" color="success">New Lessons</Tags>
      <Tags color="danger">New Lessons</Tags>
    </div>
  ```

  ---

   ### ✎ Tags Sizes

  ```jsx
    <Tags class="mb-4" size="xs">New Lessons</Tags>
    <Tags class="mb-4" size="small">New Lessons</Tags>
    <Tags class="mb-4">New Lessons</Tags>
    <Tags class="mb-4" size="large">New Lessons</Tags>
  ```

  ---

</docs>
