import AuthModal from '@/Components/Shared/AuthModal'

export default {
  methods: {
    openSignInModal(nextRoute, contentTextKey = undefined) {
      let path = nextRoute || this.$router.currentRoute.path // Use path instead of fullPath
      let queryParameters = `?origin=${encodeURIComponent(path)}`

      if (!this.currentUser) {
        queryParameters += '&authentication=Standard' // Append additional parameters
      }

      this.$modal.show(AuthModal, {
        contentTextKey: contentTextKey,
        next: path,
        queryParameters: queryParameters
      }, {
        width: '95%',
        maxWidth: 500
      })
    }
  }
}
