export const imgSrcset = {

  methods: {

    imgSrcsetBanner(imgObj) {
      let result = {url: imgObj, srcset: []}

      if (typeof imgObj === 'object') {
        result.url = imgObj.url
        if (imgObj.srcset) {
          result.srcset = imgObj.srcset
        } else if (imgObj.sizes) {
          result.srcset = [
            `${imgObj.sizes.large} 1024w`,
            `${imgObj.sizes.medium} 980w`,
            `${imgObj.sizes.small} 768w`
          ]
        }
      }

      return result
    }
  }
}

export default imgSrcset
